<template>
    <v-card
        :elevation="dragover ? 7 : 2"
        :disabled="loading"
        :loading="loading || fetching"
        @drop.prevent="dragover = false"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
    >
        <template slot="progress">
            <v-progress-linear v-if="fetching" indeterminate></v-progress-linear>
            <v-progress-linear v-else v-model="progress" dark height="25">
                <strong>{{ Math.ceil(progress) }}%</strong>
            </v-progress-linear>
        </template>
        <v-card-text>
            <div class="context-title">
                {{ title }} <span v-if="max > 0">{{ getUploadedFilesCount }} / {{ max }}</span>
            </div>
            <div ref="filedrop">
                <v-row v-if="canUpload" class="d-flex flex-column" dense align="center" justify="center">
                    <v-icon :class="[dragover ? 'mt-5, mb-5' : 'mt-5']" size="40" :color="min > 0 ? 'orange' : 'green'">
                        fa-solid fa-cloud-arrow-up
                    </v-icon>
                    <div class="mt-3 mb-5">{{ $t('upload_file_here') }}</div>
                </v-row>
                <v-row v-else class="d-flex flex-column" dense align="center" justify="center">
                    <v-icon :class="'mt-5 mb-5'" size="40" color="green">fa-solid fa-check</v-icon>
                </v-row>
            </div>
            <v-list subheader two-line dense>
                <div>
                    <v-switch
                        v-show="showDeleted"
                        v-model="showDeletedFiles"
                        :label="$t('show_deleted_files')"
                    ></v-switch>
                </div>
                <v-divider v-if="files.models && files.models.length > 0"></v-divider>
                <div v-for="group in getFilesGroupedByParentContext" :key="group.id">
                    <v-subheader>
                        <strong>{{ $t(group.parent_context) }}</strong>
                    </v-subheader>

                    <v-list-item
                        v-for="item in group.files"
                        v-show="!item.deleted || (item.deleted && showDeletedFiles)"
                        :key="item.id"
                        @click.stop="onDownloadDocumentClick(item)"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.filename }}
                                <span class="ml-3 text--secondary"> {{ getFileSize(item.size) }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $t('uploaded_at') }}
                                {{ getDateTime(item.created) }} /
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.deteled">
                                {{ $t('deleted_at') }}
                                {{ getDateTime(item.deleted) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-row dense>
                                <v-col>
                                    <v-btn v-if="!readonly" icon @click.stop="onDeleteFile(item)">
                                        <v-icon color="red">fa-regular fa-trash</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </div>

                <v-divider v-if="files.models && files.models.length > 0"></v-divider>
            </v-list>
        </v-card-text>
        <ConfirmDlg
            v-model="confirmDelDlg"
            :title="$t('rec_remove')"
            :text="$t('confirm_rec_delete')"
            @yes="confirmDestroy"
        />

        <v-dialog :value="showContextDialog" width="350px" scrollable persistent>
            <v-card>
                <slot name="title">
                    <v-card-title class="headline">{{ $t('upload_file') }}</v-card-title>
                </slot>
                <v-card-text>
                    <v-select v-model="context" :items="getContexts" :label="$t('kategorie')" dense></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="action white--text" type="submit" @click="onUploadFile">
                        <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                        {{ $t('upload') }}
                    </v-btn>
                    <v-btn color="decline" text @click="showContextDialog = false"> {{ $t('close') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Flow from '@flowjs/flow.js';
import { getCsrfToken } from '@/helpers/auth.js';
import { mapActions } from 'vuex';
import { buildUrl } from '@/helpers/api.js';
import { intToFilesizeStr } from '@/helpers/tools';
import { errToStr } from '@/helpers/errors';
import dayjs from 'dayjs';
import ConfirmDlg from '@/components/confirm_dialog.vue';
// import { ECH_STATUS } from '@/models/Status';

export default {
    components: {
        ConfirmDlg
    },
    props: {
        ppa: {
            type: Object
        },
        contexts: {
            type: Array,
            default: null
        },
        parentId: {
            type: Number,
            default: 0
        },
        entity: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: true
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 0
        },
        showDeleted: {
            type: Boolean,
            default: false
        },
        decisionRulingId: {
            type: Number,
            default: 0
        }
    },

    data: function () {
        return {
            showContextDialog: false,
            context: 'ohne_kategorie',
            dragover: false,
            files: [],
            movebtn: false,
            loading: false,
            confirmDelDlg: false,
            fileToDelete: null,
            progress: 0,
            fetching: false,
            lastContext: '',
            allowUpload: true,
            showDeletedFiles: false
        };
    },
    computed: {
        title() {
            let result = [];

            this.contexts.forEach((context) => {
                result.push(this.$t(context));
            });

            return result.join(', ');
        },
        getUploadedFilesCount() {
            if (this.files && this.files.models === undefined) {
                return 0;
            }

            let count = 0;
            this.files.models.forEach((file) => {
                if (!file.deleted) {
                    count++;
                }
            });

            return count;
        },
        canUpload() {
            if (this.readonly) {
                return false;
            }

            if (this.max === 0) {
                return true;
            }

            if (this.files && this.files.models === undefined) {
                return true;
            }

            if (this.getUploadedFilesCount < this.max) {
                return true;
            }

            return false;
        },
        getContexts() {
            let result = [];

            this.contexts.forEach((context) => {
                result.push({
                    value: context,
                    text: this.$t(context)
                });
            });

            return result;
        },
        getFilesGroupedByParentContext() {
            let result = {};

            if (this.files && this.files.models && this.files.models.length) {
                this.files.models.forEach((file) => {
                    if (!result[file.parent_context]) {
                        result[file.parent_context] = {
                            parent_context: file.parent_context,
                            files: []
                        };
                    }

                    result[file.parent_context]['files'].push(file);
                });
            }

            return result;
        }
    },
    mounted() {
        this.fetchExistingFiles();
        this.initFlow();
    },
    methods: {
        ...mapActions('document', ['fetchDocuments', 'destroyDocument']),
        getFileSize(size) {
            return intToFilesizeStr(size);
        },
        initFlow() {
            this.flow = new Flow({
                singleFile: false,
                simultaneousUploads: 1,
                target: buildUrl('/document/uploadFile'),
                testChunks: false,
                chunkSize: 1 * 1024 * 1024,
                allowDuplicateUploads: true,
                headers: {
                    'X-CSRF-Token': getCsrfToken()
                }
            });
            this.flow.on('fileAdded', this.onFlowFileAdded.bind(this));

            if (!this.readonly) {
                this.flow.assignBrowse(this.$refs.filedrop);
                this.flow.assignDrop(this.$refs.filedrop);
            }
        },
        async fetchExistingFiles() {
            this.fetching = true;
            let data = await this.fetchDocuments({
                parent_entity: this.entity,
                parent_id: this.parentId,
                contexts: this.contexts,
                decision_ruling_id: this.decisionRulingId
            });
            this.files = data;
            this.fetching = false;
        },
        onDownloadDocumentClick(file) {
            let url = buildUrl('/Document/downloadDocument', {
                document_id: file.id
            });
            window.open(url, 'ebau-dl');
        },

        async onFlowFileAdded(file) {
            if (!this.canUpload || this.readonly) {
                this.$toast({ message: this.$t('no_more_upload_to_category'), color: 'error' });
                return false;
            }
            if (this.contexts && this.contexts.length > 1) {
                this.showContextDialog = true;
            } else if (this.contexts && this.contexts.length === 1) {
                this.context = this.contexts[0];
                this.onUploadFile();
            } else {
                this.context = 'ohne_kategorie';
                this.onUploadFile();
            }
        },

        async onUploadFile() {
            this.showContextDialog = false;

            this.$nextTick(async () => {
                try {
                    await this.uploadFile();
                } catch (err) {
                    try {
                        let json = JSON.parse(err);
                        if (json && json.error) {
                            this.$toast({ message: json.error.msg, color: 'error' });
                        }
                    } catch (innerErr) {
                        this.$toast({ message: this.$t('upload_file_error'), color: 'error' });
                    }
                }
                await this.fetchExistingFiles();
                this.loading = false;
            });
        },

        onDeleteFile(file) {
            this.fileToDelete = file;
            this.confirmDelDlg = true;
        },

        onDrop(e) {
            this.dragover = false;
        },

        async confirmDestroy() {
            try {
                await this.destroyDocument(this.fileToDelete);
                this.fileToDelete = null;
                this.$toast({ message: this.$t('erfolgreich_geloescht') });
                this.fetchExistingFiles();
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },

        uploadFile() {
            return new Promise((resolve, reject) => {
                this.progress = 0;
                this.loading = true;
                this.uploadSize = this.flow.getSize();
                this.flow.opts.query = {
                    parent_entity: this.entity,
                    parent_id: this.parentId,
                    parent_context: this.context,
                    decision_ruling_id: this.decisionRulingId
                };

                let fn = () => {
                    this.flow.off('complete', fn);
                    resolve();
                };

                this.flow.on('complete', fn);

                this.flow.on('error', (message) => {
                    this.flow.cancel();
                    this.flow.off('complete', fn);
                    reject(message);
                });

                this.flow.on('progress', () => {
                    this.progress = Math.round((this.flow.sizeUploaded() / this.flow.getSize()) * 100);
                });

                this.flow.upload();
            });
        },

        getDateTime(date) {
            let str = [];
            str.push(dayjs(date).format('DD.MM.YYYY'));
            str.push(this.$t('at'));
            str.push(dayjs(date).format('HH:mm'));
            return str.join(' ');
        }
    }
};
</script>

<style lang="scss" scoped>
.context-title {
    font-size: 15pt;
    text-align: center;
    font-weight: bold;
}
</style>
