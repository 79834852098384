<template>
    <div>
        <base-remote-list
            session-key="bg-list-remote"
            :maintitle="$t('baugesuche')"
            maintitle-icon-cls="fa-regular fa-map"
            :collection-class="BaugesuchCollection"
            :headers="headers"
            :filters="filters"
            :options="options"
            :extra-params="extraParams"
            :confirm-delete-text="$t('confirm_dossier_destroy')"
            csv-export
            :height="height"
            @loading="loading = $event"
            @edit-item="onRowClicked"
            @new-item="onAdd"
        >
            <template #before-query-field>
                <BauvorhabenArtSelect
                    v-model="bauvorhabenArtFilter"
                    class="filter-field"
                    :hide-details="true"
                    clearable
                    dense
                    hide-selected
                ></BauvorhabenArtSelect>
            </template>
            <template #after-query-field>
                <v-btn rounded class="hidden-xs-only ml-1" color="subtleHint" @click="showTypeChooser = true">
                    <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                    {{ $t('add_baugesuch') }}
                </v-btn>
                <TypeChooser v-if="showTypeChooser" v-model="showTypeChooser" @create="onAdd"></TypeChooser>
            </template>

            <template #item.type="{ item }">
                {{ $t(item.type) }}
            </template>

            <template #[`item.construction_project_type`]="{ item }">
                {{ $t(item.construction_project_type) }}
            </template>

            <template #[`item.application_type`]="{ item }">
                {{ $t(item.application_type) }}
            </template>

            <template #[`item.aktueller_status`]="{ item }">
                <v-chip :class="'status-' + item.aktueller_status_ebau_value.replaceAll(' ', '-')">
                    {{ item.aktueller_status }}
                </v-chip>
            </template>
            <template #[`item.created`]="{ item }">
                {{ formatDateTime(item.created) }}
            </template>
            <template #[`item.deleted`]="{ item }">
                {{ formatDateTime(item.deleted) }}
            </template>

            <template #subtoolbar>
                <v-tabs v-model="filterTab" background-color="primary" dark style="border-top: 2px solid white">
                    <v-tab key="circulation" v-show="user.isRoleFachstelle">
                        <v-icon left>fa-solid fa-gavel</v-icon>
                        {{ $t('baugesuche_circulation') }}
                    </v-tab>
                    <v-tab key="open">
                        <v-icon left>fa-solid fa-lock-open</v-icon>
                        {{ $t('baugesuche_offen') }}
                    </v-tab>
                    <v-tab key="closed">
                        <v-icon left>fa-solid fa-lock</v-icon>
                        {{ $t('baugesuche_abgeschlossen') }}
                    </v-tab>
                </v-tabs>
            </template>
        </base-remote-list>
    </div>
</template>

<script>
import { Baugesuch, BaugesuchCollection } from '@/models/Baugesuch';
import { mapActions, mapState } from 'vuex';
import DateFunctions from '@/mixins/date-functions.js';
import TypeChooser from '@/components/baugesuch/baugesuch-type-chooser.vue';
import BauvorhabenArtSelect from '@/components/bauvorhaben-art-select.vue';

export default {
    components: {
        TypeChooser,
        BauvorhabenArtSelect
    },
    mixins: [DateFunctions],
    props: {
        dense: { type: Boolean, default: false },
        nofooter: { type: Boolean, default: false },
        selectable: { type: Boolean, default: false },
        height: { type: String, default: 'calc(100vh - 340px)' },
        selection: { type: Baugesuch, default: null },
        edit: { type: Boolean, default: true }
    },
    data() {
        return {
            showTypeChooser: false,
            BaugesuchCollection,
            baugesuche: [],
            baugesucheLoading: false,
            calculateWidth: true,
            total: 0,
            toDelete: null,
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['id'],
                sortDesc: [false],
                multiSort: false,
                mustSort: true
            },
            filters: [
                { id: 'type', property: 'type', value: 'bg' },
                {
                    id: 'aktueller_status',
                    property: 'aktueller_status',
                    operator: 'NOT IN',
                    value: ['dossier closed', 'dossier archived']
                }
            ],
            extraParams: {},
            filterTab: -1,
            bauvorhabenArtFilter: null
        };
    },
    computed: {
        ...mapState('user', {
            user: 'record'
        }),
        params() {
            return {
                ...this.options,
                filter: this.filter
            };
        },
        headers() {
            let res = [
                {
                    text: this.$t('id'),
                    value: 'id',
                    sortable: true,
                    filterable: true,
                    width: '55px'
                },
                {
                    text: this.$t('boa_bgnumber'),
                    value: 'boa_bgnumber',
                    sortable: true,
                    filterable: true,
                    width: '85px'
                },
                {
                    text: this.$t('gemeinde'),
                    value: 'stelle',
                    sortable: true,
                    filterable: true,
                    width: '250px'
                },
                {
                    text: this.$t('bauvorhaben_art'),
                    value: 'construction_project_type',
                    sortable: true,
                    filterable: true,
                    width: '200px'
                },
                {
                    text: this.$t('gesuchsart'),
                    value: 'application_type',
                    sortable: true,
                    filterable: true,
                    width: '200px'
                },
                {
                    text: this.$t('status'),
                    value: 'aktueller_status',
                    sortable: true,
                    filterable: true,
                    width: '200px'
                },
                {
                    text: this.$t('created'),
                    value: 'created',
                    sortable: true,
                    filterable: true,
                    width: '80px'
                }
            ];
            if (this.user.isRoleAdmin) {
                res.push({
                    text: this.$t('deleted'),
                    value: 'deleted',
                    sortable: true,
                    filterable: true,
                    width: 150
                });
            }
            if (this.edit) {
                res.push({
                    value: 'actions',
                    sortable: false,
                    filterable: false,
                    width: 100
                });
            }

            return res;
        }
    },
    watch: {
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getBaugesuche();
                }
            },
            deep: true
        },
        filterTab(tab) {
            let remaining = this.filters.filter((e) => e.id !== 'aktueller_status');
            this.filters = remaining;
            this.filters.push({
                id: 'aktueller_status',
                property: 'aktueller_status',
                operator: tab !== 2 ? 'NOT IN' : 'IN',
                value: ['dossier closed', 'dossier archived']
            });
            if (tab === 0) {
                this.extraParams.inCirculation = true;
            } else {
                delete this.extraParams.inCirculation;
            }
        },
        bauvorhabenArtFilter: {
            handler(val) {
                if (val) {
                    var removeIndex = this.filters.map((item) => item.id).indexOf('construction_project_type');
                    if (removeIndex) {
                        this.filters.splice(removeIndex, 1);
                    }

                    this.filters.push({
                        id: 'construction_project_type',
                        property: 'construction_project_type',
                        value: val
                    });
                } else {
                    this.filters = this.filters.filter((item) => {
                        return item.id !== 'construction_project_type';
                    });
                }
            }
        }
    },
    mounted() {
        if (!this.user.isRoleFachstelle) {
            this.filterTab = 1;
        } else {
            this.filterTab = 0;
        }
    },
    methods: {
        ...mapState('baugesuch', {
            baugesuch: 'record'
        }),
        ...mapActions('baugesuch', ['fetchBaugesuche', 'storeBaugesuch', 'destroyBaugesuch']),
        async getBaugesuche() {
            this.baugesucheLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                let data = await this.fetchBaugesuche(this.params);
                this.baugesuche = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.baugesucheLoading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onRowClicked(row) {
            this.showForm(row.id);
        },
        showForm(id) {
            this.$router.push({
                name: 'bg_uebersicht',
                params: { id: id || 'new' }
            });
        },
        async onAdd(value) {
            this.showTypeChooser = false;
            let baugesuch = new Baugesuch({
                id: 'new',
                application_type_id: value
            });
            await this.storeBaugesuch(baugesuch);
            this.$router.push({ name: 'bg_edit_lage', params: { id: baugesuch.id } });
        }
    }
};
</script>

<style scoped>
.fixed-table >>> table {
    table-layout: fixed;
    width: 100%;
}
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    .fixed-table >>> table td {
        text-overflow: ellipsis;
    }
    .fixed-overflow-table >>> table td {
        overflow: hidden;
    }
}
@media screen and (max-width: 600px) {
    .fixed-table >>> table th {
        width: 100%;
    }
}
</style>
