import { instance as api } from '@/helpers/api';

class ParzelleMapClickHandler {
    disabled = false;
    view;
    map;

    constructor(map, view, opts = {}) {
        this.map = map;
        this.view = view;
        if (opts.disabled) {
            this.disable();
        }

        this.map.on('singleclick', this.onMapClick.bind(this));
    }

    disable() {
        this.disabled = true;
    }

    enable() {
        this.disabled = false;
    }

    async onMapClick(e) {
        if (this.disabled) {
            return;
        }

        var coords = e.coordinate;
        try {
            let res = await api().post('/Realestate/getByCoord', {
                x: coords[0],
                y: coords[1]
            });
            if (res.data && res.data.success) {
                this.view.$emit('parzelleClicked', res.data.record);
            }
            return null;
        } finally {
            //do nothing
        }
    }
}

export default ParzelleMapClickHandler;
