import { getI18nInstance } from '@/i18n';

/**
 * Tries to make sense of the given "Error", which may be a string,
 * an error object, some kind of response.... you name it.
 * @param {Something} error The error "thingie"
 * @return A string representing the error msg.
 */
export function errToStr(error) {
    const vueI18n = getI18nInstance();
    let msg = vueI18n.t('unknown_error');

    if (error) {
        // Try to extract a data property:
        let data =
            (error.response && error.response.data) ||
            (error.response && error.response.response && error.response.response.data);

        if (data && data.error && data.error.msg) {
            msg = `${data.error.code}: ${data.error.msg}`;
        } else if (error.response && error.response.responseText) {
            let obj = JSON.parse(error.response.responseText);
            if (obj && obj.error && obj.error.msg) {
                msg = `${obj.error.code}: ${obj.error.msg}`;
            } else {
                msg = String(error.response.responseText);
            }
        } else if (error.responseText) {
            let obj = JSON.parse(error.responseText);
            if (obj && obj.error && obj.error.msg) {
                msg = `${obj.error.code}: ${obj.error.msg}`;
            } else {
                msg = String(error.responseText);
            }
        } else if (typeof error === 'string') {
            try {
                let obj = JSON.parse(error);
                if (obj && obj.error && obj.error.msg) {
                    msg = `${obj.error.code}: ${obj.error.msg}`;
                } else {
                    msg = String(error);
                }
            } catch (x) {
                msg = String(error);
            }
        } else {
            msg = String(error);
        }
    }
    return msg;
}
