import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/auth/Home.vue';
import Admin from '@/views/auth/Admin.vue';
import { getStore } from '@/store/index';

import AdminMail from '@/views/auth/system/Mail.vue';
import AdminNews from '@/views/auth/system/News.vue';
import AdminNewsForm from '@/views/auth/system/NewsForm.vue';

import guestRoutes from './guest_routes';
import baugesuchRoutes from './baugesuche_routes';
import planungsgeschaefteRoutes from './planungsgeschaefte_routes';
import usersRoutes from './users_routes';
import stelleRoutes from './stelle_routes';
import { apiUrl } from '@/helpers/globals';

Vue.use(VueRouter);

const authRoutes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        meta: {
            requiresAuth: true,
            role: ['admin']
        },
        children: [
            {
                name: 'admin_mail',
                path: 'mail',
                component: AdminMail
            },
            {
                name: 'admin_news',
                path: 'news',
                component: AdminNews
            },
            {
                name: 'admin_news_form',
                path: 'news/:id',
                component: AdminNewsForm
            }
        ]
    }
];

const routes = [
    ...guestRoutes,
    ...authRoutes,
    ...baugesuchRoutes,
    ...planungsgeschaefteRoutes,
    ...usersRoutes,
    ...stelleRoutes
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // Auth requiring routes need an auth user:
    if (to.matched.some((rec) => rec.meta.requiresAuth)) {
        if (!getStore().getters.isAuthenticated) {
            if (to.name === 'home') {
                return next({
                    name: 'welcome'
                });
            }
            let fromUrl = window.location.href;
            window.location.href = apiUrl('/oidc/login', { url: encodeURIComponent(fromUrl) });
            return;
            /*return next({
                name: 'login',
                query: {
                    redirect: to.path
                }
            });*/
        } else {
            // Check if the route needs specific privileges
            const user = getStore().getters.user;

            let allRoutesAllowed =
                user.isAdmin ||
                to.matched.every((routeRec) => {
                    if (routeRec.meta.roles && routeRec.meta.roles.length > 0) {
                        for (let neededRole of routeRec.meta.roles) {
                            switch (neededRole) {
                                case 'admin':
                                    return user.isAdmin;
                                default:
                                    return false;
                            }
                        }
                        // no role matches
                        return false;
                    } else {
                        // no roles needed
                        return true;
                    }
                });
            if (allRoutesAllowed) {
                return next();
            } else {
                // no access, abort
                return next(false);
            }
        }
    }

    // guest routes can only be accessed when not auth:
    if (to.matched.some((rec) => rec.meta.guest)) {
        if (getStore().getters.isAuthenticated) {
            return next({
                name: 'home'
            });
        } else {
            return next();
        }
    }

    // If you come this far, the route can be displayed in either case:
    return next();
});

router.afterEach((to, from) => {
    // see https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609 why we use
    // nextTick here:
    Vue.nextTick(() => {
        // Update the page's title, based on the Route's meta data, if applicable:
        let title = (to.meta && to.meta.title) || null;
        if (title instanceof Function) {
            title = title(to, from);
        }
        document.title = (title || '') + (title ? ' - eBau' : 'eBau');
    });
});

export default router;
