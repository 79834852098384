<template>
    <div ref="map-root" style="width: 100%; height: 500px; position: relative">
        <v-autocomplete
            v-model="searchModel"
            class="search-field"
            dense
            solo
            :items="searchItems"
            :loading="loading"
            :search-input.sync="searchInput"
            clearable
            background-color="white"
            hide-no-data
            no-filter
            item-text="label"
            placeholder="Suche..."
            prepend-inner-icon="fa-solid fa-magnifying-glass"
            return-object
            @click:clear="searchItems = []"
        >
            <template #item="props">
                <div v-html="props.item.label" />
            </template>
            <template #selection="props">
                <div v-html="props.item.label" />
            </template>
        </v-autocomplete>
        <v-card class="layer-card" width="17em">
            <v-card-title style="display: flow-root">
                <v-btn icon @click.stop="layerChooserDlg = true">
                    <v-icon>fa-solid fa-layer-group</v-icon>
                </v-btn>
                <span style="float: left">{{ $t('maps') }}</span>
                <v-btn text icon style="float: right" :class="{ active: drawer }" @click.stop="drawer = !drawer">
                    <v-icon>fa-solid fa-chevron-down</v-icon>
                </v-btn>
            </v-card-title>
            <v-expand-transition>
                <v-list v-show="drawer" subheader two-line flat dense class="layer-list">
                    <draggable
                        v-model="layerOrderingArray"
                        group="layer"
                        @update="reorderLayers"
                        @start="drag = true"
                        @end="drag = false"
                    >
                        <v-list-item v-for="layer in layerOrderingArray" :key="layer.serverLayerName">
                            <v-list-item-content>
                                <v-list-item-title>{{ layer.label }}</v-list-item-title>
                                <v-slider
                                    dense
                                    max="100"
                                    min="0"
                                    value="100"
                                    @input="(o) => layerMap[layer.serverLayerName].setOpacity(o / 100)"
                                ></v-slider>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-switch
                                    v-model="layer.visible"
                                    inset
                                    @change="(v) => layerMap[layer.serverLayerName].setVisible(v)"
                                ></v-switch>
                            </v-list-item-action>
                        </v-list-item>
                    </draggable>
                </v-list>
            </v-expand-transition>
        </v-card>
        <v-speed-dial
            v-model="baseMapSelector"
            class="baselayer-card"
            bottom
            right
            direction="left"
            open-on-hover
            transition="slide-x-reverse-transition"
        >
            <template #activator>
                <v-btn v-model="baseMapSelector" color="white" dark fab>
                    <img :src="mapCombiImg" height="50" alt="Kartenauswahl" class="map-image" />
                </v-btn>
            </template>
            <v-btn
                v-for="layer in baseLayers"
                :key="layer.serverLayerName"
                fab
                dark
                :color="activeBaseLayer === layer.serverLayerName ? 'red' : 'white'"
                @click="selectBaseLayer(layer)"
            >
                <img :src="getImageSource(layer.thumb_mobile)" height="50" alt="Karte" class="map-image" />
            </v-btn>
            <v-btn fab dark :color="activeBaseLayer === null ? 'red' : 'white'" @click="selectBaseLayer(null)">
                <img :src="getImageSource('resources/img/voidlayer_thumb_mobile.png')" height="50" alt="Karte" />
            </v-btn>
        </v-speed-dial>
        <layerchooser v-if="layerChooserDlg" :value="layerChooserDlg" @close="onLayerChooserDlgClose"> </layerchooser>
    </div>
</template>

<script>
import View from 'ol/View';
import OlMap from 'ol/Map';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Style, Text } from 'ol/style';
import { mapActions } from 'vuex';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { getCenter } from 'ol/extent';
import { TileWMS, Vector, WMTS } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { defaults as defaultControls } from 'ol/control.js';
import { get as getProjection } from 'ol/proj';
import { registerProjections } from '@/helpers/olHelper';
import { MapCombi, Swissimage2019, BasemapFarbig } from '@/helpers/images';
import draggable from 'vuedraggable';
import layerchooser from '@/components/map/layerchooser';
import ParzelleMapClickHandler from '@/ol/handlers/ParzelleMapClickHandler';
import WKB from 'ol/format/WKB';
// importing the OpenLayers stylesheet is required for having
import Fill from 'ol/style/Fill';
// good looking buttons!
import 'ol/ol.css';

export default {
    name: 'MapContainer',
    components: { draggable, layerchooser },
    props: {
        deliverParzelleOnClick: {
            type: Boolean,
            default: false
        },
        hoverdRealestate: {
            type: Object,
            default: () => {
                return {};
            }
        },
        mainRealestates: {
            type: Object,
            default: () => {
                return {};
            }
        },
        neighbours: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            drawer: false,
            loading: false,
            valid: false,
            layers: [],
            baseLayers: [],
            layerOrderingArray: [],
            zoom: 2,
            center: getCenter([2680000, 1246000, 2756000, 1285000]),
            rotation: 0,
            parser: null,
            olView: null,
            olMap: null,
            layerMap: {},
            baseMapSelector: false,
            mapCombiImg: MapCombi,
            activeBaseLayer: null,
            drag: false,
            layerChooserDlg: false,
            imageMap: {
                swissimage2019: Swissimage2019,
                basemap_farbig: BasemapFarbig
            },
            searchModel: null,
            searchItems: [],
            searchInput: null,
            searchResultLayer: null,
            searchFeature: null,
            activeRealestateLayer: null,
            neighboursLayer: null,
            activeRealestateFeature: null
        };
    },
    _mapClickHandler: null,
    watch: {
        deliverParzelleOnClick: {
            handler: function (value) {
                if (value) {
                    this._mapClickHandler.enable();
                } else {
                    this._mapClickHandler.disable();
                }
            }
        },
        async searchInput(val) {
            let res = await this.search({ searchText: val });
            let mappedValues = res ? res.map((e) => e.attrs) : [];
            this.searchItems = mappedValues;
            this.isLoading = false;
        },
        searchModel(val) {
            if (val) {
                this.olView.setCenter([val.y, val.x]);
                this.olView.setZoom(val.zoomlevel + 2);
                this.searchFeature.getGeometry().setCoordinates([val.y, val.x]);
                this.olMap.removeLayer(this.searchResultLayer);
                this.olMap.addLayer(this.searchResultLayer);
                this.searchResultLayer.setZIndex(1000);
            } else {
                this.olMap.removeLayer(this.searchResultLayer);
                this.searchItems = [];
            }
        },
        mainRealestates(val) {
            this.activeRealestateLayer.getSource().clear(true);
            this.olMap.removeLayer(this.activeRealestateLayer);
            let wkb = new WKB({ hex: true, ewkb: true });
            let features = [];

            for (const realestate of val.models) {
                let style = new Style({
                    fill: new Fill({
                        color: 'rgba(0,0,255,0.4)'
                    }),
                    text: new Text({
                        font: '16px Arial,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        text: realestate.nummer
                    })
                });

                let feature = wkb.readFeature(realestate.wkb_geometry);

                feature.setStyle(style);
                features.push(feature);
            }

            this.activeRealestateLayer.getSource().addFeatures(features);
            this.activeRealestateLayer.setZIndex(2000);
            this.olMap.addLayer(this.activeRealestateLayer);
            if (val.models && val.models.length > 0) {
                let centerWkb = new WKB({ hex: true, ewkb: true });
                let geom = centerWkb.readGeometry(val.models[0].center_box);
                this.olView.fit(geom.getExtent());
            }
        },

        neighbours(val) {
            this.neighboursLayer.getSource().clear(true);
            this.olMap.removeLayer(this.neighboursLayer);
            let wkb = new WKB({ hex: true, ewkb: true });
            // let geoms = val.models.map((g) => g.wkb_geometry);
            let features = [];

            for (const realestate of val.models) {
                let feature = wkb.readFeature(realestate.wkb_geometry);

                let style = new Style({
                    fill: new Fill({
                        color: 'rgba(255,0,0,0.4)'
                    })
                });
                if (realestate.is_manually_added_neighbour) {
                    style = new Style({
                        fill: new Fill({
                            color: 'rgba(255,255,0,0.4)'
                        })
                    });
                }

                style.setText(
                    new Text({
                        font: '16px Arial,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        text: realestate.nummer
                    })
                );

                feature.setStyle(style);
                features.push(feature);
            }

            this.neighboursLayer.getSource().addFeatures(features);
            this.neighboursLayer.setZIndex(2000);
            this.olMap.addLayer(this.neighboursLayer);
        }
    },
    mounted() {
        // this is where we create the OpenLayers map
        let proj = getProjection('EPSG:2056');
        proj.setExtent([2680000, 1246000, 2756000, 1285000]);
        this.olView = new View({
            zoom: this.zoom,
            center: this.center,
            constrainResolution: true,
            projection: proj
        });

        this.olMap = new OlMap({
            target: this.$refs['map-root'],
            layers: [],
            view: this.olView,
            controls: defaultControls({ attributionOptions: { collapsible: false } })
        });
        this.parser = new WMTSCapabilities();

        this._mapClickHandler = new ParzelleMapClickHandler(this.olMap, this, { disabled: true });

        this.createSearchResultLayer();
        this.createSelectedRealestateLayer();
    },
    async created() {
        registerProjections();
        await this.loadLayers();
    },
    methods: {
        ...mapActions('planning_permission_application', ['fetchLayers', 'fetchBaseLayers', 'search']),

        getExtent() {
            return this.olView.calculateExtent(this.olMap.getSize());
        },

        reorderLayers() {
            let idx = Object.keys(this.layerMap).length;
            for (const layer of this.layerOrderingArray) {
                this.layerMap[layer.serverLayerName].setZIndex(idx--);
            }
            this.searchResultLayer.setZIndex(1000);
        },

        addWMSLayer(layerProps, layerIndex) {
            let proj = getProjection(layerProps.projection);
            let wmsLayer = new TileLayer({
                opacity: 1,
                visible: layerProps.visible,
                zIndex: layerIndex,
                source: new TileWMS({
                    params: {
                        LAYERS: layerProps.wmsLayers,
                        LANG: 'de'
                    },
                    serverType: 'geoserver',
                    transition: 0,
                    attributions: [layerProps.attribution, layerProps.attributionUrl],
                    format: layerProps.format,
                    layer: layerProps.serverLayerName,
                    url: layerProps.wmsUrl,
                    projection: proj,
                    crossOrigin: 'Anonymous'
                })
            });
            this.layerMap[layerProps.serverLayerName] = wmsLayer;
            this.olMap.addLayer(wmsLayer);
        },

        addWMTSLayer(layerProps, layerIndex) {
            let proj = getProjection(layerProps.projection);
            const matrixIds = [];
            for (let i = 0; i <= layerProps.resolutions.length; i++) {
                matrixIds[i] = i;
            }
            const tileGrid = new WMTSTileGrid({
                origin: [2680000, 1300000],
                extent: proj.getExtent(),
                resolutions: layerProps.resolutions,
                matrixIds: matrixIds
            });
            let wmtsLayer = new TileLayer({
                opacity: 1,
                visible: layerProps.visible,
                zIndex: layerIndex,
                source: new WMTS({
                    attributions: layerProps.attribution,
                    format: layerProps.format,
                    layer: layerProps.serverLayerName,
                    matrixSet: layerProps.tileMatrixSet,
                    resolutions: layerProps.resolutions,
                    requestEncoding: 'REST',
                    tileGrid: tileGrid,
                    transition: 0,
                    url: layerProps.urlTemplate
                })
            });
            this.layerMap[layerProps.serverLayerName] = wmtsLayer;
            this.olMap.addLayer(wmtsLayer);
        },

        async loadLayers() {
            let layers = await this.fetchLayers();
            this.layers = layers || [];

            let layerKeys = Object.keys(this.layers);
            let toBeAdded = layerKeys.filter(
                (lk) => this.layerOrderingArray.filter((l) => l.serverLayerName === lk).length === 0
            );
            let toBeRemoved = this.layerOrderingArray.filter((lk) => !layerKeys.includes(lk.serverLayerName));

            for (const layer of toBeRemoved) {
                let wmtsLayer = this.layerMap[layer.serverLayerName];
                let removed = this.olMap.removeLayer(wmtsLayer);
                if (removed) {
                    this.layerOrderingArray = this.layerOrderingArray.filter(
                        (lk) => lk.serverLayerName !== layer.serverLayerName
                    );
                }
            }
            this.reorderLayers();

            let idx = 1000;
            for (const layer in this.layers) {
                let layerProps = this.layers[layer];
                if (toBeAdded.includes(layerProps.serverLayerName)) {
                    this.layerOrderingArray.push(layerProps);
                    if (layerProps.type === 'wmts') {
                        this.addWMTSLayer(layerProps, idx--);
                    } else if (layerProps.type === 'wms') {
                        this.addWMSLayer(layerProps, idx--);
                    }
                }
            }

            let baseLayers = await this.fetchBaseLayers();
            this.baseLayers = baseLayers || [];
            for (const layer in this.baseLayers) {
                let layerProps = this.baseLayers[layer];
                if (layerProps.type === 'wmts') {
                    this.addWMTSLayer(layerProps, 0);
                } else if (layerProps.type === 'wms') {
                    this.addWMSLayer(layerProps, 0);
                }
                if (layerProps.visible) {
                    this.activeBaseLayer = layerProps.serverLayerName;
                }
            }
        },

        getImageSource(url) {
            return (
                this.$store.state.globals.mapurl +
                '/layersConfig/thumb/' +
                (url || 'resources/img/orthofoto_thumb_mobile.png')
            );
        },

        selectBaseLayer(layer) {
            for (const l in this.baseLayers) {
                this.layerMap[l].setVisible(layer && l === layer.serverLayerName);
            }
            this.activeBaseLayer = layer ? layer.serverLayerName : null;
        },
        onLayerChooserDlgClose() {
            this.layerChooserDlg = false;
            this.loadLayers();
        },

        createSearchResultLayer() {
            this.searchResultLayer = new VectorLayer({
                source: new Vector(),
                title: 'suche'
            });

            this.searchFeature = new Feature({
                geometry: new Point([0, 0])
            });
            this.searchFeature.setStyle(
                new Style({
                    text: new Text({
                        text: '\uf3c5', // fa-map-marker-alt, unicode f04b
                        font: '900 30px "Font Awesome 5 Free"', // font weight must be 900
                        fill: new Fill({ color: '#FF0000' }),
                        textBaseline: 'bottom'
                    })
                })
            );

            this.searchResultLayer.getSource().addFeature(this.searchFeature);
        },

        createSelectedRealestateLayer() {
            this.activeRealestateLayer = new VectorLayer({
                source: new Vector(),
                title: 'mainRealestates',
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255,0,0,0.4)'
                    })
                })
            });

            this.neighboursLayer = new VectorLayer({
                source: new Vector(),
                title: 'neighbours',
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(0,0,255,0.4)'
                    })
                })
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.layer-card {
    position: absolute;
    z-index: 2;
    right: 0em;
}

.search-field {
    position: absolute;
    z-index: 2;
    top: 0.3rem;
    width: 30vw;
    left: 3rem;
    opacity: 0.9;
}

.map-image {
    border-radius: 25px;
}

.baselayer-card {
    position: absolute;
    bottom: 2em;
    right: 0.5em;
}

.v-btn.active .v-icon {
    transform: rotate(-180deg);
}

.layer-list {
    overflow-y: auto;
    max-height: 400px;
}
</style>
