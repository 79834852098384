/**
 * Store module: Building
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { Building, BuildingCollection } from '@/models/Building';
import { instance as getApi } from '@/helpers/api';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchBuildings({ commit }, options = {}) {
            let list = new BuildingCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadBuilding({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new Building();
                } else {
                    record = new Building({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storeBuilding({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyBuilding({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async createBuildingTree({ commit }, options = {}) {
            try {
                let res = await getApi().post('/Building/createBuildingTree', options);
                if (res && res.data && res.data.data) {
                    return res.data.data;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async getBuildingTree({ commit }, options = {}) {
            try {
                let res = await getApi().post('/Building/getBuildingTree', options);
                if (res && res.data && res.data.data) {
                    return res.data.data;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async getHeizungsDaten({ commit }, options = {}) {
            try {
                let res = await getApi().post('/Building/getHeizungsDaten', options);
                if (res && res.data && res.data.data) {
                    return res.data.data;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        }
    }
};
