<template>
    <div>
        <base-remote-list
            session-key="user-list-remote"
            :maintitle="$t('users')"
            maintitle-icon-cls="fa-regular fa-user"
            :collection-class="UserCollection"
            :headers="headers"
            :filters="filters"
            :extra-params="extraParams"
            :options="{ sortBy: ['username'] }"
            csv-export
            @loading="loading = $event"
            @edit-item="onEdit"
            @new-item="onAdd"
        >
            <template #before-query-field>
                <v-btn-toggle v-model="activeFilter" borderless tile background-color="primary">
                    <IconBtn
                        value="active"
                        color="action"
                        :disabled="loading"
                        icon-cls="fa-solid fa-check"
                        :tooltip="$t('aktiv')"
                    />
                </v-btn-toggle>
            </template>
            <template #after-query-field>
                <v-btn rounded class="hidden-xs-only ml-1" color="subtleHint" @click="onAdd">
                    <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                    {{ $t('add_user') }}
                </v-btn>
            </template>

            <template #[`item.ist_admin`]="{ item }">
                <span> <v-icon v-if="item.ist_admin" color="green" small> fa-solid fa-check </v-icon> </span>
            </template>
        </base-remote-list>
    </div>
</template>
<script>
import { UserCollection } from '@/models/User';
import sessionStorageMixin from '@/mixins/session-storage';

export default {
    name: 'UserIndexRemote',
    components: {},
    mixins: [sessionStorageMixin],
    data() {
        this.sessionKey = 'user-index-remote';
        return {
            UserCollection,
            loading: false,
            headers: [
                {
                    text: this.$t('username'),
                    value: 'username',
                    sortable: true,
                    filterable: true,
                    width: '15%'
                },
                {
                    text: this.$t('name_vorname'),
                    value: 'nachname_vorname',
                    sortable: true,
                    filterable: true,
                    width: '15%'
                },
                {
                    text: this.$t('email'),
                    value: 'email',
                    sortable: true,
                    filterable: true,
                    width: '20%'
                },
                {
                    text: this.$t('gemeinde'),
                    value: 'gemeinden',
                    sortable: true,
                    filterable: true,
                    width: '20%'
                },
                {
                    text: this.$t('fachstelle'),
                    value: 'fachstellen',
                    sortable: true,
                    filterable: true,
                    width: '20%'
                },
                {
                    text: this.$t('admin'),
                    value: 'ist_admin',
                    sortable: true,
                    filterable: true,
                    width: '5%'
                },
                {
                    text: 'Aktionen',
                    sortable: false,
                    width: 120,
                    value: 'actions'
                }
            ],

            activeFilter: this.getSessionValue('activeFilter') || null
        };
    },
    computed: {
        // gewisse Parameter können / müssen als Filter-Array mitgegeben wrden.
        // Hier werden diese aufbereitet für die Remote List:
        filters() {
            const filters = [];
            if (this.activeFilter === 'active') {
                filters.push({ property: 'active', value: true });
            }
            if (this.activeFilter === 'inactive') {
                filters.push({ property: 'active', value: false });
            }
            return filters;
        },

        // gewisse Parameter können / müssen als ExtraParam mitgegeben werden.
        // Hier werden diese aufbereitet für die Remote List:
        extraParams() {
            const extraParams = {};
            // const enhancedFilters = {};

            // for (let key in this.enhancedFilters) {
            //     if (this.enhancedFilters[key]) {
            //         enhancedFilters[key] = this.enhancedFilters[key];
            //     }
            // }
            // extraParams.enhancedFilters = enhancedFilters;
            return extraParams;
        }
    },
    watch: {
        activeFilter(val) {
            if (val) {
                this.setSessionValue('activeFilter', !!val);
            } else {
                this.clearSessionValue('activeFilter');
            }
        }
    },
    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onAdd() {
            this.showForm('new');
        },

        onEdit(rec) {
            if (rec) {
                this.showForm(rec.id);
            }
        },

        showForm(id) {
            this.$router.push({
                name: 'user_form',
                params: { id: id || 'new' }
            });
        }
    }
};
</script>
