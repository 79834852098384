<template>
    <v-dialog :value="show" width="800px" scrollable persistent>
        <v-card max-height="750">
            <slot name="title">
                <v-card-title v-if="record" class="headline">{{ $t('edit_person') }}</v-card-title>
                <v-card-title v-else class="headline">{{ $t('new_person') }}</v-card-title>
            </slot>
            <v-card-text>
                <v-form v-if="record" v-model="valid">
                    <v-row no-gutters>
                        <RolleSelect
                            v-model="record.role"
                            :label="$t('rolle')"
                            :context="context"
                            required
                            :readonly="readonly"
                        ></RolleSelect>
                    </v-row>
                    <v-row
                        v-if="
                            context === 'bg' &&
                            record.role &&
                            record.role !== 'applicant' &&
                            firstGesuchssteller &&
                            !record.id
                        "
                        no-gutters
                    >
                        <v-checkbox
                            v-model="sameAsCreator"
                            :readonly="readonly"
                            :label="$t('sameAsCreator')"
                        ></v-checkbox>
                    </v-row>
                    <v-row no-gutters>
                        <v-radio-group
                            v-model="record.address_type"
                            :disabled="sameAsCreator"
                            :readonly="readonly"
                            column
                        >
                            <v-radio :label="$t('person')" value="person"></v-radio>
                            <v-radio :label="$t('organisation')" value="organisation"></v-radio>
                        </v-radio-group>
                    </v-row>
                    <v-row v-if="record.address_type === 'organisation'" no-gutters>
                        <v-col>
                            <v-text-field
                                v-model="record.organisation_name"
                                :disabled="sameAsCreator"
                                :label="$t('organisation')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="record.address_type !== 'organisation'" no-gutters>
                        <v-col>
                            <v-text-field
                                v-model="record.last_name"
                                :disabled="sameAsCreator"
                                :label="$t('name')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="record.first_name"
                                :disabled="sameAsCreator"
                                :label="$t('vorname')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="10">
                            <v-text-field
                                v-model="record.street"
                                :disabled="sameAsCreator"
                                :label="$t('strasse')"
                                :readonly="readonly"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="record.house_number"
                                :disabled="sameAsCreator"
                                :label="$t('nummer')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-text-field
                                v-model="record.swiss_zip_code"
                                :disabled="sameAsCreator"
                                :label="$t('plz')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                v-model="record.town"
                                :disabled="sameAsCreator"
                                :label="$t('ort')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="record.address_type !== 'organisation'" no-gutters>
                        <v-col>
                            <v-text-field
                                v-model="record.phone"
                                :disabled="sameAsCreator"
                                :label="$t('telefon') + ' / ' + $t('mobile')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="record.email"
                                :disabled="sameAsCreator || emailLocked"
                                :label="$t('email')"
                                :readonly="readonly"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card v-if="record.address_type === 'organisation'">
                    <v-card-title>{{ $t('ansprechperson') }}</v-card-title>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    v-model="record.last_name"
                                    :disabled="sameAsCreator"
                                    :label="$t('nachname')"
                                    :readonly="readonly"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="record.first_name"
                                    :disabled="sameAsCreator"
                                    :label="$t('vorname')"
                                    :readonly="readonly"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    v-model="record.phone"
                                    :disabled="sameAsCreator"
                                    :label="$t('telefon') + ' / ' + $t('mobile')"
                                    :readonly="readonly"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="record.email"
                                    :disabled="sameAsCreator"
                                    :label="$t('email')"
                                    :readonly="readonly"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="!readonly" color="decline" text @click="applyOwnUserData">{{
                    $t('apply_own_user_data')
                }}</v-btn>
                <v-spacer />
                <v-btn v-if="!readonly" color="action white--text" type="submit" :disabled="!isValid" @click="onSave">
                    <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                    {{ $t('save') }}
                </v-btn>
                <v-btn color="decline" text @click="$emit('close')">{{ $t('close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formRules } from '@/helpers/forms.js';

import RolleSelect from '@/components/rolle-select.vue';

export default {
    components: {
        RolleSelect
    },
    props: {
        relationshipToPerson: {
            type: Object
        },
        show: {
            type: Boolean,
            default: false
        },
        context: {
            type: String,
            default: 'pg'
        },
        firstGesuchssteller: {
            type: Object,
            default: () => {}
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            record: this.relationshipToPerson,
            valid: true,
            sameAsCreator: false,
            emailLocked: false,
            formRules
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.record
        }),
        isValid() {
            return this.record.role !== null;
        }
    },
    watch: {
        relationshipToPerson: {
            immediate: true,
            handler(value, oldValue) {
                this.record = value;
            }
        },
        sameAsCreator: {
            handler(value) {
                if (value && this.firstGesuchssteller) {
                    this.record.address_type = this.firstGesuchssteller.address_type;
                    this.record.mr_mrs = this.firstGesuchssteller.mr_mrs;
                    this.record.title = this.firstGesuchssteller.title;
                    this.record.first_name = this.firstGesuchssteller.first_name;
                    this.record.last_name = this.firstGesuchssteller.last_name;
                    this.record.address_line_1 = this.firstGesuchssteller.address_line_1;
                    this.record.address_line_2 = this.firstGesuchssteller.address_line_2;
                    this.record.street = this.firstGesuchssteller.street;
                    this.record.house_number = this.firstGesuchssteller.house_number;
                    this.record.dwelling_number = this.firstGesuchssteller.dwelling_number;
                    this.record.post_office_box = this.firstGesuchssteller.post_office_box;
                    this.record.post_office_box_text = this.firstGesuchssteller.post_office_box_text;
                    this.record.swiss_zip_code = this.firstGesuchssteller.swiss_zip_code;
                    this.record.swiss_zip_code_add_on = this.firstGesuchssteller.swiss_zip_code_add_on;
                    this.record.swiss_zip_code_id = this.firstGesuchssteller.swiss_zip_code_id;
                    this.record.foreign_zip_code = this.firstGesuchssteller.foreign_zip_code;
                    this.record.locality = this.firstGesuchssteller.locality;
                    this.record.town = this.firstGesuchssteller.town;
                    this.record.country_id = this.firstGesuchssteller.country_id;
                    this.record.organisation_name = this.firstGesuchssteller.organisation_name;
                    this.record.organisation_name_add_on_1 = this.firstGesuchssteller.organisation_name_add_on_1;
                    this.record.organisation_name_add_on_2 = this.firstGesuchssteller.organisation_name_add_on_2;
                    this.record.email = this.firstGesuchssteller.email;
                    this.record.phone = this.firstGesuchssteller.phone;
                }
            }
        }
    },
    methods: {
        ...mapState('relationship_to_person', {
            relationship_to_person: 'record'
        }),
        ...mapActions('relationship_to_person', ['storeRelationshipToPerson']),
        async onSave() {
            await this.storeRelationshipToPerson(this.record);
            this.$emit('saved');
        },
        applyOwnUserData() {
            this.record.email = this.record.email || this.user.email;
            this.record.first_name = this.record.first_name || this.user.vorname;
            this.record.last_name = this.record.last_name || this.user.nachname;

            if (this.record.address_type === 'organisation') {
                this.record.organisation_name = this.record.organisation_name || this.user.active_role_stelle;
                this.record.street = this.record.street || this.user.active_role_stelle_street;
                this.record.house_number = this.record.house_number || this.user.active_role_stelle_house_number;
                this.record.swiss_zip_code = this.record.swiss_zip_code || this.user.active_role_stelle_swiss_zip_code;
                this.record.town = this.record.town || this.user.active_role_stelle_town;
            }
        }
    }
};
</script>
