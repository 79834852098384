<template>
    <div>
        <div ref="map-root" style="position: relative; height: 200px; width: 300px; border: 1px solid black"></div>
        <div v-if="stelleCollection" no-gutters class="d-flex flex-wrap">
            <div
                v-for="stelle in activeStellen"
                :key="stelle.id + '_static'"
                cols="3"
                :class="['ma-2', stelle.bfsnr === selectedBfs ? 'selectedGemeinde' : '']"
            >
                <v-card v-if="stelle.active" elevation="0" outlined width="205px" height="130px">
                    <v-card-title>
                        {{ stelle.organisation_name }}
                    </v-card-title>
                    <v-card-subtitle>
                        {{ stelle.first_name }} {{ stelle.last_name }}<br />
                        {{ stelle.email }}<br />
                        {{ stelle.phone }}
                    </v-card-subtitle>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { StelleCollection } from '@/models/Stelle';
import View from 'ol/View';
import OlMap from 'ol/Map';
import { get as getProjection } from 'ol/proj';
import { registerProjections } from '@/helpers/olHelper';
import { Style } from 'ol/style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import 'ol/ol.css';
import { Vector } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import WKB from 'ol/format/WKB.js';
import { instance as getApi } from '@/helpers/api';

export default {
    name: 'StelleCmp',
    data: function () {
        return {
            stelleCollection: null,
            stelleLoading: false,
            olMap: null,
            gemeindeLayer: null,
            selectedBfs: null,
            zoom: 0,
            center: [2723632.2808265686, 1266450.0217437744],
            filters: [
                {
                    id: 'type',
                    property: 'type',
                    value: 'gemeinde'
                }
            ]
        };
    },
    computed: {
        activeStellen() {
            return this.stelleCollection.models.filter((item) => {
                return item.active;
            });
        }
    },
    mounted() {
        this.loadStelles();
        this.initializeMap();
    },
    async created() {
        registerProjections();
    },
    methods: {
        initializeMap() {
            let proj = getProjection('EPSG:2056');
            proj.setExtent([2680000, 1246000, 2756000, 1285000]);
            this.olView = new View({
                zoom: this.zoom,
                center: this.center,
                constrainResolution: true,
                projection: proj
            });

            this.olMap = new OlMap({
                target: this.$refs['map-root'],
                layers: [],
                view: this.olView,
                controls: [],
                interactions: []
            });

            this.olMap.on('pointermove', (evt) => {
                if (evt.dragging) {
                    return;
                }
                const pixel = this.olMap.getEventPixel(evt.originalEvent);
                this.onGemeindeSelect(pixel);
            });

            this.olMap.on('click', (evt) => {
                this.onGemeindeSelect(evt.pixel);
            });
            this.olMap.getTargetElement().classList.add('spinner');

            this.createGemeindeLayer();
        },
        async loadStelles() {
            this.stelleLoading = true;
            if (!this.stelleCollection) {
                this.stelleCollection = new StelleCollection();
            }
            await this.stelleCollection.query({ filter: this.filters });
            this.stelleLoading = false;
        },
        createGemeindeLayer() {
            this.gemeindeLayer = new VectorLayer({
                source: new Vector(),
                title: 'gemeinden'
            });

            this.olMap.addLayer(this.gemeindeLayer);

            this.loadGemeindeFeatures();
        },
        async loadGemeindeFeatures() {
            try {
                let res = await getApi().post('/stelle/getGemeindeGeometries');
                const format = new WKB();
                let style = new Style({
                    fill: new Fill({
                        color: 'rgba(255,0,0,0.4)'
                    }),
                    stroke: new Stroke({
                        color: 'black',
                        width: 1
                    })
                });
                let activeStyle = new Style({
                    fill: new Fill({
                        color: 'rgba(0,255,0,0.4)'
                    }),
                    stroke: new Stroke({
                        color: 'black',
                        width: 1
                    })
                });
                for (let i = 0; i < res.data.records.length; i++) {
                    const rec = res.data.records[i];
                    const feature = format.readFeature(rec.wkb_geometry);
                    feature.setProperties(rec);
                    feature.setStyle(rec.active ? activeStyle : style);
                    this.gemeindeLayer.getSource().addFeature(feature);
                }
                this.olMap.getTargetElement().classList.remove('spinner');
            } catch (err) {
                console.error(err);
            }
        },
        onGemeindeSelect(pixel) {
            if (!this.gemeindeLayer) {
                return;
            }
            this.gemeindeLayer.getFeatures(pixel).then((features) => {
                const feature = features.length ? features[0] : undefined;
                if (feature) {
                    this.selectedBfs = feature.get('gemeinde_bfsnr');
                } else {
                    this.selectedBfs = null;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.stelle {
    h2 {
        font-size: 1.2em;
        color: #333;
    }
    h3 {
        font-size: 1em;
        color: #888;
    }
}
.selectedGemeinde {
    border: 1px solid black;
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 5px solid rgba(180, 180, 180, 0.6);
    border-top-color: rgba(0, 0, 0, 0.6);
    animation: spinner 0.6s linear infinite;
}
</style>
