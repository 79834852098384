<template>
    <v-dialog :value="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ $t('building_properties') }}</span>
            </v-card-title>

            <v-card-text>
                <v-form>
                    <ech-code-select
                        v-model="record.building_class"
                        :label="$t('building_class')"
                        ech-doc-id="eCH-0129"
                        ech-variable-name="buildingClass"
                    ></ech-code-select>
                    <ech-code-select
                        v-model="record.building_category"
                        :label="$t('building_category')"
                        ech-doc-id="eCH-0129"
                        ech-variable-name="buildingCategory"
                    ></ech-code-select>
                    <kp-numberfield
                        v-model="record.building_volume"
                        :label="$t('building_volume')"
                        suffix="m³"
                    ></kp-numberfield>

                    <kp-numberfield
                        v-model="record.surface_area_of_building"
                        :label="$t('surface_area_of_building')"
                        suffix="m²"
                    ></kp-numberfield>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>
                <v-btn color="action" text @click="$emit('save', record)">{{ $t('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import kpNumberfield from './kp-numberfield.vue';
import EchCodeSelect from './ech-code-select.vue';

export default {
    components: { kpNumberfield, EchCodeSelect },
    props: {
        value: { type: Boolean, default: false },
        building: { type: Object }
    },
    data() {
        return {
            record: this.building
        };
    }
};
</script>

<style></style>
