<template>
    <PlanungsgeschaefteGrid
        :edit="user.isRoleGemeinde || user.isRoleAdmin"
        height="var(--usable-height-in-main-tab)"
    ></PlanungsgeschaefteGrid>
</template>
<script>
import { mapState } from 'vuex';

import PlanungsgeschaefteGrid from '@/components/planungsgeschaeft/planungsgeschaefte-grid.vue';

export default {
    name: 'PlanugnsgeschaeftIndex',
    components: {
        PlanungsgeschaefteGrid
    },
    data: () => ({}),
    computed: {
        ...mapState('user', {
            user: 'record'
        })
    },
    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        }
    }
};
</script>
