/**
 * Circulation entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Circulation extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            creator_id: null,
            modified: null,
            modifier_id: null,
            planning_permission_application_id: null,
            stelle_id: null,
            is_kanton: null,
            completed: null,
            bemerkungen: null,

            //view
            stelle_organisation_name: null,
            planning_permission_application_type: null,
            creator: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Circulation';
    }
}

export class CirculationCollection extends BaseCollection {
    options() {
        return {
            model: Circulation
        };
    }

    entityName() {
        return 'Circulation';
    }
}
