<template>
    <v-dialog :value="value" :persistent="true" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ $t('add_documents_dlg_title') }}</span>
            </v-card-title>
            <v-card-text>
                <p>{{ $t('add_documents_dlg_body') }}</p>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="no">{{ $t('no') }}</v-btn>
                <v-btn color="action" text @click="yes">{{ $t('yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { formRules } from '@/helpers/forms';
export default {
    props: {
        value: { type: Boolean, default: false }
    },
    created() {
        this.formRules = formRules;
    },
    methods: {
        yes() {
            this.$emit('generate', true);
        },
        no() {
            this.$emit('generate', false);
        }
    }
};
</script>
