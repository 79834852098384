<template>
    <v-menu offset-y>
        <template #activator="{ on }">
            <v-btn text v-on="on">
                <span class="mr-2 d-none d-md-inline">{{ getText() }}</span>
                <v-icon color="grey darken-1">fa-solid fa-user</v-icon>
            </v-btn>
        </template>

        <v-list dense>
            <v-list-item @click="$emit('logout')">
                <v-list-item-icon>
                    <v-icon>fa-solid fa-right-from-bracket</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="benutzer.roles.length > 1" @click="$emit('roleChange')">
                <v-list-item-icon>
                    <v-icon>fa-solid fa-hat-cowboy-side</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                    $t('rolle_wechseln') +
                    (benutzer.active_role ? ' (' + $t('rolle_' + benutzer.active_role) + ')' : '')
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-checkbox
                        :input-value="benutzer.event_notification_per_mail"
                        @change="$emit('emailChange', $event)"
                    ></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>{{ $t('event_notification_per_mail') }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    components: {},
    props: {
        benutzer: {
            type: Object
        },
        text: {
            type: String,
            default: null
        }
    },
    data: function () {
        return {};
    },
    computed: {},
    methods: {
        getText() {
            return this.text ? this.text : this.$t('mein_profil');
        }
    }
};
</script>
