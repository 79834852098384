<template>
    <v-data-table
        v-bind="$attrs"
        class="row-pointer"
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="tableOptions"
        :search="query"
        :single-select="true"
        fixed-header
        v-on="$listeners"
        @click:row="editItem"
    >
        <template #top="{ pagination, options, updateOptions }">
            <slot name="top" v-bind="{ pagination, options, updateOptions }">
                <v-toolbar v-if="!hideToolbar" class="elevation-0" color="primary white--text">
                    <div>
                        <v-toolbar-title>
                            <v-icon v-if="maintitleIconCls" left color="white">{{ maintitleIconCls }}</v-icon>
                            {{ maintitle }}
                        </v-toolbar-title>
                        <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
                    </div>
                    <slot name="toolbar-items"></slot>
                    <v-spacer />
                    <IconBtn
                        v-if="enableReload"
                        color="action"
                        :disabled="loading"
                        icon-cls="fa-solid fa-arrows-rotate"
                        :tooltip="$t('reload')"
                        @click="$emit('reload')"
                    />
                    <IconBtn
                        v-if="csvExport"
                        color="action"
                        :disabled="loading"
                        icon-cls="fa-solid fa-download"
                        :tooltip="$t('csv_export')"
                        @click="$emit('csv-export')"
                    />
                    <slot name="before-query-field" v-bind="{ pagination, options, updateOptions }"></slot>
                    <slot name="query-field" v-bind="{ pagination, options, updateOptions }">
                        <v-text-field
                            v-if="showQuery"
                            v-model="query"
                            class="query-field flex-grow-0"
                            dense
                            background-color="white"
                            append-icon="ic-20 ic-searchmagnifier-grey"
                            clear-icon="ic-20 ic-x-grey"
                            clearable
                            hide-details
                            :placeholder="$t('suchen')"
                            @keydown.stop="search"
                            @click:clear="clearSearch"
                        />
                        <span v-else></span>
                    </slot>
                    <slot name="after-query-field" v-bind="{ pagination, options, updateOptions }"></slot>

                    <ConfirmDlg
                        :value="confirm"
                        :context="itemToDelete ? String(itemToDelete.id) : null"
                        :message="confirmDeleteText ?? $t('confirm_rec_delete')"
                        :show-html="true"
                        @yes="deleteItem"
                        @no="itemToDelete = null"
                    />

                    <ConfirmDlg
                        :value="confirmRestore"
                        :context="itemToRestore ? String(itemToRestore.id) : null"
                        :title="$t('rec_restore')"
                        :message="$t('confirm_rec_restore')"
                        @yes="restoreItem"
                        @no="itemToRestore = null"
                    />
                </v-toolbar>
                <slot name="subtoolbar"></slot>
            </slot>
        </template>
        <template
            v-for="field in headers.filter((i) => i.value && i.value !== 'actions')"
            #[createSlotName(field.value)]="data"
        >
            <slot :name="createSlotName(field.value)" v-bind="data">{{ data.value }}</slot>
        </template>

        <template #footer="{ props, on }">
            <slot name="footer" v-bind="{ props, on }"><div></div></slot>
        </template>

        <template #item.actions="{ item }">
            <icon-btn
                v-if="isRestoreAllowed(item)"
                icon-cls="fa-solid fa-trash-arrow-up"
                icon-color="green"
                :tooltip="$t('rec_restore')"
                @click.stop="itemToRestore = item"
            />
            <icon-btn
                v-else
                icon-cls="fa-solid fa-trash"
                icon-color="red"
                :tooltip="$t('delete')"
                :disabled="!isDeleteAllowed(item)"
                @click.stop="itemToDelete = item"
            />
            <slot name="additional-actions" :item="item"> </slot>
        </template>
    </v-data-table>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmDlg from '@/components/confirm_dialog.vue';
import sessionStorageMixin from '@/mixins/session-storage';

/**
 * Base list: Acts as a generic base list to display a grid with
 * basic functionality like querying, sorting, deleting, editing.
 *
 * The component DOES load and manage the collection by itself
 *
 * Slots:
 * - light-toolbar-before-query
 * - query-field (defaults to query field)
 * - light-toolbar-after-query
 * - toolbar-items
 * - additional-actions
 *
 * Events:
 * - update:options: If the table options changed. can be used with options.sync="".
 * - delete-item: if the delete action btn is clicked on an item, delete-item with the model clicked is called, AFTER the user confirmed with yes
 * - new-item: if the new action btn is clicked
 * - reload: if the reload btn is clicked
 * - edit-item: if the edit action btn is clicked on an item, edit-item with the model clicked is called
 */
export default {
    name: 'BaseList',
    components: {
        ConfirmDlg
    },
    mixins: [sessionStorageMixin],

    props: {
        items: { type: Array, default: () => [] },
        // needed for sessionSet/Get to identify the component uniquely. Provide something like "user-list".
        sessionKey: {
            type: String,
            required: true
        },
        // the displayed title
        maintitle: {
            type: String,
            default: ''
        },
        maintitleIconCls: {
            type: String,
            default: ''
        },
        enableReload: {
            type: Boolean,
            default: true
        },
        // true to show the quick query box
        showQuery: {
            type: Boolean,
            default: true
        },

        // true to show a csv download btn. it emits a 'csv-export' event if clicked.
        csvExport: Boolean,
        // the data table's headers
        headers: {
            type: Array,
            required: true
        },
        loading: Boolean,
        options: {
            type: Object,
            default: () => {
                return {
                    page: 1,
                    itemsPerPage: 50
                };
            }
        },
        subtitle: { type: String, default: '' },
        hideToolbar: { type: Boolean, default: false },
        confirmDeleteText: { type: String, default: null }
    },

    data() {
        return {
            itemToDelete: null,
            itemToRestore: null,
            tableOptions: {
                ...this.options,
                ...this.getSessionValue('tableOptions')
            },
            total: 0,
            query: this.getSessionValue('query', '')
        };
    },

    computed: {
        ...mapState('user', {
            user: 'record'
        }),
        confirm() {
            return this.itemToDelete !== null;
        },
        confirmRestore() {
            return this.itemToRestore !== null;
        }
    },

    watch: {
        tableOptions: {
            deep: true,
            handler() {
                this.setSessionValue('tableOptions', { ...this.tableOptions });
                this.$emit('update:options', { ...this.tableOptions });
            }
        }
    },

    methods: {
        createSlotName(value) {
            return `item.${value}`;
        },

        search() {
            this.setSessionValue('query', this.query);
        },

        isDeleteAllowed(item) {
            if (typeof item.isDeleteAllowed === 'function') {
                return item.isDeleteAllowed(this.user);
            } else {
                return true;
            }
        },

        deleteItem() {
            let item = this.itemToDelete;
            this.$emit('delete-item', item);
            this.itemToDelete = null;
        },

        restoreItem() {
            let item = this.itemToRestore;
            this.$emit('restore-item', item);
            this.itemToRestore = null;
        },

        isRestoreAllowed(item) {
            if (!item.deleted) {
                return false;
            }

            return this.user.isRoleAdmin;
        },

        clearSearch() {
            this.query = '';
            this.clearSessionValue('query');
        },

        editItem(item) {
            this.$emit('edit-item', item);
        }
    }
};
</script>

<style lang="scss" scoped>
.subtitle {
    font-size: 12px;
    color: var(--gray-text-color);
}
.query-field {
    background-color: white;
    padding: 3px 5px;
}
.row-pointer {
    // // VUE3 way to go
    // :deep(tbody tr :hover) {
    //     cursor: pointer;
    // }
    ::v-deep tbody {
        tr :hover {
            cursor: pointer;
        }
    }
}
</style>
