import UserIndex from '@/views/auth/user/index.vue';
import UserForm from '@/views/auth/user/_id.vue';
import { getI18nInstance } from '@/i18n';

export default [
    {
        path: '/users',
        name: 'users',
        component: UserIndex,
        meta: {
            requiresAuth: true,
            roles: ['admin'],
            title: () => getI18nInstance().t('users')
        }
    },
    {
        path: '/users/:id',
        name: 'user_form',
        component: UserForm,
        meta: {
            requiresAuth: true,
            roles: ['admin'],
            title: (to) => `Benutzer: ${to.params.id}`
        }
    }
];
