/**
 * The globals helper reads global variables, that were loaded before the app start:
 * index.html loads the window.GLOBALS and window.SESSION vars before all other things.
 *
 * This file is part of Minasa
 * (c) Kaden und Partner AG
 */

import qs from 'qs';

/**
 * Returns a global variable value from window.GLOBALS
 * @param {string} key The global var name
 * @returns the value or null
 */
export function globalVar(key) {
    const globals = window.GLOBALS || {};
    return globals[key] || null;
}

/**
 * Returns the window.SESSION session info object
 * @returns {Object}
 */
export function sessionInfo() {
    return window.SESSION || {};
}

/**
 * Returns the window.SESSION.user object, or an empty object.
 * @returns {Object}
 */
export function userInfo() {
    return sessionInfo().user || {};
}

/**
 * forms a complete backend API url from a relative URL,
 * e.g. relUrl = '/login' => 'https://domain.com/services/login
 * @param {string} relUrl
 * @param {string|object} query Query part of the URL, either as string or key/value pairs
 * @returns {string} The fully formed URL
 */
export function apiUrl(relUrl, query) {
    return formUrl(window.apiRoot, relUrl, query);
}

/**
 * forms a complete Frontend app url from a relative URL,
 * e.g. relUrl = '/locale/de.json' => 'https://domain.com/admin-cockpit/locale/de.json'
 * @param {string} relUrl
 * @param {string|object} query Query part of the URL, either as string or key/value pairs
 * @returns {string} The fully formed URL
 */
export function appUrl(relUrl, query) {
    return formUrl(window.appRoot, relUrl, query);
}

function formUrl(baseUrl, relUrl, query) {
    // remove leading slash:
    relUrl = (relUrl || '').replace(/^\//, '');
    // remove trailing slash:
    let root = baseUrl.replace(/\/$/, '');
    let url = `${root}/${relUrl}`;

    if (query) {
        url += url.match(/\?/) ? '&' : '?';
        if (typeof query === 'string') {
            url += query;
        } else if (typeof query === 'object') {
            url += qs.stringify(query);
        }
    }
    return url;
}

export async function awaitAsyncFunction(asyncFn, vuexStore) {
    return await vuexStore.dispatch('runAsync', asyncFn);
}
