<template>
    <StatusVerlauf
        v-if="baugesuch"
        :planning-permission-application-id="baugesuch.id"
        :readonly="readonly"
        height="500px"
    ></StatusVerlauf>
</template>

<script>
import StatusVerlauf from '@/components/status/status-verlauf-grid.vue';

export default {
    components: {
        StatusVerlauf
    },
    props: {
        baugesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false
        };
    }
};
</script>
