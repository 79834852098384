<template>
    <div>LS Wärmepumpen</div>
</template>

<script>
export default {
    props: {
        gesuch: { type: Object },
        additionalData: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false
        };
    }
};
</script>
