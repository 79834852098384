/**
 * Baugesuch entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import {
    PlanningPermissionApplication,
    PlanningPermissionApplicationCollection
} from './PlanningPermissionApplication';
import { ECH_STATUS } from '@/models/Status.js';

export class Baugesuch extends PlanningPermissionApplication {
    defaults() {
        return {
            ...super.defaults(),
            type: 'bg'
        };
    }

    mutations() {
        return {
            ...super.mutations()
        };
    }

    entityName() {
        return 'Baugesuch';
    }

    isDeleteAllowed(user) {
        if (this.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW) {
            return false;
        }

        if (user.isRoleAdmin) {
            return true;
        }

        if (this.deleted) {
            return false;
        }

        return true;
    }
}

export class BaugesuchCollection extends PlanningPermissionApplicationCollection {
    options() {
        return {
            model: Baugesuch
        };
    }

    entityName() {
        return 'Baugesuch';
    }
}
