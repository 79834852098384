<template>
    <div class="pa-2">
        <v-row no-gutters>
            <v-col sm="3" class="text-right"> {{ $t('beschreibung') }} </v-col>
            <v-col sm="9">
                <v-textarea v-model="record.description" :readonly="readonly"></v-textarea>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col sm="3" class="text-right"> {{ $t('geschaeftsart') }} </v-col>
            <v-col sm="9"
                ><ApplicationTypeSelect
                    v-model="record.application_type_id"
                    :readonly="readonly"
                    type="pg"
                    label=""
                ></ApplicationTypeSelect
            ></v-col>
        </v-row>

        <v-row no-gutters>
            <v-col sm="3" class="text-right"> {{ $t('offene_rekurse') }} </v-col>
            <v-col sm="9">
                <v-checkbox
                    v-model="record.has_open_appeals"
                    :readonly="readonly"
                    :indeterminate="record.has_open_appeals === null"
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col sm="3" class="text-right"> {{ $t('business_type') }} </v-col>
            <v-col sm="9"
                ><BusinessTypeSelect
                    v-model="record.business_type_ids"
                    :readonly="readonly"
                    type="pg"
                    label=""
                ></BusinessTypeSelect
            ></v-col>
        </v-row>

        <v-row no-gutters>
            <v-col sm="3" class="text-right"> {{ $t('referenziertes_geschaeft') }} </v-col>
            <v-col sm="9"
                ><PpaAutocomplete
                    v-model="record.reference_planning_permission_application_id"
                    :readonly="readonly"
                    type="pg"
                    label=""
                ></PpaAutocomplete
            ></v-col>
        </v-row>
    </div>
</template>

<script>
import ApplicationTypeSelect from '@/components/application-type-select.vue';
import BusinessTypeSelect from '@/components/business-type-select.vue';
import PpaAutocomplete from '@/components/ppa-autocomplete.vue';

export default {
    components: { ApplicationTypeSelect, BusinessTypeSelect, PpaAutocomplete },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            record: this.gesuch
        };
    }
};
</script>
