<template>
    <v-container>
        <v-timeline align-top clipped dense>
            <v-timeline-item fill-dot class="white--text mb-12" color="primary" right large>
                <template #icon>
                    <span>{{ user.initialen }}</span>
                </template>
                <v-textarea
                    v-model="input"
                    :loading="loading"
                    rows="1"
                    auto-grow
                    hide-details
                    flat
                    :label="$t('new_comment')"
                    solo
                >
                    <template #append>
                        <v-row no-gutters class="d-flex align-center">
                            <template v-if="user.isRoleFachstelle || user.isRoleGemeinde || user.isAdmin">
                                <v-col dense>
                                    <v-tooltip v-model="showtooltip" top>
                                        <template #activator="{ on, attrs }">
                                            <v-icon
                                                dense
                                                v-bind="attrs"
                                                style="color: rgb(0, 34, 186)"
                                                v-on="on"
                                                @click="showtooltip = true"
                                            >
                                                fa-regular fa-circle-info fa-xs
                                            </v-icon>
                                        </template>
                                        <div v-html="$t('intern_extern_tooltip')" />
                                    </v-tooltip>
                                </v-col>
                                <v-col class="pb-6">
                                    <v-switch v-model="intern" hide-details="auto" inset dense></v-switch>
                                </v-col>
                                <v-col> {{ intern ? 'Intern' : 'Extern' }}</v-col>
                            </template>
                            <v-col> <v-btn class="ml-1 mr-0" depressed @click="onNewComment"> Post </v-btn></v-col>
                        </v-row>
                    </template>
                </v-textarea>
            </v-timeline-item>
            <v-slide-y-transition group>
                <v-timeline-item
                    v-for="comment in comments ? comments.models : []"
                    :key="comment.id"
                    class="mb-4"
                    color="secondary"
                    small
                >
                    <v-row :class="comment.intern ? 'intern' : 'extern'" justify="space-between">
                        <v-col cols="2">
                            <div style="font-weight: 500">{{ dayjs(comment.created).format('DD.MM.YYYY HH:mm') }}</div>
                            <div style="font-weight: 500">{{ comment.creator }}</div>
                        </v-col>
                        <v-col cols="10">
                            <div style="white-space: break-spaces">{{ comment.comment }}</div>
                        </v-col>
                    </v-row>
                </v-timeline-item>
            </v-slide-y-transition>
        </v-timeline>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import { PlanningPermissionApplicationComment } from '@/models/PlanningPermissionApplicationComment';
export default {
    props: {
        planningPermissionApplication: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data: () => ({
        comments: null,
        input: null,
        loading: false,
        dayjs: dayjs,
        intern: false,
        showtooltip: false
    }),
    created() {
        this.loadComments();
    },
    methods: {
        ...mapActions('planning_permission_application_comment', [
            'fetchPlanningPermissionApplicationComments',
            'storePlanningPermissionApplicationComment',
            'destroyPlanningPermissionApplicationComment'
        ]),
        async loadComments() {
            this.loading = true;
            try {
                const comments = await this.fetchPlanningPermissionApplicationComments({
                    filter: [
                        {
                            id: 'planning_permission_application_id',
                            property: 'planning_permission_application_id',
                            value: this.planningPermissionApplication.id
                        }
                    ]
                });
                this.comments = comments;
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        async onNewComment() {
            let comment = new PlanningPermissionApplicationComment({
                comment: this.input,
                planning_permission_application_id: this.planningPermissionApplication.id,
                intern: this.intern
            });
            try {
                await this.storePlanningPermissionApplicationComment(comment);
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            }
            this.input = null;
            this.intern = false;
            this.loadComments();
        }
    }
};
</script>

<style>
.intern {
    background-color: rgb(250, 188, 188);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.extern {
    background-color: rgb(199, 252, 199);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
</style>
