<template>
    <div class="pa-2">
        <v-row no-gutters>
            <Gesuchsteller
                v-if="record"
                :reload="reload"
                context="bg"
                :planning-permission-application-id="record.id"
                :readonly="readonly"
                height="400px"
                @reloaded="reload = false"
            ></Gesuchsteller>
        </v-row>
        <v-row v-if="grundeigentuemer && !readonly" class="ma-2">
            <v-menu offset-y max-height="300px">
                <template #activator="{ attrs, on }">
                    <v-btn :loading="loading" v-bind="attrs" v-on="on">{{
                        $t('grundeigentuemer_uebernehmen_aus')
                    }}</v-btn>
                </template>

                <v-list>
                    <v-list-item
                        v-for="item in grundeigentuemer"
                        :key="item.objectid"
                        link
                        @click="createGrundeigentuemer(item)"
                    >
                        <v-list-item-title v-text="item.egrid"></v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-row>
    </div>
</template>

<script>
import Gesuchsteller from '@/components/relationship-to-person/relationship-to-person-grid.vue';
import { instance as getApi } from '@/helpers/api';
import { errToStr } from '@/helpers/errors.js';

export default {
    components: { Gesuchsteller },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            record: this.gesuch,
            grundeigentuemer: null,
            reload: false,
            loading: false
        };
    },
    async mounted() {
        this.loadGrundeigentuemerData();
    },
    methods: {
        async loadGrundeigentuemerData() {
            let res = await getApi().post('/Baugesuch/getGrundeigentuemer', {
                baugesuch_id: this.gesuch.id
            });
            if (res.data && res.data.records) {
                this.grundeigentuemer = res.data.records;
            }
        },

        async createGrundeigentuemer(item) {
            this.loading = true;
            try {
                await getApi().post('/Baugesuch/createGrundeigentuemer', {
                    baugesuch_id: this.gesuch.id,
                    egrid: item.egrid
                });
            } catch (err) {
                this.$toast({ message: errToStr(err), color: 'error' });
            }
            this.reload = true;
            this.loading = false;
        }
    }
};
</script>
