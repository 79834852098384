<template>
    <div v-if="editor" class="editor">
        <menu-bar class="editor__header" :editor="editor" />
        <editor-content class="editor__content" :editor="editor" height="400px" />
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import MenuBar from './MenuBar.vue';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import HardBreak from '@tiptap/extension-hard-break';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import History from '@tiptap/extension-history';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';

export default {
    components: {
        EditorContent,
        MenuBar
    },

    props: {
        value: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            editor: null
        };
    },

    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value;

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(this.value, false);
        }
    },

    mounted() {
        // this.editor = new Editor({
        //     content: '<p>I’m running Tiptap with Vue.js. 🎉</p>',
        //     extensions: [StarterKit]
        // });

        this.editor = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text,
                Link,
                Bold,
                Italic,
                Strike,
                HardBreak,
                BulletList,
                OrderedList,
                ListItem,
                History,
                Dropcursor,
                Gapcursor
            ],
            content: this.value,
            onUpdate: () => {
                this.$emit('input', this.editor.getHTML());
            }
        });
    },

    beforeDestroy() {
        this.editor.destroy();
    }
};
</script>

<style lang="scss" scoped>
.editor {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 600px;
    color: #212121;
    background-color: none;
    border: 1px solid rgb(148, 148, 148);
    margin-bottom: 1rem;

    &__header {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        flex-wrap: wrap;
        padding: 0.25rem;
        border-bottom: 1px solid rgb(148, 148, 148);
    }

    &__content {
        margin: 1rem 1rem 0 1rem !important;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        :focus-visible {
            outline: 1px solid rgb(148, 148, 148) !important;
        }
    }
}
</style>
