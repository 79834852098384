<template>
    <v-dialog :value="value" :persistent="true" max-width="500px">
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1"> {{ $t('decision_ruling_dlg_step_1') }} </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2"> {{ $t('decision_ruling_dlg_step_2') }} </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ $t('decision_ruling_dlg_title') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <p>{{ $t('decision_ruling_dlg_body') }}</p>
                            <v-form v-model="formValid">
                                <v-textarea v-model="ruling" rows="5" :rules="[formRules.required]"></v-textarea>
                                <JudgementTypeSelect v-model="judgement_type_id" label="Test"></JudgementTypeSelect>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="cancel">{{ $t('cancel') }}</v-btn>
                            <v-btn
                                color="action"
                                text
                                :disabled="!formValid"
                                :loading="loading"
                                @click="createDecisionRuling"
                                >{{ $t('next') }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card>
                        <v-card-text>
                            <fileupload
                                ref="fileupload"
                                :ppa="baugesuch"
                                :parent-id="baugesuch.id"
                                :contexts="['decision_ruling']"
                                entity="baugesuch"
                                :min="0"
                                :max="10"
                                :readonly="false"
                                :decision-ruling-id="decision_ruling_id"
                            ></fileupload>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="cancel">{{ $t('cancel') }}</v-btn>
                            <v-btn color="action" text @click="finish">{{ $t('save') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>

<script>
import { formRules } from '@/helpers/forms';
import { errToStr } from '@/helpers/errors';
import { instance as getApi } from '@/helpers/api';
import JudgementTypeSelect from '@/components/judgement-type-select.vue';
import Fileupload from '@/components/fileupload';

export default {
    components: {
        JudgementTypeSelect,
        Fileupload
    },
    props: {
        baugesuch: { type: Object, default: null },
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            step: 1,
            ruling: '',
            judgement_type_id: null,
            formValid: false,
            decision_ruling_id: 0
        };
    },
    created() {
        this.formRules = formRules;
    },
    methods: {
        async finish() {
            try {
                await getApi().post('/DecisionRuling/finalize', {
                    id: this.decision_ruling_id
                });
                this.$emit('finish', true);
                this.step = 1;
                this.ruling = '';
                this.judgement_type_id = null;
                this.decision_ruling_id = 0;
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            }
        },
        cancel() {
            if (this.decision_ruling_id) {
                this.deleteDecisionRuling();
            } else {
                this.$emit('input', false);
            }
        },
        async deleteDecisionRuling() {
            try {
                await getApi().post('/DecisionRuling/destroy', {
                    id: this.decision_ruling_id
                });

                this.step = 2;
            } catch (err) {
                this.$toast({ message: errToStr(err), codalor: 'error' });
            } finally {
                this.$emit('input', false);
            }
        },
        async createDecisionRuling() {
            try {
                let result = await getApi().post('/DecisionRuling/create', {
                    planning_permission_application_id: this.baugesuch.id,
                    ruling: this.ruling,
                    judgement_type_id: this.judgement_type_id,
                    type: 'decision'
                });

                this.decision_ruling_id = result?.data?.id;
                this.$refs.fileupload.decisionRulingId = this.decision_ruling_id;
                this.$refs.fileupload.fetchExistingFiles();

                this.step = 2;
            } catch (err) {
                this.$toast({ message: errToStr(err), codalor: 'error' });
            } finally {
                this.showUnterlagenNachfordernDlgLoading = false;
            }
        }
    }
};
</script>
