<template>
    <v-card class="d-flex mb-6" color="grey lighten-2" flat tile>
        <v-card class="pa-2" outlined tile width="400px" min-width="400px" max-width="400px">
            <v-list>
                <v-list-item-group v-model="activeItem" color="primary" mandatory @change="onSelectionChanged">
                    <template v-for="group in sortedGroups">
                        <!-- wrap items in an item group, if the group is visible: -->
                        <v-list-group v-if="group.display" :key="group.key" :value="true" class="item-group">
                            <template #activator>
                                <v-list-item-title class="item-group-header">{{ group.title }}</v-list-item-title>
                            </template>
                            <template v-for="(item, index) in itemsInGroups[group.key]">
                                <FancyListTabCompListEntryVue
                                    v-if="showItem(item)"
                                    :key="`${group.key}-${index}`"
                                    :item="item"
                                    :value="item.id"
                                />
                            </template>
                        </v-list-group>

                        <!-- else, display items without group, if group is not visible: -->
                        <template v-for="(item, index) in itemsInGroups[group.key]" v-else>
                            <FancyListTabCompListEntryVue
                                v-if="showItem(item)"
                                :key="`${group.key}-${index}`"
                                :item="item"
                                :value="item.id"
                            />
                        </template>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-card class="flex-grow-1 pa-2 content-card" outlined tile>
            <router-view
                ref="activeChild"
                class="router-content"
                :gesuch="record"
                :additional-data="additionalData"
                :stelle="stelle"
                :readonly="readonly"
                :user="user"
            ></router-view>

            <v-card-actions class="action-buttons">
                <v-btn
                    v-if="activeItemIndex > 0"
                    color="error white--text"
                    :disabled="loading"
                    :loading="loading"
                    @click="onBack"
                >
                    {{ $t('back') }}
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="activeItemIndex + 1 !== items.length"
                    color="action white--text"
                    :disabled="loading"
                    :loading="loading"
                    @click="onNext"
                >
                    {{ $t('next') }}
                </v-btn>
                <v-btn
                    v-if="!hasAbschluss && activeItemIndex + 1 === items.length && !readonly"
                    color="action white--text"
                    :disabled="loading"
                    :loading="loading"
                    @click="onSave"
                >
                    {{ $t('save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-card>
</template>

<script>
import { instance as getApi } from '@/helpers/api.js';
import FancyListTabCompListEntryVue from './fancy-list-tab-comp-list-entry.vue';

export default {
    components: {
        FancyListTabCompListEntryVue
    },
    props: {
        record: { type: Object },
        additionalData: { type: Object },
        readonly: { type: Boolean },
        items: { type: Array },
        /**
         * Array of {key: string, order: number, display: boolean, title?: string}
         */
        itemGroups: { type: Array },
        stelle: { type: Object },
        user: { type: Object },
        saveRoute: { type: String },
        hasAbschluss: { type: Boolean, default: false }
    },
    data() {
        return {
            loading: false,
            activeItem: this.items.find((item) => item.to.name === this.$route.name)?.id || this.items[0].id
        };
    },
    computed: {
        itemsInGroups() {
            const groups = {};
            this.itemGroups.forEach((g) => (groups[g.key] = []));

            this.items?.forEach((item) => {
                if (item.group) {
                    const group = item.group;
                    if (!groups[group]) {
                        groups[group] = [];
                    }
                    groups[group].push(item);
                } else {
                    groups['000__no_group__'].push(item);
                }
            });
            return groups;
        },

        sortedGroups() {
            return [...this.itemGroups]
                .sort((a, b) => a.order < b.order)
                .filter((group) => this.groupItemCount(group.key) > 0);
        },

        activeItemIndex() {
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].id === this.activeItem) {
                    return i;
                }
            }
            return -1;
        }
    },

    watch: {
        $route() {
            this.activeItem = this.items.find((item) => item.to.name === this.$route.name)?.id || this.items[0].id;
        },
        additionalData() {
            alert(this.additionalData?.id);
        }
    },

    mounted: function () {
        this.$nextTick(function () {
            this.validateAll();
            let activeItem = this.items[0].id;
            for (const item of this.items) {
                if (item.to.name === this.$route.name) {
                    activeItem = item.id;
                }
            }

            this.activeItem = activeItem;
        });
    },
    methods: {
        onBack() {
            this.save();
            this.validate(this.items[this.activeItemIndex]);
            let prevIndex = this.activeItemIndex - 1;
            if (prevIndex >= 0) {
                this.$router.push(this.items[prevIndex].to);
            }
        },
        onNext() {
            this.save();
            this.validate(this.items[this.activeItemIndex]);
            let nextIndex = this.activeItemIndex + 1;
            while (nextIndex < this.items.length) {
                if (!this.items[nextIndex].hideInStatus?.includes(this.record.aktueller_status_ebau_value)) {
                    this.$router.push(this.items[nextIndex].to);
                    break;
                }
                nextIndex += 1;
            }
        },
        async onSave() {
            await this.save();
            await this.validate(this.items[this.activeItemIndex]);
            if (this.saveRoute) {
                this.$router.push({ name: this.saveRoute });
            }
        },
        async save() {
            if (!this.readonly) {
                if (this.$refs.activeChild?.beforeSave) {
                    let res = await this.$refs.activeChild.beforeSave();
                    if (!res) {
                        return;
                    }
                }
                this.record.save();
            } else {
                if (this.$refs.activeChild?.readonlySave) {
                    return await this.$refs.activeChild.readonlySave();
                }
            }
        },
        async onSelectionChanged(item) {
            this.save();
            this.validateAll();
            let nextItem = this.items.find((el) => el.id === item);
            if (nextItem?.to) {
                this.$router.push(nextItem.to);
            }
        },
        validateAll() {
            this.items.forEach((item) => {
                this.validate(item);
            });
        },
        async validate(item) {
            try {
                let response = await getApi().post('/PlanningPermissionApplication/checkValidity', {
                    id: this.record.id,
                    sections: [item.id]
                });

                if (response.data) {
                    item.valid = response.data.valid;
                } else {
                    throw new Error('Error');
                }
            } catch (e) {
                throw new Error('Error');
            }
        },
        showItem(item) {
            if (item.hideIfReadonly && this.readonly) {
                return false;
            }
            if (item.hideInStatus) {
                if (item.hideInStatus.indexOf(this.record.aktueller_status_ebau_value) >= 0) {
                    return false;
                }
            }
            if (item.hidden) {
                return false;
            }
            return true;
        },
        groupItemCount(key) {
            var count = 0;
            this.items?.forEach((item) => {
                if (item.group && item.group === key && this.showItem(item)) {
                    count++;
                }
            });

            return count;
        }
    }
};
</script>
<style lang="scss" scoped>
.content-card {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    grid-template-areas:
        'buttons'
        'content';
    .router-content {
        grid-area: content;
    }
    .action-buttons {
        grid-area: buttons;
        height: 50px;
    }
}

.item-group {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: 5px;

    :deep(.v-list-group__header) {
        background-color: #eee;
    }
}
</style>
