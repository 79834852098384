/**
 * Building entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Building extends BaseModel {
    defaults() {
        return {
            id: null,
            planning_permission_application_id: null,
            egid: null,
            official_building_no: null,
            name: null,
            date_of_construction: null,
            period_of_construction: null,
            date_of_renovation: null,
            date_of_demolition: null,
            number_of_floors: null,
            number_of_separate_habitable_rooms: null,
            surface_area_of_building: null,
            sub_surface_area_of_building: null,
            surface_area_of_building_single_object: null,
            building_category: null,
            building_class: null,
            status: null,
            coordinate_east: null,
            coordinate_north: null,
            civil_defense_shelter: null,
            neighbourhood: null,
            local_code: null,
            energy_relevant_surface: null,
            building_volume: null,
            building_volume_information_source: null,
            building_volume_norm: null,
            heating_1_heat_generator: null,
            heating_1_energy_source: null,
            heating_1_geothermal_probes_count: null,
            heating_1_geothermal_probes_depth: null,
            heating_1_others: null,
            heating_2_heat_generator: null,
            heating_2_energy_source: null,
            heating_2_geothermal_probes_count: null,
            heating_2_geothermal_probes_depth: null,
            heating_2_others: null,
            hot_water_1_heat_generator: null,
            hot_water_1_energy_source: null,
            hot_water_2_heat_generator: null,
            hot_water_2_energy_source: null,

            // view fields
            building_category_text: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Building';
    }
}

export class BuildingCollection extends BaseCollection {
    options() {
        return {
            model: Building
        };
    }

    entityName() {
        return 'Building';
    }
}
