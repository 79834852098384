import StelleIndex from '@/views/auth/stelle/index.vue';
import StelleForm from '@/views/auth/stelle/_id.vue';
import { getI18nInstance } from '@/i18n';

export default [
    {
        path: '/stelle',
        name: 'stelle',
        component: StelleIndex,
        meta: {
            requiresAuth: true,
            title: () => getI18nInstance().t('behoerdenverzeichnis')
        }
    },
    {
        path: '/stelle/:id',
        name: 'stelle_form',
        component: StelleForm,
        meta: {
            requiresAuth: true,
            title: (to) => getI18nInstance().t('stelle') + `: ${to.params.id}`
        }
    }
];
