<template>
    <v-container v-if="anstoesser">
        <v-row v-for="(neighbours, egrid) in anstoesser" :key="egrid">
            {{ egrid }}
            <v-container>
                <v-row dense>
                    <v-col v-for="(neighbour, idx) in neighbours" :key="idx" cols="3" xl="3" lg="4" md="6" sm="12">
                        <v-card>
                            <v-card-text>
                                <div>
                                    <strong>{{ neighbour.Vorname }} {{ neighbour.Name }}</strong>
                                </div>
                                <div>{{ neighbour.strasse }}</div>
                                <div>{{ neighbour.plz }} {{ neighbour.ort }}</div>
                            </v-card-text>
                        </v-card></v-col
                    ></v-row
                ></v-container
            >
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        anstoesser: {
            type: Object,
            default: null
        }
    },
    data() {
        return {};
    }
};
</script>

<style></style>
