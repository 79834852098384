<template>
    <div>
        <template v-for="(item, index) in items">
            <div v-if="item.type === 'divider'" :key="index" class="divider" />
            <menu-item v-else :key="index" v-bind="item" />
        </template>
    </div>
</template>

<script>
import MenuItem from './MenuItem.vue';

export default {
    components: {
        MenuItem
    },

    props: {
        editor: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            items: [
                {
                    icon: 'bold',
                    title: this.$t('bold') || 'Bold',
                    action: () => this.editor.chain().focus().toggleBold().run(),
                    isActive: () => this.editor.isActive('bold')
                },
                {
                    icon: 'italic',
                    title: this.$t('italic') || 'Italic',
                    action: () => this.editor.chain().focus().toggleItalic().run(),
                    isActive: () => this.editor.isActive('italic')
                },
                {
                    icon: 'strikethrough',
                    title: this.$t('strike') || 'Strike',
                    action: () => this.editor.chain().focus().toggleStrike().run(),
                    isActive: () => this.editor.isActive('strike')
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'list-ul',
                    title: this.$t('bullet_list') || 'Bullet List',
                    action: () => this.editor.chain().focus().toggleBulletList().run(),
                    isActive: () => this.editor.isActive('bulletList')
                },
                {
                    icon: 'list-ol',
                    title: this.$t('ordered_list') || 'Ordered List',
                    action: () => this.editor.chain().focus().toggleOrderedList().run(),
                    isActive: () => this.editor.isActive('orderedList')
                },
                {
                    icon: 'link',
                    title: this.$t('link') || 'Link',
                    action: () => {
                        if (this.editor.isActive('link')) {
                            this.editor.chain().focus().unsetLink().run();
                        } else {
                            const url = window.prompt('URL');
                            this.editor.chain().focus().setLink({ href: url }).run();
                        }
                    },
                    isActive: () => this.editor.isActive('link')
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'remove-format',
                    title: this.$t('clear_format') || 'Clear Format',
                    action: () => this.editor.chain().focus().clearNodes().unsetAllMarks().run()
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'undo',
                    title: this.$t('undo') || 'Undo',
                    action: () => this.editor.chain().focus().undo().run()
                },
                {
                    icon: 'redo',
                    title: this.$t('redo') || 'Redo',
                    action: () => this.editor.chain().focus().redo().run()
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.divider {
    width: 2px;
    height: 1.25rem;
    margin-left: 0.5rem;
    margin-right: 0.75rem;
    background-color: var(--v-primary-base);
}
</style>
