<template>
    <div>
        <v-card>
            <v-card-text>
                <p class="text-h4 text--primary">{{ $t('decision_ruling') }}</p>
                <DecisionRuling
                    v-if="planungsgeschaeft"
                    :planning-permission-application-id="planungsgeschaeft.id"
                    :readonly="readonly"
                    type="decision"
                    height="500px"
                ></DecisionRuling>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import DateFunctions from '@/mixins/date-functions.js';

import DecisionRuling from '@/components/decision-ruling/decision-ruling-grid.vue';

export default {
    components: {
        DecisionRuling
    },
    mixins: [DateFunctions],
    props: {
        planungsgeschaeft: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false,
            statement: null
        };
    }
};
</script>
