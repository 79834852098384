import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class News extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            creator_id: null,
            title: null,
            content: null,
            display_from: null,
            display_to: null,
            static_display: null,
            // views
            creator: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'News';
    }
}

export class NewsCollection extends BaseCollection {
    options() {
        return {
            model: News
        };
    }

    entityName() {
        return 'News';
    }
}
