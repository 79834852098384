/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Minasa
 * (c) Kaden und Partner AG
 */
import { Model, Response } from 'vue-mc';
import { instance as apiInstance } from '@/helpers/api';

export default class BaseModel extends Model {
    static fromData(data) {
        if (data instanceof this) {
            return data;
        } else if (data) {
            return new this(data);
        } else {
            return null;
        }
    }

    /**
     * Override in child classes: This is the API endpoint's
     * entity name (e.g. like in /services/User/list --> User)
     *
     * @return {String} The API endpoint's Entity name
     */
    entityName() {
        return this.constructor.name;
    }

    routes() {
        // Note that we use relative URLs here - we use a pre-configured instance
        // of axios, which has a baseUrl set.
        let className = this.entityName();
        return {
            fetch: `/${className}/get?id={id}`,
            save: `/${className}/store`,
            delete: `/${className}/destroy?id={id}`
        };
    }

    options() {
        return {
            identifier: 'id',
            validateRecursively: false,
            patch: true,
            methods: {
                fetch: 'GET',
                save: 'POST',
                update: 'POST',
                create: 'POST',
                delete: 'GET',
                patch: 'POST'
            }
        };
    }

    isDirty() {
        let props = this.changed();
        if (props) {
            for (let p of props) {
                if (this.get(p) !== this.saved(p)) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Override original method:
     *
     * vue-mc has no possibility to use an existing axios (pre-configured) instance.
     * So we mimic a vue-mc "request" here: Request just needs a send()-Method, which we will
     * create here:
     */
    createRequest(config) {
        config.headers = Object.assign(apiInstance().defaults.headers, config.headers);
        return {
            send: () =>
                apiInstance()
                    .request(config)
                    .then((response) => new Response(response))
        };
    }
}
