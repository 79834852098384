<template>
    <CommentGrid
        v-if="planungsgeschaeft"
        :planning-permission-application-id="planungsgeschaeft.id"
        :readonly="true"
        height="500px"
    ></CommentGrid>
</template>

<script>
import CommentGrid from '@/components/comment/comment-grid.vue';

export default {
    components: {
        CommentGrid
    },
    props: {
        planungsgeschaeft: { type: Object },
        user: { type: Object }
    },
    data() {
        return {
            loading: false
        };
    }
};
</script>
