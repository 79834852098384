<template>
    <v-card fluid>
        <v-card-text>
            <base-remote-list
                session-key="news-list-remote"
                :maintitle="$t('news')"
                maintitle-icon-cls="fa-regular fa-envelope"
                :collection-class="NewsCollection"
                :headers="headers"
                :filters="filters"
                :extra-params="extraParams"
                :options="{ sortBy: ['display_to'] }"
                csv-export
                @loading="loading = $event"
                @edit-item="onEdit"
                @new-item="onAdd"
            >
                <template #after-query-field>
                    <v-btn rounded class="hidden-xs-only ml-1" color="subtleHint" @click="onAdd">
                        <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                        {{ $t('add_news') }}
                    </v-btn>
                </template>

                <template #[`item.display_from`]="{ item }">
                    {{ formatDate(item.display_from) }}
                </template>

                <template #[`item.display_to`]="{ item }">
                    {{ formatDate(item.display_to) }}
                </template>
                <template #[`item.static_display`]="{ item }">
                    {{ item.static_display ? $t('yes') : $t('no') }}
                </template>
            </base-remote-list>
        </v-card-text>
    </v-card>
</template>

<script>
import { NewsCollection } from '@/models/News';
import sessionStorageMixin from '@/mixins/session-storage';
import DateFunctions from '@/mixins/date-functions.js';

export default {
    components: {},
    mixins: [sessionStorageMixin, DateFunctions],
    data() {
        this.sessionKey = 'user-index-remote';
        return {
            NewsCollection,
            loading: false,
            headers: [
                {
                    text: this.$t('title'),
                    value: 'title',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('display_from'),
                    value: 'display_from',
                    sortable: true,
                    filterable: true,
                    width: '15%'
                },
                {
                    text: this.$t('display_to'),
                    value: 'display_to',
                    sortable: true,
                    filterable: true,
                    width: '15%'
                },
                {
                    text: this.$t('static_display'),
                    value: 'static_display',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                },
                {
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false,
                    width: 120
                }
            ]
        };
    },
    computed: {
        // gewisse Parameter können / müssen als Filter-Array mitgegeben wrden.
        // Hier werden diese aufbereitet für die Remote List:
        filters() {
            const filters = [];
            return filters;
        },

        // gewisse Parameter können / müssen als ExtraParam mitgegeben werden.
        // Hier werden diese aufbereitet für die Remote List:
        extraParams() {
            const extraParams = {};
            // const enhancedFilters = {};

            // for (let key in this.enhancedFilters) {
            //     if (this.enhancedFilters[key]) {
            //         enhancedFilters[key] = this.enhancedFilters[key];
            //     }
            // }
            // extraParams.enhancedFilters = enhancedFilters;
            return extraParams;
        }
    },
    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onAdd() {
            this.showForm('new');
        },

        onEdit(rec) {
            if (rec) {
                this.showForm(rec.id);
            }
        },

        showForm(id) {
            this.$router.push({
                name: 'admin_news_form',
                params: { id: id || 'new' }
            });
        }
    }
};
</script>
