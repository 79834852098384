<template>
    <div class="tg-horizont">
        <v-app-bar :app="app" class="kp-header">
            <img :src="tgImg" height="45" class="mr-3" alt="Thurgau Wappen" />
            <div>
                <h1 class="title">eBau / ePlan</h1>
                <div class="subtitle">
                    {{ $t('k_tg') }}
                </div>
            </div>
        </v-app-bar>
    </div>
</template>

<script>
import { TgWappenSvg } from '@/helpers/images.js';

export default {
    props: {
        app: { type: Boolean, default: false },
        small: { type: Boolean, default: false }
    },
    data: function () {
        return {
            tgImg: TgWappenSvg
        };
    }
};
</script>

<style lang="scss" scoped>
.tg-horizont {
    padding-bottom: 97px !important;
    box-shadow: inset 0 -15px 0 #4ab1eb, inset 0px -19px 0px #00a4e8, inset 0px -23px 0px #72a536,
        inset 0px -29px 0px #adc427, inset 0px -33px 0px #ffec00;
}
</style>
