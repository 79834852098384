<template>
    <v-dialog style="z-index: 2000" :value="value" persistent :max-width="825" @click:outside="$emit('close')">
        <v-window v-model="page">
            <v-window-item :value="1">
                <v-card>
                    <v-card-title>{{ $t('thema') }}</v-card-title>
                    <v-card-text :max-width="700" class="d-flex flex-wrap flex-row mb-3">
                        <v-card
                            v-for="topic in layerTopics"
                            :key="topic.id"
                            class="ma-2"
                            @click.stop="() => onTopicClick(topic)"
                        >
                            <v-card-text
                                >{{ topic.title }}
                                <v-img
                                    :width="150"
                                    :src="$store.state.globals.mapurl + '/layersConfig/thumb/' + topic.thumb_desktop"
                                ></v-img>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="decline" text :loading="loading" @click="$emit('close')">
                            {{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-window-item>

            <v-window-item :value="2">
                <v-card :height="800" class="d-flex flex-column">
                    <v-card-title>{{ selectedTopicLabel }}</v-card-title>
                    <v-card-text class="flex-grow-1 overflow-auto">
                        <v-treeview
                            v-if="page === 2"
                            :value="activeLayers"
                            dense
                            retiu
                            selectable
                            item-text="label"
                            :items="subLayers.children"
                            @input="onActiveLayersChange"
                        ></v-treeview>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="decline" text :loading="loading" @click="page = 1">
                            {{ $t('back') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="decline" text :loading="loading" @click="$emit('close')">
                            {{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-window-item>
        </v-window>
    </v-dialog>
</template>

<script>
import { TgWappenSvg } from '@/helpers/images.js';
import { getTgLayers, getSubLayers } from '@/helpers/layers.js';
import { mapMutations } from 'vuex';

export default {
    components: {},
    props: {
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            tgImg: TgWappenSvg,
            layerTopics: [],
            subLayers: [],
            page: 1,
            topicId: 0,
            selectedTopicLabel: null
        };
    },
    computed: {
        activeLayers() {
            if (this.topicId) {
                let storeValue = this.$store.state.layers.activeLayers[this.topicId] || [];
                return [...storeValue];
            }
            return [];
        }
    },
    async mounted() {
        let res = await getTgLayers();
        this.layerTopics = res.topics;
    },

    methods: {
        ...mapMutations('layers', ['setActiveLayers']),
        async onTopicClick(topic) {
            this.topicId = topic.id;
            this.selectedTopicLabel = topic.title;
            let res = await getSubLayers(this.topicId);
            this.subLayers = res.results.root;
            this.page = 2;
        },
        onActiveLayersChange(layers) {
            if (this.topicId) {
                this.setActiveLayers({ topicId: this.topicId, activeLayers: layers });
            }
        }
    }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}

h3 {
    margin-top: 1.5em;
}

address {
    font-style: normal;
}
</style>
