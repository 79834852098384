/**
 * LocationAddress entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class LocationAddress extends BaseModel {
    defaults() {
        return {
            id: null,
            planning_permission_application_id: null,
            address_line_1: null,
            address_line_2: null,
            street: null,
            house_number: null,
            dwelling_number: null,
            locality: null,
            town: null,
            swiss_zip_code: null,
            swiss_zip_code_add_on: null,
            swiss_zip_code_id: null,
            country_id: null,
            insurance_number: null,
            coordinate_east: null,
            coordinate_north: null,
            egid: null,
            edid: null,
            egaid: null,
            esid: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            planning_permission_application_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'LocationAddress';
    }
}

export class LocationAddressCollection extends BaseCollection {
    options() {
        return {
            model: LocationAddress
        };
    }

    entityName() {
        return 'LocationAddress';
    }
}
