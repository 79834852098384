<template>
    <v-dialog :value="value" :persistent="persistent" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ title }}</span>
                <span v-if="context" style="margin-left: 1ch"> ({{ context }})</span>
            </v-card-title>
            <v-card-text>
                <div v-if="showHtml" v-html="message" />
                <div v-else>{{ message }}</div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="showNoBtn" text @click="no">{{ noBtnText }}</v-btn>
                <v-btn color="action" text @click="yes">{{ yesBtnText }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { getI18nInstance as i18n } from '@/i18n';

export default {
    name: 'ConfirmDialog',

    props: {
        title: {
            type: String,
            default: () => i18n().t('rec_delete')
        },
        message: {
            type: String,
            default: () => i18n().t('confirm_rec_delete')
        },
        // context is the additional text to be displayed in the confirm message,
        // e.g. "Delete <context>" --> "Delete user x"
        context: {
            type: String,
            default: null
        },
        value: Boolean,
        persistent: Boolean,
        showNoBtn: {
            type: Boolean,
            default: true
        },
        yesBtnText: {
            type: String,
            default: () => i18n().t('yes')
        },
        noBtnText: {
            type: String,
            default: () => i18n().t('no')
        },
        showHtml: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        no() {
            this.$emit('no');
            this.$emit('input', false);
        },

        yes() {
            this.$emit('yes');
            this.$emit('input', false);
        }
    }
};
</script>
