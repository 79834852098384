<template>
    <v-autocomplete
        v-bind="$attrs"
        :value="value"
        :dense="dense"
        :readonly="readonly"
        :label="$t('judgement_type') + (rules.includes(formRules.required) ? ' *' : '')"
        :items="judgementTypeCollection ? judgementTypeCollection.models : []"
        :loading="isLoading"
        :rules="rules"
        item-text="display_value"
        item-value="id"
        clear-icon="fa-solid fa-xmark"
        hide-no-data
        hide-selected
        @input="onSelect"
    >
    </v-autocomplete>
</template>

<script>
import { JudgementTypeCollection } from '@/models/JudgementType';
import { formRules } from '@/helpers/forms.js';

/**
 * PLZ-Ort-Select
 *
 * loads Orte from /lookup/ort,
 * emits the selected
 */
export default {
    props: {
        value: {
            type: Number,
            default: () => null
        },
        rules: {
            type: Array,
            default: () => []
        },
        dense: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            judgementTypeCollection: null,
            isLoading: false,
            formRules: formRules,
            oldSearchInput: false,
            filter: []
        };
    },
    mounted: async function () {
        if (!this.judgementTypeCollection) {
            this.judgementTypeCollection = new JudgementTypeCollection();
        }
        await this.loadJudgementType({ filter: this.filter });
    },
    methods: {
        onSelect(records) {
            this.$emit('input', records);
        },
        async loadJudgementType(options) {
            this.isLoading = true;
            if (!this.judgementTypeCollection) {
                this.judgementTypeCollection = new JudgementTypeCollection();
            }
            await this.judgementTypeCollection.query(options);
            this.isLoading = false;
        }
    }
};
</script>
