import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Event extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            creator_id: null,
            user_id: null,
            planning_permission_application_id: null,
            type: null,

            // views
            creator: null,
            ppa_type: null,
            ppa_stelle_id: null,
            ppa_stelle: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'News';
    }
}

export class NewsCollection extends BaseCollection {
    options() {
        return {
            model: Event
        };
    }

    entityName() {
        return 'Event';
    }
}
