/**
 * Wraps the backend / ajax lib for better centralizing
 * common interceptions.
 */

import axios, { Cancel } from 'axios';
import { getCsrfToken } from '@/helpers/auth.js';
import { apiUrl } from '@/helpers/globals';

let _instance = null;
/**
 * Interceptor for the CSRF token: Each request
 * is enhanced with the CSRF token stored in localStorage
 * @param {Object} config
 */
export const csrfInterceptor = (config) => {
    let token = getCsrfToken();
    if (token) {
        config.headers[config.xsrfHeaderName] = getCsrfToken();
    } else {
        delete config.headers[config.xsrfHeaderName];
    }
    return config;
};

export const sessionTimeoutInterceptor = (error) => {
    // Ignore axios cancellations:
    if (error instanceof Cancel) {
        return;
    }
    // ... or else!
    if (error.response.status === 401) {
        //window.alert('Die Session ist abgelaufen, bitte erneut anmelden.');
        let fromUrl = window.location.href;
        window.location.href = apiUrl('/oidc/login', { url: encodeURIComponent(fromUrl) });
        // } else if (error.response.status === 503 && error.response.data.error.msg === 'Maintenance Mode on') {
        //     window.location.href = 'maintenance.html?customer=' + error.response.data.error.customer;
    } else if (error?.response?.data?.error?.msg) {
        throw error?.response?.data?.error?.msg;
    } else {
        throw error;
    }
};

axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.request.use(csrfInterceptor);
axios.interceptors.response.use((response) => response, sessionTimeoutInterceptor);

async function loadAuthInfo(baseURL) {
    try {
        let res = await axios.get('/auth/sessionInfo', {
            baseURL
        });
        return res.data;
    } catch (err) {
        console.error(err.toString());
        return {};
    }
}

function init({ baseURL }) {
    _instance = axios.create({
        baseURL,
        timeout: 30000
    });
    _instance.interceptors.request.use(csrfInterceptor);
    _instance.interceptors.response.use((response) => response, sessionTimeoutInterceptor);
    return _instance;
}

export function instance() {
    if (!_instance) {
        throw new Error('API not initialized. Use init() first.');
    }
    return _instance;
}

export function getTokenSource() {
    return axios.CancelToken.source();
}

export function getBaseURL() {
    return instance().defaults.baseURL;
}

export function buildUrl(path, query) {
    let url = getBaseURL() + path;
    url += url.match(/\?/) ? '&' : '?';
    if (typeof query === 'string') {
        url += query;
    } else if (typeof query === 'object') {
        url += Object.keys(query)
            .map((key) => String(key) + '=' + encodeURIComponent(query[key]))
            .join('&');
    }
    return url;
}

export default {
    loadAuthInfo,
    init,
    instance,
    getTokenSource
};
