<template>
    <div>
        <v-row no-gutters>
            <v-col sm="4">
                <strong>{{ $t('stammdaten').toUpperCase() }}</strong>
            </v-col>
            <v-col sm="2"> {{ $t('ebau_nr').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ baugesuch.id }} </v-col>
            <v-col sm="2"> {{ $t('adresse').toUpperCase() }} </v-col>
            <v-col sm="2"> - </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="2"> {{ $t('gesuchsart').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ $t(baugesuch.application_type) }} </v-col>
            <v-col sm="2"> {{ $t('gemeinde').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ baugesuch.stelle }} </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="2"> {{ $t('status').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ baugesuch.aktueller_status }} </v-col>
            <v-col sm="2"> {{ $t('leitbehoerde').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ baugesuch.stelle }} </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="2"> {{ $t('bauvorhaben').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ $t(baugesuch.construction_project_type) }} </v-col>
            <v-col sm="2"> {{ $t('intended_purpose').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ intended_purpose }} </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="2"> {{ $t('bg_nummer_kanton').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ baugesuch.boa_bgnumber || '-' }} </v-col>
        </v-row>
        <hr />
        <v-row no-gutters>
            <v-col sm="4">
                <strong>{{ $t('aktionen').toUpperCase() }}</strong>
            </v-col>
            <v-col sm="8"></v-col>
        </v-row>
        <v-row v-if="!readonly" no-gutters>
            <v-col sm="4"></v-col>
            <v-col sm="8">
                <strong>{{ $t('formular_weiterbearbeiten') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('formular_weiterbearbeiten_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn :to="{ name: 'bg_edit_lage' }" outlined>{{ $t('formular_bearbeiten').toUpperCase() }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!readonly" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong> {{ $t('weitere_personen_freischalten') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                {{ $t('weitere_personen_freischalten_description') }}
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn :to="{ name: 'bg_berechtigungen' }" outlined>{{
                    $t('berechtigungen_verwalten').toUpperCase()
                }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!readonly" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong> {{ $t('dossier_einreichen') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('dossier_einreichen_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn outlined :to="{ name: 'bg_edit_abschluss' }">{{ $t('dossier_einreichen').toUpperCase() }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="showNachfordern()" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong>{{ $t('unterlagen_nachfordern') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">{{ $t('unterlagen_nachfordern_text') }}</v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn outlined @click="showUnterlagenNachfordernDlg = true">{{
                    $t('unterlagen_nachfordern').toUpperCase()
                }}</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="showDecisionRuling()" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong>{{ $t('decision_ruling') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">{{ $t('decision_ruling_text') }}</v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn outlined @click="showDecisionRulingDlg = true">
                    {{ $t('decision_ruling').toUpperCase() }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="showBearbeiten()" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong>{{ $t('verfahren_beginnen') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('verfahren_beginnen_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn outlined @click="onStartBearbeitung"> {{ $t('verfahren_beginnen').toUpperCase() }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="showStatement()" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong>{{ $t('statement_erfassen') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('statement_erfassen_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn outlined @click="showStatementDlg = true"> {{ $t('statement_erfassen').toUpperCase() }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="isDeleteAllowed()" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong>{{ $t('dossier_loeschen') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('dossier_loeschen_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn :disabled="!isDeleteAllowed" outlined @click="onDelete">{{
                    $t('dossier_loeschen').toUpperCase()
                }}</v-btn>
            </v-col>
        </v-row>
        <hr />
        <v-row no-gutters>
            <v-col sm="4">
                <strong>{{ $t('rueckmeldungen').toUpperCase() }}</strong>
            </v-col>
            <v-col sm="8">
                {{ $t('rueckmeldungen_description') }}
            </v-col>
        </v-row>
        <hr />
        <v-row no-gutters>
            <v-col sm="4">
                <strong>{{ $t('formulare').toUpperCase() }}</strong>
            </v-col>
            <v-col>
                <v-btn outlined @click="onDeckblattPdf">{{ $t('deckblatt') }}</v-btn>
                <v-btn outlined class="ml-5" @click="onBaugesuchsformularPdf">{{ $t('baugesuchsformular') }}</v-btn>
                <v-btn v-if="gemeindeMitarbeiter" outlined class="ml-5" @click="onSchutzplatzsteuerungFormularPdf">
                    {{ $t('formular_schutzplatzsteuerung') }}
                </v-btn>
            </v-col>
        </v-row>
        <Download ref="downloader"></Download>
        <UnterlagenNachfordernDlg
            v-model="showUnterlagenNachfordernDlg"
            :loading="showUnterlagenNachfordernDlgLoading"
            @save="onUnterlagenNachfordern"
        />
        <UnterlagenAnhaengenDlg
            v-model="showUnterlagenAnhaengenDlg"
            @generate="generateSchutzplatzsteuerungFormularPdf"
        />
        <DecisionRulingDlg v-model="showDecisionRulingDlg" :baugesuch="baugesuch" @finish="onDecisionRuling" />
        <StatementDlg v-model="showStatementDlg" :baugesuch="baugesuch" @finish="onStatement" />
    </div>
</template>

<script>
import { errToStr } from '@/helpers/errors';
import { mapActions } from 'vuex';
import { instance as getApi } from '@/helpers/api';
import { ECH_STATUS } from '@/models/Status';
import { getI18nInstance } from '@/i18n';

import Download from '@/components/download.vue';
import UnterlagenNachfordernDlg from '@/components/baugesuch/unterlagen-nachfordern-dlg';
import UnterlagenAnhaengenDlg from '@/components/baugesuch/unterlagen-anhaengen-dlg';
import DecisionRulingDlg from '@/components/baugesuch/decision-ruling-dlg';
import StatementDlg from '@/components/baugesuch/statement-dlg';

export default {
    components: { Download, UnterlagenNachfordernDlg, UnterlagenAnhaengenDlg, DecisionRulingDlg, StatementDlg },
    props: {
        baugesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        accessControlReadonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false,
            showUnterlagenNachfordernDlg: false,
            showUnterlagenNachfordernDlgLoading: false,
            showUnterlagenAnhaengenDlg: false,
            showDecisionRulingDlg: false,
            showStatementDlg: false
        };
    },
    computed: {
        intended_purpose() {
            let purpose = [];

            if (this.baugesuch.intended_purpose) {
                this.baugesuch.intended_purpose.forEach((type_key) => {
                    purpose.push(this.$t(type_key));
                });
            }

            //$t(baugesuch.intended_purpose)
            return purpose.join(', ');
        },
        gemeindeMitarbeiter() {
            return this.user.isRoleGemeinde && this.user.isInGemeinde(this.baugesuch.stelle_id);
        }
    },
    methods: {
        ...mapActions(['runAsync']),

        onDelete() {
            this.delete(this.baugesuch);
        },

        isDeleteAllowed() {
            if (typeof this.baugesuch.isDeleteAllowed === 'function') {
                return this.baugesuch.isDeleteAllowed(this.user);
            } else {
                return false;
            }
        },

        async delete(baugesuch) {
            if (baugesuch) {
                try {
                    this.loading = true;
                    await this.runAsync(() => baugesuch.delete());
                    this.$router.push({ name: 'baugesuche' });
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                    baugesuch.reset();
                } finally {
                    this.loading = false;
                }
            }
        },

        onDeckblattPdf() {
            this.$refs.downloader.downloadFile(
                '/Baugesuch/createDeckblattPdf?id=' + this.baugesuch.id,
                getI18nInstance().t('deckblatt_wird_erstellt')
            );
        },

        onBaugesuchsformularPdf() {
            this.$refs.downloader.downloadFile(
                '/Baugesuch/createBaugesuchsformularPdf?id=' + this.baugesuch.id,
                getI18nInstance().t('baugesuch_formular_wird_erstellt')
            );
        },

        onSchutzplatzsteuerungFormularPdf() {
            this.showUnterlagenAnhaengenDlg = true;
        },

        generateSchutzplatzsteuerungFormularPdf(attachPdf) {
            this.showUnterlagenAnhaengenDlg = false;

            this.$refs.downloader.downloadFile(
                '/Baugesuch/createSchutzplatzSteuerungFormularPdf?id=' + this.baugesuch.id + '&attachPdf=' + attachPdf,
                getI18nInstance().t('formular_schutzplatz_wird_erstellt')
            );
        },

        showBearbeiten() {
            return (
                this.baugesuch.aktueller_status_ebau_value === 'submitted' &&
                !this.accessControlReadonly &&
                ((this.user.isRoleGemeinde && this.user.isInGemeinde(this.baugesuch.stelle_id)) ||
                    this.user.isRoleAdmin)
            );
        },

        showStatement() {
            return (
                this.baugesuch?.aktueller_status_ebau_value === ECH_STATUS.IN_PROGRESS &&
                this.user.isRoleFachstelle &&
                (this.baugesuch.open_stelle_ids || []).indexOf(this.user.active_role_stelle_id || -1) >= 0
            );
        },

        onStatement() {
            this.showStatementDlg = false;
        },

        async onStartBearbeitung() {
            this.loading = true;
            try {
                await getApi().post('/Baugesuch/startBearbeitung', {
                    id: this.baugesuch.id
                });
                this.$emit('statuschange', 'in_progress');
            } catch (err) {
                this.$toast({ message: errToStr(err), color: 'error' });
            } finally {
                this.loading = false;
            }
        },

        showNachfordern() {
            return (
                this.baugesuch?.aktueller_status_ebau_value === ECH_STATUS.IN_PROGRESS &&
                (this.user.isRoleGemeinde || this.user.isRoleAdmin)
            );
        },

        async onUnterlagenNachfordern(infoText) {
            this.showUnterlagenNachfordernDlgLoading = true;
            try {
                await getApi().post('/Baugesuch/nachforderung', {
                    id: this.baugesuch.id,
                    grund: infoText
                });
                this.$emit('statuschange', 'demand_documents');
                this.showUnterlagenNachfordernDlg = false;
            } catch (err) {
                this.$toast({ message: errToStr(err), color: 'error' });
            } finally {
                this.showUnterlagenNachfordernDlgLoading = false;
            }
        },

        showDecisionRuling() {
            return this.baugesuch?.aktueller_status_ebau_value === ECH_STATUS.IN_PROGRESS && this.user.isRoleGemeinde;
        },

        onDecisionRuling() {
            this.showDecisionRulingDlg = false;
        }
    }
};
</script>
