<template>
    <v-skeleton-loader v-if="loading" type="list-item-avatar, list-item-three-line@3, actions"></v-skeleton-loader>
    <div v-else class="pg-box">
        <v-container v-if="!loading" fluid class="pg-container">
            <status-verlauf-banner
                v-if="planungsgeschaeft && planungsgeschaeft.aktueller_status_verlauf_id"
                :status-verlauf-id="planungsgeschaeft.aktueller_status_verlauf_id"
            />
            <v-card fluid class="px-10 pg-card">
                <v-card-title text-color="white" class="pg-card-title">
                    {{ $t('planungsgeschaeft') + ': ' + formTitle }}&nbsp;
                    <v-chip
                        :class="'status-' + planungsgeschaeft.aktueller_status_ebau_value.replaceAll(' ', '-')"
                        small
                    >
                        {{ planungsgeschaeft.aktueller_status }}</v-chip
                    >
                </v-card-title>

                <v-tabs class="pg-card-tabs">
                    <v-tab key="uebersicht" :to="{ name: 'pg_uebersicht' }">{{ $t('uebersicht') }}</v-tab>
                    <v-tab key="planungsgeschaeft" :to="{ name: 'pg_edit_beteiligte' }">{{
                        $t('planungsgeschaeft')
                    }}</v-tab>
                    <v-tab key="berechtigungen" :to="{ name: 'pg_berechtigungen' }">{{ $t('berechtigungen') }}</v-tab>
                    <v-tab key="status_verlauf" :to="{ name: 'pg_status_verlauf' }">{{ $t('statusverlauf') }}</v-tab>
                    <v-tab key="kommentare" :to="{ name: 'pg_kommentare' }">{{ $t('kommentare') }}</v-tab>
                    <v-tab v-if="user.isRoleAdmin" key="messages" :to="{ name: 'pg_messages' }">{{
                        $t('messages')
                    }}</v-tab>
                    <v-tab
                        v-if="planungsgeschaeft.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW || user.ist_admin"
                        key="decision_ruling"
                        :to="{ name: 'pg_decision_ruling' }"
                        >{{ $t('decision_ruling') }}</v-tab
                    >
                </v-tabs>

                <router-view
                    class="pg-router-view"
                    :planungsgeschaeft="planungsgeschaeft"
                    :stelle="stelle"
                    :readonly="readonly || accessControlReadonly"
                    :user="user"
                ></router-view>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { formRules } from '@/helpers/forms.js';
import { errToStr } from '@/helpers/errors.js';
import { ECH_STATUS } from '@/models/Status.js';

import StatusVerlaufBanner from '@/components/status/status-verlauf-banner.vue';

export default {
    components: { StatusVerlaufBanner },
    data() {
        return {
            planungsgeschaeft: Object,
            stelle: Object,
            formRules,
            loading: false,
            valid: false,
            accessControlReadonly: true,
            ECH_STATUS: ECH_STATUS
        };
    },
    computed: {
        ...mapState('user', {
            user: 'record'
        }),
        formTitle() {
            if (this.planungsgeschaeft && this.planungsgeschaeft.id) {
                return this.planungsgeschaeft.id + ' (' + this.planungsgeschaeft.stelle + ')';
            }

            return this.$t('neues_planungsgeschaeft');
        },
        readonly() {
            if (!this.user) {
                return true;
            }

            if (
                this.planungsgeschaeft.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW &&
                this.planungsgeschaeft.aktueller_status_ebau_value !== ECH_STATUS.SUSPENDED &&
                this.planungsgeschaeft.aktueller_status_ebau_value !== ECH_STATUS.DEMAND_DOCUMENTS
            ) {
                return true;
            }

            if (this.user.isRoleAdmin) {
                return false;
            }

            return false;
        }
    },
    watch: {
        $route: function () {
            // We already are loaded, do nothing:
            if (this.planungsgeschaeft && this.planungsgeschaeft.id === Number(this.$route.params.id)) {
                return;
            }
            this.loadRecord(this.$route.params.id);
        }
    },
    async created() {
        await this.loadRecord(this.$route.params.id);
    },
    methods: {
        ...mapActions('planungsgeschaeft', [
            'loadPlanungsgeschaeft',
            'storePlanungsgeschaeft',
            'destroyPlanungsgeschaeft'
        ]),
        ...mapActions('access_control', ['isReadonlyPpa']),
        ...mapActions('stelle', ['loadStelle']),
        async loadRecord(id) {
            this.loading = true;
            try {
                this.accessControlReadonly = await this.isReadonlyPpa(id);
                this.planungsgeschaeft = await this.loadPlanungsgeschaeft(id);
                this.stelle = await this.loadStelle(this.planungsgeschaeft.stelle_id);
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        closeForm() {
            this.$router.push({ name: 'planungsgeschaefte' });
        },
        async onSubmit() {
            try {
                await this.storePlanungsgeschaeft(this.planungsgeschaeft);
                this.closeForm();
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.pg-box {
    height: 100%;
    .pg-container {
        height: 100%;
        .pg-card {
            display: grid;
            grid-template-rows: auto auto 1fr;
            grid-template-columns: auto;
            height: 100%;
            grid-template-areas:
                'title'
                'tabs'
                'content';
            .pg-card-title {
                grid-area: title;
            }
            .pg-card-tabs {
                grid-area: tabs;
            }
            .pg-router-view {
                grid-area: content;
            }
        }
    }
}
</style>
