<template>
    <v-dialog :value="value" :persistent="true" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ $t('nachf_dlg_title') }}</span>
            </v-card-title>
            <v-card-text>
                <p>{{ $t('nachf_dlg_body') }}</p>
                <v-form v-model="formValid">
                    <v-textarea
                        v-model="grundText"
                        rows="5"
                        :placeholder="$t('nachf_dlg_field_placeholder')"
                        :rules="[formRules.required]"
                    ></v-textarea>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="no">{{ $t('cancel') }}</v-btn>
                <v-btn color="action" text :disabled="!formValid" :loading="loading" @click="yes">{{
                    $t('save')
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { formRules } from '@/helpers/forms';
export default {
    props: {
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            grundText: '',
            formValid: false
        };
    },
    created() {
        this.formRules = formRules;
    },
    methods: {
        yes() {
            this.$emit('save', this.grundText);
        },
        no() {
            this.$emit('input', false);
        }
    }
};
</script>
