/**
 * Mailbox entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Mailbox extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            sender_id: null,
            message_id: null,
            message_type: null,
            subject: null,
            message_date: null,
            action: null,
            test_delivery_flag: null,
            event: null,
            event_type: null,
            ech_message_type: null,
            payload: null,
            error: null,
            delivered: null,
            recipient: null,
            recipient_stelle_id: null,
            planning_permission_application_id: null,

            // View
            recipient_display: null,
            direction: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Mailbox';
    }
}

export class MailboxCollection extends BaseCollection {
    options() {
        return {
            model: Mailbox
        };
    }

    entityName() {
        return 'Mailbox';
    }
}
