<template>
    <div>
        <v-card class="mb-4">
            <v-card-title> {{ $t('decision_ruling') }}</v-card-title>
            <v-card-text>
                <v-container v-if="statement" fluid>
                    <v-row dense>
                        <v-col cols="card.flex">
                            <v-row>
                                <v-col sm="4">
                                    <strong> {{ $t('decision_ruling') }}</strong>
                                </v-col>
                                <v-col sm="8">
                                    {{ statement.judgement_type_display_value }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="4">
                                    <strong> {{ $t('created') }}</strong>
                                </v-col>
                                <v-col sm="8">
                                    {{ formatDateTime(statement.created) }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="4">
                                    <strong> {{ $t('creator') }}</strong>
                                </v-col>
                                <v-col sm="8">
                                    {{ statement.creator }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="4">
                                    <strong> {{ $t('comment') }}</strong>
                                </v-col>
                                <v-col sm="8">
                                    {{ statement.ruling }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="card.flex">
                            <fileupload
                                ref="fileupload"
                                :parent-id="statement.planning_permission_application_id"
                                :contexts="['decision_ruling']"
                                entity="baugesuch"
                                :decision-ruling-id="statement.id"
                            ></fileupload>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <v-card>
            <v-card-text>
                <p class="text-h4 text--primary">{{ $t('statement') }}</p>
                <DecisionRuling
                    v-if="baugesuch"
                    :planning-permission-application-id="baugesuch.id"
                    :readonly="readonly"
                    type="statement"
                    height="500px"
                ></DecisionRuling>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { instance as getApi } from '@/helpers/api';
import { errToStr } from '@/helpers/errors';
import DateFunctions from '@/mixins/date-functions.js';

import DecisionRuling from '@/components/decision-ruling/decision-ruling-grid.vue';
import Fileupload from '@/components/fileupload';

export default {
    components: {
        DecisionRuling,
        Fileupload
    },
    mixins: [DateFunctions],
    props: {
        baugesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false,
            statement: null
        };
    },
    mounted: async function () {
        await this.loadStatement();
    },
    methods: {
        async loadStatement() {
            this.loading = true;

            try {
                let res = await getApi().post('/DecisionRuling/list', {
                    filter: [
                        {
                            id: 'planning_permission_application_id',
                            property: 'planning_permission_application_id',
                            value: this.baugesuch.id
                        },
                        { id: 'type', property: 'type', value: 'decision' }
                    ]
                });
                if (res && res.data) {
                    this.statement = res.data.records[0];
                }
            } catch (err) {
                this.$toast({ message: errToStr(err), color: 'error' });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
