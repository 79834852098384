import { getI18nInstance } from '@/i18n';

import Index from '@/views/auth/planungsgeschaeft/index.vue';
import Form from '@/views/auth/planungsgeschaeft/_id.vue';

import Uebersicht from '@/views/auth/planungsgeschaeft/tabs/uebersicht.vue';
import Planungsgeschaeft from '@/views/auth/planungsgeschaeft/tabs/planungsgeschaeft.vue';
import Berechtigungen from '@/views/auth/planungsgeschaeft/tabs/berechtigungen.vue';
import StatusVerlauf from '@/views/auth/planungsgeschaeft/tabs/status_verlauf.vue';
import Kommentare from '@/views/auth/planungsgeschaeft/tabs/kommentare.vue';
import Messages from '@/views/auth/planungsgeschaeft/tabs/messages.vue';
import DecisionRuling from '@/views/auth/planungsgeschaeft/tabs/decision_ruling.vue';

import Beteiligte from '@/views/auth/planungsgeschaeft/sections/beteiligte.vue';
import Geschaeft from '@/views/auth/planungsgeschaeft/sections/geschaeft.vue';
import Unterlagen from '@/views/auth/planungsgeschaeft/sections/unterlagen.vue';
import Abschluss from '@/views/auth/planungsgeschaeft/sections/abschluss.vue';

export default [
    {
        path: '/planungsgeschaefte',
        name: 'planungsgeschaefte',
        component: Index,
        meta: {
            requiresAuth: true,
            title: () => getI18nInstance().t('planungsgeschaefte')
        }
    },
    {
        path: '/planungsgeschaefte/:id',
        name: 'planungsgeschaeft_form',
        component: Form,
        meta: {
            requiresAuth: true,
            title: (to) => `Planungsgeschäft: ${to.params.id}`
        },
        children: [
            {
                name: 'pg_uebersicht',
                path: 'uebersicht',
                component: Uebersicht
            },
            {
                path: 'planungsgeschaefte',
                component: Planungsgeschaeft,
                children: [
                    {
                        name: 'pg_edit_beteiligte',
                        path: '/',
                        component: Beteiligte
                    },
                    {
                        name: 'pg_edit_geschaeft',
                        path: 'geschaeft',
                        component: Geschaeft
                    },
                    {
                        name: 'pg_edit_unterlagen',
                        path: 'unterlagen',
                        component: Unterlagen
                    },
                    {
                        name: 'pg_edit_abschluss',
                        path: 'abschluss',
                        component: Abschluss
                    }
                ]
            },
            {
                name: 'pg_berechtigungen',
                path: 'berechtigungen',
                component: Berechtigungen
            },
            {
                name: 'pg_status_verlauf',
                path: 'status_verlauf',
                component: StatusVerlauf
            },
            {
                name: 'pg_kommentare',
                path: 'kommentare',
                component: Kommentare
            },
            {
                name: 'pg_messages',
                path: 'messages',
                component: Messages,
                meta: {
                    roles: ['admin']
                }
            },
            {
                name: 'pg_decision_ruling',
                path: 'decision_ruling',
                component: DecisionRuling
            }
        ]
    }
];
