<template>
    <FancyListTabComp
        :record="planungsgeschaeft"
        :item-groups="itemGroups"
        :items="items"
        :stelle="stelle"
        :readonly="readonly"
        :user="user"
        save-route="pg_uebersicht"
        has-abschluss
    >
    </FancyListTabComp>
</template>
<script>
import FancyListTabComp from '@/components/fancy-list-tab-comp.vue';

export default {
    components: {
        FancyListTabComp
    },
    props: {
        planungsgeschaeft: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false,
            itemGroups: [{ key: '001', order: 1, display: false }],
            items: [
                {
                    id: 'beteiligte',
                    title: 'Beteiligte',
                    to: { name: 'pg_edit_beteiligte' },
                    valid: false,
                    group: '001'
                },
                {
                    id: 'geschaeft',
                    title: 'Geschäft',
                    to: { name: 'pg_edit_geschaeft' },
                    valid: false,
                    group: '001'
                },
                {
                    id: 'geschaeftsunterlagen',
                    title: 'Geschäftsunterlagen',
                    to: { name: 'pg_edit_unterlagen' },
                    valid: false,
                    group: '001'
                },
                {
                    id: 'abschluss',
                    title: this.$t('abschluss'),
                    to: { name: 'pg_edit_abschluss' },
                    valid: true,
                    hideValid: true,
                    hideIfReadonly: true,
                    group: '001'
                }
            ]
        };
    }
};
</script>
