<template>
    <v-dialog :value="value" width="800px" scrollable persistent>
        <v-card>
            <v-card-title>{{ $t('add_circulation') }}</v-card-title>
            <v-card-text>
                <v-form v-model="valid" :loading="loading">
                    <v-checkbox v-model="isKanton" :label="$t('kanton')"> </v-checkbox
                    ><StelleAutocomplete
                        v-if="!isKanton"
                        :rules="[formRules.required]"
                        :value="stelleId"
                        type="fachstelle"
                        @input="onStelleSelect"
                    ></StelleAutocomplete>
                    <v-textarea v-model="bemerkungen" :label="$t('comments')"> </v-textarea></v-form
            ></v-card-text>
            <v-card-actions>
                <v-btn color="action white--text" type="submit" :disabled="!valid" @click="onSave">
                    <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                    {{ $t('save') }}
                </v-btn>
                <v-btn color="decline" text @click="closeForm">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import StelleAutocomplete from '@/components/stelle-autocomplete.vue';
import { formRules } from '@/helpers/forms.js';
import { Circulation } from '@/models/Circulation';

export default {
    components: { StelleAutocomplete },
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: null
        },
        value: { type: Boolean, default: false }
    },
    data() {
        return {
            formRules,
            loading: false,
            valid: false,
            stelleId: null,
            isKanton: false,
            bemerkungen: null
        };
    },
    mounted() {},
    methods: {
        async onSave() {
            this.loading = true;
            try {
                let rec = new Circulation({
                    planning_permission_application_id: this.planningPermissionApplicationId,
                    bemerkungen: this.bemerkungen
                });
                if (this.isKanton) {
                    rec.is_kanton = true;
                } else {
                    rec.stelle_id = this.stelleId;
                }
                await rec.save();
                this.$emit('save');
                this.closeForm();
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        closeForm() {
            this.$emit('close');
            this.stelleId = null;
        },
        onStelleSelect(stelle_id) {
            console.log(stelle_id);
            this.stelleId = stelle_id;
        }
    }
};
</script>
