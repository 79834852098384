<template>
    <v-dialog max-width="500px" :value="value" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ $t('choose_gesuchsart') }}</span>
            </v-card-title>

            <v-card-text>
                <ApplicationTypeSelect
                    v-model="selection"
                    tiles
                    type="bg"
                    label=""
                    return-object
                    @select="create"
                ></ApplicationTypeSelect>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import ApplicationTypeSelect from '@/components/application-type-select.vue';
export default {
    components: { ApplicationTypeSelect },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selection: null
        };
    },
    methods: {
        cancel() {
            this.$emit('cancel');
            this.$emit('input', false);
        },

        create(selection) {
            this.$emit('create', selection.id);
        }
    }
};
</script>
