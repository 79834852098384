<template>
    <v-container>
        <div style="font-weight: bold">{{ $t('betriebe_mit_plangenehmigungspflicht_long') }}</div>
        <div style="margin-bottom: 20px" v-html="$t('betriebe_mit_plangenehmigungspflicht_description')"></div>
        <fileupload
            ref="fileupload"
            :ppa="gesuch"
            :parent-id="gesuch.id"
            :contexts="['betrieb_mit_plangenehmigungspflicht']"
            entity="baugesuch"
            :min="1"
            :readonly="readonly"
        ></fileupload>
    </v-container>
</template>

<script>
import Fileupload from '@/components/fileupload';

export default {
    components: {
        Fileupload
    },
    props: {
        gesuch: { type: Object },
        additionalData: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        beforeSave() {
            return false;
        }
    }
};
</script>
