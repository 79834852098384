/**
 * PlanningPermissionApplicationComment entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class PlanningPermissionApplicationComment extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            creator_id: null,
            comment: null,
            planning_permission_application_id: null,
            intern: null,
            // view fields
            creator: null,
            gemeinden_ids: null,
            gemeinden: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            creator_id: (id) => (Number(id) > 0 ? Number(id) : null),
            planning_permission_application_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'PlanningPermissionApplicationComment';
    }
}

export class PlanningPermissionApplicationCommentCollection extends BaseCollection {
    options() {
        return {
            model: PlanningPermissionApplicationComment
        };
    }

    entityName() {
        return 'PlanningPermissionApplicationComment';
    }
}
