/**
 * EchCode entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class EchCode extends BaseModel {
    defaults() {
        return {
            id: null,
            ech_doc_id: null,
            ech_variable_name: null,
            code: null,
            i18n_key: null,
            i18n_index: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'EchCode';
    }
}

export class EchCodeCollection extends BaseCollection {
    options() {
        return {
            model: EchCode
        };
    }

    entityName() {
        return 'EchCode';
    }
}
