<template>
    <FancyListTabComp
        :record="baugesuch"
        :additional-data="additionalData"
        :item-groups="itemGroups"
        :items="items"
        :stelle="stelle"
        :readonly="readonly"
        :user="user"
        save-route="bg_uebersicht"
        has-abschluss
    >
    </FancyListTabComp>
</template>
<script>
import FancyListTabComp from '@/components/fancy-list-tab-comp.vue';

export default {
    components: {
        FancyListTabComp
    },
    props: {
        baugesuch: { type: Object },
        additionalData: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false,
            itemGroups: [
                { key: '001', order: 1, display: false },
                { key: 'bauvorhaben', order: 2, display: true, title: this.$t('angaben_zum_bauvorhaben') },
                { key: 'triage', order: 3, display: true, title: this.$t('triage') },
                { key: 'parzellinfo', order: 4, display: true, title: this.$t('parzelleninfos') },
                { key: '003', order: 5, display: false }
            ],
            items: [
                {
                    id: 'lage',
                    title: this.$t('lage'),
                    to: { name: 'bg_edit_lage' },
                    valid: false,
                    group: '001'
                },
                {
                    id: 'beteiligte',
                    title: this.$t('beteiligte'),
                    to: { name: 'bg_edit_beteiligte' },
                    valid: false,
                    group: '001'
                },
                // -----------
                {
                    id: 'allgemeine_angaben',
                    title: this.$t('allgemeine_angaben'),
                    to: { name: 'bg_edit_allgemeine_angaben' },
                    valid: false,
                    group: 'bauvorhaben'
                },
                {
                    id: 'angaben_zum_bauvorhaben',
                    title: this.$t('gwr_lang'),
                    to: { name: 'bg_edit_angaben_zum_bauvorhaben' },
                    valid: false,
                    group: 'bauvorhaben'
                },
                {
                    id: 'einstufung_baugesuch',
                    title: this.$t('einstufung_baugesuch'),
                    to: { name: 'bg_einstufung_baugesuch' },
                    valid: false,
                    group: 'bauvorhaben'
                },
                {
                    id: 'gewaesserschutz',
                    title: this.$t('gewaesserschutz'),
                    to: { name: 'bg_edit_gewaesserschutz' },
                    valid: false,
                    group: 'bauvorhaben'
                },
                {
                    id: 'heizungsart',
                    title: this.$t('heizung_warmwasser'),
                    to: { name: 'bg_edit_heizungsart' },
                    valid: false,
                    group: 'bauvorhaben',
                    hidden: !this.additionalData.get('heizung_bau_vorgesehen')
                },
                {
                    id: 'ls_waermepumpen',
                    title: this.$t('ls_waermepumpen'),
                    to: { name: 'bg_edit_ls_waermepumpen' },
                    valid: false,
                    group: 'bauvorhaben',
                    hidden: true
                },
                {
                    id: 'ls_hlkk',
                    title: this.$t('ls_hlkk'),
                    to: { name: 'bg_edit_ls_hlkk' },
                    valid: false,
                    group: 'bauvorhaben',
                    hidden: !this.additionalData.get('heizung_geraete_vorgesehen')
                },
                {
                    id: 'betriebe_mit_plangenehmigungspflicht',
                    title: this.$t('betriebe_mit_plangenehmigungspflicht'),
                    to: { name: 'bg_edit_betriebe_mit_plangenehmigungspflicht' },
                    valid: false,
                    group: 'bauvorhaben',
                    hidden:
                        !this.additionalData.get('arbeitnehmende_beschaeftigt_verordnung_4') ||
                        (this.baugesuch.get('intended_purpose') ?? []).indexOf('commercial_and_industrial_use') < 0
                },
                // ---------------
                {
                    id: 'schutzraeume',
                    title: this.$t('schutzraum'),
                    to: { name: 'bg_edit_schutzraeume' },
                    valid: false,
                    group: 'triage',
                    hideInStatus: ['new']
                },
                // ---------------

                // ---------------
                {
                    id: 'zonenplan',
                    title: this.$t('oereb_zonenplan'),
                    to: { name: 'bg_edit_zonenplan' },
                    valid: false,
                    group: 'parzellinfo'
                },
                {
                    id: 'gischecks',
                    title: this.$t('lage_an_in_gischecks'),
                    to: { name: 'bg_edit_gischecks' },
                    valid: false,
                    group: 'parzellinfo'
                },
                {
                    id: 'anstoesser',
                    title: this.$t('anstoesser'),
                    to: { name: 'bg_edit_anstoesser' },
                    valid: false,
                    group: 'parzellinfo'
                },
                // ---------------
                {
                    id: 'geschaeftsunterlagen',
                    title: this.$t('geschaeftsunterlagen'),
                    to: { name: 'bg_edit_unterlagen' },
                    valid: false,
                    group: '003'
                },
                {
                    id: 'abschluss',
                    title: this.$t('abschluss'),
                    to: { name: 'bg_edit_abschluss' },
                    valid: true,
                    hideValid: true,
                    hideIfReadonly: true,
                    group: '003'
                }
            ]
        };
    },
    computed: {
        heizung_geraete_vorgesehen() {
            return this.additionalData.get('heizung_geraete_vorgesehen');
        },
        heizung_bau_vorgesehen() {
            return this.additionalData.get('heizung_bau_vorgesehen');
        },
        arbeitnehmende_beschaeftigt_verordnung_4() {
            return this.additionalData.get('arbeitnehmende_beschaeftigt_verordnung_4');
        },
        intended_purpose() {
            return this.baugesuch.get('intended_purpose');
        }
    },
    watch: {
        heizung_geraete_vorgesehen() {
            let newItems = [];

            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                if (item.id === 'ls_hlkk') {
                    item.hidden = !this.heizung_geraete_vorgesehen;
                }
                newItems.push(item);
            }

            this.items = newItems;
        },

        heizung_bau_vorgesehen() {
            let newItems = [];

            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                if (item.id === 'heizungsart') {
                    item.hidden = !this.heizung_bau_vorgesehen;
                }
                newItems.push(item);
            }

            this.items = newItems;
        },

        arbeitnehmende_beschaeftigt_verordnung_4() {
            let newItems = [];

            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                if (item.id === 'betriebe_mit_plangenehmigungspflicht') {
                    item.hidden =
                        !this.arbeitnehmende_beschaeftigt_verordnung_4 ||
                        (this.baugesuch.get('intended_purpose') ?? []).indexOf('commercial_and_industrial_use') < 0;
                }
                newItems.push(item);
            }

            this.items = newItems;
        },

        intended_purpose() {
            let newItems = [];

            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                if (item.id === 'betriebe_mit_plangenehmigungspflicht') {
                    item.hidden =
                        !this.arbeitnehmende_beschaeftigt_verordnung_4 ||
                        (this.baugesuch.get('intended_purpose') ?? []).indexOf('commercial_and_industrial_use') < 0;
                }
                newItems.push(item);
            }

            this.items = newItems;
        }
    }
};
</script>
