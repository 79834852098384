<template>
    <i :class="classObject" :title="title"></i>
</template>

<script>
export default {
    props: {
        /** the normal icon class name including color, e.g. 'new-white' */
        iconCls: String,

        /** if used in button context, the disabled btn icon class name including color, e.g. 'new-grey' */
        iconDisabledCls: String,

        /** if used in list/menu context, the active btn icon class name including color, e.g. 'new-grey' */
        iconActiveCls: String,

        /** a small right-padding, if used left of an item */
        left: Boolean,

        /** a small left-padding, if used left of an item */
        right: Boolean,

        title: String
    },
    computed: {
        classObject() {
            return {
                ic: true,
                [`ic-${this.size || 20}`]: true,
                [`ic-${this.iconCls}`]: true,
                [`ic-disabled-${this.iconDisabledCls}`]: this.iconDisabledCls,
                [`ic-active-${this.iconActiveCls}`]: this.iconActiveCls,
                'mr-1': this.left,
                'ml-1': this.right
            };
        }
    }
};
</script>
