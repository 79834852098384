/**
 * Store module: EchCode
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { EchCode, EchCodeCollection } from '@/models/EchCode';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchEchCodesByVariable({ commit }, options = {}) {
            let list = new EchCodeCollection();
            try {
                commit('loading', true, { root: true });
                let opts = {
                    filter: [
                        { property: 'ech_doc_id', value: options.ech_doc_id },
                        { property: 'ech_variable_name', value: options.ech_variable_name }
                    ]
                };
                await list.query(opts);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async fetchEchCodes({ commit }, options = {}) {
            let list = new EchCodeCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadEchCode({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new EchCode();
                } else {
                    record = new EchCode({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        }
    }
};
