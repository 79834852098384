<template>
    <div>
        <base-list
            session-key="parz-list-local"
            :maintitle="title"
            maintitle-icon-cls="fa-regular fa-map"
            :show-query="false"
            :enable-reload="false"
            :headers="headers"
            :items="parzellen.models"
            :loading="loading"
            :dense="dense"
            :height="height"
            :options="options"
            hide-default-footer
        >
            <template #after-query-field>
                <div v-if="enableAdding && !readonly">
                    <v-btn v-if="!adding" rounded class="hidden-xs-only ml-1" color="subtleHint" @click="onAddParzelle">
                        <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                        {{ $t('add_parzelle') }}
                    </v-btn>
                    <v-btn v-else rounded class="hidden-xs-only ml-1" color="subtleHint" @click="onStopAdding">
                        {{ $t('cancel') }}
                    </v-btn>
                </div>
            </template>
            <template #item.myactions="{ item }">
                <icon-btn
                    v-if="
                        enableAdding &&
                        !readonly &&
                        ((item.is_neighbour && item.is_manually_added_neighbour) || !item.is_neighbour)
                    "
                    icon-cls="fa-solid fa-trash"
                    icon-color="grey"
                    :tooltip="$t('remove')"
                    @click.stop="$emit('removeParzelle', item)"
                />
                <icon-btn
                    icon-cls="fa-regular fa-file-pdf"
                    icon-color="grey"
                    :tooltip="$t('oereb_doc_download')"
                    @click.stop="downloadOerebFile(item)"
                />
            </template>
        </base-list>
    </div>
</template>
<script>
import { getI18nInstance } from '@/i18n';
import sessionStorageMixin from '@/mixins/session-storage';

export default {
    name: 'UserIndexLocal',
    components: {},
    mixins: [sessionStorageMixin],
    props: {
        parzellen: {
            type: Object,
            default: () => {
                return {};
            }
        },
        enableAdding: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: function () {
                return this.$t('parzellen');
            }
        },
        dense: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    data() {
        this.sessionKey = 'parz-index-local';
        return {
            expanded: [],
            loading: false,
            adding: false,
            headers: [
                {
                    text: this.$t('parzellen_nummer'),
                    value: 'nummer',
                    sortable: false,
                    filterable: false,
                    width: '20%'
                },
                {
                    text: this.$t('egrid'),
                    value: 'egrid',
                    sortable: false,
                    filterable: false,
                    width: '80%'
                },
                { text: this.$t('aktionen'), sortable: false, width: 160, value: 'myactions' }
            ],
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['parzellen_nummer'],
                sortDesc: [false],
                multiSort: false,
                mustSort: true
            }
        };
    },

    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },

        translate(val) {
            const vue18n = getI18nInstance();
            return vue18n.t(val);
        },

        onAddParzelle() {
            this.adding = true;
            this.$emit('startAdding');
        },

        onStopAdding() {
            this.adding = false;
            this.$emit('stopAdding');
        },

        downloadOerebFile(item) {
            let url = this.$store.state.globals.oereburl + '/pdf?EGRID=' + item.egrid;
            window.open(url, 'oereb');
        }
    }
};
</script>
