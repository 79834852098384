<template>
    <v-app id="guest-app">
        <AppHeaderBarGuestTG v-if="$route.name !== 'welcome'" app />
        <v-main class="white lighten-2">
            <v-fade-transition mode="out-in">
                <router-view />
            </v-fade-transition>
        </v-main>
        <KpFooterTG app />
    </v-app>
</template>

<script>
import AppHeaderBarGuestTG from '@/components/app-header-bar-guest.vue';
import KpFooterTG from '@/components/app-footer.vue';

export default {
    components: {
        AppHeaderBarGuestTG,
        KpFooterTG
    }
};
</script>
