/**
 * RelationshipToPerson entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class RelationshipToPerson extends BaseModel {
    defaults() {
        return {
            id: null,
            planning_permission_application_id: null,
            role: null,
            address_type: 'person',
            //eCH
            mr_mrs: null,
            title: null,
            first_name: null,
            last_name: null,
            address_line_1: null,
            address_line_2: null,
            street: null,
            house_number: null,
            dwelling_number: null,
            post_office_box: null,
            post_office_box_text: null,
            swiss_zip_code: null,
            swiss_zip_code_add_on: null,
            swiss_zip_code_id: null,
            foreign_zip_code: null,
            locality: null,
            town: null,
            country_id: null,
            organisation_name: null,
            organisation_name_add_on_1: null,
            organisation_name_add_on_2: null,
            email: null,
            phone: null,

            // View
            name_text: '',
            country_short_name_de: '',
            country_iso2_id: ''
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'RelationshipToPerson';
    }
}

export class RelationshipToPersonCollection extends BaseCollection {
    options() {
        return {
            model: RelationshipToPerson
        };
    }

    entityName() {
        return 'RelationshipToPerson';
    }
}
