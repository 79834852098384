import { instance as getApi } from '@/helpers/api.js';
import { getI18nInstance } from '@/i18n';
import { apiUrl } from '@/helpers/globals';

/** only used when localStorage is not available */
let noStorageCsrfToken = null;

/**
 * Stores the CSRF token in the browser's localStorage
 * for further use during the session.
 *
 * This is needed to support multiple browser windows.
 *
 * @param {String} token
 */
export function storeCsrfToken(token) {
    if (Storage) {
        localStorage.setItem('ebau.csrfToken', token);
    } else {
        noStorageCsrfToken = token;
    }
}

/**
 * Retrieves the csrf token from the browser's
 * local storage
 */
export function getCsrfToken() {
    if (Storage) {
        return localStorage.getItem('ebau.csrfToken') || '';
    } else {
        return noStorageCsrfToken || '';
    }
}

export async function login(username, password) {
    const vueI18n = getI18nInstance();
    try {
        let response = await getApi().post('/auth/login', {
            username: username,
            password: password
        });
        if (response.data.user) {
            let redirectUrl = '/';
            let split = window.location.href.split('?redirect=');
            if (split.length === 2) {
                redirectUrl = split[1];
            }
            return { user: response.data.user, redirectUrl: redirectUrl };
        } else {
            throw new Error(vueI18n.t('denied_wrong_login'));
        }
    } catch (e) {
        // if (e.response && e.response.data && e.response.data.error && e.response.data.error.msg) {
        //     throw new Error(vueI18n.t(e.response.data.error.msg));
        // }
        throw new Error(vueI18n.t('denied_wrong_login'));
    }
}

// export async function resetPassword(username) {
//     try {
//         await getApi().post('/auth/reset', {
//             email: username
//         });
//     } catch (e) {
//         throw new Error('Hoppla, da ist was schief gelaufen...');
//     }
// }

export async function logout() {
    window.location.href = apiUrl('/auth/logout');
}

export async function verifyUser(password) {
    try {
        let response = await getApi().post('/auth/verifyUser', {
            password: password
        });
        return response.data.verified;
    } catch (e) {
        throw new Error('Hoppla, da ist was schief gelaufen...');
    }
}

export default {
    login,
    logout,
    verifyUser
    // resetPassword,
};
