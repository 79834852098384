import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/de-ch';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Configure dayjs locale:
dayjs.locale('de-ch');
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

Vue.prototype.$dayjs = dayjs;
