/**
 * Ort entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class BusinessType extends BaseModel {
    defaults() {
        return {
            id: null,
            bezeichnung: null,
            ech_value: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'BusinessType';
    }
}

export class BusinessTypeCollection extends BaseCollection {
    options() {
        return {
            model: BusinessType
        };
    }

    entityName() {
        return 'BusinessType';
    }
}
