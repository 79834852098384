<template>
    <v-container>
        <v-form v-if="additionalData" :readonly="readonlySchutzraum" class="my-4">
            <v-row>
                <v-col md="3" class="text-right"></v-col>
                <v-col md="9">
                    {{ $t('shelter_required') }}
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3" class="text-right"></v-col>
                <v-col md="3">
                    <v-select
                        v-model="additionalData.civil_defence_shelter_required"
                        :items="yesNo"
                        :readonly="readonlySchutzraum"
                        hide-details
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="additionalData.civil_defence_shelter_required">
                <v-col md="3" class="text-right"></v-col>
                <v-col md="9">
                    <v-textarea
                        v-model="additionalData.civil_defence_comments"
                        :label="$t('comments_shelter_control')"
                        rows="3"
                        :readonly="readonlySchutzraum"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3" class="text-right"></v-col>
                <v-col md="9">
                    {{ $t('civil_defence_shelter_request') }}
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3" class="text-right"></v-col>
                <v-col md="3">
                    <v-checkbox
                        v-model="additionalData.civil_defence_shelter_replacement_contribution"
                        :label="$t('civil_defence_shelter_replacement_contribution')"
                        :readonly="readonlySchutzraum"
                        @change="onCheckboxChange($event, 'civil_defence_shelter_replacement_contribution')"
                    ></v-checkbox>
                </v-col>
                <v-col md="3">
                    <v-checkbox
                        v-model="additionalData.civil_defence_shelter_create_mandatory_shelter"
                        :label="$t('civil_defence_shelter_create_mandatory_shelter')"
                        :readonly="readonlySchutzraum"
                        @change="onCheckboxChange($event, 'civil_defence_shelter_create_mandatory_shelter')"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3" class="text-right"></v-col>
                <v-col md="6">
                    <v-checkbox
                        v-model="additionalData.civil_defence_shelter_create_public_shelter"
                        :label="$t('civil_defence_shelter_create_public_shelter')"
                        :readonly="readonlySchutzraum"
                        @change="onCheckboxChange($event, 'civil_defence_shelter_create_public_shelter')"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { formRules } from '@/helpers/forms.js';
import { mapActions } from 'vuex';
import { AdditionalBaugesuchData } from '@/models/AdditionalBaugesuchData.js';
export default {
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            formRules,
            record: this.gesuch,
            additionalData: null,
            requireds: false,
            yesNo: [
                { value: false, text: this.$t('no') },
                { value: true, text: this.$t('yes') }
            ]
        };
    },
    computed: {
        readonlySchutzraum() {
            if (this.user.isRoleGemeinde || this.user.isRoleAdmin) {
                return false;
            }

            if (this.readonly) {
                return true;
            }

            return false;
        }
    },
    created() {
        this.loadAdditionalData();
    },
    methods: {
        ...mapActions('additional_baugesuch_data', [
            'fetchAdditionalBaugesuchData',
            'storeAdditionalBaugesuchData',
            'destroyAdditionalBaugesuchData'
        ]),

        async loadAdditionalData() {
            this.loading = true;
            try {
                const data = await this.fetchAdditionalBaugesuchData({
                    filter: [
                        {
                            id: 'planning_permission_application_id',
                            property: 'planning_permission_application_id',
                            value: this.gesuch.id
                        }
                    ]
                });
                this.additionalData = data.first();
                if (!this.additionalData) {
                    this.additionalData = new AdditionalBaugesuchData({
                        planning_permission_application_id: this.gesuch.id
                    });
                }
                if (
                    this.additionalData.construction_project_type === 'building_new' &&
                    this.gesuch.intended_purpose.includes('residential_use')
                ) {
                    this.additionalData.civil_defence_shelter_required = true;
                    this.requiredReadOnly = true;
                }
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        async beforeSave() {
            if (this.additionalData) {
                if (this.shelter) {
                    this.additionalData.civil_defence_shelter = this.shelter.value;
                }
                await this.storeAdditionalBaugesuchData(this.additionalData);
            }
        },
        async readonlySave() {
            if (this.readonly && (this.user.isRoleAdmin || this.user.isRoleGemeinde)) {
                if (this.additionalData) {
                    if (this.shelter) {
                        this.additionalData.civil_defence_shelter = this.shelter.value;
                    }
                    await this.storeAdditionalBaugesuchData(this.additionalData);
                }
            }
        },

        async onCheckboxChange(value, field) {
            if (this.additionalData) {
                // allow only one checkbox to be checked
                if (field !== 'civil_defence_shelter_replacement_contribution') {
                    this.additionalData.civil_defence_shelter_replacement_contribution = false;
                }
                if (field !== 'civil_defence_shelter_create_mandatory_shelter') {
                    this.additionalData.civil_defence_shelter_create_mandatory_shelter = false;
                }
                if (field !== 'civil_defence_shelter_create_public_shelter') {
                    this.additionalData.civil_defence_shelter_create_public_shelter = false;
                }
            }
        }
    }
};
</script>
