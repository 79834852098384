<template>
    <v-autocomplete
        :value="value"
        :dense="dense"
        :readonly="readonly"
        :label="myLabel"
        :items="resultCollection ? resultCollection.models : []"
        :loading="isLoading"
        :search-input.sync="query"
        :rules="rules"
        item-text="full_display"
        item-value="id"
        clear-icon="fa-solid fa-xmark"
        hide-no-data
        hide-selected
        prepend-inner-icon="fa-solid fa-magnifying-glass"
        v-on="$listeners"
    >
    </v-autocomplete>
</template>

<script>
import { debounce } from '@/helpers/tools.js';
import { formRules } from '@/helpers/forms.js';
import { getI18nInstance as i18n } from '@/i18n';
import { PlanningPermissionApplicationCollection } from '@/models/PlanningPermissionApplication';

export default {
    props: {
        rules: {
            type: Array,
            default: () => []
        },
        dense: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            resultCollection: null,
            isLoading: false,
            query: null,
            formRules: formRules,
            oldSearchInput: false
        };
    },
    computed: {
        myLabel() {
            return this.label !== null
                ? this.label
                : this.type === 'bg'
                ? i18n().t('baugesuche')
                : i18n().t('planungsgeschaefte');
        }
    },

    watch: {
        query: debounce(async function (val, oldVal) {
            // prevent lookup on first render (no user input)
            if (!oldVal) {
                return;
            }
            // Here comes an ugly hack: we need to prevent loading
            // when a value is selected from the dropdown list.
            // But as we cannot determine if this function was called
            // during a user text entry, or due to a selection from
            // the OTHER field, we reset the preventLoading flag after.... some time
            if (this.preventLoading) {
                setTimeout(() => (this.preventLoading = false), 500);
                return;
            }
            if (!val || val.length < 3 || this.isLoading) {
                return;
            }
            if (!this.resultCollection) {
                this.resultCollection = new PlanningPermissionApplicationCollection();
            }
            await this.loadRecords({ query: val });
        })
    },
    mounted() {
        if (this.value) {
            this.loadRecords({ filter: [{ property: 'id', value: this.value }] });
        }
    },

    methods: {
        async loadRecords(query) {
            this.isLoading = true;
            if (!this.resultCollection) {
                this.resultCollection = new PlanningPermissionApplicationCollection();
            }
            await this.resultCollection.query(query);
            this.isLoading = false;
        }
    }
};
</script>
