<template>
    <v-btn icon class="menu-item" :class="{ 'is-active': isActive ? isActive() : null }" :title="title" @click="action">
        <v-icon small>fas fa-{{ icon }} fa-xs</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        },

        title: {
            type: String,
            required: true
        },

        action: {
            type: Function,
            required: true
        },

        isActive: {
            type: Function,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.menu-item {
    max-width: 1.75rem !important;
    min-width: 1.75rem !important;
    max-height: 1.75rem !important;
    color: #333;
    background-color: transparent !important;
    padding: 0.25rem;
    margin-right: 0.25rem;

    &.is-active,
    &:hover {
        color: var(--v-accent-base);
        background-color: var(--v-primary-base);
    }
}
</style>
