/**
 * Access to the window.sessionStorage object in components: Makes it
 * easy to get/set session-only values (like settings in a query field, sort directions, ....)
 *
 * Note that you have to set a 'sessionKey' property (either via prop or data) on your
 * component, do uniquely identify it: it avoids naming conflicts in different components.
 *
 * Use it as a mixin in VueJS components
 *
 */
const sessionStorageMixin = {
    methods: {
        sessionValueKey(key) {
            const prefix = this.sessionKey;
            if (!prefix) {
                throw new Error(
                    'session storage mixin: to use this mixin, you HAVE to set a unique sessionKey (either via prop or data) on the Vue component.'
                );
            }
            return `${prefix}-${key}`;
        },

        /**
         * Returns a value from the session by key, or a default value.
         *
         * @param {String} key
         * @param {any} defaultValue
         * @returns {any} The stored value, if any
         */
        getSessionValue(key, defaultValue = null) {
            if (window.sessionStorage) {
                let rawVal = window.sessionStorage.getItem(this.sessionValueKey(key)) || null;
                return rawVal ? JSON.parse(rawVal) : defaultValue;
            }
            return defaultValue;
        },

        /**
         * Sets a value in the session. Note that all values are serialzed (json.stringify), as the
         * session storage only allows string values.
         * @param {String} key
         * @param {any} value
         */
        setSessionValue(key, value) {
            if (window.sessionStorage && value !== undefined) {
                window.sessionStorage.setItem(this.sessionValueKey(key), JSON.stringify(value));
            }
        },

        /**
         * Removes an entry in the session by key
         * @param {String} key
         */
        clearSessionValue(key) {
            if (window.sessionStorage) {
                window.sessionStorage.removeItem(this.sessionValueKey(key));
            }
        }
    }
};

export default sessionStorageMixin;
