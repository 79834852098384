/**
 * Base collection model. All Minasa model collections should inherit this class.
 *
 * This file is part of Minasa
 * (c) Kaden und Partner AG
 */
import { Collection } from 'vue-mc';
import { instance as getApi, getTokenSource } from '@/helpers/api';

const globalCancelTokens = {};

export default class BaseCollection extends Collection {
    defaults() {
        return {
            // The total record count as delivered by the backend
            remoteTotal: 0,
            apiCancelToken: null
        };
    }
    /**
     * Override in child classes: This is the API endpoint's
     * entity name (e.g. like in /api/User/list --> User)
     *
     * @return {String} The API endpoint's Entity name
     */
    entityName() {
        return this.constructor.name;
    }

    /**
     * Creates a new cancel token, cancels an ALREADY issued token for this collection.
     * If the 'apiCancelToken' attribute is set on this collection, a global token
     * will be used, otherwise an instance-only token is used.
     */
    createCancelToken() {
        let tokenSrc = null;
        let cancelTokenIdent = this.get('apiCancelToken');

        // Cancel an already running request, if applicable:
        if (cancelTokenIdent && globalCancelTokens[cancelTokenIdent]) {
            tokenSrc = globalCancelTokens[cancelTokenIdent];
        } else {
            tokenSrc = this._cancelTokenSrc;
        }

        if (tokenSrc) {
            tokenSrc.cancel();
            this.removeCancelToken();
        }

        tokenSrc = getTokenSource();

        // If requested, store a global cancel token for later cancellation:
        if (cancelTokenIdent) {
            globalCancelTokens[cancelTokenIdent] = tokenSrc;
        }
        this._cancelTokenSrc = tokenSrc;
        return tokenSrc.token;
    }

    removeCancelToken() {
        let cancelTokenIdent = this.get('apiCancelToken');
        if (cancelTokenIdent) {
            delete globalCancelTokens[cancelTokenIdent];
        }
        this._cancelTokenSrc = null;
    }

    setRemoteSort(sortObj) {
        if (!this._sorters) {
            this._sorters = [];
        }
        if (!(sortObj instanceof Array)) {
            sortObj = [sortObj];
        }
        this._sorters = [...sortObj];
        return this;
    }

    addRemoteSort(sortObj) {
        if (!(sortObj instanceof Array)) {
            sortObj = [sortObj];
        }
        return this.setRemoteSort([...(this._sorters || []), ...sortObj]);
    }

    /**
     * Add extra paramters that are sent with every request, e.g. a fixed
     * filter param for an entity.
     * @param {} key
     * @param {*} value
     */
    addExtraParam(key, value) {
        if (!this._extraParams) {
            this._extraParams = {};
        }
        this._extraParams[key] = value;
        return this;
    }

    removeExtraParam(key) {
        if (!this._extraParams) {
            this._extraParams = {};
        }
        delete this._extraParams[key];
        return this;
    }

    /**
     * Simple, generic query function: calls /entity/list API, with
     * the given options as request params.
     *
     * Fills this collection with the returned records.
     *
     * @param {Object} options request parameters
     */
    async query(options) {
        this.loading = true;

        // apply sorters
        if (this._sorters) {
            options = {
                ...{ sort: this._sorters },
                ...options
            };
        }
        options = { ...options, ...(this._extraParams || {}) };

        let cancelToken = this.createCancelToken();
        let response = await getApi().post(`/${this.entityName()}/list`, options, {
            cancelToken: cancelToken
        });
        this.removeCancelToken();

        this.replace(response ? response.data.records : null);
        this.set('remoteTotal', response ? response.data.total : null);
        this.loading = false;
        return this;
    }
}
