<template>
    <v-container fluid class="text-center">
        <v-data-table
            v-model="selectedItems"
            :class="{
                'fixed-table': true,
                'fixed-overflow-table': !dense
            }"
            :headers="headers"
            :calculate-widths="calculateWidth"
            :items="RelationshipToPersons.models"
            :options.sync="options"
            :server-items-length="total"
            :loading="RelationshipToPersonsLoading"
            :dense="dense"
            :single-select="true"
            item-key="id"
            :show-select="selectable"
            :hide-default-footer="nofooter"
            no-data-text="keine Datensätze vorhanden"
            :height="height"
            fixed-header
            :disable-pagination="true"
            @click:row="onRowClicked"
            @item-selected="onItemSelected"
        >
            <template #[`item.role`]="{ item }">
                <span>
                    {{ $t(item.role) }}
                </span>
            </template>

            <template #[`item.action`]="{ item }">
                <span>
                    <v-btn
                        v-if="!readonly"
                        class="mr-1"
                        x-small
                        fab
                        rounded
                        color="error"
                        @click.stop="destroyItem(item)"
                    >
                        <v-icon x-small>fa-solid fa-trash</v-icon>
                    </v-btn>
                </span>
            </template>
        </v-data-table>
        <v-btn v-if="!readonly" class="ma-2" outlined fab small @click="onAddClicked">
            <v-icon>fa-solid fa-plus</v-icon>
        </v-btn>
        <Dialog
            v-if="relationshipToPersonRecord && showNewDialog"
            :relationship-to-person="relationshipToPersonRecord"
            :first-gesuchssteller="firstGesuchsteller"
            :show="showNewDialog"
            :context="context"
            :readonly="readonly"
            @close="showNewDialog = false"
            @saved="onRecordSaved"
        ></Dialog>
        <ConfirmDialog
            v-model="showConfirmDelDialog"
            title="Bestätigung"
            text="Soll dieser Datensatz wirklich gelöscht werden?"
            @yes="confirmDestroy"
        />
    </v-container>
</template>

<script>
import { RelationshipToPerson } from '@/models/RelationshipToPerson';
import { mapActions, mapState } from 'vuex';
import { errToStr } from '@/helpers/errors';

import Dialog from '@/components/relationship-to-person/relationship-to-person-dialog.vue';
import ConfirmDialog from '@/components/confirm_dialog.vue';

export default {
    components: { Dialog, ConfirmDialog },
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: null
        },
        context: { type: String, default: 'pg' },
        dense: { type: Boolean, default: false },
        nofooter: { type: Boolean, default: true },
        selectable: { type: Boolean, default: false },
        height: { type: String, default: 'calc(100vh - 340px)' },
        selection: { type: RelationshipToPerson, default: null },
        edit: { type: Boolean, default: true },
        reload: { type: Boolean, default: false },
        readonly: { type: Boolean, default: true },
        defaultNewType: { type: String, default: 'person' }
    },
    data() {
        return {
            RelationshipToPersons: [],
            RelationshipToPersonsLoading: false,
            calculateWidth: true,
            total: 0,
            selectedItems: [],
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['id'],
                sortDesc: [false],
                multiSort: false,
                mustSort: true
            },
            filter: [
                {
                    id: 'planning_permission_application_id',
                    property: 'planning_permission_application_id',
                    value: this.planningPermissionApplicationId
                }
            ],
            showNewDialog: false,
            relationshipToPersonRecord: null,
            showConfirmDelDialog: false,
            toDelete: null,
            firstGesuchsteller: null
        };
    },
    computed: {
        params() {
            return {
                ...this.options,
                filter: this.filter
            };
        },
        headers() {
            let res = [
                {
                    text: this.$t('rolle'),
                    value: 'role',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                },
                {
                    text: this.$t('name'),
                    value: 'name_text',
                    sortable: true,
                    filterable: true,
                    width: '25%'
                },
                {
                    text: this.$t('telefon') + ' / ' + this.$t('mobile'),
                    value: 'phone',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                },
                {
                    text: this.$t('email'),
                    value: 'email',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                }
            ];
            if (this.edit) {
                res.push({
                    value: 'action',
                    sortable: false,
                    filterable: false,
                    width: '5%'
                });
            }

            return res;
        }
    },
    watch: {
        reload: {
            async handler(val) {
                if (val) {
                    await this.getRelationshipToPersons();
                    this.$emit('reloaded');
                }
            }
        },
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getRelationshipToPersons();
                }
            },
            deep: true
        },
        selection: {
            handler(val) {
                if (!Array.isArray(val)) {
                    val = [val];
                }
                this.selectedItems = val;
            }
        }
    },
    methods: {
        ...mapState('relationship_to_person', {
            relationship_to_person: 'record'
        }),
        ...mapActions('relationship_to_person', [
            'fetchRelationshipToPersons',
            'storeRelationshipToPerson',
            'loadRelationshipToPerson',
            'destroyRelationshipToPerson'
        ]),
        async getRelationshipToPersons() {
            this.RelationshipToPersonsLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                let data = await this.fetchRelationshipToPersons(this.params);
                this.RelationshipToPersons = data;
                this.total = data.get('remoteTotal');
                this.firstGesuchsteller = this.RelationshipToPersons
                    ? this.RelationshipToPersons.find({ role: 'applicant' })
                    : null;
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.RelationshipToPersonsLoading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onRowClicked(row) {
            this.showForm(row.id);
        },
        async showForm(id) {
            try {
                this.relationshipToPersonRecord = await this.loadRelationshipToPerson(id);
                this.showNewDialog = true;
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        onItemSelected(evt) {
            this.selectedItems = evt.value ? [evt.item] : [];
            this.emitSelection();
        },
        emitSelection() {
            if (this.selectedItems.length === 1) {
                this.$emit('selection', this.selectedItems[0]);
            }
        },
        async onAddClicked() {
            try {
                this.relationshipToPersonRecord = new RelationshipToPerson({
                    id: 'new',
                    planning_permission_application_id: this.planningPermissionApplicationId,
                    address_type: this.defaultNewType
                });
                this.showNewDialog = true;
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        async onRecordSaved() {
            try {
                this.relationshipToPersonRecord = null;
                this.showNewDialog = false;
                this.getRelationshipToPersons();
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        destroyItem(item) {
            this.toDelete = item;
            this.showConfirmDelDialog = true;
        },
        async confirmDestroy() {
            try {
                await this.destroyRelationshipToPerson(this.toDelete);
                this.toDelete = null;
                this.total = this.total - 1;
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        }
    }
};
</script>

<style scoped>
.fixed-table >>> table {
    table-layout: fixed;
    width: 100%;
}
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    .fixed-table >>> table td {
        text-overflow: ellipsis;
    }
    .fixed-overflow-table >>> table td {
        overflow: hidden;
    }
}
@media screen and (max-width: 600px) {
    .fixed-table >>> table th {
        width: 100%;
    }
}
</style>
