<template>
    <v-container fluid class="text-center">
        <v-data-table
            v-model="selectedItems"
            :class="{
                'fixed-table': true,
                'fixed-overflow-table': !dense
            }"
            :headers="headers"
            :calculate-widths="calculateWidth"
            :items="DecisionRuling.models"
            :options.sync="options"
            :server-items-length="total"
            :loading="DecisionRulingLoading"
            :dense="dense"
            :single-select="true"
            item-key="id"
            :show-select="selectable"
            :hide-default-footer="nofooter"
            no-data-text="keine Datensätze vorhanden"
            :height="height"
            fixed-header
            :disable-pagination="true"
            @click:row="onRowClicked"
            @item-selected="onItemSelected"
        >
            <template #[`item.created`]="{ item }">
                {{ formatDateTime(item.created) }}
            </template>
            <template #[`item.files`]="{ item }">
                <v-btn icon dark x-small color="primary" @click="onShowFiles(item)">
                    <v-icon dark left>fa-regular fa-file</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-dialog v-model="showFilesDialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t('files') }}</span>
                </v-card-title>
                <v-card-text>
                    <fileupload
                        ref="fileupload"
                        :parent-id="filesDialogPPAId"
                        :contexts="['decision_ruling']"
                        entity="baugesuch"
                        :decision-ruling-id="filesDialogDecisionRulingId"
                    ></fileupload>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { DecisionRuling } from '@/models/DecisionRuling';
import { mapActions, mapState } from 'vuex';
import DateFunctions from '@/mixins/date-functions.js';
import Fileupload from '@/components/fileupload';

export default {
    components: { Fileupload },
    mixins: [DateFunctions],
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'decision_ruling'
        },

        dense: { type: Boolean, default: false },
        nofooter: { type: Boolean, default: true },
        selectable: { type: Boolean, default: false },
        height: { type: String, default: 'calc(100vh - 340px)' },
        selection: { type: DecisionRuling, default: null },
        edit: { type: Boolean, default: true }
    },
    data() {
        return {
            DecisionRuling: [],
            DecisionRulingLoading: false,
            calculateWidth: true,
            total: 0,
            selectedItems: [],
            dlgMessage: null,
            showFilesDialog: false,
            filesDialogPPAId: null,
            filesDialogDecisionRulingId: null,
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['id'],
                sortDesc: [false],
                multiSort: false,
                mustSort: true
            },
            filter: [
                {
                    id: 'planning_permission_application_id',
                    property: 'planning_permission_application_id',
                    value: this.planningPermissionApplicationId
                },
                {
                    id: 'type',
                    property: 'type',
                    value: this.type
                }
            ]
        };
    },
    computed: {
        params() {
            return {
                ...this.options,
                filter: this.filter
            };
        },
        headers() {
            let res = [
                {
                    text: this.$t('decision_ruling'),
                    value: 'judgement_type_display_value',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('comment'),
                    value: 'ruling',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('files'),
                    value: 'files',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('created'),
                    value: 'created',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('creator'),
                    value: 'creator',
                    sortable: true,
                    filterable: true
                }
            ];
            if (this.edit) {
                res.push({
                    value: 'action',
                    sortable: false,
                    filterable: false,
                    width: '5%'
                });
            }

            return res;
        },
        showAddBtn() {
            return this.user.isRoleAdmin;
        },
        ...mapState('user', {
            user: 'record'
        })
    },
    watch: {
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getDecisionRuling();
                }
            },
            deep: true
        },
        selection: {
            handler(val) {
                if (!Array.isArray(val)) {
                    val = [val];
                }
                this.selectedItems = val;
            }
        }
    },
    methods: {
        ...mapState('decision_ruling', {
            decision_ruling: 'record'
        }),
        ...mapActions('decision_ruling', ['fetchDecisionRuling']),
        async getDecisionRuling() {
            this.DecisionRulingLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                let data = await this.fetchDecisionRuling(this.params);
                this.DecisionRuling = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.DecisionRulingLoading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onRowClicked(row) {},
        onItemSelected(evt) {
            this.selectedItems = evt.value ? [evt.item] : [];
            this.emitSelection();
        },
        emitSelection() {
            if (this.selectedItems.length === 1) {
                this.$emit('selection', this.selectedItems[0]);
            }
        },
        onShowFiles(item) {
            this.showFilesDialog = true;
            this.filesDialogPPAId = this.planningPermissionApplicationId;
            this.filesDialogDecisionRulingId = item.id;
        }
    }
};
</script>

<style scoped>
.fixed-table >>> table {
    table-layout: fixed;
    width: 100%;
}
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    .fixed-table >>> table td {
        text-overflow: ellipsis;
    }
    .fixed-overflow-table >>> table td {
        overflow: hidden;
    }
}
@media screen and (max-width: 600px) {
    .fixed-table >>> table th {
        width: 100%;
    }
}
</style>
