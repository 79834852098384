<template>
    <v-skeleton-loader v-if="loading" type="list-item-avatar, list-item-three-line@3, actions"></v-skeleton-loader>
    <div v-else>
        <v-container fluid>
            <v-card fluid class="px-10">
                <v-card-title text-color="white">
                    {{ formTitle }}
                </v-card-title>

                <v-tabs v-model="activeTab">
                    <v-tab key="grunddaten">{{ $t('adresse') }}</v-tab>
                    <v-tab v-if="!readonly || invited" key="members">
                        {{ $t('benutzer') }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab">
                    <v-tab-item key="grunddaten">
                        <v-card elevation="0">
                            <v-form
                                v-model="valid"
                                class="mt-2"
                                @input="$emit('validchange', valid)"
                                @submit.stop="onSubmit"
                            >
                                <v-row v-if="user.isRoleAdmin" dense>
                                    <v-col>
                                        <v-checkbox
                                            v-model="stelle.active"
                                            :label="$t('active')"
                                            :readonly="readonly"
                                        ></v-checkbox></v-col
                                ></v-row>
                                <v-row dense>
                                    <v-col>
                                        <v-text-field
                                            v-model="stelle.organisation_name"
                                            :label="$t('name')"
                                            :readonly="readonly || !user.isRoleAdmin"
                                        ></v-text-field></v-col
                                ></v-row>
                                <v-row dense>
                                    <v-col>
                                        <v-text-field
                                            v-model="stelle.uid"
                                            :label="$t('uid')"
                                            :readonly="readonly || !user.isRoleAdmin"
                                        ></v-text-field></v-col
                                    ><v-col v-if="!readonly && user.isRoleAdmin">
                                        <v-text-field
                                            v-model="stelle.iam_id"
                                            :label="$t('iam_id')"
                                        ></v-text-field></v-col
                                ></v-row>
                                <v-row dense>
                                    <v-col
                                        ><StelleTypSelect
                                            v-model="stelle.type"
                                            :label="$t('name')"
                                            :readonly="!user || !user.isRoleAdmin"
                                        ></StelleTypSelect></v-col
                                    ><v-col
                                        ><v-text-field
                                            v-if="stelle.type === 'gemeinde'"
                                            v-model="stelle.bfsnr"
                                            :label="$t('bfsnr')"
                                            :readonly="readonly || !user.isRoleAdmin"
                                        ></v-text-field></v-col
                                ></v-row>
                                <v-row dense>
                                    <v-col
                                        ><v-text-field
                                            v-model="stelle.street"
                                            :label="$t('strasse')"
                                            :readonly="readonly"
                                        ></v-text-field
                                    ></v-col>
                                    <v-col
                                        ><v-text-field
                                            v-model="stelle.house_number"
                                            :label="$t('nr')"
                                            :readonly="readonly"
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col
                                        ><v-text-field
                                            v-model="stelle.post_office_box"
                                            :label="$t('postfach')"
                                            :readonly="readonly"
                                            :rules="[formRules.isInt()]"
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <PlzOrtSelect
                                    :readonly="readonly"
                                    :plz="stelle.swiss_zip_code_string"
                                    :ort="stelle.town"
                                    @plzselect="stelle.swiss_zip_code = $event"
                                    @ortselect="stelle.town = $event"
                                ></PlzOrtSelect>
                            </v-form>
                            <v-card>
                                <v-card-title>{{ $t('ansprechperson') }}</v-card-title>
                                <v-row dense>
                                    <v-col
                                        ><AnredeSelect v-model="stelle.mr_mrs" :readonly="readonly"></AnredeSelect
                                    ></v-col>
                                    <v-col
                                        ><v-text-field
                                            v-model="stelle.first_name"
                                            :label="$t('vorname')"
                                            :readonly="readonly"
                                        ></v-text-field
                                    ></v-col>
                                    <v-col
                                        ><v-text-field
                                            v-model="stelle.last_name"
                                            :label="$t('nachname')"
                                            :readonly="readonly"
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col>&nbsp;</v-col>
                                    <v-col
                                        ><v-text-field
                                            v-model="stelle.email"
                                            :label="$t('email')"
                                            :readonly="readonly"
                                        ></v-text-field
                                    ></v-col>
                                    <v-col
                                        ><v-text-field
                                            v-model="stelle.phone"
                                            :label="$t('telefon')"
                                            :readonly="readonly"
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                            </v-card>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn
                                    v-if="!readonly"
                                    color="action white--text"
                                    type="submit"
                                    :disabled="!valid || loading"
                                    :loading="loading"
                                    @click="onSubmit"
                                >
                                    <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                                    {{ $t('save') }}
                                </v-btn>
                                <v-btn color="decline" :disabled="loading" text @click="closeForm">
                                    {{ $t('close') }}
                                </v-btn></v-card-actions
                            >
                        </v-card>
                    </v-tab-item>

                    <v-tab-item key="members">
                        <v-card v-if="!readonly || invited">
                            <base-remote-list
                                ref="userList"
                                session-key="user-stelle-list-remote"
                                :show-query="false"
                                :maintitle="$t('user')"
                                maintitle-icon-cls="fa-regular fa-users"
                                :collection-class="UserStelleCollection"
                                :headers="usHeaders"
                                :filters="usFilters"
                                :options="{ sortBy: ['user_username'] }"
                            >
                                <template #after-query-field>
                                    <v-btn
                                        v-if="!readonly"
                                        rounded
                                        class="hidden-xs-only ml-1"
                                        color="subtleHint"
                                        @click="onAdd"
                                    >
                                        <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                                        {{ $t('attach_user') }}
                                    </v-btn>
                                </template>
                                <template #item.status="{ item }">
                                    {{ $t(item.status) }}
                                </template>
                                <template #item.myactions="{ item }">
                                    <v-btn
                                        v-if="invited && item.user_id === user.id && item.invitation_accepted === null"
                                        rounded
                                        class="hidden-xs-only ml-1"
                                        color="subtleHint"
                                        @click="onAcceptInvitationClick(item)"
                                    >
                                        {{ $t('einladung_annehmen') }}
                                    </v-btn>
                                    <icon-btn
                                        icon-cls="fa-solid fa-link-slash"
                                        icon-color="orange"
                                        :tooltip="$t('delete')"
                                        @click="userToRemove = item"
                                    />
                                </template>
                            </base-remote-list>
                        </v-card>
                        <v-dialog v-model="showAddUserDlg" width="400">
                            <v-card>
                                <v-card-text>
                                    <v-form v-model="newUserEmailValid">
                                        <v-text-field
                                            v-model="eMailForNewUser"
                                            :label="$t('email')"
                                            :rules="[formRules.required, formRules.email]"
                                        ></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="action white--text"
                                        type="submit"
                                        :disabled="!newUserEmailValid"
                                        :loading="loading"
                                        @click="onInviteNewUser"
                                    >
                                        <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                                        {{ $t('save') }}
                                    </v-btn>
                                    <v-btn text @click="showAddUserDlg = false">Close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <ConfirmDlg
                            :value="confirm"
                            :context="userToRemove ? String(userToRemove.id) : null"
                            :message="$t('confirm_remove_user_from_stelle')"
                            :title="$t('rec_remove')"
                            @yes="removeUserFromStelle"
                            @no="userToRemove = null"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { formRules } from '@/helpers/forms.js';
import { errToStr } from '@/helpers/errors.js';
import StelleTypSelect from '@/components/stelle-typ-select.vue';
import { UserStelleCollection } from '@/models/UserStelle';
import PlzOrtSelect from '@/components/plz-ort-select.vue';
import AnredeSelect from '@/components/anrede-select.vue';
import { instance as getApi } from '@/helpers/api';
import ConfirmDlg from '@/components/confirm_dialog.vue';

export default {
    components: {
        StelleTypSelect,
        PlzOrtSelect,
        AnredeSelect,
        ConfirmDlg
    },
    data() {
        return {
            activeTab: null,
            stelle: Object,
            formRules,
            loading: false,
            valid: false,
            UserStelleCollection,
            showAddUserDlg: false,
            newUserEmailValid: false,
            eMailForNewUser: null,
            userToRemove: null
        };
    },
    computed: {
        confirm() {
            return this.userToRemove !== null;
        },
        formTitle() {
            if (this.stelle && this.stelle.id) {
                return this.stelle.id + ', ' + this.stelle.organisation_name + ' (' + this.$t(this.stelle.type) + ')';
            }

            return this.$t('add_stelle');
        },
        readonly() {
            if (!this.user) {
                return true;
            }

            if (this.user.isRoleAdmin) {
                return false;
            }

            if (this.stelle && this.stelle.id) {
                switch (this.stelle.type) {
                    case 'fachstelle':
                        return (
                            !this.user.isRoleFachstelle ||
                            !this.user.fachstellen_ids ||
                            this.user.fachstellen_ids.indexOf(this.stelle.id) < 0
                        );
                    case 'gemeinde':
                        return (
                            !this.user.isRoleGemeinde ||
                            !this.user.gemeinden_ids ||
                            this.user.gemeinden_ids.indexOf(this.stelle.id) < 0
                        );
                }
            }

            return true;
        },
        invited() {
            if (!this.user) {
                return false;
            }

            if (this.stelle && this.stelle.id) {
                switch (this.stelle.type) {
                    case 'fachstelle':
                        return (
                            this.user.fachstellen_invitations_ids &&
                            this.user.fachstellen_invitations_ids.indexOf(this.stelle.id) >= 0
                        );
                    case 'gemeinde':
                        return (
                            this.user.gemeinden_invitations_ids &&
                            this.user.gemeinden_invitations_ids.indexOf(this.stelle.id) >= 0
                        );
                }
            }

            return false;
        },
        ...mapState('user', {
            user: 'record'
        }),
        usHeaders() {
            return [
                {
                    text: this.$t('username'),
                    value: 'user_username',
                    sortable: true,
                    filterable: true,
                    width: '30%'
                },
                {
                    text: this.$t('email'),
                    value: 'user_email',
                    sortable: true,
                    filterable: true,
                    width: '30%'
                },
                {
                    text: this.$t('status'),
                    value: 'status',
                    sortable: true,
                    filterable: true,
                    width: '20%'
                },
                {
                    text: '',
                    value: 'myactions',
                    sortable: true,
                    filterable: true,
                    width: '20%'
                }
            ];
        },
        usFilters() {
            return [{ id: 'stelle_id', property: 'stelle_id', value: this.stelle.id || -1 }];
        }
    },
    watch: {
        $route: function () {
            // We already are loaded, do nothing:
            if (this.stelle && this.stelle.id === Number(this.$route.params.id)) {
                return;
            }
            this.loadRecord(this.$route.params.id);
        },
        activeTab: function () {
            console.log(this.activeTab);
        }
    },
    async created() {
        await this.loadRecord(this.$route.params.id);
        if (this.$route.params.tab) {
            this.activeTab = this.$route.params.tab;
        }
    },
    methods: {
        ...mapActions('stelle', ['loadStelle', 'storeStelle', 'destroyStelle']),
        async loadRecord(id) {
            this.loading = true;
            try {
                this.stelle = await this.loadStelle(id);
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        closeForm() {
            this.$router.push({ name: 'stelle' });
        },
        async onSubmit() {
            try {
                await this.storeStelle(this.stelle);
                this.closeForm();
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        async onAdd() {
            this.showAddUserDlg = true;
        },
        async onInviteNewUser() {
            try {
                await getApi().post('/stelle/inviteUser', {
                    email: this.eMailForNewUser,
                    stelle_id: this.stelle.id
                });
            } catch (err) {
                console.error(err);
                this.$toast({ message: errToStr(err), color: 'error' });
            }
            this.showAddUserDlg = false;
            this.$refs.userList.reload();
        },
        async onAcceptInvitationClick(item) {
            try {
                await getApi().post('/stelle/acceptInvitation', {
                    id: item.id
                });
            } catch (err) {
                console.error(err);
                this.$toast({ message: errToStr(err), color: 'error' });
            }
            this.$refs.userList.reload();
        },
        async removeUserFromStelle() {
            let item = this.userToRemove;
            try {
                await getApi().post('/stelle/removeUser', {
                    id: item.id
                });
            } catch (err) {
                console.error(err);
                this.$toast({ message: errToStr(err), color: 'error' });
            }
            this.userToRemove = null;
            this.$refs.userList.reload();
        }
    }
};
</script>
