/**
 * Ort entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export const ECH_STATUS = {
    STATUS_NEW: 'new',
    SUBMITTED: 'submitted',
    IN_PROGRESS: 'in progress',
    WITHDRAWN: 'withdrawn',
    SUSPENDED: 'suspended',
    APPELLANT_PROCESS: 'appellant process',
    OBJECTION_PENDING: 'objection pending',
    DECISION_ISSUED: 'decision issued',
    DECISION_LEGALLY_BINDING: 'decision legally binding',
    BUILDING_FREEZE: 'building freeze',
    UNDER_CONSTRUCTION: 'under construction',
    BUILDING_CLEARANCE: 'building clearance',
    DOSSIER_CLOSED: 'dossier closed',
    DOSSIER_ARCHIVED: 'dossier archived',
    DEMAND_DOCUMENTS: 'demand documents'
};

export class Status extends BaseModel {
    defaults() {
        return {
            id: null,
            bezeichnung: null,
            ech_value: null,
            ebau_value: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Status';
    }

    get plz_string() {
        return this.plz ? '' + this.plz : '';
    }
}

export class StatusCollection extends BaseCollection {
    options() {
        return {
            model: Status
        };
    }

    entityName() {
        return 'Status';
    }
}
