<template>
    <div class="pa-2">
        <v-row no-gutters>
            <Gesuchsteller
                v-if="record"
                context="pg"
                :planning-permission-application-id="record.id"
                :readonly="readonly"
                height="200px"
                defaultNewType="organisation"
            ></Gesuchsteller>
        </v-row>
        <v-row no-gutters>
            <v-col sm="3" class="text-right"> Gemeinde </v-col>
            <v-col sm="9"
                ><StelleSelect
                    :stelle="record.stelle"
                    :readonly="readonly"
                    :filter="stelleFilters"
                    @stelleselect="record.stelle_id = $event"
                ></StelleSelect
            ></v-col>
        </v-row>
    </div>
</template>

<script>
import Gesuchsteller from '@/components/relationship-to-person/relationship-to-person-grid.vue';
import StelleSelect from '@/components/stelle-select.vue';

export default {
    components: { Gesuchsteller, StelleSelect },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            record: this.gesuch
        };
    },
    computed: {
        stelleFilters() {
            return [
                {
                    id: 'type',
                    property: 'type',
                    value: 'gemeinde'
                },
                {
                    id: 'id',
                    property: 'id',
                    value: this.user.gemeinden_ids,
                    operator: 'IN'
                }
            ];
        }
    }
};
</script>
