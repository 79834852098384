<template>
    <v-list-item :value="value">
        <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="!item.hideValid">
            <v-checkbox :input-value="item.valid" color="primary" :ripple="false" readonly></v-checkbox>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    props: {
        /**
         * Menu item:
         * {title: string, hideValid:boolean, valid: boolean, hideIfReadonly: boolean}
         */
        value: [String, Number],
        item: { type: Object },
        readonly: { type: Boolean }
    }
};
</script>
