/**
 * Ort entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Ort extends BaseModel {
    defaults() {
        return {
            id: null,
            ortschaftsname: null,
            plz: null,
            zusatzziffer: null,
            gemeindename: null,
            bfsnr: null,
            kantonskuerzel: null,
            e: null,
            n: null,
            sprache: null,

            //view
            plz_ort: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Ort';
    }

    get plz_string() {
        return this.plz ? '' + this.plz : '';
    }
}

export class OrtCollection extends BaseCollection {
    options() {
        return {
            model: Ort
        };
    }

    entityName() {
        return 'Ort';
    }
}
