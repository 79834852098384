/**
 * User entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class User extends BaseModel {
    defaults() {
        return {
            id: null,
            username: null,
            email: null,
            ist_admin: null,
            last_name: null,
            active_role: null,
            active_role_stelle_id: null,
            gemeinden_ids: null,
            gemeinden: null,
            fachstellen_ids: null,
            fachstellen: null,
            gemeinden_invitations_ids: null,
            gemeinden_invitations: null,
            fachstellen_invitations_ids: null,
            fachstellen_invitations: null,
            nachname: null,
            vorname: null,
            active_role_stelle: null,
            active_role_stelle_street: null,
            active_role_stelle_house_number: null,
            active_role_stelle_swiss_zip_code: null,
            active_role_stelle_town: null,
            count_pg_acls: null,
            event_notification_per_mail: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'User';
    }

    get isAdmin() {
        return this.ist_admin;
    }

    get isRoleAdmin() {
        return this.ist_admin && this.active_role === 'admin';
    }

    get isRoleFachstelle() {
        return this.fachstellen_ids && this.fachstellen_ids.length > 0 && this.active_role === 'fachstelle';
    }

    get isRoleGemeinde() {
        return this.gemeinden_ids && this.gemeinden_ids.length > 0 && this.active_role === 'gemeinde';
    }

    get roles() {
        let roles = ['user'];
        if (this.fachstellen_ids && this.fachstellen_ids.length > 0) {
            roles.push('fachstelle');
        }
        if (this.gemeinden_ids && this.gemeinden_ids.length > 0) {
            roles.push('gemeinde');
        }
        if (this.isAdmin) {
            roles.push('admin');
        }

        return roles;
    }

    isInGemeinde(gemeinde_id) {
        return this.gemeinden_ids && this.gemeinden_ids.indexOf(gemeinde_id) >= 0;
    }

    isInFachstelle(stelle_id) {
        return this.fachstellen_ids && this.fachstellen_ids.indexOf(stelle_id) >= 0;
    }

    isInStelle(stelle_id) {
        return this.isInGemeinde(stelle_id) || this.isInFachstelle(stelle_id);
    }

    get name() {
        let name = [];
        if (this.vorname) {
            name.push(this.vorname);
        }
        if (this.nachname) {
            name.push(this.nachname);
        }
        if (name.length === 0) {
            return this.username;
        }
        return name.join(' ');
    }

    get initialen() {
        let initialen = [];
        if (this.vorname) {
            initialen.push(this.vorname[0].toUpperCase());
        }
        if (this.nachname) {
            initialen.push(this.nachname[0].toUpperCase());
        }
        if (initialen.length === 0) {
            return this.username[0].toUpperCase();
        }
        return initialen.join('');
    }
}

export class UserCollection extends BaseCollection {
    options() {
        return {
            model: User
        };
    }

    entityName() {
        return 'User';
    }
}
