<template>
    <div class="pa-2">
        <v-row no-gutters>
            <fileupload
                :ppa="record"
                :contexts="contexts"
                :parent-id="gesuch.id"
                entity="planungsgeschaeft"
                :readonly="readonly"
            ></fileupload>
        </v-row>
    </div>
</template>

<script>
import Fileupload from '@/components/fileupload';

export default {
    components: { Fileupload },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            record: this.gesuch,
            contexts: [
                'weitere_unterlagen',
                'formulare',
                'plaene',
                'bericht',
                'tabelle',
                'vertraege',
                'vorschriften',
                'fotos'
            ]
        };
    }
};
</script>
