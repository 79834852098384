<template>
    <v-dialog :value="value" width="800px" scrollable persistent>
        <v-card>
            <v-card-title>{{ $t('add_status') }}</v-card-title>
            <v-card-text>
                <v-form v-model="valid" :loading="loading" lazy-validation>
                    <v-select
                        v-model="statusId"
                        :label="$t('status')"
                        :items="statusCollection ? statusCollection.models : []"
                        :loading="statusLoading"
                        item-text="bezeichnung"
                        item-value="id"
                        clear-icon="fa-solid fa-xmark"
                        hide-no-data
                        hide-selected
                        required
                    >
                    </v-select>
                    <v-text-field v-model="kurzBemerkung" :label="$t('description')"></v-text-field>
                    <v-textarea v-model="bemerkung" :label="$t('comment')"></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="action white--text" type="submit" :disabled="!statusId" @click="onSave">
                    <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                    {{ $t('save') }}
                </v-btn>
                <v-btn color="decline" text @click="closeForm">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { StatusCollection } from '@/models/Status';
import { StatusVerlauf } from '@/models/StatusVerlauf';

export default {
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: null
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            statusCollection: null,
            statusLoading: false,
            statusId: null,
            bemerkung: null,
            kurzBemerkung: null,
            valid: false,
            loading: false
        };
    },
    mounted() {
        this.loadStatus();
    },
    methods: {
        ...mapActions('status_verlauf', ['storeStatusVerlauf']),
        async loadStatus(query) {
            this.statusLoading = true;
            if (!this.statusCollection) {
                this.statusCollection = new StatusCollection();
            }
            await this.statusCollection.query(query);
            this.statusLoading = false;
        },
        async onSave() {
            this.loading = true;
            let statusVerlauf = new StatusVerlauf();
            statusVerlauf.planning_permission_application_id = this.planningPermissionApplicationId;
            statusVerlauf.status_id = this.statusId;
            statusVerlauf.bemerkung = this.bemerkung;
            statusVerlauf.kurz_bemerkung = this.kurzBemerkung;
            try {
                await this.storeStatusVerlauf(statusVerlauf);
                this.closeForm();
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        closeForm() {
            this.$emit('close');
            this.statusId = null;
            this.bemerkung = null;
            this.kurzBemerkung = null;
        }
    }
};
</script>

<style></style>
