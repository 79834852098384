<template>
    <Comment :planning-permission-application="baugesuch" :stelle="stelle" :readonly="readonly" :user="user"></Comment>
</template>

<script>
import Comment from '@/components/comment';
export default {
    components: {
        Comment
    },
    props: {
        baugesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    }
};
</script>
