/**
 * The global Vuex store. The store is separated by entity in sub-stores.
 *
 * This file is the store entrypoint as well as the global store structure definition.
 *
 * This file is part of Minasa
 * (c) Kaden und Partner AG
 */
import Vue from 'vue';
import Vuex from 'vuex';

import application_type from './modules/application_type';
import baugesuch from './modules/baugesuch';
import country from './modules/country';
import document from './modules/document';
import layers from './modules/layers';
import ort from './modules/ort';
import realestate from './modules/realestate';
import planning_permission_application from './modules/planning_permission_application';
import planungsgeschaeft from './modules/planungsgeschaeft';
import relationship_to_person from './modules/relationship_to_person';
import status from './modules/status';
import status_verlauf from './modules/status_verlauf';
import stelle from './modules/stelle';
import user from './modules/user';
import user_stelle from './modules/user_stelle';
import planning_permission_application_comment from './modules/planning_permission_application_comment';
import location_address from './modules/location_address';
import additional_baugesuch_data from './modules/additional_baugesuch_data';
import access_control from './modules/access_control';
import building from './modules/building';
import building_entrance from './modules/building_entrance';
import dwelling from './modules/dwelling';
import ech_code from './modules/ech_code';
import mailbox from './modules/mailbox';
import news from './modules/news';
import event from './modules/event';
import judgement_type from './modules/judgement_type';
import decision_ruling from './modules/decision_ruling';

Vue.use(Vuex);

let _store = null;

const init = function (initialData) {
    _store = new Vuex.Store({
        state: {
            session: null,
            globals: null,
            appVersion: '',
            loading: false,
            loadingCounter: 0,
            ...initialData
        },
        mutations: {
            setSession(state, session) {
                state.session = session;
            },

            setGlobals(state, globals) {
                state.globals = globals;
            },

            loading(state, loading) {
                if (loading) {
                    state.loadingCounter++;
                } else {
                    state.loadingCounter = Math.max(0, state.loadingCounter - 1);
                }
                state.loading = state.loadingCounter > 0;
            },

            toast(state, payload) {
                state.snackbarContent = payload.content;
            }
        },
        actions: {
            async runAsync({ commit }, runner) {
                commit('loading', true);
                let res = null;
                try {
                    res = await runner();
                } finally {
                    commit('loading', false);
                }
                return res;
            }
        },
        getters: {
            isAuthenticated: (state) => state.user !== null && state.user.record !== null && state.user.record.id > 0,
            isAdmin: (state) => state.user.record !== null && state.user.record.isAdmin(),
            user: (state) => state.user.record
        },
        modules: {
            application_type,
            baugesuch,
            country,
            document,
            layers,
            ort,
            planning_permission_application,
            planungsgeschaeft,
            relationship_to_person,
            status_verlauf,
            status,
            stelle,
            user,
            user_stelle,
            realestate,
            planning_permission_application_comment,
            location_address,
            additional_baugesuch_data,
            access_control,
            building,
            building_entrance,
            dwelling,
            ech_code,
            mailbox,
            news,
            event,
            judgement_type,
            decision_ruling
        }
    });
    return _store;
};

export const getStore = function () {
    if (!_store) {
        throw new Error('Store is not initialized. Use init().');
    }
    return _store;
};

export default {
    init,
    getStore
};
