<template>
    <v-container>
        <v-form :readonly="readonly" class="my-4">
            <v-row>
                <v-col md="7">
                    <div>{{ $t('heizung_bau_vorgesehen_question') }}</div>
                </v-col>
                <v-col md="2">
                    <v-select
                        :value="additionalData.heizung_bau_vorgesehen"
                        :items="yesNoItems"
                        :readonly="readonly"
                        @input="onInput('heizung_bau_vorgesehen', $event)"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="additionalData.heizung_bau_vorgesehen">
                <v-col md="7">
                    <div></div>
                </v-col>
                <v-col md="5">
                    <b>{{ $t('heizung_bau_vorgesehen_ja_weiter') }}</b>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="7">
                    <div v-html="$t('heizung_geraete_vorgesehen_question')"></div>
                </v-col>
                <v-col md="2">
                    <v-select
                        :value="additionalData.heizung_geraete_vorgesehen"
                        :items="yesNoItems"
                        :readonly="readonly"
                        @input="onInput('heizung_geraete_vorgesehen', $event)"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="additionalData.heizung_geraete_vorgesehen">
                <v-col md="7">
                    <div></div>
                </v-col>
                <v-col md="5">
                    <b>{{ $t('heizung_geraete_vorgesehen_ja_weiter') }}</b>
                </v-col>
            </v-row>
            <v-row v-if="baugesuchIsGewerbe">
                <v-col md="7">
                    <div>{{ $t('arbeitnehmende_beschaeftigt_question') }}</div>
                </v-col>
                <v-col md="2">
                    <v-select
                        :value="additionalData.arbeitnehmende_beschaeftigt"
                        :items="yesNoItems"
                        :readonly="readonly"
                        @input="onInput('arbeitnehmende_beschaeftigt', $event)"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="baugesuchIsGewerbe && additionalData.arbeitnehmende_beschaeftigt">
                <v-col md="7">
                    <div>{{ $t('arbeitnehmende_beschaeftigt_verordnung_4_question') }}</div>
                </v-col>
                <v-col md="2">
                    <v-select
                        :value="additionalData.arbeitnehmende_beschaeftigt_verordnung_4"
                        :items="yesNoItems"
                        :readonly="readonly"
                        @input="onInput('arbeitnehmende_beschaeftigt_verordnung_4', $event)"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="additionalData.arbeitnehmende_beschaeftigt_verordnung_4">
                <v-col md="7">
                    <div></div>
                </v-col>
                <v-col md="5">
                    <b>{{ $t('arbeitnehmende_beschaeftigt_verordnung_4_ja_weiter') }}</b>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        gesuch: { type: Object },
        additionalData: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: true,
            yesNoItems: [
                { value: false, text: this.$t('no') },
                { value: true, text: this.$t('yes') }
            ]
        };
    },
    computed: {
        baugesuchIsGewerbe() {
            return (this.gesuch.intended_purpose ?? []).indexOf('commercial_and_industrial_use') >= 0;
        }
    },
    created() {},
    mounted() {},
    methods: {
        ...mapActions('additional_baugesuch_data', ['storeAdditionalBaugesuchData']),
        onInput(field, value) {
            this.additionalData.set(field, value);

            if (field === 'arbeitnehmende_beschaeftigt' && !value) {
                this.additionalData.set('arbeitnehmende_beschaeftigt_verordnung_4', false);
            }
        },
        async beforeSave() {
            if (this.additionalData) {
                await this.storeAdditionalBaugesuchData(this.additionalData);
            }
            return false;
        }
    }
};
</script>
