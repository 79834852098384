<template>
    <div v-if="newsCollection">
        <div v-for="news in newsCollection.models" :key="news.id + '_static'">
            <v-card v-if="news.static_display" elevation="0" class="my-4">
                <v-card-title>
                    <h2>{{ news.title }}</h2>
                </v-card-title>
                <v-card-text class="px-4">
                    <div v-html="news.content"></div>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import { NewsCollection } from '@/models/News';

export default {
    name: 'NewsCmp',
    data: function () {
        return {
            newsCollection: null,
            newsLoading: false
        };
    },
    mounted() {
        this.loadNews();
    },
    methods: {
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        },
        async loadNews(query) {
            this.newsLoading = true;
            if (!this.newsCollection) {
                this.newsCollection = new NewsCollection();
            }
            await this.newsCollection.query(query);
            this.newsLoading = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.news {
    h2 {
        font-size: 1.2em;
        color: #333;
    }
    h3 {
        font-size: 1em;
        color: #888;
    }
}
</style>
