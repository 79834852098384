/**
 * Realestate entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export const REALESTATE_TYPE = {
    LIEGENSCHAFT: 1,
    STOCKWERKS_EINHEIT: 2,
    GEWOEHNLICHES_MITEIGENTUM: 3,
    KONZESSION: 4,
    GEWOEHNLICHES_SDR: 5,
    BERGWERK: 6,
    QUELLENRECHT: 7,
    WEITERE: 8
};

export class Realestate extends BaseModel {
    defaults() {
        return {
            id: null,
            dossier_id: null,
            nbident: null,
            nummer: null,
            egrid: null,
            realestate_type: null,
            created: null,
            creator_id: null,
            is_neighbour: null,
            is_manually_added_neighbour: null,
            wkb_geometry: null,
            center_box: null,
            center_x: null,
            center_y: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            dossier_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Realestate';
    }
}

export class RealestateCollection extends BaseCollection {
    options() {
        return {
            model: Realestate
        };
    }

    entityName() {
        return 'Realestate';
    }
}
