<template>
    <v-card fluid>
        <v-card-title> <span class="fa fa-envelope" style="margin-right: 10pt"></span> {{ $t('email') }} </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="4" sm="6">
                    <v-form v-model="email_valid">
                        <v-text-field
                            v-model="test_email"
                            :label="$t('email')"
                            :rules="[test_email !== null, formRules.email, formRules.maxLength(255)]"
                            outlined
                        ></v-text-field></v-form
                ></v-col>
                <v-col>
                    <div style="margin-top: 12px">
                        <v-btn :disabled="!email_valid" @click="sendTestMail">
                            {{ $t('test_email_versenden') }}
                        </v-btn>
                        <span
                            v-if="mail_error === false"
                            style="padding-left: 20px; font-weight: bold; color: green; font-size: 22px"
                            ><b class="fa-solid fa-check"></b
                        ></span>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div v-if="mail_error" style="font-weight: bold; color: red; padding-bottom: 10px">
                        {{ mail_error }}
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { formRules } from '@/helpers/forms.js';
import { instance as getApi } from '@/helpers/api';
import { errToStr } from '@/helpers/errors.js';
export default {
    data() {
        return {
            formRules,
            test_email: null,
            email_valid: false,
            mail_error: null
        };
    },
    methods: {
        async sendTestMail() {
            this.mail_error = null;
            try {
                let response = await getApi().post('/admin/sendTestEmail', {
                    email: this.test_email
                });
                if (response && response.data && response.data.success) {
                    this.mail_error = false;
                }
            } catch (e) {
                this.mail_error = errToStr(e);
            }
        }
    }
};
</script>
