<template>
    <v-container>
        <v-form v-if="buildings" :readonly="readonly" class="my-4">
            <v-container v-for="building in buildings.models" :key="building.id">
                <v-row>
                    <v-col md="3" class="text-right">{{ $t('building') }} {{ building.egid }}</v-col>
                    <v-col md="9">
                        <h4>{{ $t('heating_1') }}</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="7">
                        <div>{{ $t('new_or_changed_heating_1') }}</div>
                    </v-col>
                    <v-col md="2">
                        <v-select v-model="heating_1_changed" :items="yesNoItems" :readonly="readonly"></v-select>
                    </v-col>
                </v-row>
                <template v-if="heating_1_changed">
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.heating_1_heat_generator"
                                ech-doc-id="eCH-0129"
                                ech-variable-name="heatGeneratorHeating"
                                :label="$t('heat_generator')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.heating_1_energy_source"
                                ech-doc-id="eCH-0129"
                                :dependent-ech="building.heating_1_heat_generator"
                                ech-variable-name="energySource"
                                :label="$t('energy_source')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="building.heating_1_energy_source === 7511">
                        <v-col md="3" class="text-right"></v-col>
                        <v-row class="mx-2">
                            <v-col md="6">
                                <kp-numberfield
                                    v-model="building.heating_1_geothermal_probes_count"
                                    :label="$t('count')"
                                    :readonly="readonly"
                                >
                                </kp-numberfield>
                            </v-col>
                            <v-col md="6">
                                <kp-numberfield
                                    v-model="building.heating_1_geothermal_probes_depth"
                                    :label="$t('depth')"
                                    :readonly="readonly"
                                >
                                </kp-numberfield>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-row v-if="building.heating_1_energy_source === 7599">
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <v-textarea
                                v-model="building.heating_1_others"
                                :label="$t('specify_other_heating')"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </template>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="9"><v-divider></v-divider></v-col>
                </v-row>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="9">
                        <h4>{{ $t('heating_2') }}</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="7">
                        <div>{{ $t('new_or_changed_heating_2') }}</div>
                    </v-col>
                    <v-col md="2">
                        <v-select v-model="heating_2_changed" :items="yesNoItems" :readonly="readonly"></v-select>
                    </v-col>
                </v-row>
                <template v-if="heating_2_changed">
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.heating_2_heat_generator"
                                ech-doc-id="eCH-0129"
                                ech-variable-name="heatGeneratorHeating"
                                :label="$t('heat_generator')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.heating_2_energy_source"
                                :dependent-ech="building.heating_2_heat_generator"
                                ech-doc-id="eCH-0129"
                                ech-variable-name="energySource"
                                :label="$t('energy_source')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="building.heating_2_energy_source === 7511">
                        <v-col md="3" class="text-right"></v-col>
                        <v-row class="mx-2">
                            <v-col md="6">
                                <kp-numberfield
                                    v-model="building.heating_2_geothermal_probes_count"
                                    :label="$t('count')"
                                    :readonly="readonly"
                                >
                                </kp-numberfield>
                            </v-col>
                            <v-col md="6">
                                <kp-numberfield
                                    v-model="building.heating_2_geothermal_probes_depth"
                                    :label="$t('depth')"
                                    :readonly="readonly"
                                >
                                </kp-numberfield>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-row v-if="building.heating_2_energy_source === 7599">
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <v-textarea
                                v-model="building.heating_2_others"
                                :label="$t('specify_other_heating')"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </template>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="9"><v-divider></v-divider></v-col>
                </v-row>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="9">
                        <h4>{{ $t('hot_water_1') }}</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="7">
                        <div>{{ $t('new_or_changed_hot_water_1') }}</div>
                    </v-col>
                    <v-col md="2">
                        <v-select v-model="hot_water_1_changed" :items="yesNoItems" :readonly="readonly"></v-select>
                    </v-col>
                </v-row>
                <template v-if="hot_water_1_changed">
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.hot_water_1_heat_generator"
                                ech-doc-id="eCH-0129"
                                ech-variable-name="heatGeneratorHeating"
                                :label="$t('heat_generator')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.hot_water_1_energy_source"
                                :dependent-ech="building.hot_water_1_heat_generator"
                                ech-doc-id="eCH-0129"
                                ech-variable-name="energySource"
                                :label="$t('energy_source')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                </template>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="9"><v-divider></v-divider></v-col>
                </v-row>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="9">
                        <h4>{{ $t('hot_water_2') }}</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="3" class="text-right"></v-col>
                    <v-col md="7">
                        <div>{{ $t('new_or_changed_hot_water_2') }}</div>
                    </v-col>
                    <v-col md="2">
                        <v-select v-model="hot_water_2_changed" :items="yesNoItems" :readonly="readonly"></v-select>
                    </v-col>
                </v-row>
                <template v-if="hot_water_2_changed">
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.hot_water_2_heat_generator"
                                ech-doc-id="eCH-0129"
                                ech-variable-name="heatGeneratorHeating"
                                :label="$t('heat_generator')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3" class="text-right"></v-col>
                        <v-col md="9">
                            <ech-code-select
                                v-model="building.hot_water_2_energy_source"
                                :dependent-ech="building.hot_water_2_heat_generator"
                                ech-doc-id="eCH-0129"
                                ech-variable-name="energySource"
                                :label="$t('energy_source')"
                            ></ech-code-select>
                        </v-col>
                    </v-row>
                </template>
            </v-container>
        </v-form>
        <div v-else>{{ $t('load_building_info_first') }}</div>
    </v-container>
</template>

<script>
import { formRules } from '@/helpers/forms.js';
import { mapActions } from 'vuex';
import KpNumberfield from '@/components/kp-numberfield.vue';
import EchCodeSelect from '@/components/ech-code-select.vue';
export default {
    components: { KpNumberfield, EchCodeSelect },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            formRules,
            record: this.gesuch,
            buildings: null,
            yesNoItems: [
                { value: false, text: this.$t('no') },
                { value: true, text: this.$t('yes') }
            ],
            heating_1_changed: false,
            heating_2_changed: false,
            hot_water_1_changed: false,
            hot_water_2_changed: false
        };
    },
    created() {
        this.loadBuilding();
    },
    methods: {
        ...mapActions('building', ['fetchBuildings', 'storeBuilding', 'getHeizungsDaten']),

        async loadBuilding() {
            this.loading = true;
            try {
                let b = await this.fetchBuildings({
                    filter: [
                        {
                            id: 'planning_permission_application_id',
                            property: 'planning_permission_application_id',
                            value: this.gesuch.id
                        }
                    ]
                });
                if (b && b.length > 0) {
                    this.buildings = b;
                    for (let i = 0; i < b.length; i++) {
                        const e = b.models[i];
                        let heating = await this.getHeizungsDaten({ egid: e.egid });
                        if (!e.heating_1_heat_generator) {
                            e.heating_1_heat_generator = heating.waermeerzeuger_heizung_1;
                            e.heating_1_energy_source = heating.energietraeger_heizung_1;
                        }
                        if (!e.heating_2_heat_generator) {
                            e.heating_2_heat_generator = heating.waermeerzeuger_heizung_2;
                            e.heating_2_energy_source = heating.energietraeger_heizung_2;
                        }
                        if (!e.hot_water_1_heat_generator) {
                            e.hot_water_1_heat_generator = heating.waermeerzeuger_warmwasser_1;
                            e.hot_water_1_energy_source = heating.energietraeger_warmwasser_1;
                        }
                        if (!e.hot_water_2_heat_generator) {
                            e.hot_water_2_heat_generator = heating.waermeerzeuger_warmwasser_2;
                            e.hot_water_2_energy_source = heating.energietraeger_warmwasser_2;
                        }
                    }
                }
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },

        async beforeSave() {
            if (this.buildings) {
                this.buildings.each(async (building) => {
                    try {
                        await this.storeBuilding(building);
                    } catch (e) {
                        this.$toast({ message: e, color: 'error' });
                    }
                });
            }
        }
    }
};
</script>
