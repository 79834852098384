<template>
    <v-container>
        <v-row>
            <building-expansion-view :readonly="readonly" class="pa-3" :gesuch="gesuch"></building-expansion-view>
        </v-row>
    </v-container>
</template>

<script>
import BuildingExpansionView from '@/components/building-expansion-view.vue';
export default {
    components: {
        'building-expansion-view': BuildingExpansionView
    },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    }
};
</script>
