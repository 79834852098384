<template>
    <v-container>
        <v-card v-if="!readonly" class="mb-2" elevation="0">
            <v-card-title>{{ $t('remarks') }}</v-card-title>
            <v-card-text>
                <v-textarea
                    v-model="record.remark"
                    auto-grow
                    hide-details
                    :placeholder="$t('new_remark')"
                    @blur.stop="saveGesuch"
                ></v-textarea>
            </v-card-text>
        </v-card>
        <v-card v-if="!readonly" class="mb-2" elevation="0">
            <v-card-title> {{ $t('check_dossier') }} </v-card-title>
            <v-card-text>
                <v-btn
                    v-if="!checkResult"
                    color="action white--text"
                    :loading="checkLoading"
                    class="mb-2"
                    @click="checkGesuch"
                >
                    <v-icon left>fa-solid fa-list-check</v-icon>
                    {{ $t('check_dossier') }}
                </v-btn>
                <template v-if="checkResult && checkResult.valid">
                    <v-alert type="success" border="left" colored-border elevation="0">
                        {{ $t('dossier_complete') }}
                    </v-alert>
                </template>
                <template v-if="checkResult && !checkResult.valid">
                    <v-alert type="error" border="left" colored-border elevation="0">
                        <div class="text-title-1">{{ $t('existing_errors_msg') }}:</div>
                        <template v-for="(section, desc, idx) in checkResult.sections">
                            <v-container v-if="desc != 'valid' && !section.valid" :key="idx" class="mb-2">
                                <v-row>
                                    <div class="text-subtitle-1 primary--text">{{ $t(desc) }}</div>
                                    <v-btn color="primary" class="ml-3" icon small @click="gotoSubPage(desc)">
                                        <v-icon>fa-solid fa-arrow-up-right-from-square</v-icon>
                                    </v-btn>
                                </v-row>
                                <template v-for="(subSections, subDesc, subIdx) in section">
                                    <v-row v-if="!subSections.valid" :key="subIdx">
                                        <div v-if="subDesc != 'valid'">
                                            <strong>{{ $t(subDesc) }}:</strong> {{ $t(subSections.message) }}
                                        </div>
                                    </v-row>
                                </template>
                            </v-container>
                        </template>
                    </v-alert>
                </template>
            </v-card-text>
        </v-card>
        <v-card v-if="!readonly" class="mb-2" elevation="0">
            <v-form ref="commitform" v-model="formvalid" lazy-validation>
                <v-card-title> {{ $t('commit_dossier') }}</v-card-title>
                <v-card-text>
                    <v-alert v-if="!checkResult || !checkResult.valid" type="error" border="left" colored-border>
                        {{ $t('commit_dossier_msg') }}
                    </v-alert>
                    <template v-else>
                        <v-alert type="warning" border="left" colored-border>
                            {{ $t('no_edit_after_commit_msg') }}
                        </v-alert>
                        <v-checkbox
                            v-model="acceptFee"
                            :label="$t('fee_warning')"
                            :rules="[(v) => !!v || $t('accept_fee')]"
                            required
                        ></v-checkbox>
                    </template>
                </v-card-text>
                <v-card-actions v-if="checkResult && checkResult.valid">
                    <v-spacer />
                    <v-btn
                        color="action white--text"
                        :disabled="!checkResult || !checkResult.valid || !acceptFee"
                        :loading="verifyLoading || saving"
                        @click="commitBaugesuch"
                    >
                        <v-icon left>fa-solid fa-paper-plane</v-icon>
                        {{ $t('commit_dossier') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import { errToStr } from '@/helpers/errors';
import { instance as getApi } from '@/helpers/api';
import { mapActions } from 'vuex';
import { getStore } from '@/store/index';

export default {
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            checkResult: null,
            checkLoading: false,
            showEinreichung: false,
            formvalid: false,
            acceptFee: false,
            verifyLoading: false,
            saving: false,
            record: this.gesuch
        };
    },
    computed: {},
    watch: {},
    created() {},
    methods: {
        ...mapActions('benutzer', ['verifyUser']),
        async checkGesuch() {
            if (this.gesuch) {
                getStore().commit('loading', true);
                this.checkLoading = true;
                try {
                    let res = await getApi().post('/Baugesuch/check', {
                        ppa_id: this.gesuch.id
                    });
                    this.checkResult = res.data;
                    this.showEinreichung = res.data?.valid;
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    this.checkLoading = false;
                    getStore().commit('loading', false);
                }
            }
        },

        async commitBaugesuch() {
            if (this.gesuch) {
                try {
                    getStore().commit('loading', true);
                    this.saving = true;
                    let res = await getApi().post('/Baugesuch/commit', {
                        baugesuch_id: this.gesuch.id,
                        timeout: 300000
                    });
                    this.checkResult = res.data;
                    if (this.checkResult?.valid) {
                        this.$toast({ message: this.$t('commit_successful'), color: 'success' });
                        this.$router.push({ name: 'baugesuche' });
                    }
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    getStore().commit('loading', false);
                    this.saving = false;
                }
            }
        },

        gotoSubPage(route) {
            this.$router.push({ name: 'bg_edit_' + route });
        },

        saveGesuch() {
            this.gesuch.save();
        }
    }
};
</script>
