<template>
    <v-autocomplete
        v-bind="$attrs"
        :value="value"
        :dense="dense"
        :readonly="readonly"
        :label="$t('business_type') + (rules.includes(formRules.required) ? ' *' : '')"
        :items="businessTypeCollection ? businessTypeCollection.models : []"
        :loading="isLoading"
        :search-input.sync="businessTypeSearch"
        :rules="rules"
        item-text="bezeichnung"
        item-value="id"
        clear-icon="fa-solid fa-xmark"
        hide-no-data
        hide-selected
        multiple
        @input="onSelect"
    >
    </v-autocomplete>
</template>

<script>
import { debounce } from '@/helpers/tools.js';
import { BusinessTypeCollection } from '@/models/BusinessType';
import { formRules } from '@/helpers/forms.js';

/**
 * PLZ-Ort-Select
 *
 * loads Orte from /lookup/ort,
 * emits the selected
 */
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: 'bg'
        },
        rules: {
            type: Array,
            default: () => []
        },
        dense: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            businessTypeCollection: null,
            isLoading: false,
            businessTypeSearch: null,
            formRules: formRules,
            oldSearchInput: false,
            filter: [{ id: 'type', property: 'type', value: this.type }]
        };
    },
    watch: {
        businessTypeSearch: debounce(async function (val, oldVal) {
            // prevent lookup on first render (no user input)
            if (!oldVal) {
                return;
            }
            // Here comes an ugly hack: we need to prevent loading
            // when a value is selected from the dropdown list.
            // But as we cannot determine if this function was called
            // during a user text entry, or due to a selection from
            // the OTHER field, we reset the preventLoading flag after.... some time
            if (this.preventLoading) {
                setTimeout(() => (this.preventLoading = false), 500);
                return;
            }
            if (!val || val.length < 3 || this.isLoading) {
                return;
            }
            if (!this.businessTypeCollection) {
                this.businessTypeCollection = new BusinessTypeCollection();
            }
            await this.loadBusinessType({ query: val, filter: this.filter });
        })
    },
    mounted: async function () {
        if (!this.businessTypeCollection) {
            this.businessTypeCollection = new BusinessTypeCollection();
        }
        await this.loadBusinessType({ filter: this.filter });
    },
    methods: {
        onSelect(records) {
            this.$emit('input', records);
        },
        async loadBusinessType(options) {
            this.isLoading = true;
            if (!this.businessTypeCollection) {
                this.businessTypeCollection = new BusinessTypeCollection();
            }
            await this.businessTypeCollection.query(options);
            this.isLoading = false;
        }
    }
};
</script>
