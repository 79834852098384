/**
 * Store module: ort
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { News, NewsCollection } from '@/models/News';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchNews({ commit }, options = {}) {
            let list = new NewsCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadNews({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new News();
                } else {
                    record = new News({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storeNews({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyEvent({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        }
    }
};
