/**
 * PlanningPermissionApplication entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class PlanningPermissionApplication extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            creator_id: null,
            modified: null,
            modifier_id: null,
            type: null,
            boa_id: null,
            description: null,
            application_type_id: null,
            remark: null,
            proceeding_type: null,
            profiling_yes_no: null,
            profiling_date: null,
            has_open_appeals: null,
            intended_purpose: null,
            parking_lots_yes_no: null,
            construction_cost: null,
            dossier_identification: null,
            gemeinde_id: null,
            stelle_id: null,
            business_type_ids: null,
            deleted: null,
            boa_bgnumber: null,
            location_address_id: null,
            dossier_nr_gemeinde: null,

            // view
            stelle: null,
            stelle_active: null,
            stelle_uid: null,
            application_type: null,
            aktueller_status_id: null,
            aktueller_status: null,
            aktueller_status_ech_value: null,
            aktueller_status_ebau_value: null,
            aktueller_status_verlauf_id: null,
            business_type: null,

            construction_project_type: null,
            construction_project_type_other_description: null,

            open_circulation_count: null,
            open_stelle_ids: [],
            circulated_to_kanton: false
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'PlanningPermissionApplication';
    }

    get full_display() {
        return (this.dossier_identification || '?') + ' (ID: ' + this.id + ')';
    }
}

export class PlanningPermissionApplicationCollection extends BaseCollection {
    options() {
        return {
            model: PlanningPermissionApplication
        };
    }

    entityName() {
        return 'PlanningPermissionApplication';
    }
}
