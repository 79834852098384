/**
 * Store module: ort
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { StatusVerlauf, StatusVerlaufCollection } from '@/models/StatusVerlauf';
import { instance as getApi } from '@/helpers/api';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchStatusVerlauf({ commit }, options = {}) {
            let list = new StatusVerlaufCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadStatusVerlauf({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new StatusVerlauf();
                } else {
                    record = new StatusVerlauf({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storeStatusVerlauf({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyStatusVerlauf({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async compareStatusVerlauf({ commit }, options = {}) {
            try {
                let res = await getApi().post('/StatusVerlauf/compare', options);
                if (res && res.data && res.data.data) {
                    return res.data.data;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        }
    }
};
