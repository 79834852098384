/**
 * Ort entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class DecisionRuling extends BaseModel {
    defaults() {
        return {
            id: null,
            creator_id: null,
            modified: null,
            modifier_id: null,
            planning_permission_applicati: null,
            ruling_date: null,
            judgement_type_id: null,
            stelle_id: null,
            ruling: null,
            kanton: null,
            type: null,
            finalized: false,
            fields: null,
            stelle_organisation_name: null,
            stelle_uid: null,
            judgement_type_code: null,
            judgement_type_ech_value: null,
            judgement_type_display_value: null,
            judgement_type_is_positive: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'DecisionRuling';
    }
}

export class DecisionRulingCollection extends BaseCollection {
    options() {
        return {
            model: DecisionRuling
        };
    }

    entityName() {
        return 'DecisionRuling';
    }
}
