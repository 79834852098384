<template>
    <v-container class="d-flex mb-6" fluid>
        <v-card>
            <v-navigation-drawer permanent outlined class="pa-2" width="400px" min-width="400px" max-width="400px">
                <v-list dense nav>
                    <v-list-item link :to="{ name: 'admin_news' }">
                        <v-list-item-icon>
                            <span class="fa fa-message" style="margin-right: 10pt"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('news') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link :to="{ name: 'admin_mail' }">
                        <v-list-item-icon>
                            <span class="fa fa-envelope" style="margin-right: 10pt"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('email') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </v-card>

        <div class="flex-grow-1 ml-4 content-card">
            <router-view ref="activeChild" class="router-content"></router-view>
        </div>
    </v-container>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>
