<template>
    <v-container fluid class="text-center">
        <base-remote-list
            ref="circulationList"
            session-key="circulation-list-remote"
            :show-query="false"
            :hide-toolbar="true"
            :hide-default-footer="true"
            :collection-class="collection"
            :headers="headers"
            :filters="filter"
            :options="options"
            v-bind="$attrs"
        >
            <template #[`item.stelle_organisation_name`]="{ item }">
                {{ item.is_kanton ? $t('kanton') : item.stelle_organisation_name }}
            </template>
            <template #[`item.created`]="{ item }">
                {{ formatDateTime(item.created) }}
            </template>
            <template #[`item.completed`]="{ item }">
                {{ formatDateTime(item.completed) }}
            </template>

            <template #top>
                <div class="d-flex">
                    <v-btn
                        v-if="showAddBtn"
                        rounded
                        class="hidden-xs-only mb-2 ml-auto"
                        color="subtleHint"
                        @click="onAddCirculation"
                    >
                        <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                        {{ $t('add_circulation') }}
                    </v-btn>
                </div>
            </template> </base-remote-list
        ><CirculationForm
            v-model="addCirculation"
            :planning-permission-application-id="planningPermissionApplicationId"
            @close="onCloseAddCirculation"
            @save="onCirculationSave"
        ></CirculationForm
    ></v-container>
</template>

<script>
import { CirculationCollection } from '@/models/Circulation';
import DateFunctions from '@/mixins/date-functions.js';
import CirculationForm from './circulation-form.vue';

export default {
    components: { CirculationForm },
    mixins: [DateFunctions],
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: null
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            collection: CirculationCollection,
            addCirculation: false,
            options: {
                page: 1,
                itemsPerPage: 100,
                sortBy: ['created'],
                sortDesc: [true],
                multiSort: false,
                mustSort: true
            },
            filter: [
                {
                    id: 'planning_permission_application_id',
                    property: 'planning_permission_application_id',
                    value: this.planningPermissionApplicationId
                }
            ]
        };
    },
    computed: {
        params() {
            return {
                ...this.options,
                filter: this.filter
            };
        },
        headers() {
            let res = [
                {
                    text: this.$t('stelle'),
                    value: 'stelle_organisation_name',
                    sortable: true,
                    filterable: true,
                    width: 310
                },
                {
                    text: this.$t('created'),
                    value: 'created',
                    sortable: true,
                    filterable: true,
                    width: 180
                },
                {
                    text: this.$t('abgeschlossen'),
                    value: 'completed',
                    sortable: true,
                    filterable: true,
                    width: 180
                },
                {
                    text: this.$t('comments'),
                    value: 'bemerkungen',
                    sortable: true,
                    filterable: true
                }
            ];

            return res;
        },
        showAddBtn() {
            return !this.readonly;
        }
    },
    watch: {
        selection: {
            handler(val) {
                if (!Array.isArray(val)) {
                    val = [val];
                }
                this.selectedItems = val;
            }
        }
    },
    methods: {
        onCloseAddCirculation() {
            this.addCirculation = false;
            this.$refs.circulationList.reload();
        },
        onAddCirculation() {
            this.addCirculation = true;
        },
        onCirculationSave() {
            this.$emit('datachange');
        }
    }
};
</script>

<style scoped>
.fixed-table >>> table {
    table-layout: fixed;
    width: 100%;
}
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    .fixed-table >>> table td {
        text-overflow: ellipsis;
    }
    .fixed-overflow-table >>> table td {
        overflow: hidden;
    }
}
@media screen and (max-width: 600px) {
    .fixed-table >>> table th {
        width: 100%;
    }
}
</style>
