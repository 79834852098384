/**
 * Takes a data table 'options' object, as delvered by the Vuetify data table.
 * It contains  paging and sorting infos, as in this example:
 *
 * <code>
 *  tableOptions: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['username'],
        sortDesc: [false],
        multiSort: false,
        mustSort: true
    }
 * </code>
 *
 * For sending it to the backend, we need to convert it to a proper API format,
 * to be used as POST params with axios.
 *
 * @param {Object} tableOptions
 */
export function tableOptionsToQueryParams(tableOptions) {
    const opts = {
        limit: tableOptions.itemsPerPage,
        start: ((tableOptions.page || 1) - 1) * tableOptions.itemsPerPage, // vuetify delivers the page number, we need a record offset
        sort: []
    };
    if (tableOptions.sortBy && tableOptions.sortBy.length > 0) {
        for (let i = 0; i < tableOptions.sortBy.length; i++) {
            const sortBy = tableOptions.sortBy[i].replace(/^\$\./, ''); // replace '$.foo' with 'foo': $. is from vue-mc's "active value".
            const sortDesc = tableOptions.sortDesc[i];
            opts.sort.push({ property: sortBy, direction: sortDesc ? 'desc' : 'asc' });
        }
    }
    return opts;
}

/**
 * Creates a function that can only be called "debounced", means, only all n miliseconds.
 *
 * @param {Function} fn The function to execute
 * @param {NUmber} millis The nr of miliseconds the function is NOT called again
 * @returns a new Function that can rapidly be called, but is only invoked max all n miliseconds.
 */
export function debounce(fn, millis = 350) {
    // hoisted timeout variable
    let timeout;

    return function () {
        let context = this;
        let args = arguments;
        let callbackFn = function () {
            timeout = null;
            fn.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(callbackFn, millis);
        if (!timeout) {
            fn.apply(context, args);
        }
    };
}

export function intToFilesizeStr(bytes) {
    if (bytes / 1000000000 > 1) {
        return (bytes / 1000000000).toFixed(2) + ' GB';
    }
    if (bytes / 1000000 > 1) {
        return (bytes / 1000000).toFixed(2) + ' MB';
    }
    if (bytes / 1000 > 1) {
        return (bytes / 1000).toFixed(2) + ' KB';
    }
    return bytes + ' B';
}
