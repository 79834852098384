<template>
    <v-container fluid class="text-center">
        <base-remote-list
            session-key="message-list-remote"
            :show-query="false"
            :hide-toolbar="true"
            :collection-class="MailboxCollection"
            :headers="headers"
            :filters="filter"
            :options="options"
            v-bind="$attrs"
        >
            <template #[`item.created`]="{ item }">
                {{ formatDateTime(item.created) }}
            </template>
            <template #item.myactions="{ item }">
                <v-btn :color="getXmlBtnColor(item)" @click="onXmlClick(item)"> &lt; /&gt; </v-btn>
                <v-btn v-if="item.error" color="error white--text" class="mt-1 mb-1" @click="onshowErrorClick(item)"
                    ><v-icon>fa-solid fa-bug</v-icon></v-btn
                >
            </template>
        </base-remote-list>
    </v-container>
</template>

<script>
import { MailboxCollection } from '@/models/Mailbox';
import { mapActions } from 'vuex';
import DateFunctions from '@/mixins/date-functions.js';
import { apiUrl } from '@/helpers/globals';

export default {
    mixins: [DateFunctions],
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            MailboxCollection,
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['created'],
                sortDesc: [true],
                multiSort: false,
                mustSort: true
            },
            filter: [
                {
                    id: 'planning_permission_application_id',
                    property: 'planning_permission_application_id',
                    value: this.planningPermissionApplicationId
                }
            ]
        };
    },
    computed: {
        headers() {
            let res = [
                { text: this.$t('dir'), value: 'direction', sortable: true, filterable: true, width: 80 },
                {
                    text: this.$t('sender'),
                    value: 'sender_id',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('empfaenger'),
                    value: 'recipient_display',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('event'),
                    value: 'event',
                    sortable: true,
                    filterable: true,
                    width: 310
                },
                {
                    text: this.$t('event_type'),
                    value: 'event_type',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('message_type'),
                    value: 'ech_message_type',
                    sortable: true,
                    filterable: true,
                    width: 130
                },
                {
                    text: this.$t('created'),
                    value: 'created',
                    sortable: true,
                    filterable: true,
                    width: 180
                },
                {
                    value: 'myactions',
                    sortable: false,
                    filterable: false,
                    width: 100
                }
            ];

            return res;
        }
    },
    watch: {},
    methods: {
        ...mapActions('mailbox', ['fetchMailbox']),
        async getMessages() {
            this.messagesLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                let data = await this.fetchMailbox(this.params);
                this.mailbox = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.messagesLoading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onRowClicked(row) {},
        onItemSelected(evt) {
            this.selectedItems = evt.value ? [evt.item] : [];
            this.emitSelection();
        },
        emitSelection() {
            if (this.selectedItems.length === 1) {
                this.$emit('selection', this.selectedItems[0]);
            }
        },
        onXmlClick(item) {
            const url = apiUrl(`/mailbox/getPayload`, { id: item.id });
            window.open(url, 'csvExport');
        },
        getXmlBtnColor(item) {
            return (item.error ? 'error' : 'action') + ' white--text';
        },
        onshowErrorClick(item) {
            alert(item.error);
        }
    }
};
</script>
