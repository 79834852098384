import { getI18nInstance } from '@/i18n';

import BaugesuchIndex from '@/views/auth/baugesuch/index.vue';
import BaugesuchForm from '@/views/auth/baugesuch/_id.vue';

import Uebersicht from '@/views/auth/baugesuch/tabs/uebersicht.vue';
import Baugesuch from '@/views/auth/baugesuch/tabs/baugesuch.vue';
import Berechtigungen from '@/views/auth/baugesuch/tabs/berechtigungen.vue';
import StatusVerlauf from '@/views/auth/baugesuch/tabs/status_verlauf.vue';
import DecisionRuling from '@/views/auth/baugesuch/tabs/decision_ruling.vue';
import Kommentare from '@/views/auth/baugesuch/tabs/kommentare.vue';
import Messages from '@/views/auth/baugesuch/tabs/messages.vue';
import Circulation from '@/views/auth/baugesuch/tabs/circulation.vue';

import Beteiligte from '@/views/auth/baugesuch/sections/beteiligte.vue';
import Lage from '@/views/auth/baugesuch/sections/lage.vue';
import Unterlagen from '@/views/auth/baugesuch/sections/unterlagen.vue';
import Bauvorhaben from '@/views/auth/baugesuch/sections/bauvorhaben.vue';
import AngabenZumBauvorhaben from '@/views/auth/baugesuch/sections/angaben_zum_bauvorhaben.vue';
import Gewaesserschutz from '@/views/auth/baugesuch/sections/gewaesserschutz.vue';
import Schutzraeume from '@/views/auth/baugesuch/sections/schutzraeume.vue';
import Anstoesser from '@/views/auth/baugesuch/sections/anstoesser.vue';
import Heizungsart from '@/views/auth/baugesuch/sections/heizungsart.vue';
import Zonenplan from '@/views/auth/baugesuch/sections/zonenplan.vue';
import Gischecks from '@/views/auth/baugesuch/sections/gischecks.vue';
import Abschluss from '@/views/auth/baugesuch/sections/abschluss.vue';
import EinstufungBaugesuch from '@/views/auth/baugesuch/sections/einstufung_baugesuch.vue';
import LaermschutzWaermepumpen from '@/views/auth/baugesuch/sections/laermschutz_waermepumpen.vue';
import LaermschutzHlkk from '@/views/auth/baugesuch/sections/laermschutz_hlkk.vue';
import BetriebeMitP from '@/views/auth/baugesuch/sections/betriebe_mit_plangenehmigungspflicht.vue';

export default [
    {
        path: '/baugesuche',
        name: 'baugesuche',
        component: BaugesuchIndex,
        meta: {
            requiresAuth: true,
            title: () => getI18nInstance().t('baugesuche')
        }
    },
    {
        path: '/baugesuche/:id',
        name: 'baugesuch_form',
        component: BaugesuchForm,
        meta: {
            requiresAuth: true,
            title: (to) => `Baugesuch: ${to.params.id}`
        },
        children: [
            {
                name: 'bg_uebersicht',
                path: 'uebersicht',
                component: Uebersicht
            },
            {
                path: 'baugesuch',
                component: Baugesuch,
                children: [
                    {
                        name: 'bg_edit_beteiligte',
                        path: 'beteiligte',
                        component: Beteiligte
                    },
                    {
                        name: 'bg_edit_lage',
                        path: '/',
                        component: Lage
                    },
                    {
                        name: 'bg_edit_unterlagen',
                        path: 'unterlagen',
                        component: Unterlagen
                    },
                    {
                        name: 'bg_edit_allgemeine_angaben',
                        path: 'allgemeine_angaben',
                        component: Bauvorhaben
                    },
                    {
                        name: 'bg_edit_angaben_zum_bauvorhaben',
                        path: 'angaben_zum_bauvorhaben',
                        component: AngabenZumBauvorhaben
                    },
                    {
                        name: 'bg_einstufung_baugesuch',
                        path: 'einstufung',
                        component: EinstufungBaugesuch
                    },
                    {
                        name: 'bg_edit_gewaesserschutz',
                        path: 'gewaesserschutz',
                        component: Gewaesserschutz
                    },
                    {
                        name: 'bg_edit_heizungsart',
                        path: 'heizungsart',
                        component: Heizungsart
                    },
                    {
                        name: 'bg_edit_ls_waermepumpen',
                        path: 'ls_waermepumpen',
                        component: LaermschutzWaermepumpen
                    },
                    {
                        name: 'bg_edit_ls_hlkk',
                        path: 'ls_hlkk',
                        component: LaermschutzHlkk
                    },
                    {
                        name: 'bg_edit_betriebe_mit_plangenehmigungspflicht',
                        path: 'bmp',
                        component: BetriebeMitP
                    },
                    {
                        name: 'bg_edit_schutzraeume',
                        path: 'schutzraeume',
                        component: Schutzraeume
                    },
                    {
                        name: 'bg_edit_anstoesser',
                        path: 'anstoesser',
                        component: Anstoesser
                    },
                    {
                        name: 'bg_edit_zonenplan',
                        path: 'zonenplan',
                        component: Zonenplan
                    },
                    {
                        name: 'bg_edit_gischecks',
                        path: 'gischecks',
                        component: Gischecks
                    },
                    {
                        name: 'bg_edit_abschluss',
                        path: 'abschluss',
                        component: Abschluss
                    }
                ]
            },
            {
                name: 'bg_berechtigungen',
                path: 'berechtigungen',
                component: Berechtigungen
            },
            {
                name: 'bg_status_verlauf',
                path: 'status_verlauf',
                component: StatusVerlauf
            },
            {
                name: 'bg_circulation',
                path: 'circulation',
                component: Circulation
            },
            {
                name: 'bg_kommentare',
                path: 'kommentare',
                component: Kommentare
            },
            {
                name: 'bg_messages',
                path: 'messages',
                component: Messages,
                meta: {
                    roles: ['admin']
                }
            },
            {
                name: 'bg_decision_ruling',
                path: 'decision_ruling',
                component: DecisionRuling
            }
        ]
    }
];
