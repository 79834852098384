<template>
    <v-container>
        <v-form v-if="additionalData" :readonly="readonly" class="my-4">
            <v-row>
                <v-col md="3" class="text-right">{{ $t('intended_wastewater_disposal_method') }}</v-col>
                <v-col md="9">
                    <v-textarea
                        v-model="additionalData.water_protection_op_waste_water_disposal"
                        :label="$t('operational_waste_water')"
                        rows="3"
                        :readonly="readonly"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3" class="text-right"></v-col>
                <v-col md="9">
                    <v-textarea
                        v-model="additionalData.water_protection_roofwater_leachate"
                        :label="$t('roofwater_leachate')"
                        rows="3"
                        :readonly="readonly"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3" class="text-right"></v-col>
                <v-col md="7">
                    <div>{{ $t('water_pollution_question') }}</div>
                </v-col>
                <v-col md="2">
                    <v-select
                        v-model="additionalData.water_protection_pollution"
                        :items="pollutionItems"
                        :readonly="readonly"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="additionalData.water_protection_pollution">
                <v-col md="3" class="text-right"></v-col>
                <v-col md="9">
                    <v-textarea
                        v-model="additionalData.water_protection_pollution_kind"
                        :label="$t('kind')"
                        rows="3"
                        :readonly="readonly"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row v-if="additionalData.water_protection_pollution">
                <v-col md="3" class="text-right"></v-col>
                <v-col md="3">
                    <kp-numberfield
                        v-model="additionalData.water_protection_pollution_amount"
                        :label="$t('amount')"
                        :readonly="readonly"
                    ></kp-numberfield>
                </v-col>
                <v-col md="3">
                    <v-select
                        v-model="additionalData.water_protection_pollution_unit"
                        :items="unitItems"
                        :label="$t('unit')"
                        :readonly="readonly"
                    ></v-select>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { formRules } from '@/helpers/forms.js';
import { mapActions } from 'vuex';
import { AdditionalBaugesuchData } from '@/models/AdditionalBaugesuchData.js';
import kpNumberfield from '@/components/kp-numberfield.vue';
export default {
    components: { kpNumberfield },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            formRules,
            record: this.gesuch,
            additionalData: null,
            pollutionItems: [
                { value: false, text: this.$t('no') },
                { value: true, text: this.$t('yes') }
            ],
            unit: null,
            unitItems: [
                { value: 'l', text: this.$t('liter') },
                { value: 'kg', text: this.$t('kg') }
            ]
        };
    },
    created() {
        this.loadAdditionalData();
    },
    methods: {
        ...mapActions('additional_baugesuch_data', [
            'fetchAdditionalBaugesuchData',
            'storeAdditionalBaugesuchData',
            'destroyAdditionalBaugesuchData'
        ]),

        async loadAdditionalData() {
            this.loading = true;
            try {
                const data = await this.fetchAdditionalBaugesuchData({
                    filter: [
                        {
                            id: 'planning_permission_application_id',
                            property: 'planning_permission_application_id',
                            value: this.gesuch.id
                        }
                    ]
                });
                this.additionalData = data.first();
                if (!this.additionalData) {
                    this.additionalData = new AdditionalBaugesuchData({
                        planning_permission_application_id: this.gesuch.id
                    });
                }
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },

        async beforeSave() {
            if (this.additionalData) {
                if (!this.additionalData.water_protection_pollution) {
                    this.additionalData.water_protection_pollution_kind = null;
                    this.additionalData.water_protection_pollution_amount = null;
                    this.additionalData.water_protection_pollution_unit = null;
                }
                await this.storeAdditionalBaugesuchData(this.additionalData);
            }
        },

        showItem() {
            alert('no');
            return false;
        }
    }
};
</script>
