<template>
    <v-container v-if="tiles">
        <v-row justify="center">
            <v-col
                v-for="appType in applicationTypeCollection ? applicationTypeCollection.models : []"
                :key="appType.id"
                align="middle"
            >
                <div v-ripple class="select-tile pa-2" @click="$emit('select', appType)">
                    <h3 class="ml-2">{{ appType.bezeichnung }}</h3>
                </div>
            </v-col>
        </v-row>
    </v-container>
    <v-select
        v-else
        v-bind="$attrs"
        :dense="dense"
        :readonly="readonly"
        :value="value"
        :label="getLabelText()"
        :items="applicationTypeCollection ? applicationTypeCollection.models : []"
        :loading="isLoading"
        :search-input.sync="applicationTypeSearch"
        :rules="rules"
        item-text="bezeichnung"
        item-value="id"
        clear-icon="fa-solid fa-xmark"
        hide-no-data
        @input="onSelect"
    >
    </v-select>
</template>

<script>
import { debounce } from '@/helpers/tools.js';
import { ApplicationTypeCollection } from '@/models/ApplicationType';
import { formRules } from '@/helpers/forms.js';

/**
 * PLZ-Ort-Select
 *
 * loads Orte from /lookup/ort,
 * emits the selected
 */
export default {
    props: {
        value: {
            type: [Number, Object],
            default: null
        },
        type: {
            type: String,
            default: 'bg'
        },
        rules: {
            type: Array,
            default: () => []
        },
        dense: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        tiles: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            applicationTypeCollection: null,
            isLoading: false,
            applicationTypeSearch: null,
            oldSearchInput: false,
            filter: [{ id: 'type', property: 'type', value: this.type }]
        };
    },
    watch: {
        applicationTypeSearch: debounce(async function (val, oldVal) {
            // prevent lookup on first render (no user input)
            if (!oldVal) {
                return;
            }
            // Here comes an ugly hack: we need to prevent loading
            // when a value is selected from the dropdown list.
            // But as we cannot determine if this function was called
            // during a user text entry, or due to a selection from
            // the OTHER field, we reset the preventLoading flag after.... some time
            if (this.preventLoading) {
                setTimeout(() => (this.preventLoading = false), 500);
                return;
            }
            if (!val || val.length < 3 || this.isLoading) {
                return;
            }
            if (!this.applicationTypeCollection) {
                this.applicationTypeCollection = new ApplicationTypeCollection();
            }
            await this.loadApplicationType({ query: val, filter: this.filter });
        })
    },
    mounted: async function () {
        if (!this.applicationTypeCollection) {
            this.applicationTypeCollection = new ApplicationTypeCollection();
        }
        await this.loadApplicationType({ filter: this.filter });
    },
    methods: {
        getLabelText() {
            let res = this.type === 'pg' ? this.$t('geschaeftsart') : this.$t('gesuchsart');
            if (this.rules.includes(formRules.required)) {
                res += ' *';
            }
            return res;
        },
        onSelect(value) {
            if (value) {
                this.$emit('input', value);
            }
        },
        async loadApplicationType(options) {
            this.isLoading = true;
            if (!this.applicationTypeCollection) {
                this.applicationTypeCollection = new ApplicationTypeCollection();
            }
            await this.applicationTypeCollection.query(options);
            this.isLoading = false;
        },
        onClick() {
            console.log('yuppie');
        }
    }
};
</script>
<style lang="scss">
.select-tile {
    background: rgb(224, 224, 224);
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 200px;
    height: 130px;
    border-radius: 5px;
    &:hover {
        opacity: 0.7;
    }
    h3 {
        width: 100%;
    }
}
</style>
