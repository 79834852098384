/**
 * Country entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Country extends BaseModel {
    defaults() {
        return {
            id: null,
            country_id: null,
            un_id: null,
            iso2_id: null,
            iso3_id: null,
            short_name_de: null,
            short_name_fr: null,
            short_name_it: null,
            short_name_en: null,
            official_name_de: null,
            official_name_fr: null,
            official_name_it: null,
            continent: null,
            region: null,
            un_entry_date: null,
            date_of_change: null,
            recognized_date: null,
            remark_fr: null,
            remark_de: null,
            remark_it: null,
            area_state: null,
            state: null,
            un_member: null,
            recognized_ch: null,
            entry_valid: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Country';
    }
}

export class CountryCollection extends BaseCollection {
    options() {
        return {
            model: Country
        };
    }

    entityName() {
        return 'Country';
    }
}
