<template>
    <v-select
        :value="value"
        :items="items"
        item-text="text"
        item-value="value"
        filled
        :label="$t('typ')"
        v-bind="{ ...$props, ...$attrs }"
        @input="onSelect"
    ></v-select>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null }
    },
    data() {
        return {
            items: [
                {
                    text: this.$t('gemeinde'),
                    value: 'gemeinde'
                },
                {
                    text: this.$t('fachstelle'),
                    value: 'fachstelle'
                }
            ]
        };
    },
    methods: {
        onSelect(value) {
            this.$emit('input', value);
        }
    }
};
</script>
