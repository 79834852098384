<template>
    <v-tooltip v-if="tooltip" bottom>
        <template #activator="{ on, attrs }">
            <v-btn
                :class="finalBtnClass"
                x-small
                fab
                depressed
                v-bind="{ ...$attrs, ...attrs }"
                v-on="{ ...$listeners, ...on }"
            >
                <v-icon :class="[iconActiveCls, iconDisabledCls]" :color="iconColor">{{ iconCls }}</v-icon>
            </v-btn>
        </template>
        {{ tooltip }}
    </v-tooltip>

    <v-btn v-else :class="finalBtnClass" x-small fab depressed v-bind="{ ...$attrs }" v-on="{ ...$listeners }">
        <v-icon :class="[iconActiveCls, iconDisabledCls]" :color="iconColor">{{ iconCls }}</v-icon>
    </v-btn>
</template>
<script>
/**
 * An icon-only button.
 * Provide 'icon-cls' (e.g. 'fa-regular fa-home'), icon-color (e.g. 'white'), tooltip (e.g. 'hello)
 */
export default {
    name: 'IconBtn',
    props: {
        iconCls: String,
        iconColor: { type: String, default: 'white' },
        iconActiveCls: String,
        iconDisabledCls: String,
        tooltip: {
            type: String,
            default: ''
        },
        btnClass: [String, Object],
        marginClass: {
            type: String,
            default: 'ma-1'
        }
    },
    data() {
        let btnClass = {};
        if (this.marginClass) {
            btnClass[this.marginClass] = true;
        }
        if (typeof this.btnClass === 'string') {
            btnClass = `ma-1 ${this.btnClass}`;
        }
        if (this.btnClass instanceof Object) {
            btnClass = { 'ma-1': true, ...this.btnClass };
        }
        return {
            finalBtnClass: btnClass
        };
    }
};
</script>
