/**
 * Guest routes: routes that do not need authentication
 * (login, welcome etc).
 *
 * Will me merged into the router's 'route' array
 */

import Welcome from '@/views/guest/Welcome.vue';
import Login from '@/views/guest/Login.vue';

const routes = [
    {
        path: '/welcome',
        name: 'welcome',
        component: Welcome,
        meta: {
            guest: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true,
            title: 'Anmelden'
        }
    }
];

export default routes;
