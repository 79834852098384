<template>
    <v-dialog :value="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ $t('dwelling_properties') }}</span>
            </v-card-title>

            <v-card-text>
                <v-form>
                    <kp-numberfield v-model="dwellingRec.ewid" :label="$t('ewid_long')"></kp-numberfield>
                    <ech-code-select
                        v-model="dwellingRec.floor"
                        ech-doc-id="eCH-0129"
                        ech-variable-name="floor"
                        i18n-key="floorNo"
                        :label="$t('floor')"
                    ></ech-code-select>
                    <v-checkbox v-model="dwellingRec.multiple_floor" :label="$t('maisonette')"></v-checkbox>
                    <v-checkbox v-model="dwellingRec.kitchen" :label="$t('has_kitchen')"></v-checkbox>
                    <v-row>
                        <v-col cols="10">
                            <v-text-field v-model="entranceRec.street_name" :label="$t('street')">
                            </v-text-field> </v-col
                        ><v-col cols="2">
                            <v-text-field v-model="entranceRec.building_entrance_no" :label="$t('nr')">
                            </v-text-field> </v-col
                    ></v-row>
                    <kp-numberfield
                        v-model="dwellingRec.no_of_habitable_rooms"
                        :label="$t('no_of_habitable_rooms')"
                    ></kp-numberfield>
                    <kp-numberfield
                        v-model="dwellingRec.surface_area_of_dwelling"
                        :label="$t('surface_area_of_dwelling')"
                        suffix="m²"
                    ></kp-numberfield>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>
                <v-btn color="action" text @click="$emit('save', dwellingRec)">{{ $t('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import kpNumberfield from './kp-numberfield.vue';
import EchCodeSelect from './ech-code-select.vue';

export default {
    components: { kpNumberfield, EchCodeSelect },
    props: {
        value: { type: Boolean, default: false },
        dwelling: { type: Object },
        entrance: { type: Object }
    },
    data() {
        return {
            dwellingRec: this.dwelling,
            entranceRec: this.entrance
        };
    }
};
</script>

<style></style>
