<template>
    <div>
        <base-remote-list
            session-key="pg-list-remote"
            :maintitle="$t('planungsgeschaefte')"
            maintitle-icon-cls="fa-regular fa-map"
            :collection-class="PlanungsgeschaeftCollection"
            :headers="headers"
            :filters="filters"
            :options="{ sortBy: ['created'], itemsPerPage: -1 }"
            csv-export
            :height="height"
            @loading="loading = $event"
            @edit-item="onRowClicked"
            @new-item="onAdd"
        >
            <template #after-query-field>
                <v-btn
                    v-if="user && user.isRoleGemeinde"
                    rounded
                    class="hidden-xs-only ml-1"
                    color="subtleHint"
                    @click="onAdd"
                >
                    <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                    {{ $t('add_planungsgeschaeft') }}
                </v-btn>
            </template>

            <template #item.type="{ item }">
                {{ $t(item.type) }}
            </template>

            <template #[`item.aktueller_status`]="{ item }">
                <v-chip :class="'status-' + item.aktueller_status_ebau_value.replaceAll(' ', '-')">
                    {{ item.aktueller_status }}
                </v-chip>
            </template>
            <template #[`item.created`]="{ item }">
                {{ formatDateTime(item.created) }}
            </template>
            <template #[`item.deleted`]="{ item }">
                {{ formatDateTime(item.deleted) }}
            </template>
            <template #subtoolbar>
                <v-tabs v-model="filterTab" background-color="primary" dark style="border-top: 2px solid white">
                    <v-tab key="open">
                        <v-icon left>fa-solid fa-lock-open</v-icon>
                        {{ $t('planungsgeschaefte_offen') }}
                    </v-tab>
                    <v-tab key="closed">
                        <v-icon left>fa-solid fa-lock</v-icon>
                        {{ $t('planungsgeschaefte_abgeschlossen') }}
                    </v-tab>
                </v-tabs>
            </template>
        </base-remote-list>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DateFunctions from '@/mixins/date-functions.js';

import { Planungsgeschaeft, PlanungsgeschaeftCollection } from '@/models/Planungsgeschaeft';

export default {
    components: {},
    mixins: [DateFunctions],
    props: {
        dense: { type: Boolean, default: false },
        nofooter: { type: Boolean, default: false },
        selectable: { type: Boolean, default: false },
        height: { type: String, default: 'calc(100vh - 340px)' },
        selection: { type: Planungsgeschaeft, default: null },
        edit: { type: Boolean, default: true }
    },
    data() {
        return {
            PlanungsgeschaeftCollection,
            planungsgeschaefts: [],
            planungsgeschaeftsLoading: false,
            calculateWidth: true,
            total: 0,
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['id'],
                sortDesc: [false],
                multiSort: false,
                mustSort: true
            },
            filters: [
                { id: 'type', property: 'type', value: 'pg' },
                {
                    id: 'aktueller_status',
                    property: 'aktueller_status',
                    operator: 'NOT IN',
                    value: ['dossier closed', 'dossier archived']
                }
            ],
            filterTab: 0
        };
    },
    computed: {
        ...mapState('user', {
            user: 'record'
        }),
        confirm() {
            return this.itemToDelete !== null;
        },
        params() {
            return {
                ...this.options,
                filters: this.filters
            };
        },
        headers() {
            let res = [
                {
                    text: this.$t('id'),
                    value: 'id',
                    sortable: true,
                    filterable: true,
                    width: 55
                },
                {
                    text: this.$t('gemeinde'),
                    value: 'stelle',
                    sortable: true,
                    filterable: true,
                    width: 250
                },
                {
                    text: this.$t('status'),
                    value: 'aktueller_status',
                    sortable: true,
                    filterable: true,
                    width: 200
                },
                {
                    text: this.$t('created'),
                    value: 'created',
                    sortable: true,
                    filterable: true,
                    width: 80
                },
                {
                    text: this.$t('beschreibung'),
                    value: 'description',
                    sortable: true,
                    filterable: true
                }
            ];
            if (this.user.isRoleAdmin) {
                res.push({
                    text: this.$t('deleted'),
                    value: 'deleted',
                    sortable: true,
                    filterable: true,
                    width: 150
                });
            }
            if (this.edit) {
                res.push({
                    value: 'actions',
                    sortable: false,
                    filterable: false,
                    width: 100
                });
            }

            return res;
        }
    },
    watch: {
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getPlanungsgeschaefts();
                }
            },
            deep: true
        },
        filterTab(tab) {
            let remaining = this.filters.filter((e) => e.id !== 'aktueller_status');
            this.filters = remaining;
            this.filters.push({
                id: 'aktueller_status',
                property: 'aktueller_status',
                operator: tab === 0 ? 'NOT IN' : 'IN',
                value: ['dossier closed', 'dossier archived']
            });
        }
    },
    methods: {
        ...mapState('planungsgeschaeft', {
            planungsgeschaeft: 'record'
        }),
        ...mapActions('planungsgeschaeft', [
            'fetchPlanungsgeschaefte',
            'storePlanungsgeschaeft',
            'destroyPlanungsgeschaeft'
        ]),
        async getPlanungsgeschaefts() {
            this.PlanungsgeschaeftsLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                let data = await this.fetchPlanungsgeschaefte(this.params);
                this.planungsgeschaefts = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.planungsgeschaeftsLoading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onRowClicked(row) {
            this.showForm(row.id);
        },
        showForm(id) {
            this.$router.push({
                name: 'pg_uebersicht',
                params: { id: id || 'new' }
            });
        },
        async onAdd() {
            let planungsgeschaeft = new Planungsgeschaeft({
                id: 'new',
                stelle_id: this.user.active_role_stelle_id ?? this.user.gemeinden_ids[0]
            });

            await this.storePlanungsgeschaeft(planungsgeschaeft);

            this.$router.push({ name: 'pg_edit_beteiligte', params: { id: planungsgeschaeft.id } });
        }
    }
};
</script>

<style scoped>
.fixed-table >>> table {
    table-layout: fixed;
    width: 100%;
}
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    .fixed-table >>> table td {
        text-overflow: ellipsis;
    }
    .fixed-overflow-table >>> table td {
        overflow: hidden;
    }
}
@media screen and (max-width: 600px) {
    .fixed-table >>> table th {
        width: 100%;
    }
}
</style>
