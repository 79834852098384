<template>
    <v-container fluid>
        <v-row class="text-center mt-6 mb-4">
            <v-col class="">
                <h1 class="display-2 font-weight-bold mb-3">
                    {{ welcomeMessage }}
                </h1>
                <h2>{{ $t('ebau_portal') }}</h2>
            </v-col>
        </v-row>
        <v-row v-if="invites && invites.lenght > 0" justify="center">
            <v-col sm="4">
                <v-card class="pa-2">
                    {{ numberOfInvitesTxt }}
                    <v-list v-if="invites && invites.length > 0">
                        <v-list-item v-for="item in invites" :key="item.id" link @click="onInviteCLick(item)">
                            <v-list-item-icon>
                                <v-icon>fa-solid fa-building</v-icon>
                            </v-list-item-icon>
                            {{ item.stelle_organisation_name }}
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-if="(user.isRoleFachstelle || user.isRoleGemeinde) && circulations && circulations.lenght > 0"
            justify="center"
        >
            <v-col sm="4">
                <v-card class="pa-2">
                    {{ numberOfOpenCirculationsTxt }}
                    <v-list v-if="circulations && circulations.length > 0">
                        <v-list-item v-for="item in circulations" :key="item.id" link @click="onCircClick(item)">
                            <v-list-item-icon>
                                <v-icon>fa-solid fa-building</v-icon>
                            </v-list-item-icon>
                            {{ item.id }}
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="d-flex justify-center">
                <v-btn
                    @click="bgShowTypeChooser = true"
                    color="green"
                    dark
                    large
                    width="400px"
                    height="160px"
                    class="mr-4"
                >
                    <v-icon x-large>fa-solid fa-plus fa-2xl</v-icon>
                    <h1 class="ml-2">{{ $t('baugesuch') }} <br />{{ $t('create') }}</h1>
                    <TypeChooser v-if="bgShowTypeChooser" v-model="bgShowTypeChooser" @create="onBgAdd"></TypeChooser>
                </v-btn>
                <v-btn :to="{ name: 'baugesuche' }" color="orange" dark large width="400px" height="160px">
                    <v-icon x-large>fa-solid fa-pen-to-square fa-xs</v-icon>
                    <h1 class="ml-2">{{ $t('baugesuch') }} <br />{{ $t('edit') }}</h1>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="user.isRoleGemeinde || user.isRoleAdmin" class="mt-4">
            <v-col class="d-flex justify-center">
                <v-btn @click="onPgAdd" color="green" dark large width="400px" height="160px" class="mr-4">
                    <v-icon left x-large>fa-solid fa-plus fa-xs</v-icon>
                    <h1 class="ml-2">{{ $t('planungsgeschaeft') }} <br />{{ $t('create') }}</h1>
                </v-btn>
                <v-btn :to="{ name: 'planungsgeschaefte' }" color="orange" dark large width="400px" height="160px">
                    <v-icon left x-large>fa-solid fa-pen-to-square fa-xs</v-icon>
                    <h1 class="ml-2">{{ $t('planungsgeschaeft') }} <br />{{ $t('edit') }}</h1>
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="changes && changes.length > 0" justify="center">
            <v-col sm="5">
                <h2>{{ $t('status_change_since_last_login') }}</h2>
                <v-card class="pa-2">
                    <v-list v-if="changes && changes.length > 0" three-line>
                        <v-list-item v-for="item in changes" :key="item.id" link @click="onChangeVerlaufClick(item)">
                            <v-list-item-icon>
                                <v-icon v-if="item.ppa_type === 'bg'" dense>fa-regular fa-house-building fa-xs</v-icon>
                                <v-icon v-else dense>fa-regular fa-map fa-xs</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span v-if="item.ppa_type === 'bg'">{{ $t('baugesuch') }}</span>
                                    <span v-else>{{ $t('planungsgeschaeft') }}</span>
                                    : {{ item.planning_permission_application_id }} -
                                    {{ item.kurz_bemerkung }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item.created }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ item.bemerkung }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <p v-else>{{ $t('keine_datensaetze_vorhanden') }}</p>
                </v-card>
            </v-col>
            <v-col sm="5">
                <h2>{{ $t('events') }}</h2>
                <v-card class="pa-2">
                    <v-list v-if="events && events.length > 0" three-line>
                        <v-list-item v-for="item in events" :key="item.id" link @click="onEventClick(item)">
                            <v-list-item-icon>
                                <v-icon v-if="item.ppa_type === 'bg'" dense>fa-regular fa-house-building fa-xs</v-icon>
                                <v-icon v-else dense>fa-regular fa-map fa-xs</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span v-if="item.ppa_type === 'bg'">{{ $t('baugesuch') }}</span>
                                    <span v-else>{{ $t('planungsgeschaeft') }}</span>
                                    : {{ item.planning_permission_application_id }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item.created }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <icon-btn
                                    icon-cls="fa-solid fa-trash"
                                    icon-color="red"
                                    :tooltip="$t('delete')"
                                    @click="onEventDelete(item)"
                                    @click.stop
                                />
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <p v-else>{{ $t('keine_datensaetze_vorhanden') }}</p>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VueImg from '@/assets/logo.svg';
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import { instance as getApi } from '@/helpers/api';

import { Baugesuch } from '@/models/Baugesuch';
import TypeChooser from '@/components/baugesuch/baugesuch-type-chooser.vue';
import { Planungsgeschaeft } from '@/models/Planungsgeschaeft';

export default {
    name: 'HomeView',
    components: {
        TypeChooser
    },
    data: () => ({
        VueImg,
        now: dayjs(),
        invites: [],
        circulations: [],
        changes: [],
        events: [],
        bgShowTypeChooser: false
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.record
        }),
        numberOfInvitesTxt() {
            return (
                this.$t('number_of_invites_text').replace('%NUM%', this.invites.length) +
                (this.invites.length > 0 ? ':' : '')
            );
        },
        numberOfOpenCirculationsTxt() {
            return (
                this.$t('number_of_circulations_text').replace('%NUM%', this.circulations.length) +
                (this.circulations.length > 0 ? ':' : '')
            );
        },
        welcomeMessage() {
            if (this.user.isRoleGemeinde || this.user.isRoleAdmin) {
                return this.$t('wellcome_message_gemeinde_user');
            }

            return this.$t('wellcome_message_normal_user');
        }
    },
    mounted: function () {
        this.loadOpenInvitations();
        this.loadOpenCirculations();
        this.loadChangesSinceLastLogin();
        this.loadEvents();
    },
    methods: {
        ...mapActions('baugesuch', ['storeBaugesuch']),
        ...mapActions('planungsgeschaeft', ['storePlanungsgeschaeft']),
        async loadOpenInvitations() {
            let res = await getApi().post('/stelle/listInvitations');
            if (res && res.data) {
                this.invites = res.data.records;
            }
        },
        async loadOpenCirculations() {
            let res = await getApi().post('/stelle/listOpenCirculations');
            if (res && res.data) {
                this.circulations = res.data.records;
            }
        },
        onInviteCLick(item) {
            this.$router.push({
                name: 'stelle_form',
                params: { id: item.stelle_id, tab: 1 }
            });
        },
        onCircClick(item) {
            let form = item.planning_permission_application_type === 'bg' ? 'bg_uebersicht' : 'pg_uebersicht';
            this.$router.push({
                name: form,
                params: { id: item.planning_permission_application_id }
            });
        },
        async loadChangesSinceLastLogin() {
            let res = await getApi().post('/user/getChangesSinceLastLogin');
            if (res && res.data) {
                this.changes = res.data.records;
            }
        },
        async loadEvents() {
            let res = await getApi().post('/event/getEventsForAuthUser');
            if (res && res.data) {
                this.events = res.data.records;
            }
        },
        onChangeVerlaufClick(item) {
            let form = item.ppa_type === 'bg' ? 'bg_uebersicht' : 'pg_uebersicht';
            this.$router.push({
                name: form,
                params: { id: item.planning_permission_application_id }
            });
        },
        onEventClick(item) {
            let form = item.ppa_type === 'bg' ? 'bg_uebersicht' : 'pg_uebersicht';
            this.$router.push({
                name: form,
                params: { id: item.planning_permission_application_id }
            });
        },
        async onEventDelete(item) {
            let res = await getApi().post('/event/destroy', { id: item.id });
            if (res && res.data) {
                this.loadEvents();
            }
        },
        async onBgAdd(value) {
            this.bgShowTypeChooser = false;
            let baugesuch = new Baugesuch({
                id: 'new',
                application_type_id: value
            });
            await this.storeBaugesuch(baugesuch);
            this.$router.push({ name: 'bg_edit_lage', params: { id: baugesuch.id } });
        },
        async onPgAdd() {
            let planungsgeschaeft = new Planungsgeschaeft({
                id: 'new',
                stelle_id: this.user.active_role_stelle_id ?? this.user.gemeinden_ids[0]
            });

            await this.storePlanungsgeschaeft(planungsgeschaeft);

            this.$router.push({ name: 'pg_edit_beteiligte', params: { id: planungsgeschaeft.id } });
        }
    }
};
</script>
