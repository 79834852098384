<template>
    <v-dialog v-model="generatingPDF" hide-overlay persistent width="300">
        <v-card color="primary" dark>
            <v-card-text>
                {{ loadingText }}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            generatingPDF: false,
            loadingText: 'Datei wird erstellt'
        };
    },
    methods: {
        downloadFile(url, message, params = []) {
            this.loadingText = message;
            this.generatingPDF = true;

            axios({
                url: window.apiRoot + url,
                method: 'GET',
                responseType: 'blob',
                params: params
            })
                .then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    let fileName = 'unknown';

                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                        if (fileNameMatch.length === 2) {
                            fileName = fileNameMatch[1];
                        }
                    }
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    this.generatingPDF = false;
                    this.downloadUrl = null;
                })
                .catch((error) => {
                    this.generatingPDF = false;
                    this.downloadUrl = null;
                    this.$toast({ message: error, color: 'error' });
                });
        }
    }
};
</script>
