<template>
    <v-skeleton-loader v-if="loading" type="list-item-avatar, list-item-three-line@3, actions"></v-skeleton-loader>
    <div v-else>
        <v-container fluid>
            <v-card fluid class="px-10">
                <v-card-title text-color="white">
                    {{ formTitle }}
                </v-card-title>

                <v-card elevation="0">
                    <v-form v-model="valid" @input="$emit('validchange', valid)" @submit.stop="onSubmit">
                        <v-row dense>
                            <v-col>
                                <kp-textfield v-model="news.title" :label="$t('title')"></kp-textfield>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <HtmlEditor v-model="news.content" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-menu
                                    v-model="showDatePickerFromDate"
                                    hide-details
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template #activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="formattedDisplayFromDate"
                                            prepend-icon="fa-solid fa-calendar"
                                            :label="$t('display_from')"
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="news.display_from"
                                        first-day-of-week="1"
                                        @input="showDatePickerFromDate = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    v-model="showDatePickerToDate"
                                    hide-details
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template #activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="formattedDisplayToDate"
                                            prepend-icon="fa-solid fa-calendar"
                                            :label="$t('display_to')"
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="news.display_to"
                                        first-day-of-week="1"
                                        @input="showDatePickerToDate = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-checkbox v-model="news.static_display" :label="$t('static_display')"></v-checkbox>
                            </v-col>
                            <v-col> &nbsp; </v-col>
                        </v-row>
                    </v-form>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="action white--text"
                            type="submit"
                            :disabled="!valid || loading"
                            :loading="loading"
                            @click="onSubmit"
                        >
                            <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                            {{ $t('save') }}
                        </v-btn>
                        <v-btn color="decline" :disabled="loading" text @click="closeForm">
                            {{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formRules } from '@/helpers/forms.js';
import { errToStr } from '@/helpers/errors.js';
import KpTextfield from '@/components/kp-textfield.vue';
import { News } from '@/models/News';
import HtmlEditor from '@/components/html-editor/Editor.vue';
import dayjs from 'dayjs';

export default {
    components: {
        KpTextfield,
        HtmlEditor
    },
    data() {
        return {
            news: Object,
            formRules,
            loading: true,
            valid: false,
            showDatePickerFromDate: false,
            showDatePickerToDate: false
        };
    },
    computed: {
        formTitle() {
            if (this.news && this.news.id) {
                return 'ID: ' + this.news.id + ', ' + this.news.title;
            }

            return this.$t('new_news');
        },
        formattedDisplayFromDate() {
            return this.news.display_from ? dayjs(this.news.display_from).format('DD.MM.YYYY') : '';
        },
        formattedDisplayToDate() {
            return this.news.display_to ? dayjs(this.news.display_to).format('DD.MM.YYYY') : '';
        }
    },
    watch: {
        $route: function () {
            // We already are loaded, do nothing:
            if (this.news && this.news.id === Number(this.$route.params.id)) {
                return;
            }
            this.loadRecord(this.$route.params.id);
        }
    },
    async created() {
        await this.loadRecord(this.$route.params.id);
    },
    methods: {
        ...mapActions(['runAsync']),
        async loadRecord(id) {
            this.loading = true;
            try {
                if (id === 'new') {
                    this.news = new News();
                } else {
                    this.news = new News({ id });
                    await this.news.fetch();
                }
            } catch (e) {
                this.$toast({ news: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        closeForm() {
            this.$router.push({ name: 'admin_news' });
        },
        async onSubmit() {
            try {
                await this.news.save();
                this.closeForm();
            } catch (e) {
                this.$toast({ news: errToStr(e), color: 'error' });
            }
        }
    }
};
</script>
