<template>
    <div>
        <Toast></Toast>
        <GuestRootView v-if="!authenticated" />
        <AuthRootView v-if="authenticated" />
    </div>
</template>

<script>
import AuthRootView from '@/views/auth/AuthRootView.vue';
import GuestRootView from '@/views/guest/GuestRootView.vue';
import Toast from '@/components/Toast.vue';

export default {
    name: 'App',
    components: {
        AuthRootView,
        GuestRootView,
        Toast
    },

    computed: {
        authenticated() {
            return this.$store.getters.isAuthenticated;
        }
    }
};
</script>
