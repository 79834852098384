import auth from '@/helpers/auth';

import { User, UserCollection } from '@/models/User';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {
        setUser(state, user) {
            state.record = User.fromData(user);
        },
        setError(state, error) {
            state.error = error;
        }
    },
    actions: {
        /**
         * Executes a login request. Returns the user
         * object when successful, or sets an error if not.
         *
         * Stores the user in the state. (user/record)
         */
        async loginTry({ commit }, { username, password }) {
            commit('loading', true, { root: true });
            commit('setError', null);
            let user = null;
            try {
                let loginResponse = await auth.login(username, password);
                user = User.fromData(loginResponse.user);
                commit('setUser', user);
            } catch (err) {
                commit('setError', String(err.message || err));
            }
            commit('loading', false, { root: true });
            return user;
        },

        /**
         * Logs out the user and sets the user state to null.
         */
        async logout({ commit }) {
            auth.logout();
        },

        async fetchUsers({ commit }, options = {}) {
            let list = new UserCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadUser({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new User();
                } else {
                    record = new User({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storeUser({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyUser({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async verifyUser({ commit }, password) {
            commit('loading', true, { root: true });
            let res = await auth.verifyUser(password);
            commit('loading', false, { root: true });
            return res;
        }
    }
};
