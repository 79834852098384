/**
 * Store module: access_control
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { AccessControl, AccessControlCollection } from '@/models/AccessControl';
import { instance as getApi } from '@/helpers/api';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async createAccessControl({ commit }, options = {}) {
            commit('loading', true, { root: true });
            try {
                let res = await getApi().post('/AccessControl/create', options);
                if (res && res.data && res.data.data) {
                    return res.data.data;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        },
        async fetchAccessControls({ commit }, options = {}) {
            let list = new AccessControlCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async isReadonlyPpa({ commit }, id) {
            if (id === null) {
                return false;
            }
            commit('loading', true, { root: true });
            try {
                let res = await getApi().post('/AccessControl/isReadonlyPpa', {
                    planning_permission_application_id: id
                });
                if (res && res.data) {
                    return res.data.readonly;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async loadAccessControl({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new AccessControl();
                } else {
                    record = new AccessControl({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storeAccessControl({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyAccessControl({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        }
    }
};
