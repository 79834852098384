<template>
    <base-list
        :session-key="sessionKey"
        :loading="loading"
        :loading-text="$t('loading')"
        :items="modelCollection ? modelCollection.models : []"
        :headers="headers"
        item-key="id"
        :items-per-page="50"
        :options.sync="tableOptions"
        :server-items-length="total"
        :subtitle="subtitle"
        :hide-default-footer="hideDefaultFooter"
        :hide-toolbar="hideToolbar"
        v-bind="$attrs"
        v-on="$listeners"
        @csv-export="csvExport"
        @reload="reload"
        @delete-item="deleteItem"
        @restore-item="restoreItem"
    >
        <template #top="{ pagination, options, updateOptions }">
            <slot name="top" v-bind="{ pagination, options, updateOptions }"></slot>
        </template>

        <template #before-query-field="{ pagination, options, updateOptions }">
            <slot name="before-query-field" v-bind="{ pagination, options, updateOptions }"></slot>
        </template>
        <template #query-field>
            <v-text-field
                v-if="showQuery"
                v-model="query"
                class="query-field flex-grow-0"
                background-color="white"
                append-icon="ic-20 ic-searchmagnifier-grey"
                clearable
                dense
                clear-icon="ic-20 ic-x-grey"
                hide-details
                :placeholder="$t('suchen')"
                @keydown.enter.stop="search"
                @click:clear="clearSearch"
            />
            <span v-else></span>
        </template>
        <template #toolbar-items>
            <slot name="toolbar-items"></slot>
        </template>

        <template #additional-actions="{ item }">
            <slot name="additional-actions" :item="item"> </slot>
        </template>

        <template #after-query-field="{ pagination, options, updateOptions }">
            <slot name="after-query-field" v-bind="{ pagination, options, updateOptions }"></slot>
        </template>

        <template #footer="{ props, on }">
            <slot name="footer" v-bind="{ props, on }">
                <div class="d-flex justify-end">
                    <v-data-footer
                        class="pagination"
                        :options="props.options"
                        :pagination="props.pagination"
                        :items-per-page-options="[]"
                        show-first-last-page
                        show-current-page
                        disable-items-per-page
                        v-on="on"
                    /></div
            ></slot>
        </template>

        <template
            v-for="field in headers.filter((i) => i.value && i.value !== 'actions')"
            #[createSlotName(field.value)]="data"
        >
            <slot :name="createSlotName(field.value)" v-bind="data">{{ data.value }}</slot>
        </template>
        <template #subtoolbar>
            <slot name="subtoolbar"></slot>
        </template>
    </base-list>
</template>

<script>
import { mapActions } from 'vuex';
import { tableOptionsToQueryParams, debounce } from '@/helpers/tools';
import { errToStr } from '@/helpers/errors';
import sessionStorageMixin from '@/mixins/session-storage';
import { apiUrl } from '@/helpers/globals';
import { instance as getApi } from '@/helpers/api';

/**
 * Base Remote list: Acts as a generic REMOTE base list to display a grid with
 * basic functionality like querying, sorting, deleting, editing.
 * It loads its data remotely from the given ModelCollection class.
 *
 * The component DOES load and manage the collection by itself
 *
 * NOTE: Based on base-list: Adapt both components if needed, this is a PROOF-of-CONCEPT!
 *
 * Slots:
 * - before-query-field
 * - after-query-field
 * - toolbar-items
 *
 * Events:
 * - all events from BaseList (base-list.vue) are transparently passed
 *
 * - delete-item: handled by the ModelCollection, invokes [entityName]/destroy?id=x
 * - new-item: if the new action btn is clicked
 * - edit-item: if the edit action btn is clicked on an item, edit-item with the model clicked is called
 * - loading: emit when the loading state toggles
 * - loaded: emit when the collection is loaded successfully
 */
export default {
    name: 'BaseRemoteList',
    mixins: [sessionStorageMixin],

    props: {
        // needed for sessionSet/Get to identify the component uniquely. Provide something like "user-list".
        sessionKey: {
            type: String,
            required: true
        },
        // The class constructor of a ModelCollection class that represents this list content
        collectionClass: {
            type: Function,
            required: true
        },
        /** Set to true to use cached data when this component is re-built. */
        cacheData: {
            type: Boolean,
            default: false
        },
        // the data table's headers
        headers: {
            type: Array,
            required: true
        },
        // true to show the quick query box
        showQuery: {
            type: Boolean,
            default: true
        },
        // filters for the list, an array of {property,operator,value} entries
        filters: {
            type: Array,
            default: () => []
        },
        // extra queryparams to send with each remote query (e.g. active: true)
        extraParams: {
            type: Object,
            default: () => {}
        },
        // the data table's options object. Set if you want to set a default, e.g. default sort order
        options: {
            type: Object,
            default: () => {
                return {
                    page: 1,
                    itemsPerPage: 100,
                    sortBy: [],
                    sortDesc: [false],
                    multiSort: false,
                    mustSort: true
                };
            }
        },
        subtitle: { type: String, default: '' },
        hideDefaultFooter: { type: Boolean, default: true },
        hideToolbar: { type: Boolean, default: false }
    },

    data() {
        return {
            modelCollection: null,
            loading: false,
            tableOptions: {
                ...this.options,
                ...this.getSessionValue('tableOptions')
            },
            total: 0,
            query: this.getSessionValue('query', '')
        };
    },

    watch: {
        filters: {
            deep: true,
            handler() {
                this.setSessionValue('filters', [...this.filters]);
                this.loadCollection();
            }
        },
        extraParams: {
            deep: true,
            handler() {
                this.setSessionValue('extraParams', { ...this.extraParams });
                this.loadCollection();
            }
        },
        tableOptions: {
            deep: true,
            handler() {
                this.setSessionValue('tableOptions', { ...this.tableOptions });
                this.$emit('update:options', { ...this.tableOptions });
                this.loadCollection();
            }
        },
        loading(val) {
            this.$emit('loading', val);
        }
    },

    methods: {
        ...mapActions(['runAsync']),

        createSlotName(value) {
            return `item.${value}`;
        },

        async deleteItem(item) {
            if (item) {
                try {
                    this.loading = true;
                    await this.runAsync(() => item.delete());
                    this.loadCollection();
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                    item.reset();
                } finally {
                    this.loading = false;
                }
            }
        },

        async restoreItem(item) {
            try {
                let res = await getApi().post('/' + item.entityName() + '/restore', { id: item.id });
                if (res && res.data) {
                    console.log(res.data);
                }
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }

            this.loadCollection();
        },

        reload() {
            this.loadCollection();
        },

        // loadCollection needs to be debounced: It is initially called
        // multiple times during tableOptions initialization, so we
        // debouce here:
        loadCollection: debounce(async function () {
            /*
                //TODO: Maybe we need this later...
            if (this.cacheData) {
                console.log('use cached data');
                let data = this.$store.state.dataCache[this.sessionKey] || null;
                if (!data) {
                    const collection = new this.collectionClass();
                    // this.modelCollection = collection;
                    this.$store.dispatch('updateDataCache', { key: this.sessionKey, data: collection });
                    this.loading = true;
                    try {
                        await this.runAsync(async () => collection.query(this.buildQueryParams()));
                        this.total = collection.get('remoteTotal');
                    } catch (e) {
                        this.$toast({ message: errToStr(e), color: 'error' });
                    } finally {
                        this.loading = false;
                    }
                    this.modelCollection = collection;
                } else {
                    this.modelCollection = data;
                    this.total = data.get('remoteTotal');
                }
            } else {
                this.modelCollection = collection;
            */
            let collection = await this.loadRemoteData();
            if (collection) {
                this.modelCollection = collection;
                this.total = collection.get('remoteTotal');
                this.$emit('loaded', this.modelCollection);
            }
        }, 50),

        async loadRemoteData() {
            const collection = new this.collectionClass();
            this.loading = true;
            try {
                await this.runAsync(async () => collection.query(this.buildQueryParams()));
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            } finally {
                this.loading = false;
            }
            return collection;
        },

        buildQueryParams() {
            const queryParams = { ...tableOptionsToQueryParams(this.tableOptions), ...this.extraParams };
            const query = (this.query || '').trim();
            if (query.length > 0) {
                queryParams.query = query;
            }
            let filters = [...this.filters];
            if (filters.length > 0) {
                queryParams.filter = filters;
            }

            return queryParams;
        },

        search() {
            this.setSessionValue('query', this.query);
            this.loadCollection();
        },

        clearSearch() {
            this.query = '';
            this.clearSessionValue('query');
            this.loadCollection();
        },

        csvExport() {
            if (this.modelCollection && this.modelCollection.entityName()) {
                const params = this.buildQueryParams();
                params.output = 'csv';
                const url = apiUrl(`/${this.modelCollection.entityName()}/list`, params);
                window.open(url, 'csvExport');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.pagination {
    border-top: none !important;
    margin: 0 !important;
    padding: 0 !important;
}
.query-field {
    background-color: white;
    padding: 3px 5px;
    max-width: 250px;
}
</style>
