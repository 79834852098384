/**
 * BuildingEntrance entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class BuildingEntrance extends BaseModel {
    defaults() {
        return {
            id: null,
            building_id: null,
            egaid: null,
            edid: null,
            building_entrance_no: null,
            coordinate_east: null,
            coordinate_north: null,
            is_official_address: null,
            street_name: null,
            swiss_zip_code: null,
            swiss_zip_code_add_on: null,
            locality_name: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'BuildingEntrance';
    }
}

export class BuildingEntranceCollection extends BaseCollection {
    options() {
        return {
            model: BuildingEntrance
        };
    }

    entityName() {
        return 'BuildingEntrance';
    }
}
