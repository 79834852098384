<template>
    <v-container>
        <v-container v-if="loading">{{ $t('loading') }}...</v-container>
        <v-container v-else>
            <v-row v-if="checks.length > 0">
                <v-col> {{ $t('lage_an_in') }}: </v-col>
            </v-row>
            <v-row v-for="(row, index) in rows" :key="index">
                <v-col v-for="item in row" :key="item.id"
                    ><v-checkbox
                        v-model="item.hit"
                        :label="getLabelForCheck(item)"
                        :ripple="false"
                        readonly
                        hide-details
                        dense
                    ></v-checkbox
                ></v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import { instance as getApi } from '@/helpers/api';
import dayjs from 'dayjs';

export default {
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: true,
            checks: [],
            dayjs: dayjs
        };
    },
    computed: {
        rows() {
            let res = [];
            for (let i = 0; i < this.checks.length; i += 3) {
                res.push(this.checks.slice(i, i + 3));
            }

            return res;
        }
    },
    created() {},
    mounted() {
        this.loadGisData();
    },
    methods: {
        async beforeSave() {
            return false;
        },

        async loadGisData() {
            this.loading = true;
            try {
                let geodata = await getApi().post('/Baugesuch/getGisChecks', {
                    baugesuch_id: this.gesuch.id
                });
                this.checks = geodata.data.checks;
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },

        getLabelForCheck(check) {
            let label = this.$t(check.label);

            if (check.type === 'distance') {
                label += ' (<' + check.distance + 'm)';
            }

            if (check.additionalInfo) {
                label += ': ' + check.additionalInfo;
            }

            return label;
        }
    }
};
</script>
