export default {
    methods: {
        formatDate(value) {
            return value ? this.$dayjs(value).format('DD.MM.YYYY') : null;
        },
        formatDateTime(value) {
            return value ? this.$dayjs(value).format('DD.MM.YYYY H:mm') : null;
        },
        formatDateForDb(value) {
            return value ? this.$dayjs(value).format('YYYY-MM-DD') : null;
        }
    }
};
