<template>
    <v-select
        :value="value"
        :items="items"
        item-text="text"
        item-value="value"
        filled
        :label="$t('rolle')"
        v-bind="{ ...$props, ...$attrs }"
        @input="onSelect"
    ></v-select>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null },
        context: { type: String, default: 'pg' }
    },
    data() {
        return {
            items: []
        };
    },
    created() {
        this.items = [
            {
                text: this.$t('applicant'),
                value: 'applicant'
            },
            {
                text: this.$t('project author'),
                value: 'project author'
            }
        ];
        if (this.context === 'pg') {
            this.items.push({
                text: this.$t('clerk'),
                value: 'clerk'
            });
        } else if (this.context === 'bg') {
            this.items.push(
                {
                    text: this.$t('landowner'),
                    value: 'landowner'
                },
                {
                    text: this.$t('invoice recipient'),
                    value: 'invoice recipient'
                }
            );
        }
    },
    methods: {
        onSelect(value) {
            this.$emit('input', value);
        }
    }
};
</script>
