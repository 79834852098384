<template>
    <v-container fluid class="text-center">
        <v-data-table
            :headers="headers"
            :items="accessControls ? accessControls.models : []"
            :items-per-page="15"
            class="elevation-1"
            @click:row="onRowClicked"
        >
            <template #item.lockstate="{ item }">
                <v-icon v-if="item.locked">fa-solid fa-lock</v-icon>
                <v-btn
                    v-if="!item.locked && item.planning_permission_application_creator_id === user.id"
                    class="mr-1"
                    x-small
                    fab
                    rounded
                    color="error"
                    @click.stop="destroyItem(item)"
                >
                    <v-icon x-small>fa-solid fa-trash</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-btn v-if="allowAddACs()" class="ma-2" outlined fab small @click="onAddClicked">
            <v-icon>fa-solid fa-plus</v-icon>
        </v-btn>
        <Dialog
            v-if="showDialog"
            :show="showDialog"
            :planning-permission-application-id="planningPermissionApplication.id"
            :access-control="accessControl"
            :max-mode="getMaxACMode()"
            @close="showDialog = false"
            @saved="onRecordSaved"
        ></Dialog>
        <ConfirmDialog
            v-model="showConfirmDelDialog"
            title="Bestätigung"
            text="Soll dieser Datensatz wirklich gelöscht werden?"
            @yes="confirmDestroy"
        />
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import Dialog from '@/components/access-control/access-control-dialog';
import ConfirmDialog from '@/components/confirm_dialog.vue';
import { errToStr } from '@/helpers/errors';
import { MODE } from '@/models/AccessControl';

export default {
    components: {
        Dialog,
        ConfirmDialog
    },
    props: {
        planningPermissionApplication: { type: Object, default: null },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            accessControls: [],
            headers: [
                {
                    text: this.$t('name'),
                    align: 'start',
                    value: 'name'
                },
                { text: this.$t('access_right'), value: 'mode_string' },
                { value: 'lockstate' }
            ],
            total: 0,
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['name'],
                sortDesc: [false],
                multiSort: false,
                mustSort: true
            },
            filter: [
                {
                    id: 'planning_permission_application_id',
                    property: 'planning_permission_application_id',
                    value: this.planningPermissionApplication.id
                }
            ],
            showDialog: false,
            accessControl: null,
            toDelete: null,
            showConfirmDelDialog: false
        };
    },
    computed: {
        params() {
            return {
                ...this.options,
                filter: this.filter
            };
        }
    },
    watch: {
        reload: {
            async handler(val) {
                if (val) {
                    await this.getAccessControls();
                    this.$emit('reloaded');
                }
            }
        },
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getAccessControls();
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getAccessControls();
    },
    methods: {
        ...mapActions('access_control', [
            'fetchAccessControls',
            'storeAccessControl',
            'loadAccessControl',
            'destroyAccessControl'
        ]),
        async getAccessControls() {
            this.loading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                let data = await this.fetchAccessControls(this.params);
                this.accessControls = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        onAddClicked() {
            this.accessControl = null;
            this.showDialog = true;
        },

        onRecordSaved(item) {
            this.showDialog = false;
            this.getAccessControls();
            this.accessControl = null;
        },
        onRowClicked(row) {
            if (!row.locked && !this.readonly) {
                this.showForm(row.id);
            }
        },
        async showForm(id) {
            try {
                this.accessControl = await this.loadAccessControl(id);
                this.showDialog = true;
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        destroyItem(item) {
            this.toDelete = item;
            this.showConfirmDelDialog = true;
        },
        async confirmDestroy() {
            try {
                await this.destroyAccessControl(this.toDelete);
                this.toDelete = null;
                this.total = this.total - 1;
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        allowAddACs() {
            for (let i = 0; i < this.accessControls.length; i++) {
                let ac = this.accessControls.models[i];
                if (ac && ac.user_id === this.user.id && ac.mode === MODE.READ_WRITE) {
                    return true;
                }
                if (ac && this.user.isInStelle(ac.stelle_id) && ac.mode === MODE.READ_WRITE) {
                    return true;
                }
            }

            return false;
        },
        getMaxACMode() {
            let mode = MODE.READ;

            if (this.user.isRoleAdmin) {
                return MODE.READ_WRITE;
            }

            for (let i = 0; i < this.accessControls.length; i++) {
                let ac = this.accessControls.models[i];
                if (
                    ac &&
                    (ac.user_id === this.user.id || (ac.stelle_id && this.user.isInStelle(ac.stelle_id))) &&
                    ac.mode === MODE.READ_WRITE
                ) {
                    mode = MODE.READ_WRITE;
                }
            }

            return mode;
        }
    }
};
</script>

<style></style>
