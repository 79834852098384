/**
 * Store module: UserStelle
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { UserStelle, UserStelleCollection } from '@/models/UserStelle';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchUserStellen({ commit }, options = {}) {
            let list = new UserStelleCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadUserStelle({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new UserStelle();
                } else {
                    record = new UserStelle({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storeUserStelle({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyUserStelle({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        }
    }
};
