<template>
    <v-container>
        <v-form v-if="additionRecord" class="my-4" :readonly="readonly">
            <v-row>
                <v-col sm="3" class="text-right"> {{ $t('bauvorhaben') }} </v-col>
                <v-col sm="9">
                    <v-row>
                        <v-col cols="6">
                            <v-select
                                v-model="bauvorhabenArt"
                                return-object
                                :items="items"
                                :label="$t('planned_works')"
                                :readonly="readonly"
                            >
                                <template #prepend>
                                    <info-tooltip :text="$t('planned_works_info')" right />
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="record.intended_purpose"
                                multiple
                                :items="zweckbestimmung"
                                :label="$t('intended_purpose_long')"
                                :readonly="readonly"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="record.dossier_identification"
                                :label="$t('dossier_identification')"
                                :readonly="readonly"
                                rows="3"
                                :rules="[formRules.maxLength(255)]"
                            >
                                <template #prepend>
                                    <info-tooltip :text="$t('dossier_identification_info')" right />
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="record.description"
                                :label="$t('description')"
                                :readonly="readonly"
                                :rules="[formRules.maxLength(1000)]"
                            >
                                <template #prepend>
                                    <info-tooltip :text="$t('description_info')" right />
                                </template>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <kp-numberfield
                                v-model="record.construction_cost"
                                :label="$t('construction_cost')"
                                prefix="CHF"
                                :readonly="readonly"
                            >
                                <template #prepend>
                                    <info-tooltip :text="$t('construction_cost_info')" right />
                                </template>
                            </kp-numberfield>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-menu
                                v-model="pojectStartMenu"
                                hide-details
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :readonly="readonly"
                            >
                                <template #activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formattedStartDate"
                                        :label="$t('planned_project_start_date')"
                                        prepend-icon="fa-solid fa-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <template #prepend>
                                            <info-tooltip
                                                :text="$t('planned_project_start_date_info')"
                                                right
                                            /> </template
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="additionRecord.project_start_date"
                                    first-day-of-week="1"
                                    @input="pojectStartMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <kp-numberfield
                                v-model="additionRecord.duration_of_construction_phase"
                                :label="$t('duration_of_construction_phase')"
                                :readonly="readonly"
                            ></kp-numberfield>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="3" class="text-right mt-3"> {{ $t('gewuenschte_anschluesse') }} </v-col>
                <v-col sm="9">
                    <v-row>
                        <v-col cols="4" dense>
                            <v-checkbox
                                v-model="anschluesse"
                                hide-details
                                value="unveraendert"
                                :readonly="readonly"
                                :label="$t('unveraendert')"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" dense>
                            <v-checkbox
                                v-model="anschluesse"
                                hide-details
                                :disabled="unveraendert"
                                value="wasser"
                                :readonly="readonly"
                                :label="$t('wasser')"
                            >
                            </v-checkbox>
                        </v-col>
                        <v-col cols="4" dense>
                            <v-checkbox
                                v-model="anschluesse"
                                hide-details
                                :disabled="unveraendert"
                                value="kanalisation"
                                :readonly="readonly"
                                :label="$t('kanalisation')"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" dense>
                            <v-checkbox
                                v-model="anschluesse"
                                hide-details
                                :disabled="unveraendert"
                                value="gas"
                                :readonly="readonly"
                                :label="$t('gas')"
                            >
                            </v-checkbox>
                        </v-col>
                        <v-col cols="4" dense>
                            <v-checkbox
                                v-model="anschluesse"
                                hide-details
                                :disabled="unveraendert"
                                value="elektrisch"
                                :readonly="readonly"
                                :label="$t('elektrisch')"
                            >
                            </v-checkbox>
                        </v-col>
                        <v-col cols="4" dense>
                            <kp-numberfield
                                v-if="elektrisch"
                                v-model="additionRecord.connected_electrical_load"
                                :disabled="unveraendert"
                                hide-details
                                dense
                                :label="$t('anschlusswert')"
                                :readonly="readonly"
                            >
                            </kp-numberfield>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" dense>
                            <v-checkbox
                                v-model="anschluesse"
                                :disabled="unveraendert"
                                value="telefon"
                                :readonly="readonly"
                                :label="$t('telefon')"
                            >
                            </v-checkbox>
                        </v-col>
                        <v-col cols="4" dense>
                            <v-checkbox
                                v-model="anschluesse"
                                style="margin-bottom: 10px"
                                :disabled="unveraendert"
                                value="glasfasernetz"
                                :readonly="readonly"
                                :label="$t('glasfasernetz')"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-3">
                <v-col sm="3" class="text-right mt-3"> {{ $t('profilierung') }} </v-col>
                <v-col sm="9">
                    <v-row>
                        <v-col cols="4">
                            <v-checkbox
                                v-model="record.profiling_yes_no"
                                :readonly="readonly"
                                hide-details
                                :label="$t('profiling_yes_no')"
                            >
                            </v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                v-if="record.profiling_yes_no"
                                v-model="profilierungMenu"
                                hide-details
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :readonly="readonly"
                            >
                                <template #activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formattedProfilingDate"
                                        prepend-icon="fa-solid fa-calendar"
                                        :label="$t('profiling_date')"
                                        hide-details
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="record.profiling_date"
                                    first-day-of-week="1"
                                    @input="profilierungMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="bauvorhabenArt ? bauvorhabenArt.showNutzungsziffer : false" class="mt-12">
                <v-col sm="3" class="text-right"> {{ $t('nutzungsziffern') }} </v-col>
                <v-col sm="9">
                    <v-row>
                        <v-col cols="6">
                            <v-select
                                v-model="additionRecord.indicator_type"
                                :items="zifferItems"
                                :label="$t('indicator_type')"
                                :readonly="readonly"
                            >
                                <template #prepend>
                                    <info-tooltip :text="$t('nutzungsziffern_info')" right />
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <kp-numberfield
                                v-model="additionRecord.indicator_value"
                                :label="$t('value')"
                                :readonly="readonly"
                            >
                            </kp-numberfield>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <kp-numberfield
                                v-model="additionRecord.property_area"
                                suffix="m²"
                                :label="$t('property_area')"
                                :readonly="readonly"
                                rows="3"
                                :rules="[formRules.maxLength(255)]"
                            >
                            </kp-numberfield>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <fileupload
                                :ppa="gesuch"
                                :contexts="['nachweis_berechnung_nutzungsziffer']"
                                :parent-id="gesuch.id"
                                :readonly="readonly"
                                entity="baugesuch"
                                :min="1"
                                :max="1"
                                :show-deleted="gesuch.aktueller_status_ebau_value != 'new'"
                            ></fileupload>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { formRules } from '@/helpers/forms.js';
import { mapActions } from 'vuex';
import KpNumberfield from '@/components/kp-numberfield.vue';
import InfoTooltip from '@/components/info-tooltip.vue';
import Fileupload from '@/components/fileupload';

export default {
    components: {
        'kp-numberfield': KpNumberfield,
        'info-tooltip': InfoTooltip,
        Fileupload
    },
    props: {
        gesuch: { type: Object },
        additionalData: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            formRules,
            record: this.gesuch,
            additionRecord: this.additionalData,
            items: [
                {
                    value: 'building_new',
                    text: this.$t('building_new'),
                    showTextfield: false,
                    showNutzungsziffer: true
                },
                {
                    value: 'building_demolition',
                    text: this.$t('building_demolition'),
                    showNutzungsziffer: false
                },
                {
                    value: 'building_extension',
                    text: this.$t('building_extension'),
                    showNutzungsziffer: true
                },
                {
                    value: 'building_conversion',
                    text: this.$t('building_conversion'),
                    showNutzungsziffer: false
                },
                {
                    value: 'building_purpose_change',
                    text: this.$t('building_purpose_change'),
                    showNutzungsziffer: false
                },
                {
                    value: 'building_reconstruction',
                    text: this.$t('building_reconstruction'),
                    showNutzungsziffer: true
                }
            ],
            zweckbestimmung: [
                { value: 'residential_use', text: this.$t('residential_use') },
                { value: 'commercial_and_industrial_use', text: this.$t('commercial_and_industrial_use') },
                { value: 'agricultural_use', text: this.$t('agricultural_use') }
            ],

            unveraendert: false,
            elektrisch: false,
            profilierungMenu: false,
            pojectStartMenu: false,
            zifferItems: [
                this.$t('construction_mass_indicator'),
                this.$t('geschossflaechenziffer'),
                this.$t('ausnuetzungsziffer')
            ]
        };
    },
    computed: {
        formattedProfilingDate() {
            return this.record.profiling_date ? dayjs(this.record.profiling_date).format('DD.MM.YYYY') : '';
        },
        formattedStartDate() {
            return this.additionRecord.project_start_date
                ? dayjs(this.additionRecord.project_start_date).format('DD.MM.YYYY')
                : '';
        }
    },
    watch: {
        anschluesse(val) {
            if (val) {
                this.elektrisch = val.includes('elektrisch');
                this.unveraendert = val.includes('unveraendert');
            }
        }
    },
    created() {
        this.anschluesse = this.additionRecord.requested_connections;
        this.bauvorhabenArt = this.items.find((i) => i.value === this.additionRecord.construction_project_type);
    },
    methods: {
        ...mapActions('additional_baugesuch_data', ['storeAdditionalBaugesuchData', 'destroyAdditionalBaugesuchData']),

        async beforeSave() {
            if (!this.additionRecord) {
                return true;
            }
            this.additionRecord.construction_project_type = this.bauvorhabenArt?.value;

            let val = [];
            if (this.unveraendert) {
                val = ['unveraendert'];
            } else if (this.anschluesse) {
                val = Array.isArray(this.anschluesse) ? this.anschluesse : [this.anschluesse];
            }
            this.additionRecord.requested_connections = val;
            await this.storeAdditionalBaugesuchData(this.additionRecord);
            return true;
        },

        async readonlySave() {
            if (this.readonly && this.gesuch.isDirty() && (this.user.isRoleAdmin || this.user.isRoleGemeinde)) {
                let changes = this.gesuch.getSaveData();
                for (const key in changes) {
                    if (key !== 'dossier_nr_gemeinde' && key !== 'id') {
                        //oh no you don't
                        return false;
                    }
                }

                this.gesuch.save();
            }
        }
    }
};
</script>
