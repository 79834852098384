/**
 * Store module: gemeinde
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import {
    PlanningPermissionApplication,
    PlanningPermissionApplicationCollection
} from '@/models/PlanningPermissionApplication';
import { instance as getApi } from '@/helpers/api';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchPlanningPermissionApplications({ commit }, options = {}) {
            let list = new PlanningPermissionApplicationCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadPlanningPermissionApplication({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new PlanningPermissionApplication();
                } else {
                    record = new PlanningPermissionApplication({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storePlanningPermissionApplication({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyPlanningPermissionApplication({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async fetchLayers({ commit, rootState }, options = {}) {
            try {
                let res = await getApi().post('/PlanningPermissionApplication/layers', {
                    topics: rootState.layers.activeLayers
                });
                if (res && res.data && res.data.data) {
                    return res.data.data;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async fetchBaseLayers({ commit }, options = {}) {
            try {
                let res = await getApi().get('/PlanningPermissionApplication/baseLayers');
                if (res && res.data && res.data.data) {
                    return res.data.data;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async search({ commit }, options = {}) {
            try {
                let res = await getApi().post('/PlanningPermissionApplication/search', { ...options });
                if (res && res.data && res.data.results) {
                    return res.data.results;
                }
                return null;
            } finally {
                commit('loading', false, { root: true });
            }
        }
    }
};
