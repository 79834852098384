/**
 * Store module: realestate
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { Realestate, RealestateCollection } from '@/models/Realestate';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchRealestates({ commit }, options = {}) {
            let list = new RealestateCollection();
            try {
                commit('loading', true, { root: true });
                await list.query({ ...options, is_neighbour: false });
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async fetchNeighbourRealestates({ commit }, options = {}) {
            let list = new RealestateCollection();
            try {
                commit('loading', true, { root: true });
                await list.query({ ...options, is_neighbour: true });
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadRealestate({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new Realestate();
                } else {
                    record = new Realestate({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        },

        async storeRealestate({ commit }, record) {
            commit('loading', true, { root: true });
            try {
                await record.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async destroyRealestate({ commit }, record) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await record.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        }
    }
};
