<template>
    <v-select
        :value="value"
        :items="items"
        item-text="text"
        item-value="value"
        :label="$t('bauvorhaben_art')"
        v-bind="{ ...$props, ...$attrs }"
        :hide-details="true"
        clearable
        dense
        hide-selected
        single-line
        @input="onSelect"
    ></v-select>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null }
    },
    data() {
        return {
            items: [
                { value: 'building_new', text: this.$t('building_new'), showTextfield: false },
                { value: 'building_extension', text: this.$t('building_extension'), showTextfield: false },
                { value: 'building_purpose_change', text: this.$t('building_purpose_change'), showTextfield: false },
                { value: 'building_conversion', text: this.$t('building_conversion'), showTextfield: false },
                { value: 'building_installation', text: this.$t('building_installation'), showTextfield: false },
                { value: 'building_reconstruction', text: this.$t('building_reconstruction'), showTextfield: false },
                { value: 'building_demolition', text: this.$t('building_demolition'), showTextfield: false },
                { value: 'building_others', text: this.$t('building_others'), showTextfield: true }
            ]
        };
    },
    methods: {
        onSelect(value) {
            this.$emit('input', value);
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-field {
    background-color: white;
    padding: 3px 5px;
    margin-right: 8px;
    max-width: 250px;
}
</style>
