<template>
    <v-skeleton-loader v-if="loading" type="list-item-avatar, list-item-three-line@3, actions"></v-skeleton-loader>
    <div v-else class="baugesuch-box">
        <v-container fluid class="baugesuch-container">
            <status-verlauf-banner
                v-if="baugesuch && baugesuch.aktueller_status_verlauf_id"
                :status-verlauf-id="baugesuch.aktueller_status_verlauf_id"
            />
            <v-card fluid class="px-10 pb-3 baugesuch-card">
                <v-card-title text-color="white" class="baugesuch-card-title">
                    {{ $t('baugesuch') + ': ' + formTitle }}&nbsp;
                    <v-chip
                        v-if="baugesuch.application_type"
                        :class="'application-type-' + baugesuch.application_type.replaceAll('_', '-')"
                        small
                    >
                        {{ $t(baugesuch.application_type) }}
                    </v-chip>
                    <v-chip :class="'status-' + baugesuch.aktueller_status_ebau_value.replaceAll(' ', '-')" small>
                        {{ baugesuch.aktueller_status }}
                    </v-chip>
                </v-card-title>
                <v-tabs v-model="activeTab" class="baugesuch-card-tabs">
                    <v-tab key="ubersicht" :to="{ name: 'bg_uebersicht' }">{{ $t('uebersicht') }}</v-tab>
                    <v-tab key="baugesuch" :to="{ name: 'bg_edit_lage' }">{{ $t('baugesuch') }}</v-tab>
                    <v-tab key="berechtigungen" :to="{ name: 'bg_berechtigungen' }">{{ $t('berechtigungen') }}</v-tab>
                    <v-tab
                        v-if="baugesuch.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW || user.ist_admin"
                        key="status_verlauf"
                        :to="{ name: 'bg_status_verlauf' }"
                        >{{ $t('statusverlauf') }}</v-tab
                    >
                    <v-tab
                        v-if="baugesuch.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW || user.ist_admin"
                        key="circulation"
                        :to="{ name: 'bg_circulation' }"
                        :access-control-readonly="accessControlReadonly"
                        >{{
                            $t('zirkulation') +
                            (baugesuch.open_circulation_count > 0 ? ' (' + baugesuch.open_circulation_count + ')' : '')
                        }}</v-tab
                    >
                    <v-tab key="kommentare" :to="{ name: 'bg_kommentare' }">{{ $t('kommentare') }}</v-tab>
                    <v-tab v-if="user.isRoleAdmin" key="messages" :to="{ name: 'bg_messages' }"
                        >{{ $t('messages') }} (eCH)</v-tab
                    >
                    <v-tab
                        v-if="baugesuch.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW || user.ist_admin"
                        key="decision_ruling"
                        :to="{ name: 'bg_decision_ruling' }"
                        >{{ $t('decision_ruling_tab') }}</v-tab
                    >
                </v-tabs>
                <router-view
                    class="baugesuch-router-view"
                    :baugesuch="baugesuch"
                    :additional-data="additionalData"
                    :stelle="stelle"
                    :readonly="readonly || accessControlReadonly"
                    :access-control-readonly="accessControlReadonly"
                    :user="user"
                    @statuschange="onStatuschange"
                    @datachange="onCirculationDataChange"
                ></router-view>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { errToStr } from '@/helpers/errors.js';
import StatusVerlaufBanner from '@/components/status/status-verlauf-banner.vue';
import { ECH_STATUS } from '@/models/Status.js';
import { AdditionalBaugesuchData } from '@/models/AdditionalBaugesuchData.js';

export default {
    components: { StatusVerlaufBanner },
    data() {
        return {
            activeTab: null,
            baugesuch: Object,
            additionalData: Object,
            stelle: Object,
            loading: false,
            valid: false,
            accessControlReadonly: true,
            ECH_STATUS: ECH_STATUS
        };
    },
    computed: {
        ...mapState('user', {
            user: 'record'
        }),
        formTitle() {
            if (this.baugesuch && this.baugesuch.id) {
                return (
                    this.baugesuch.id +
                    ' (' +
                    (this.baugesuch.application_type ? this.$t(this.baugesuch.application_type) : '-') +
                    ')'
                );
            }

            return this.$t('neues_baugesuch');
        },
        readonly() {
            if (!this.user) {
                return true;
            }

            if (
                this.baugesuch.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW &&
                this.baugesuch.aktueller_status_ebau_value !== ECH_STATUS.SUSPENDED &&
                this.baugesuch.aktueller_status_ebau_value !== ECH_STATUS.DEMAND_DOCUMENTS
            ) {
                return true;
            }

            if (this.user.isRoleAdmin) {
                return false;
            }

            return false;
        }
    },
    watch: {
        $route: function () {
            // We already are loaded, do nothing:
            if (this.baugesuch && this.baugesuch.id === Number(this.$route.params.id)) {
                return;
            }
            this.loadRecord(this.$route.params.id);
        }
    },
    async created() {
        await this.loadRecord(this.$route.params.id);
    },
    methods: {
        ...mapActions('baugesuch', ['loadBaugesuch', 'storeBaugesuch', 'destroyBaugesuch']),
        ...mapActions('access_control', ['isReadonlyPpa']),
        ...mapActions('stelle', ['loadStelle']),
        ...mapActions('additional_baugesuch_data', ['fetchAdditionalBaugesuchData']),
        async loadRecord(id) {
            this.loading = true;
            try {
                this.accessControlReadonly = await this.isReadonlyPpa(id);
                this.baugesuch = await this.loadBaugesuch(id);
                this.stelle = await this.loadStelle(this.baugesuch.stelle_id);

                await this.loadAdditionalData();
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
                this.closeForm();
            } finally {
                this.loading = false;
            }
        },
        closeForm() {
            this.$router.push({ name: 'baugesuche' });
        },
        async onSubmit() {
            try {
                await this.storeBaugesuch(this.baugesuch);
                this.closeForm();
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        onStatuschange() {
            this.loadRecord(this.$route.params.id);
        },
        onCirculationDataChange() {
            this.loadRecord(this.$route.params.id);
        },
        async loadAdditionalData() {
            this.loading = true;
            try {
                const data = await this.fetchAdditionalBaugesuchData({
                    filter: [
                        {
                            id: 'planning_permission_application_id',
                            property: 'planning_permission_application_id',
                            value: this.baugesuch.id
                        }
                    ]
                });
                this.additionalData = data.first();
                if (!this.additionalData) {
                    this.additionalData = new AdditionalBaugesuchData({
                        planning_permission_application_id: this.baugesuch.id
                    });
                }
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.baugesuch-box {
    height: 100%;
    .baugesuch-container {
        height: 100%;
        .baugesuch-card {
            display: grid;
            grid-template-rows: auto auto 1fr;
            grid-template-columns: auto;
            height: 100%;
            grid-template-areas:
                'title'
                'tabs'
                'content';
            .baugesuch-card-title {
                grid-area: title;
            }
            .baugesuch-card-tabs {
                grid-area: tabs;
            }
            .baugesuch-router-view {
                grid-area: content;
            }
        }
    }
}
</style>
