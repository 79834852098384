/**
 * Ort entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class StatusVerlauf extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            creator_id: null,
            modifed: null,
            modifier_id: null,
            planning_permission_application_id: null,
            status_id: null,
            bemerkung: null,
            kurz_bemerkung: null,
            ppa_state: null,

            // View
            status: null,
            creator: null,
            ppa_type: null,
            ppa_stelle_id: null,
            ppa_stelle: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'StatusVerlauf';
    }

    get plz_string() {
        return this.plz ? '' + this.plz : '';
    }
}

export class StatusVerlaufCollection extends BaseCollection {
    options() {
        return {
            model: StatusVerlauf
        };
    }

    entityName() {
        return 'StatusVerlauf';
    }
}
