/**
 * Ort entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';
import { getI18nInstance as i18n } from '@/i18n';

export const MODE = {
    READ: 'r',
    READ_WRITE: 'rw'
};

export const TYPE = {
    PERSON_ORGANISATION: 'person_organisation',
    GEMEINDE: 'gemeinde'
};

export class AccessControl extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            creator_id: null,
            user_id: null,
            stelle_id: null,
            planning_permission_application_id: null,
            mode: null,
            locked: null,
            user_visible: null,

            // view fields
            name: null,
            planning_permission_application_creator_id: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            creator_id: (id) => (Number(id) > 0 ? Number(id) : null),
            user_id: (id) => (Number(id) > 0 ? Number(id) : null),
            stelle_id: (id) => (Number(id) > 0 ? Number(id) : null),
            planning_permission_application_id: (id) => (Number(id) > 0 ? Number(id) : null),
            planning_permission_application_creator_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'AccessControl';
    }

    get mode_string() {
        if (this.mode === MODE.READ) {
            return i18n().t('read');
        } else if (this.mode === MODE.READ_WRITE) {
            return i18n().t('read_write');
        }
        return i18n().t('no_access');
    }
}

export class AccessControlCollection extends BaseCollection {
    options() {
        return {
            model: AccessControl
        };
    }

    entityName() {
        return 'AccessControl';
    }
}
