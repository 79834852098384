<template>
    <div>
        <base-remote-list
            session-key="stelle-list-remote"
            :maintitle="$t('behoerdenverzeichnis')"
            maintitle-icon-cls="fa-regular fa-building"
            :collection-class="StelleCollection"
            :headers="headers"
            :filters="filters"
            :extra-params="extraParams"
            :options="{ sortBy: ['organisation_name'] }"
            csv-export
            @loading="loading = $event"
            @edit-item="onEdit"
            @new-item="onAdd"
        >
            <template #before-query-field> </template>
            <template #after-query-field>
                <v-btn
                    v-if="user && user.isRoleAdmin"
                    rounded
                    class="hidden-xs-only ml-1"
                    color="subtleHint"
                    @click="onAdd"
                >
                    <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                    {{ $t('add_stelle') }}
                </v-btn>
            </template>
            <template #item.type="{ item }">
                {{ $t(item.type) }}
            </template>

            <template #subtoolbar>
                <v-tabs v-model="filterTab" background-color="primary" dark style="border-top: 2px solid white">
                    <v-tab key="open">
                        <v-icon left>fa-solid fa-building</v-icon>
                        {{ $t('gemeinden') }}
                    </v-tab>
                    <v-tab key="closed">
                        <v-icon left>fa-solid fa-building</v-icon>
                        {{ $t('fachstellen') }}
                    </v-tab>
                </v-tabs>
            </template>
        </base-remote-list>
    </div>
</template>
<script>
import { StelleCollection } from '@/models/Stelle';
import sessionStorageMixin from '@/mixins/session-storage';
import { mapState } from 'vuex';

export default {
    name: 'StelleIndex',
    components: {},
    mixins: [sessionStorageMixin],
    data() {
        this.sessionKey = 'stelle-index-remote';
        return {
            StelleCollection,
            loading: false,
            stelleTypes: [
                {
                    id: 'fachstelle',
                    typ: this.$t('fachstelle')
                },
                {
                    id: 'gemeinde',
                    typ: this.$t('gemeinde')
                }
            ],
            filterTab: 0,
            internalFilters: [
                {
                    id: 'type',
                    property: 'type',
                    value: 'gemeinde'
                }
            ]
        };
    },
    computed: {
        // gewisse Parameter können / müssen als Filter-Array mitgegeben wrden.
        // Hier werden diese aufbereitet für die Remote List:
        filters() {
            return [...(this.internalFilters ? this.internalFilters : [])];
        },

        // gewisse Parameter können / müssen als ExtraParam mitgegeben werden.
        // Hier werden diese aufbereitet für die Remote List:
        extraParams() {
            const extraParams = {};
            // const enhancedFilters = {};

            // for (let key in this.enhancedFilters) {
            //     if (this.enhancedFilters[key]) {
            //         enhancedFilters[key] = this.enhancedFilters[key];
            //     }
            // }
            // extraParams.enhancedFilters = enhancedFilters;
            return extraParams;
        },
        ...mapState('user', {
            user: 'record'
        }),
        headers() {
            let headers = [
                {
                    text: this.$t('name'),
                    value: 'organisation_name',
                    sortable: true,
                    filterable: true,
                    width: 300
                },
                {
                    text: this.$t('street'),
                    value: 'street',
                    sortable: true,
                    filterable: true,
                    width: 300
                },
                {
                    text: this.$t('nr'),
                    value: 'house_number',
                    sortable: true,
                    filterable: true,
                    width: 60
                },
                {
                    text: this.$t('ort'),
                    value: 'town',
                    sortable: true,
                    filterable: true,
                    width: 200
                },
                {
                    text: this.$t('vorname'),
                    value: 'first_name',
                    sortable: true,
                    filterable: true,
                    width: 200
                },
                {
                    text: this.$t('nachname'),
                    value: 'last_name',
                    sortable: true,
                    filterable: true,
                    width: 200
                }
            ];
            if (this.user.isRoleAdmin) {
                headers.push({ text: '', sortable: false, width: 120, value: 'actions' });
            }

            return headers;
        }
    },
    watch: {
        filterTab(tab) {
            let remaining = this.internalFilters.filter((e) => e.id !== 'type');
            this.internalFilters = remaining;
            this.internalFilters.push({
                id: 'type',
                property: 'type',
                value: tab === 0 ? 'gemeinde' : 'fachstelle'
            });
        }
    },
    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onAdd() {
            this.showForm('new');
        },

        onEdit(rec) {
            if (rec) {
                this.showForm(rec.id);
            }
        },

        showForm(id) {
            this.$router.push({
                name: 'stelle_form',
                params: { id: id || 'new' }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-field {
    background-color: white;
    padding: 3px 5px;
    margin-right: 8px;
    max-width: 250px;
}
</style>
