<template>
    <v-container v-if="parzellen && parzellen.models">
        <v-col rows="12" class="mb-3">
            <v-row>
                <v-col md="3" class="text-right">{{ $t('baugesuch') }}</v-col>
                <v-col md="9">
                    <div class="mt-1 mb-4">
                        <v-row>
                            <v-col lg="4" md="6" sm="12">
                                <v-checkbox
                                    v-model="getAllBauzonen().length"
                                    :label="$t('bauzone')"
                                    :ripple="false"
                                    readonly
                                    hide-details
                                    dense
                                ></v-checkbox>
                            </v-col>
                            <v-col lg="4" md="6" sm="12">
                                <v-checkbox
                                    v-model="getAllNichtBauzonen().length"
                                    :label="$t('nichtbauzone')"
                                    :ripple="false"
                                    readonly
                                    hide-details
                                    dense
                                ></v-checkbox>
                            </v-col>
                            <v-col lg="4" md="6" sm="12">
                                <v-checkbox
                                    v-model="getAllKleinsiedlungen().length"
                                    :label="$t('kleinsiedlung')"
                                    :ripple="false"
                                    readonly
                                    hide-details
                                    dense
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-col>

        <v-expansion-panels multiple>
            <v-expansion-panel v-for="parzelle in parzellen.models" :key="parzelle.id">
                <v-expansion-panel-header>
                    <template #default="{ open }">
                        <v-row no-gutters>
                            <v-col cols="4">{{ parzelle.egrid }}</v-col>
                            <v-col v-if="parzelle.av_data" cols="8" class="text--secondary">
                                <v-fade-transition leave-absolute>
                                    <div v-if="!open">
                                        BZ: {{ getBauzonen(parzelle.av_data.zonenplan.zonen).length }}, NBZ:
                                        {{ getNichtBauzonen(parzelle.av_data.zonenplan.zonen).length }}, KS:
                                        {{ parzelle.av_data.kleinsiedlungen.length }}
                                    </div>
                                </v-fade-transition>
                            </v-col>

                            <v-col v-else cols="8" class="text--secondary">
                                {{ $t('no_info') }}
                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-if="!parzelle.av_data">{{ $t('no_info') }}</div>
                    <v-col v-else rows="12">
                        <v-row>
                            <v-col md="3" class="text-right"></v-col>
                            <v-col md="9">
                                <div class="mt-1 mb-4">
                                    <v-row>
                                        <v-col lg="4" md="6" sm="12">
                                            <v-checkbox
                                                v-model="getBauzonen(parzelle.av_data.zonenplan.zonen).length"
                                                :label="$t('bauzone')"
                                                :ripple="false"
                                                readonly
                                                hide-details
                                                dense
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col lg="4" md="6" sm="12">
                                            <v-checkbox
                                                v-model="getNichtBauzonen(parzelle.av_data.zonenplan.zonen).length"
                                                :label="$t('nichtbauzone')"
                                                :ripple="false"
                                                readonly
                                                hide-details
                                                dense
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col lg="4" md="6" sm="12">
                                            <v-checkbox
                                                v-model="parzelle.av_data.kleinsiedlungen.length"
                                                :label="$t('kleinsiedlung')"
                                                :ripple="false"
                                                readonly
                                                hide-details
                                                dense
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn @click="openOerebAuszug(parzelle.egrid)">{{
                                                $t('oereb_auszug')
                                            }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col md="3" class="text-right">{{ $t('vorpublikation') }}</v-col>
                            <v-col md="9">
                                <div class="mt-1 mb-4">
                                    <v-row v-if="parzelle.av_data.vorpublikation.length === 0">
                                        <v-col>-</v-col>
                                    </v-row>
                                    <v-row v-for="vp in parzelle.av_data.vorpublikation" v-else :key="vp.prid">
                                        <v-col rows="12">
                                            <div>{{ vp.typ_txt }} {{ vp.name }} {{ getFromDate(vp.datum) }}</div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col md="3" class="text-right">{{ $t('bauzone') }}</v-col>
                            <v-col md="9">
                                <div class="mt-1 mb-4">
                                    <v-row v-if="getBauzonen(parzelle.av_data.zonenplan.zonen).length === 0">
                                        <v-col>-</v-col>
                                    </v-row>
                                    <v-row
                                        v-for="zone in getBauzonen(parzelle.av_data.zonenplan.zonen)"
                                        v-else
                                        :key="zone.prid"
                                    >
                                        <v-col rows="12">
                                            <div>
                                                {{ zone.zk_bezeichnung }} RRB-Nr. {{ zone.be_nummer }} vom
                                                {{ getFromDate(zone.be_datum_inkraftsetzung) }}
                                                <v-btn small icon @click="openOerebUrl(zone.be_id_oereb_url)">
                                                    <v-icon small>fa-regular fa-book-section</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col md="3" class="text-right">{{ $t('nichtbauzone') }}</v-col>
                            <v-col md="9">
                                <div class="mt-1 mb-4">
                                    <v-row v-if="getNichtBauzonen(parzelle.av_data.zonenplan.zonen).length === 0">
                                        <v-col>-</v-col>
                                    </v-row>
                                    <v-row
                                        v-for="zone in getNichtBauzonen(parzelle.av_data.zonenplan.zonen)"
                                        v-else
                                        :key="zone.prid"
                                    >
                                        <v-col rows="12">
                                            <div>
                                                {{ zone.zk_bezeichnung }} {{ $t('rrb_no') }} {{ zone.be_nummer }}
                                                {{ getFromDate(zone.be_datum_inkraftsetzung) }}
                                                <v-btn small icon @click="openOerebUrl(zone.be_id_oereb_url)">
                                                    <v-icon small>fa-regular fa-book-section</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col md="3" class="text-right">{{ $t('kleinsiedlung') }}</v-col>
                            <v-col md="9">
                                <div class="mt-1 mb-4">
                                    <v-row v-if="parzelle.av_data.kleinsiedlungen.length === 0">
                                        <v-col>-</v-col>
                                    </v-row>
                                    <v-row v-for="zone in parzelle.av_data.kleinsiedlungen" v-else :key="zone.prid">
                                        <v-col rows="12">
                                            <div>
                                                {{ zone.ks_id }} {{ zone.bezeichnung }} ({{ zone.verordnung }} KSV)
                                                <v-btn small icon @click="openOerebUrl(zone.id_oereb_url)">
                                                    <v-icon small>fa-regular fa-book-section</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col md="3" class="text-right">{{ $t('ueberlagernde_zonen') }}</v-col>
                            <v-col md="9">
                                <div class="mt-1 mb-4">
                                    <v-row v-if="parzelle.av_data.zonenplan.ueberlagernde_zonen.length === 0">
                                        <v-col>-</v-col>
                                    </v-row>
                                    <v-row
                                        v-for="zone in parzelle.av_data.zonenplan.ueberlagernde_zonen"
                                        v-else
                                        :key="zone.prid"
                                    >
                                        <v-col rows="12">
                                            <div>
                                                {{ zone.uk_bezeichnung }} {{ $t('rrb_no') }} {{ zone.be_nummer }}
                                                {{ getFromDate(zone.be_datum_inkraftsetzung) }}
                                                <v-btn small icon @click="openOerebUrl(zone.be_id_oereb_url)">
                                                    <v-icon small>fa-regular fa-book-section</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col md="3" class="text-right">{{ $t('sondernutzungsplaene') }}</v-col>
                            <v-col md="9">
                                <div class="mt-1 mb-4">
                                    <v-row
                                        v-if="
                                            parzelle.av_data.sondernutzungsplaene.gp.length === 0 &&
                                            parzelle.av_data.sondernutzungsplaene.blp.length === 0
                                        "
                                    >
                                        <v-col>-</v-col>
                                    </v-row>
                                    <template v-else>
                                        <v-row
                                            v-for="zone in parzelle.av_data.sondernutzungsplaene.gp"
                                            :key="zone.prid"
                                        >
                                            <v-col rows="12">
                                                <div>
                                                    {{ zone.gp_typ_txt }} {{ $t('rrb_no') }} {{ zone.be_nummer }}
                                                    {{ getFromDate(zone.be_datum_inkraftsetzung) }}
                                                    <v-btn small icon @click="openOerebUrl(zone.be_id_oereb_url)">
                                                        <v-icon small>fa-regular fa-book-section</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-col> </v-row
                                        ><v-row
                                            v-for="zone in parzelle.av_data.sondernutzungsplaene.blp"
                                            :key="zone.prid"
                                        >
                                            <v-col rows="12">
                                                <div>
                                                    {{ zone.bl_name }} {{ $t('rrb_no') }} {{ zone.be_nummer }}
                                                    {{ getFromDate(zone.be_datum_inkraftsetzung) }}
                                                    <v-btn small icon @click="openOerebUrl(zone.be_id_oereb_url)">
                                                        <v-icon small>fa-regular fa-book-section</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-expansion-panel-content></v-expansion-panel
            ></v-expansion-panels
        >
        <v-btn
            v-if="gesuch && !gesuch.aktueller_status.id && !readonly"
            class="mt-3"
            :disabled="loading"
            :loading="loading"
            @click="onRefreshGeoData"
        >
            {{ $t('aktualisieren') }}
        </v-btn>
    </v-container>
</template>

<script>
import { instance as getApi } from '@/helpers/api';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            parzellen: null,
            loading: false,
            dayjs: dayjs
        };
    },
    created() {
        this.loadAdditionalData();
    },
    methods: {
        ...mapActions('realestate', ['fetchRealestates']),
        async loadAdditionalData() {
            this.loading = true;
            try {
                const prz = await this.fetchRealestates({ dossier_id: this.gesuch.id });
                this.parzellen = prz;
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },

        async beforeSave() {
            return false;
        },

        async onRefreshGeoData() {
            this.loading = true;
            try {
                this.geodata = await getApi().post('/Baugesuch/refreshZonenData', {
                    baugesuch_id: this.gesuch.id
                });
                this.loadAdditionalData();
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        getNichtBauzonen(zonen) {
            let res = [];
            if (zonen) {
                zonen.forEach((zone) => {
                    if (zone.zk_obergruppe !== 'Bauzonen') {
                        res.push(zone);
                    }
                });
            }
            return res;
        },

        openOerebAuszug(egrid) {
            let url = this.$store.state.globals.oereburl + '/pdf?EGRID=' + egrid;
            window.open(url, 'oereb');
        },

        getAllBauzonen() {
            let res = [];
            if (this.parzellen && this.parzellen.models) {
                this.parzellen.models.forEach((p) => {
                    if (p.av_data) {
                        let zonen = this.getBauzonen(p.av_data?.zonenplan.zonen);
                        res = res.concat(zonen);
                    }
                });
            }
            return res;
        },

        getAllNichtBauzonen() {
            let res = [];
            if (this.parzellen && this.parzellen.models) {
                this.parzellen.models.forEach((p) => {
                    if (p.av_data) {
                        let zonen = this.getNichtBauzonen(p.av_data?.zonenplan.zonen);
                        res = res.concat(zonen);
                    }
                });
            }
            return res;
        },

        getAllKleinsiedlungen() {
            let res = [];
            if (this.parzellen && this.parzellen.models) {
                this.parzellen.models.forEach((p) => {
                    if (p.av_data) {
                        let ks = p.av_data.kleinsiedlungen;
                        res = res.concat(ks);
                    }
                });
            }
            return res;
        },

        getBauzonen(zonen) {
            let res = [];
            if (zonen) {
                zonen.forEach((zone) => {
                    if (zone.zk_obergruppe === 'Bauzonen') {
                        res.push(zone);
                    }
                });
            }
            return res;
        },

        getKleinsiedlung() {
            return [];
        },
        openOerebUrl(oerebUrl) {
            let url = this.$store.state.globals.oereblexurl + oerebUrl;
            window.open(url, 'oereb');
        },
        getFromDate(date) {
            if (date) {
                return this.$t('vom') + ' ' + this.dayjs(date).format('DD.MM.YYYY');
            }
            return '';
        },
        getKleinsiedlungText(kleinsiedlungen) {
            let res = [this.$t('kleinsiedlung')];
            if (kleinsiedlungen.length > 0) {
                kleinsiedlungen.forEach((k) => {
                    let entry = [k.ks_id, k.bezeichnung, '(', k.verordnung, ' KSV)'];
                    res.push(entry.join(' '));
                });
            }
            return res.join(', ');
        }
    }
};
</script>
