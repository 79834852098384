<template>
    <CirculationList
        v-if="baugesuch"
        :planning-permission-application-id="baugesuch.id"
        height="calc(var(--usable-height-in-ppa-tab) - 40px)"
        :readonly="readonly()"
        @datachange="onCirculationDataChange"
    ></CirculationList>
</template>

<script>
import CirculationList from '@/components/circulation/circulation-grid.vue';
export default {
    components: { CirculationList },
    props: {
        baugesuch: { type: Object },
        user: { type: Object },
        accessControlReadonly: { type: Boolean, default: true }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        readonly() {
            if (this.accessControlReadonly) {
                return true;
            }

            if (!this.user.isRoleGemeinde && !this.user.isRoleAdmin) {
                return true;
            }

            return false;
        },
        onCirculationDataChange() {
            this.$emit('datachange');
        }
    }
};
</script>
