<template>
    <v-alert v-if="banner" border="left" colored-border type="info" elevation="2" class="ma-1" dismissible text>
        <strong>{{ $t('comment_on_state') }}: </strong>
        <div v-html="banner" />
    </v-alert>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    props: {
        statusVerlaufId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            banner: null,
            statusVerlauf: null
        };
    },

    mounted() {
        this.getStatusVerlauf();
    },

    methods: {
        ...mapState('status_verlauf', {
            status_verlauf: 'record'
        }),
        ...mapActions('status_verlauf', ['loadStatusVerlauf']),
        async getStatusVerlauf() {
            try {
                let data = await this.loadStatusVerlauf(this.statusVerlaufId);
                this.statusVerlauf = data;
                if (data.bemerkung) {
                    this.banner = data.bemerkung.replaceAll('\n', '<br>');
                } else {
                    this.banner = null;
                }
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            }
        }
    }
};
</script>
