<template>
    <v-dialog :value="show" width="800px" scrollable persistent>
        <v-card max-height="750">
            <slot name="title">
                <v-card-title class="headline">{{ $t('grant_access') }}</v-card-title>
            </slot>
            <v-card-text>
                <v-form :valid="valid">
                    <v-row no-gutters>
                        <v-radio-group v-model="accessType" column :readonly="!!record">
                            <v-radio :label="$t('person_organisation')" :value="accessTypePersOrg"></v-radio>
                            <v-radio :label="$t('gemeinde')" :value="accessTypeGemeinde"></v-radio>
                        </v-radio-group>
                    </v-row>
                    <v-row v-if="accessType === 'person_organisation'" no-gutters>
                        <v-col>
                            <v-text-field
                                v-if="record"
                                v-model="record.name"
                                :label="$t('name')"
                                :readonly="true"
                            ></v-text-field>
                            <v-text-field
                                v-else
                                v-model="email"
                                :label="$t('email')"
                                :rules="[email !== '', formRules.email, formRules.maxLength(255)]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="accessType === 'gemeinde'" no-gutters>
                        <v-col>
                            <StelleSelect
                                v-if="record"
                                :stelle="record.name"
                                :loading="loading"
                                :readonly="true"
                                @stelleselect="stelleId = $event"
                            ></StelleSelect>
                            <StelleSelect
                                v-else
                                :stelle="stelle"
                                :loading="loading"
                                @stelleselect="stelleId = $event"
                            ></StelleSelect>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-select
                                v-if="record"
                                v-model="record.mode"
                                :items="accessItems"
                                :label="$t('access_right')"
                            ></v-select>
                            <v-select
                                v-else
                                v-model="accessRight"
                                :items="accessItems"
                                :label="$t('access_right')"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="action white--text" type="submit" @click="onSave">
                    <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                    {{ $t('save') }}
                </v-btn>
                <v-btn color="decline" text @click="$emit('close')">{{ $t('close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import StelleSelect from '@/components/stelle-select.vue';
import { MODE, TYPE } from '@/models/AccessControl';
import { errToStr } from '@/helpers/errors';
import { formRules } from '@/helpers/forms.js';

export default {
    components: {
        StelleSelect
    },
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            default: false
        },
        accessControl: {
            type: Object,
            default: null
        },
        maxMode: {
            type: String,
            default: MODE.READ
        }
    },
    data() {
        return {
            record: null,
            formRules: formRules,
            valid: null,
            accessTypePersOrg: TYPE.PERSON_ORGANISATION,
            accessTypeGemeinde: TYPE.GEMEINDE,
            accessType: TYPE.PERSON_ORGANISATION,
            email: null,
            stelle: null,
            stelleId: null,
            accessRight: MODE.READ,
            loading: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.record
        }),
        accessItems() {
            let items = [
                {
                    value: MODE.READ,
                    text: this.$t('read')
                }
            ];
            if (this.maxMode === MODE.READ_WRITE) {
                items.push({
                    value: MODE.READ_WRITE,
                    text: this.$t('read_write')
                });
            }
            return items;
        }
    },
    watch: {
        accessControl: {
            immediate: true,
            handler(value, oldValue) {
                this.record = value;
            }
        }
    },
    methods: {
        ...mapActions('access_control', ['createAccessControl', 'storeAccessControl']),
        async onSave() {
            try {
                if (this.record) {
                    await this.storeAccessControl(this.record);
                } else {
                    await this.createAccessControl({
                        access_type: this.accessType,
                        email: this.accessType === TYPE.PERSON_ORGANISATION ? this.email : null,
                        stelle_id: this.accessType === TYPE.GEMEINDE ? this.stelleId : null,
                        access_right: this.accessRight,
                        planning_permission_application_id: this.planningPermissionApplicationId
                    });
                }
                this.$emit('saved');
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        }
    }
};
</script>
