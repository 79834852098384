import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import { apiUrl } from '@/helpers/globals';
import dayjs from 'dayjs';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/en-gb';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

Vue.use(VueI18n);

let i18nInstance = null;
const supportedLocales = ['de', 'en'];
const dayJsLocaleMap = {
    de: 'de-ch',
    en: 'en-gb'
};

/**
 * Loads the locale strings from locales/[locale].json. Returns a promise
 * which resolves the key/value strings for that locale.
 * @param {string} locale
 * @returns {Promise<string>}
 */
export function loadLocaleStrings(locale) {
    if (supportedLocales.indexOf(locale) < 0) {
        locale = 'de'; // fallback locale
    }

    // load correct locale strings, then load customer specific strings, if any, then merge and return the result:
    return axios.get(apiUrl(`locales/${locale}.json`)).then((res) => {
        let localeStrings = res.data || {};
        return localeStrings;
    });
}

function setI18nLanguage(i18nInstance, lang) {
    // set i18n locale
    i18nInstance.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);

    // Configure dayjs locale:
    dayjs.locale(dayJsLocaleMap[lang] || 'de-ch');
    dayjs.extend(localizedFormat);
    dayjs.extend(customParseFormat);

    return lang;
}

export function i18nInit(locale, localeStrings) {
    i18nInstance = new VueI18n({
        // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
        locale: locale || 'de',
        // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
        messages: {
            [locale]: localeStrings
        }
    });
    setI18nLanguage(i18nInstance, locale);
    return i18nInstance;
}
export function getI18nInstance() {
    if (!i18nInit) {
        throw new Error('Not yet initialized. Use i18nInit() first.');
    }
    return i18nInstance;
}
