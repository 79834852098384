/**
 * Store module: layers
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */

export default {
    namespaced: true,
    state: {
        activeLayers: {}
    },
    mutations: {
        setActiveLayers(state, options) {
            state.activeLayers[options.topicId] = options.activeLayers;
        }
    },
    actions: {}
};
