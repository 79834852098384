<template>
    <v-row dense wrap>
        <v-col cols="12" md="3">
            <v-combobox
                autocomplete="new-password"
                :dense="dense"
                :readonly="readonly"
                :value="plz"
                :label="$t('plz') + (rules.includes(formRules.required) ? ' *' : '')"
                :items="orteCollection ? orteCollection.models : []"
                :loading="isLoading"
                :search-input.sync="plzSearch"
                :rules="rules"
                item-text="plz_ort"
                item-value="plz_string"
                clear-icon="fa-solid fa-xmark"
                hide-no-data
                hide-selected
                @input="onPlzSelect"
            >
            </v-combobox>
        </v-col>
        <v-col cols="12" md="9">
            <v-combobox
                autocomplete="new-password"
                :readonly="readonly"
                :value="ort"
                :dense="dense"
                :label="$t('ort') + (rules.includes(formRules.required) ? ' *' : '')"
                :items="orteCollection ? orteCollection.models : []"
                :loading="isLoading"
                :search-input.sync="ortSearch"
                :rules="rules"
                item-text="plz_ort"
                item-value="ort"
                clear-icon="fa-solid fa-xmark"
                hide-no-data
                hide-selected
                @input="onOrtSelect"
            >
            </v-combobox>
        </v-col>
    </v-row>
</template>

<script>
import { debounce } from '@/helpers/tools.js';
import { OrtCollection } from '@/models/Ort';
import { formRules } from '@/helpers/forms.js';

/**
 * PLZ-Ort-Select
 *
 * loads Orte from /lookup/ort,
 * emits the selected
 */
export default {
    props: {
        ort: {
            type: String,
            default: null
        },
        plz: {
            type: String,
            default: null
        },
        rules: {
            type: Array,
            default: () => []
        },
        dense: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            orteCollection: null,
            isLoading: false,
            ortSearch: null,
            plzSearch: null,
            formRules: formRules,
            oldSearchInput: false
        };
    },

    watch: {
        ortSearch: debounce(async function (val, oldVal) {
            // prevent lookup on first render (no user input)
            if (!oldVal) {
                return;
            }
            // Here comes an ugly hack: we need to prevent loading
            // when a value is selected from the dropdown list.
            // But as we cannot determine if this function was called
            // during a user text entry, or due to a selection from
            // the OTHER field, we reset the preventLoading flag after.... some time
            if (this.preventLoading) {
                setTimeout(() => (this.preventLoading = false), 500);
                return;
            }
            if (!val || val.length < 3 || this.isLoading) {
                return;
            }
            if (!this.orteCollection) {
                this.orteCollection = new OrtCollection();
            }
            await this.loadPlzOrte({ query: val });
        }),
        plzSearch: debounce(async function (val, oldVal) {
            // prevent lookup on first render (no user input)
            if (!oldVal) {
                return;
            }
            // Here comes an ugly hack: we need to prevent loading
            // when a value is selected from the dropdown list.
            // But as we cannot determine if this function was called
            // during a user text entry, or due to a selection from
            // the OTHER field, we reset the preventLoading flag after.... some time
            if (this.preventLoading) {
                setTimeout(() => (this.preventLoading = false), 500);
                return;
            }
            if (!val || val.length < 3 || this.isLoading) {
                return;
            }
            if (!this.orteCollection) {
                this.orteCollection = new OrtCollection();
            }
            await this.loadPlzOrte({ query: val });
        })
    },

    methods: {
        onPlzSelect(value) {
            this.preventLoading = true;
            if (this.orteCollection) {
                this.orteCollection.replace([]);
            }
            if (value && value.plz) {
                this.$emit('plzselect', value.plz);
                this.$emit('ortselect', value.ortschaftsname.trim());
            } else {
                this.$emit('plzselect', String(value).trim());
            }
        },
        onOrtSelect(value) {
            this.preventLoading = true;
            if (value && value.plz) {
                this.$emit('plzselect', value.plz);
                this.$emit('ortselect', value.ortschaftsname.trim());
            } else {
                this.$emit('ortselect', String(value).trim());
            }
        },

        async loadPlzOrte(query) {
            this.isLoading = true;
            if (!this.orteCollection) {
                this.orteCollection = new OrtCollection();
            }
            await this.orteCollection.query(query);
            this.isLoading = false;
        }
    }
};
</script>
