import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

// Locale definition:
import localeDe from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'fa'
    },
    theme: {
        themes: {
            light: {
                primary: '#40a536',
                info: '#40a536',
                accent: '#40a536',
                action: '#40a536',
                subtleHint: '#ffec0e',

                anchor: '#0645ad',
                accept: colors.green.darken1,
                decline: colors.grey.accent4,
                stop: colors.red.accent4
            }
        },
        options: {
            customProperties: true
        }
    },
    lang: {
        locales: { de: localeDe },
        current: 'de'
    }
};

export default new Vuetify(opts);
