/**
 * Ort entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class JudgementType extends BaseModel {
    defaults() {
        return {
            id: null,
            created: null,
            sender_id: null,
            message_id: null,
            code: null,
            ech_value: null,
            display_value: null,
            is_positive: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'JudgementType';
    }
}

export class JudgementTypeCollection extends BaseCollection {
    options() {
        return {
            model: JudgementType
        };
    }

    entityName() {
        return 'JudgementType';
    }
}
