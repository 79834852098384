<template>
    <v-combobox
        autocomplete="new-password"
        :dense="dense"
        :readonly="readonly"
        :value="stelle"
        :label="$t('stelle') + (rules.includes(formRules.required) ? ' *' : '')"
        :items="stelleCollection ? stelleCollection.models : []"
        :loading="isLoading"
        :search-input.sync="stelleSearch"
        :rules="rules"
        item-text="organisation_name"
        item-value="id"
        clear-icon="fa-solid fa-xmark"
        hide-no-data
        hide-selected
        prepend-inner-icon="fa-solid fa-magnifying-glass"
        @input="onSelect"
    >
    </v-combobox>
</template>

<script>
import { debounce } from '@/helpers/tools.js';
import { StelleCollection } from '@/models/Stelle';
import { formRules } from '@/helpers/forms.js';

export default {
    props: {
        stelle: {
            type: String,
            default: null
        },
        rules: {
            type: Array,
            default: () => []
        },
        dense: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        filter: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            stelleCollection: null,
            isLoading: false,
            stelleSearch: null,
            formRules: formRules,
            oldSearchInput: false
        };
    },

    watch: {
        stelleSearch: debounce(async function (val, oldVal) {
            // prevent lookup on first render (no user input)
            if (!oldVal) {
                return;
            }
            // Here comes an ugly hack: we need to prevent loading
            // when a value is selected from the dropdown list.
            // But as we cannot determine if this function was called
            // during a user text entry, or due to a selection from
            // the OTHER field, we reset the preventLoading flag after.... some time
            if (this.preventLoading) {
                setTimeout(() => (this.preventLoading = false), 500);
                return;
            }
            if (!val || val.length < 3 || this.isLoading) {
                return;
            }
            if (!this.stelleCollection) {
                this.stelleCollection = new StelleCollection();
            }
            await this.loadStelle({ query: val, filter: this.filter });
        })
    },

    methods: {
        onSelect(value) {
            this.$emit('stelleselect', value.id);
        },
        async loadStelle(query) {
            this.isLoading = true;
            if (!this.stelleCollection) {
                this.stelleCollection = new StelleCollection();
            }
            await this.stelleCollection.query(query);
            this.isLoading = false;
        }
    }
};
</script>
