<template>
    <v-container fluid>
        <BaugesuchMap
            :main-realestates="parzellen"
            :neighbours="nachbarParzellen"
            :hovered-realestate="hoveredParzelle"
            :deliver-parzelle-on-click="addParz || addNachbarParz"
            @parzelleClicked="onParzelleClicked"
        />
        <v-row class="mt-2">
            <v-col>
                <ParzelleList
                    height="400px"
                    dense
                    :parzellen="parzellen"
                    :readonly="readonly"
                    :enable-adding="true"
                    @startAdding="addParz = true"
                    @stopAdding="addParz = false"
                    @removeParzelle="onParzRemove"
                ></ParzelleList>
            </v-col>
            <v-col>
                <ParzelleList
                    :title="$t('benachbarte_parzellen')"
                    height="400px"
                    dense
                    :parzellen="nachbarParzellen"
                    :readonly="readonly"
                    :enable-adding="user.isRoleGemeinde || user.isRoleAdmin"
                    @startAdding="addNachbarParz = true"
                    @stopAdding="addNachbarParz = false"
                    @removeParzelle="onNachbarParzRemove"
                ></ParzelleList>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="3" class="text-right"> {{ $t('gemeinde') }} </v-col>
            <v-col sm="9"><v-text-field :readonly="true" :value="record.stelle"></v-text-field></v-col>
        </v-row>
        <v-row>
            <v-col sm="3" class="text-right"> {{ $t('relevante_adresse') }} </v-col>
            <v-col sm="9">
                <v-form v-if="locationAddress" v-model="valid" dense :readonly="readonly">
                    <v-row>
                        <v-col>
                            <v-select
                                :items="additionalInfo"
                                item-text="adresse"
                                item-value="objectid"
                                :label="$t('daten_uebernehmen')"
                                :clearable="!readonly"
                                return-object
                                clear-icon="fa-solid fa-xmark"
                                @change="fillForm"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10">
                            <v-text-field
                                v-model="locationAddress.street"
                                clear-icon="fa-solid fa-xmark"
                                :clearable="!readonly"
                                dense
                                :label="$t('street')"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="locationAddress.house_number"
                                :clearable="!readonly"
                                clear-icon="fa-solid fa-xmark"
                                dense
                                :label="$t('number_short')"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="locationAddress.swiss_zip_code"
                                clear-icon="fa-solid fa-xmark"
                                :clearable="!readonly"
                                dense
                                :label="$t('plz')"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                v-model="locationAddress.town"
                                :clearable="!readonly"
                                clear-icon="fa-solid fa-xmark"
                                dense
                                :label="$t('ort')"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="locationAddress.insurance_number"
                                :clearable="!readonly"
                                clear-icon="fa-solid fa-xmark"
                                dense
                                :label="$t('insurance_number')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="coord"
                                :clearable="!readonly"
                                clear-icon="fa-solid fa-xmark"
                                dense
                                :label="$t('coordinates')"
                                required
                                @input="formatCoord"
                                :rules="[formRules.coordinates]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BaugesuchMap from '@/components/map/mapContainerOl.vue';
import ParzelleList from '@/components/parzelle/local-list';
import { Realestate, REALESTATE_TYPE } from '@/models/Realestate.js';
import { LocationAddress } from '@/models/LocationAddress.js';
import { instance as api } from '@/helpers/api';
import { mapActions } from 'vuex';
import { errToStr } from '@/helpers/errors.js';
import { formRules } from '@/helpers/forms.js';

export default {
    components: {
        BaugesuchMap,
        ParzelleList
    },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            addParz: false,
            addNachbarParz: false,
            parzellen: {},
            nachbarParzellen: {},
            record: this.gesuch,
            hoveredParzelle: null,
            additionalInfo: [],
            selectedAddress: {},
            collator: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }),
            gebvers: null,
            coord: null,
            valid: false,
            strasse_nummer: null,
            strasse: null,
            locationAddress: null,
            formRules
        };
    },
    created() {
        this.loadRealestates();
        this.loadNeighbourRealestates();
        this.loadAddress();
    },
    methods: {
        ...mapActions('realestate', [
            'fetchRealestates',
            'fetchNeighbourRealestates',
            'storeRealestate',
            'destroyRealestate'
        ]),
        ...mapActions('location_address', ['fetchLocationAddresses', 'storeLocationAddress', 'destroyLocationAddress']),
        async loadRealestates() {
            this.loading = true;
            try {
                const prz = await this.fetchRealestates({ dossier_id: this.gesuch.id });
                this.parzellen = prz;
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
            this.refreshgetAdditionalInfo();
        },
        async loadNeighbourRealestates() {
            this.loading = true;
            try {
                const prz = await this.fetchNeighbourRealestates({ dossier_id: this.gesuch.id });
                this.nachbarParzellen = prz;
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },

        async onParzelleClicked(parzelle) {
            let existing = this.parzellen.find({ egrid: parzelle.gs_egris_egrid });
            if (!existing) {
                try {
                    let realestate = new Realestate({
                        dossier_id: this.gesuch.id,
                        gid: parzelle.gid,
                        nbident: parzelle.gs_nbident,
                        nummer: parzelle.gs_nummer,
                        egrid: parzelle.gs_egris_egrid,
                        realestate_type: REALESTATE_TYPE.LIEGENSCHAFT,
                        is_neighbour: false,
                        wkb_geometry: parzelle.wkb_geometry
                    });

                    if (this.addNachbarParz) {
                        realestate.is_neighbour = true;
                        realestate.is_manually_added_neighbour = true;
                    }

                    await this.storeRealestate(realestate);
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                }
                this.loadRealestates();
                this.loadNeighbourRealestates();
            }
        },

        async onParzRemove(parzelle) {
            delete this.selectedAddress[parzelle.egrid];
            if (parzelle) {
                await this.destroyRealestate(parzelle);
                this.loadRealestates();
                this.loadNeighbourRealestates();
            }
        },

        async onNachbarParzRemove(parzelle) {
            if (this.user.isRoleGemeinde || this.user.isRoleAdmin) {
                delete this.selectedAddress[parzelle.egrid];
                if (parzelle) {
                    await this.destroyRealestate(parzelle);
                    this.loadRealestates();
                    this.loadNeighbourRealestates();
                }
            }
        },

        async refreshgetAdditionalInfo() {
            try {
                let res = await api().post('/Realestate/getAdditionalInfo', {
                    egrids: this.parzellen.map((p) => p.egrid)
                });
                if (res.data && res.data.success) {
                    this.additionalInfo = this.getSortedAddresses(res.data.egrids);
                    this.record.set('stelle_id', res.data.stelle?.id);
                    this.record.set('stelle', res.data.stelle?.organisation_name);
                }
                return null;
            } finally {
                //do nothing
            }
        },

        async refreshNeighbours() {
            let prz = [];

            if (this.parzellen.length > 0) {
                let response = await api().post('/Realestate/getNeighbours', {
                    baugesuch_id: this.gesuch.id
                });

                if (response && response.data && response.data.records) {
                    prz = response.data.records;
                }
            }

            this.nachbarParzellen = prz;
        },

        getSortedAddresses(addresses) {
            let res = addresses.sort((a, b) => {
                return a.adresse.localeCompare(b.adresse, undefined, { numeric: true });
            });
            return res;
        },
        fillForm(val) {
            this.locationAddress.insurance_number = val ? val.amtliche_gebaeudenummer : null;
            this.coord = val ? val.coord : null;
            this.locationAddress.street = val ? val.strasse : null;
            this.locationAddress.house_number = val ? val.strasse_nummer : null;
            this.locationAddress.town = val ? val.ort : null;
            this.locationAddress.swiss_zip_code = val ? val.plz : null;
            this.locationAddress.swiss_zip_code_add_on = val ? val.plz_zusatzziffer : null;
            this.locationAddress.egid = val ? val.egid : null;
            this.locationAddress.edid = val ? val.edid : null;
            this.locationAddress.egaid = val ? val.egaid : null;
            this.locationAddress.esid = val ? val.esid : null;
            this.formatCoord();
        },
        formatCoord() {
            var tempValue = '',
                pattern = "x'xxx'xxx / x'xxx'xxx",
                pLength = pattern.length,
                value = this.coord,
                currentPosition = 0;

            if (
                value &&
                value.replaceAll(' ', '').replace('/', '').replaceAll("'", '').length ===
                    pattern.replaceAll(' ', '').replace('/', '').replaceAll("'", '').length
            ) {
                value = value.replaceAll(' ', '').replaceAll('/', '').replaceAll("'", '');
                for (var i = 0; i < pLength; i++) {
                    var letter = pattern[i];
                    if (letter === 'x') {
                        if (value[currentPosition]) {
                            tempValue += value[currentPosition];
                        } else {
                            tempValue += ' ';
                        }
                        currentPosition++;
                    } else {
                        tempValue += letter;
                    }
                }
            } else {
                tempValue = value;
            }
            this.coord = tempValue;
        },
        async beforeSave() {
            if (this.locationAddress) {
                if (this.coord) {
                    let coords = this.coord.replaceAll(' ', '').replaceAll("'", '').split('/');
                    if (coords.length === 2) {
                        this.locationAddress.coordinate_east = coords[0];
                        this.locationAddress.coordinate_north = coords[1];
                    }
                }
                await this.storeLocationAddress(this.locationAddress);
                this.record.location_address_id = this.locationAddress.id;
            }
        },
        async loadAddress() {
            this.loading = true;
            try {
                const adr = await this.fetchLocationAddresses({
                    filter: [
                        {
                            id: 'planning_permission_application_id',
                            property: 'planning_permission_application_id',
                            value: this.gesuch.id
                        }
                    ]
                });
                this.locationAddress = adr.first();
                if (this.locationAddress) {
                    if (this.locationAddress.coordinate_east && this.locationAddress.coordinate_north) {
                        this.coord = this.locationAddress.coordinate_east + '/' + this.locationAddress.coordinate_north;
                        this.formatCoord();
                    }
                } else {
                    this.locationAddress = new LocationAddress({ planning_permission_application_id: this.gesuch.id });
                }
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
