/**
 * UserStelle entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class UserStelle extends BaseModel {
    defaults() {
        return {
            id: null,
            user_id: null,
            stelle_id: null,
            user_username: null,
            user_email: null,
            invitaion_created: null,
            invitaion_accepted: null,
            status: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'UserStelle';
    }

    get isAdmin() {
        return this.ist_admin;
    }
}

export class UserStelleCollection extends BaseCollection {
    options() {
        return {
            model: UserStelle
        };
    }

    entityName() {
        return 'UserStelle';
    }
}
