<template>
    <BaugesuchGrid ref="pgGrid" :edit="true" height="var(--usable-height-in-main-tab)"></BaugesuchGrid>
</template>
<script>
import { mapState } from 'vuex';
import BaugesuchGrid from '@/components/baugesuch/baugesuch-grid.vue';

export default {
    name: 'BaugesuchIndex',
    components: {
        BaugesuchGrid
    },
    data: () => ({}),
    computed: {
        ...mapState('user', {
            user: 'record'
        })
    },
    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        }
    }
};
</script>
