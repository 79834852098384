<template>
    <v-container fluid class="text-center">
        <v-data-table
            v-model="selectedItems"
            :class="{
                'fixed-table': true,
                'fixed-overflow-table': !dense
            }"
            :headers="headers"
            :calculate-widths="calculateWidth"
            :items="StatusVerlauf.models"
            :options.sync="options"
            :server-items-length="total"
            :loading="StatusVerlaufLoading"
            :dense="dense"
            :single-select="true"
            item-key="id"
            :show-select="selectable"
            :hide-default-footer="nofooter"
            no-data-text="keine Datensätze vorhanden"
            :height="height"
            fixed-header
            disable-pagination
            disable-sort
            @click:row="onRowClicked"
            @item-selected="onItemSelected"
        >
            <template #[`item.created`]="{ item }">
                {{ formatDateTime(item.created) }}
            </template>
            <template #[`item.kurz_bemerkung`]="{ item }">
                <v-btn v-if="item.bemerkung" icon dark x-small color="primary" @click="onShowCommentDialog(item)">
                    <v-icon dark left>fa-regular fa-circle-info</v-icon>
                </v-btn>
                {{ item.kurz_bemerkung }}
            </template>
            <template #top>
                <div v-if="showAddBtn" class="d-flex">
                    <v-btn
                        rounded
                        class="hidden-xs-only mb-2 ml-auto"
                        color="subtleHint"
                        @click="addStatusVerlauf = true"
                    >
                        <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                        {{ $t('add_status') }}
                    </v-btn>
                </div>
            </template>
            <template #[`item.compare`]="{ item }">
                <v-checkbox v-model="compareFields" :value="item.id" @change="compare(item, $event)"></v-checkbox>
            </template>
        </v-data-table>
        <confirm-dialog
            v-model="showCommentDialog"
            :show-no-btn="false"
            :yes-btn-text="$t('close')"
            :title="$t('comment')"
            :message="dlgMessage"
            persistent
            show-html
        >
        </confirm-dialog>
        <status-verlauf-form
            v-model="addStatusVerlauf"
            :planning-permission-application-id="planningPermissionApplicationId"
            @close="onCloseAddStatusVerlauf"
        ></status-verlauf-form>
        <diff-dialog v-model="showDiffDialog" :data="diff"></diff-dialog>
    </v-container>
</template>

<script>
import { StatusVerlauf } from '@/models/StatusVerlauf';
import { mapActions, mapState } from 'vuex';
import DateFunctions from '@/mixins/date-functions.js';
import confirm_dialog from '../confirm_dialog.vue';
import StatusVerlaufForm from './status-verlauf-form.vue';
import DiffDialog from './status-verlauf-diff-dialog.vue';

export default {
    components: { 'diff-dialog': DiffDialog, 'confirm-dialog': confirm_dialog, StatusVerlaufForm },
    mixins: [DateFunctions],
    props: {
        planningPermissionApplicationId: {
            type: Number,
            default: null
        },

        dense: { type: Boolean, default: false },
        nofooter: { type: Boolean, default: true },
        selectable: { type: Boolean, default: false },
        height: { type: String, default: 'calc(100vh - 340px)' },
        selection: { type: StatusVerlauf, default: null },
        edit: { type: Boolean, default: true }
    },
    data() {
        return {
            StatusVerlauf: [],
            StatusVerlaufLoading: false,
            calculateWidth: true,
            total: 0,
            selectedItems: [],
            dlgMessage: null,
            showCommentDialog: false,
            addStatusVerlauf: false,
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['id'],
                sortDesc: [true],
                multiSort: false,
                mustSort: false
            },
            filter: [
                {
                    id: 'planning_permission_application_id',
                    property: 'planning_permission_application_id',
                    value: this.planningPermissionApplicationId
                }
            ],
            compareFields: [],
            showDiffDialog: false,
            diff: null,
            actualState: null
        };
    },
    computed: {
        params() {
            return {
                ...this.options,
                filter: this.filter
            };
        },
        headers() {
            let res = [
                {
                    text: this.$t('status'),
                    value: 'status',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                },
                {
                    text: this.$t('comment'),
                    value: 'kurz_bemerkung',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                },
                {
                    text: this.$t('created'),
                    value: 'created',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                },
                {
                    text: this.$t('creator'),
                    value: 'creator',
                    sortable: true,
                    filterable: true,
                    width: '10%'
                },
                {
                    text: this.$t('compare'),
                    value: 'compare',
                    sortable: false,
                    filterable: false,
                    width: '10%'
                }
            ];
            if (this.edit) {
                res.push({
                    value: 'action',
                    sortable: false,
                    filterable: false,
                    width: '5%'
                });
            }

            return res;
        },
        showAddBtn() {
            return this.user.isRoleAdmin;
        },
        ...mapState('user', {
            user: 'record'
        })
    },
    watch: {
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getStatusVerlauf();
                }
            },
            deep: true
        },
        selection: {
            handler(val) {
                if (!Array.isArray(val)) {
                    val = [val];
                }
                this.selectedItems = val;
            }
        }
    },
    methods: {
        ...mapState('status_verlauf', {
            status_verlauf: 'record'
        }),
        ...mapActions('status_verlauf', ['fetchStatusVerlauf', 'compareStatusVerlauf']),
        async getStatusVerlauf() {
            this.StatusVerlaufLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                let data = await this.fetchStatusVerlauf(this.params);
                this.StatusVerlauf = data;
                this.total = data.get('remoteTotal');
                this.actualState = null;
                data.each((model) => {
                    if (!this.actualState || this.actualState.id < model.id) {
                        this.actualState = model;
                    }
                });
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.StatusVerlaufLoading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onRowClicked(row) {},
        onItemSelected(evt) {
            this.selectedItems = evt.value ? [evt.item] : [];
            this.emitSelection();
        },
        emitSelection() {
            if (this.selectedItems.length === 1) {
                this.$emit('selection', this.selectedItems[0]);
            }
        },
        onShowCommentDialog(item) {
            this.dlgMessage = item.bemerkung.replaceAll('\n', '<br>');
            this.showCommentDialog = true;
        },
        onCloseAddStatusVerlauf() {
            this.addStatusVerlauf = false;
            this.getStatusVerlauf();
        },
        async compare(field, value) {
            if (this.compareFields.length === 2) {
                let diff = await this.compareStatusVerlauf({ id1: this.compareFields[0], id2: this.compareFields[1] });
                this.compareFields = [];
                this.diff = diff;
                this.showDiffDialog = true;
                console.log(diff);
            }
        }
    }
};
</script>

<style scoped>
.fixed-table >>> table {
    table-layout: fixed;
    width: 100%;
}
.fixed-table >>> table tr:first-child {
    background-color: #fff68f;
}
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    .fixed-table >>> table td {
        text-overflow: ellipsis;
    }
    .fixed-overflow-table >>> table td {
        overflow: hidden;
    }
}
@media screen and (max-width: 600px) {
    .fixed-table >>> table th {
        width: 100%;
    }
}
</style>
