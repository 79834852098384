<template>
    <v-dialog v-if="data" :value="value" scrollable persistent max-width="1500px">
        <v-card>
            <v-card-title>
                <div>
                    {{ data.a.status + ' (' + formatDate(data.a.created) + ')' }}
                    <v-icon class="mx-1 mb-1">fa-solid fa-arrows-left-right</v-icon>
                    {{ data.b.status + ' (' + formatDate(data.b.created) + ')' }}
                </div>
            </v-card-title>
            <v-card-text>
                <h3>allgemeine Daten</h3>
                <div v-if="data.diff.ppa || data.diff.additionalData">
                    <v-card-subtitle>geänderte Daten</v-card-subtitle>
                    <v-simple-table dense class="diff-table">
                        <template #default>
                            <thead>
                                <tr>
                                    <th id="key" class="text-left" style="width: 20%">{{ $t('field') }}</th>
                                    <th id="old" class="text-left" style="width: 40%">
                                        {{ $t('old_value') }} <br />{{
                                            data.a.status + ' (' + formatDate(data.a.created) + ')'
                                        }}
                                    </th>
                                    <th id="new" class="text-left" style="width: 40%">
                                        {{ $t('new_value') }}<br />
                                        {{ data.b.status + ' (' + formatDate(data.b.created) + ')' }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="data.diff.ppa">
                                    <tr v-for="(item, index) in data.diff.ppa" :key="index + item.key">
                                        <td>{{ getKey(item.key) }}</td>
                                        <td v-html="beautifyValue(item.old, item.key)"></td>
                                        <td v-html="beautifyValue(item.new, item.key)"></td>
                                    </tr>
                                </template>
                                <template v-if="data.diff.additionalData">
                                    <tr v-for="(item, index) in data.diff.additionalData" :key="index + item.key">
                                        <td>{{ getKey(item.key) }}</td>
                                        <td v-html="beautifyValue(item.old, item.key)"></td>
                                        <td v-html="beautifyValue(item.new, item.key)"></td>
                                    </tr>
                                </template>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
                <template v-for="(section, i) in sections">
                    <div v-if="section in data.diff" :key="i">
                        <h3 class="mt-4">{{ $t(section) }}</h3>
                        <div v-if="getNewRecords(section).length > 0">
                            <div v-for="item in getNewRecords(section)" :key="item.key">
                                <v-card-subtitle>{{ $t('added_record') }}</v-card-subtitle>
                                <v-simple-table dense class="diff-table">
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th id="field" class="text-left" style="width: 20%">
                                                    {{ $t('field') }}
                                                </th>
                                                <th id="value" class="text-left" style="width: 80%">
                                                    {{ $t('value') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(v, k) in item.new">
                                                <tr v-if="v" :key="k">
                                                    <td>{{ translate(k) }}</td>
                                                    <td>{{ formatValue(v) }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                        <div v-if="getDeletedRecords(section).length > 0">
                            <v-card-subtitle>{{ $t('deleted_record') }}</v-card-subtitle>
                            <div v-for="item in getDeletedRecords(section)" :key="item.key">
                                <v-simple-table dense class="diff-table">
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th id="field" class="text-left" style="width: 20%">
                                                    {{ $t('field') }}
                                                </th>
                                                <th id="value" class="text-left" style="width: 80%">
                                                    {{ $t('value') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(v, k) in item.old">
                                                <tr v-if="v" :key="k">
                                                    <td>{{ translate(k) }}</td>
                                                    <td>{{ formatValue(v) }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                        <div v-if="Object.keys(getChangedRecords(section)).length > 0">
                            <div v-for="(item, objId) in getChangedRecords(section)" :key="objId">
                                <v-card-subtitle class="mt-3">{{
                                    $t('changes_for_record_with_id', { id: objId })
                                }}</v-card-subtitle>
                                <v-simple-table dense class="diff-table">
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th id="key" class="text-left" style="width: 20%">{{ $t('field') }}</th>
                                                <th id="old" class="text-left" style="width: 40%">
                                                    {{ $t('old_value') }} <br />{{
                                                        data.a.status + ' (' + formatDate(data.a.created) + ')'
                                                    }}
                                                </th>
                                                <th id="new" class="text-left" style="width: 40%">
                                                    {{ $t('new_value') }} <br />
                                                    {{ data.b.status + ' (' + formatDate(data.b.created) + ')' }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="obj in item" :key="obj.key">
                                                <td>{{ getKey(obj.key) }}</td>
                                                <td v-html="beautifyValue(obj.old, obj.key)"></td>
                                                <td v-html="beautifyValue(obj.new, obj.key)"></td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </div>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">{{ $t('close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
export default {
    name: 'DiffDialog',

    props: {
        value: { type: Boolean, default: false },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },

    data() {
        return {
            dayjs: dayjs,
            sections: ['locationAddresses', 'buildings', 'documents', 'relationships']
        };
    },

    methods: {
        close() {
            this.$emit('input', false);
        },
        formatDate(date) {
            if (date) {
                return dayjs(date).format('DD.MM.YYYY HH:mm');
            }
            return '';
        },
        getKey(key) {
            let keys = key.split('/');
            let res = '';
            if (keys.length > 1) {
                for (const k of keys) {
                    if (!Number.isFinite(Number.parseInt(k, 10))) {
                        if (res.length > 1) {
                            res += ' → ';
                        }
                        res += this.translate(k);
                    }
                }
            }
            return res || this.translate(key);
        },
        getArrayObjectKey(key, title) {
            let keys = key.split(title + '/');
            if (keys.length === 2) {
                return this.$t(title) + ' ' + keys[1];
            }
            return '';
        },

        beautifyValue(value, key) {
            // is gis-check
            if (key.startsWith('gis_checks/checks/')) {
                if (value) {
                    return value.hit ? '<i class="fa-solid fa-check"></i>' : '<i class="fa-solid fa-xmark"></i>';
                }
                return '(kein Wert)';
            }
            if (key.startsWith('gis_checks')) {
                if (value) {
                    let res = '';
                    for (const check of value.checks) {
                        let hit = check.hit ? '<i class="fa-solid fa-check"></i>' : '<i class="fa-solid fa-xmark"></i>';
                        res += `<div>${hit} ${this.$t(check.id)}</div>`;
                    }
                    return res;
                }
                return '(kein Wert)';
            }

            return this.formatValue(value);
        },

        formatValue(value) {
            // value is a number (int/float)
            if (Number.isFinite(value)) {
                return value;
            }

            // value is null / undefined
            if (!value) {
                return '(kein Wert)';
            }

            // value is a date
            if (dayjs(value).isValid() && /^[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value)) {
                return dayjs(value).format('DD.MM.YYYY HH:mm');
            }

            return this.translate(value);
        },

        translate(value) {
            return this.$te(value) ? this.$t(value) : value;
        },

        beautifyBuilding(value) {
            if (value) {
                if (typeof value === 'string' || value instanceof String) {
                    return value;
                }
                let res = '';
                for (const v in value) {
                    res += `<div>
                            ${this.$te(v) ? this.$t(v) : v}: <b>${value[v]}</b>
                        </div>`;
                }
                return res;
            }
            return '(kein Wert)';
        },

        getChangedRecords(type) {
            let res = {};
            for (const rel of this.data.diff[type] || []) {
                let split = rel.key.split('/');
                if (split.length > 1) {
                    if (split[0] in res) {
                        res[split[0]].push(rel);
                    } else {
                        res[split[0]] = [rel];
                    }
                }
            }
            return res;
        },

        getDeletedRecords(type) {
            let res = [];
            for (const rel of this.data.diff[type] || []) {
                let split = rel.key.split('/');
                if (split.length === 1 && !rel.new && rel.old) {
                    res.push(rel);
                }
            }
            return res;
        },

        getNewRecords(type) {
            let res = [];
            for (const rel of this.data.diff[type] || []) {
                let split = rel.key.split('/');
                if (split.length === 1 && rel.new && !rel.old) {
                    res.push(rel);
                }
            }
            return res;
        }
    }
};
</script>
<style lang="scss" scoped>
.diff-table {
    tbody {
        vertical-align: top;
    }
    td {
        padding-top: 5px !important;
    }
}
</style>
