/**
 * Store module: mailbox
 *
 * Part of the eBau application
 * (c) Kaden und Partner AG
 */
import { Mailbox, MailboxCollection } from '@/models/Mailbox';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {},
    actions: {
        async fetchMailbox({ commit }, options = {}) {
            let list = new MailboxCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        async loadMailbox({ commit }, id) {
            let record = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    record = new Mailbox();
                } else {
                    record = new Mailbox({ id });
                    await record.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return record;
        }
    }
};
