<template>
    <v-skeleton-loader v-if="loading" type="list-item-avatar, list-item-three-line@3, actions"></v-skeleton-loader>
    <div v-else>
        <v-container fluid>
            <v-card fluid class="px-10">
                <v-card-title text-color="white">
                    {{ formTitle }}
                </v-card-title>

                <v-tabs v-model="activeTab">
                    <v-tab key="grunddaten">{{ $t('user') }}</v-tab>
                    <v-tab key="gemeinden">
                        {{ $t('gemeinden') }}
                    </v-tab>
                    <v-tab key="fachstellen">
                        {{ $t('fachstellen') }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab">
                    <v-tab-item key="grunddaten">
                        <v-card elevation="0">
                            <v-form v-model="valid" @input="$emit('validchange', valid)" @submit.stop="onSubmit">
                                <kp-textfield v-model="user.username" :label="$t('username')"></kp-textfield>
                                <kp-textfield
                                    v-model="user.email"
                                    type="text"
                                    :label="$t('email')"
                                    prepend-icon="fa-solid fa-at"
                                    :rules="[user.email !== '', formRules.email, formRules.maxLength(255)]"
                                />
                                <kp-textfield v-model="user.vorname" :label="$t('vorname')"></kp-textfield>
                                <kp-textfield v-model="user.nachname" :label="$t('nachname')"></kp-textfield>
                                <v-checkbox v-model="user.ist_admin" :label="$t('admin')"></v-checkbox>
                                <v-checkbox
                                    v-model="user.event_notification_per_mail"
                                    :label="$t('event_notification_per_mail')"
                                ></v-checkbox>
                            </v-form>
                            <v-card-actions>
                                <v-spacer />
                                <v-dialog width="400">
                                    <template #activator="{ on }">
                                        <v-btn
                                            color="action white--text"
                                            :disabled="loading"
                                            :loading="loading"
                                            v-on="on"
                                        >
                                            <v-icon class="mr-2">fa-solid fa-key</v-icon>
                                            {{ $t('passwort_setzen') }}
                                        </v-btn>
                                    </template>
                                    <template #default="dialog">
                                        <v-card>
                                            <v-card-text>
                                                <kp-textfield
                                                    v-model="newPw"
                                                    type="password"
                                                    :label="$t('new_password')"
                                                    :rules="[formRules.minLength(8), formRules.hasNumber]"
                                                />
                                                <kp-textfield
                                                    v-model="newPwConfirm"
                                                    type="password"
                                                    :label="$t('new_password_confirm')"
                                                    :error-messages="passwordsValid ? null : $t('pw_not_match')"
                                                />
                                            </v-card-text>
                                            <v-card-actions class="justify-end">
                                                <v-btn
                                                    color="action white--text"
                                                    type="submit"
                                                    :disabled="!passwordsValid || loading"
                                                    :loading="loading"
                                                    @click="onSubmitPw"
                                                >
                                                    <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                                                    {{ $t('save') }}
                                                </v-btn>
                                                <v-btn text @click="dialog.value = false">Close</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </template>
                                </v-dialog>

                                <v-btn
                                    color="action white--text"
                                    type="submit"
                                    :disabled="!valid || loading"
                                    :loading="loading"
                                    @click="onSubmit"
                                >
                                    <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                                    {{ $t('save') }}
                                </v-btn>
                                <v-btn color="decline" :disabled="loading" text @click="closeForm">
                                    {{ $t('close') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item key="gemeinden">
                        <v-card>
                            <base-remote-list
                                ref="userList"
                                session-key="user-stelle-list-remote"
                                :show-query="false"
                                :maintitle="$t('gemeinden')"
                                maintitle-icon-cls="fa-regular fa-building"
                                :collection-class="UserStelleCollection"
                                :headers="usHeaders"
                                :filters="usGemeindeFilters"
                                :options="{ sortBy: ['stelle_organisation_name'] }"
                            >
                                <template #after-query-field>
                                    <v-btn
                                        rounded
                                        class="hidden-xs-only ml-1"
                                        color="subtleHint"
                                        @click="onAddGemeinde"
                                    >
                                        <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                                        {{ $t('attach_gemeinde') }}
                                    </v-btn>
                                </template>
                                <template #item.status="{ item }">
                                    {{ $t(item.status) }}
                                </template>
                                <template #item.myactions="{ item }">
                                    <icon-btn
                                        icon-cls="fa-solid fa-link-slash"
                                        icon-color="orange"
                                        :tooltip="$t('delete')"
                                        @click="stelleToRemove = item"
                                    />
                                </template>
                            </base-remote-list>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item key="fachstellen">
                        <v-card>
                            <base-remote-list
                                ref="userList"
                                session-key="user-stelle-list-remote"
                                :show-query="false"
                                :maintitle="$t('fachstellen')"
                                maintitle-icon-cls="fa-regular fa-building"
                                :collection-class="UserStelleCollection"
                                :headers="usHeaders"
                                :filters="usFachstelleFilters"
                                :options="{ sortBy: ['stelle_organisation_name'] }"
                            >
                                <template #after-query-field>
                                    <v-btn
                                        rounded
                                        class="hidden-xs-only ml-1"
                                        color="subtleHint"
                                        @click="onAddFachstelle"
                                    >
                                        <v-icon left class="small-icon">fa-solid fa-plus fa-xs</v-icon>
                                        {{ $t('attach_fachstelle') }}
                                    </v-btn>
                                </template>
                                <template #item.status="{ item }">
                                    {{ $t(item.status) }}
                                </template>
                                <template #item.myactions="{ item }">
                                    <icon-btn
                                        icon-cls="fa-solid fa-link-slash"
                                        icon-color="orange"
                                        :tooltip="$t('delete')"
                                        @click="stelleToRemove = item"
                                    />
                                </template>
                            </base-remote-list>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
            <v-dialog v-model="showAddUserDlg" width="400">
                <v-card>
                    <v-card-text>
                        <v-form>
                            <StelleSelect @stelleselect="stelle_id = $event"></StelleSelect>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="action white--text" type="submit" :loading="loading" @click="onAddStelle">
                            <v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>
                            {{ $t('save') }}
                        </v-btn>
                        <v-btn text @click="showAddUserDlg = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <ConfirmDlg
                :value="confirmUnlinkStelle"
                :context="stelleToRemove ? String(stelleToRemove.id) : null"
                :message="$t('confirm_remove_user_from_stelle')"
                :title="$t('rec_remove')"
                @yes="removeStelleFromUser"
                @no="stelleToRemove = null"
            />
        </v-container>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { formRules } from '@/helpers/forms.js';
import { errToStr } from '@/helpers/errors.js';
import { instance as getApi } from '@/helpers/api';
import KpTextfield from '@/components/kp-textfield.vue';
import { UserStelleCollection } from '@/models/UserStelle';
import ConfirmDlg from '@/components/confirm_dialog.vue';
import StelleSelect from '@/components/stelle-select.vue';

export default {
    components: {
        KpTextfield,
        ConfirmDlg,
        StelleSelect
    },
    data() {
        return {
            user: Object,
            formRules,
            loading: false,
            valid: false,
            newPw: '',
            newPwConfirm: '',
            activeTab: null,
            UserStelleCollection,
            showAddUserDlg: false,
            stelle_id: null,
            stelleToRemove: null
        };
    },
    computed: {
        formTitle() {
            if (this.user && this.user.id) {
                return this.user.id + ', ' + this.user.username + ' (' + this.user.email + ')';
            }

            return this.$t('neuer_benutzer');
        },
        passwordsValid() {
            return this.newPw.length > 0 && this.newPw === this.newPwConfirm;
        },
        usHeaders() {
            return [
                {
                    text: this.$t('organisation'),
                    value: 'stelle_organisation_name',
                    sortable: true,
                    filterable: true,
                    width: '30%'
                },
                {
                    text: this.$t('status'),
                    value: 'status',
                    sortable: true,
                    filterable: true,
                    width: '20%'
                },
                {
                    text: '',
                    value: 'myactions',
                    sortable: true,
                    filterable: true,
                    width: '20%'
                }
            ];
        },
        usGemeindeFilters() {
            return [
                { id: 'user_id', property: 'user_id', value: this.user.id || -1 },
                { id: 'stelle_type', property: 'stelle_type', value: 'gemeinde' }
            ];
        },
        usFachstelleFilters() {
            return [
                { id: 'user_id', property: 'user_id', value: this.user.id || -1 },
                { id: 'stelle_type', property: 'stelle_type', value: 'fachstelle' }
            ];
        },
        confirmUnlinkStelle() {
            return this.stelleToRemove !== null;
        }
    },
    watch: {
        $route: function () {
            // We already are loaded, do nothing:
            if (this.user && this.user.id === Number(this.$route.params.id)) {
                return;
            }
            this.loadRecord(this.$route.params.id);
        }
    },
    async created() {
        await this.loadRecord(this.$route.params.id);
    },
    methods: {
        ...mapActions('user', ['loadUser', 'storeUser', 'destroyUser']),
        ...mapActions(['runAsync']),
        async loadRecord(id) {
            this.loading = true;
            try {
                this.user = await this.loadUser(id);
            } catch (e) {
                this.$toast({ message: e, color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        closeForm() {
            this.$router.push({ name: 'users' });
        },
        async onSubmit() {
            try {
                await this.storeUser(this.user);
                this.closeForm();
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        async onSubmitPw() {
            this.loading = true;
            this.runAsync(async () => {
                try {
                    await getApi().post('/auth/changePasswordAdmin', {
                        new_password: this.newPw,
                        user_id: this.user.id
                    });
                    this.$toast({ message: this.$t('pw_change_successful'), color: 'success' });
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    this.loading = false;
                }
            });
        },
        async onAddGemeinde() {
            this.showAddUserDlg = true;
        },
        async onAddFachstelle() {
            this.showAddUserDlg = true;
        },
        async onAddStelle() {
            try {
                await getApi().post('/stelle/addUser', {
                    user_id: this.user.id,
                    stelle_id: this.stelle_id
                });
            } catch (err) {
                console.error(err);
                this.$toast({ message: errToStr(err), color: 'error' });
            }
            this.showAddUserDlg = false;
            this.$refs.userList.reload();
        },
        async removeStelleFromUser() {
            let item = this.stelleToRemove;
            try {
                await getApi().post('/stelle/removeUser', {
                    id: item.id
                });
            } catch (err) {
                console.error(err);
                this.$toast({ message: errToStr(err), color: 'error' });
            }
            this.stelleToRemove = null;
            this.$refs.userList.reload();
        }
    }
};
</script>
