import '@/scss/main.scss';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import toast from '@/plugins/toast';
import './plugins/dayjs.js';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'fontawesome/css/all.css';
import { userInfo } from '@/helpers/globals';
import { i18nInit, loadLocaleStrings } from '@/i18n';

import BaseList from '@/components/base-list.vue';
import BaseRemoteList from '@/components/base-remote-list.vue';
import KpIcon from '@/components/kp-icon.vue';
import IconBtn from '@/components/IconBtn.vue';
import LightToolbar from '@/components/light_toolbar.vue';

import { storeCsrfToken } from '@/helpers/auth';
import api from '@/helpers/api';

import VueLayers from 'vuelayers';

Vue.config.productionTip = false;

if (window.appVersion !== localStorage.getItem('appVersion')) {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('appVersion', window.appVersion);
}

/** --------------- Define globally available Vue components --------------------- */
Vue.component('BaseList', BaseList);
Vue.component('BaseRemoteList', BaseRemoteList);
Vue.component('KpIcon', KpIcon);
Vue.component('IconBtn', IconBtn);
Vue.component('LightToolbar', LightToolbar);
/** --------------- END Define globally available Vue components --------------------- */

// we start async, as we have to load some things first:
const locale = userInfo().locale || 'de';

// load locale strings async here:
api.loadAuthInfo(window.apiRoot).then((authInfo) => {
    return new Promise((resolve) => {
        loadLocaleStrings(locale).then((localeStrings) => {
            storeCsrfToken(authInfo.csrfToken || '');
            api.init({ baseURL: window.apiRoot });

            // init store with initial data:
            const storeInst = store.init({
                session: { id: authInfo.sessionId },
                appVersion: window.appVersion || ''
            });

            if (authInfo.globals) {
                storeInst.commit('setGlobals', authInfo.globals || null);
            }
            if (authInfo.user && authInfo.user.id > 0) {
                storeInst.commit('user/setUser', authInfo.user);
            }

            Vue.use(toast, { store });
            Vue.use(VueLayers);
            new Vue({
                router,
                store: storeInst,
                vuetify,
                i18n: i18nInit(locale, localeStrings),
                render: (h) => h(App)
            }).$mount('#app');

            resolve();
        });
    });
});
