<template>
    <div class="pa-2">
        <v-row no-gutters>
            <v-col sm="6">
                <fileupload
                    :ppa="gesuch"
                    :contexts="['situationsplan_katasterplan']"
                    :parent-id="gesuch.id"
                    :readonly="readonly"
                    entity="baugesuch"
                    :min="1"
                    :max="1"
                    :showDeleted="gesuch.aktueller_status_ebau_value != 'new'"
                ></fileupload>
            </v-col>
            <v-col sm="6">
                <fileupload
                    :ppa="gesuch"
                    :contexts="['projektunterlagen']"
                    :parent-id="gesuch.id"
                    :readonly="readonly"
                    entity="baugesuch"
                    :min="1"
                    :max="2"
                    :showDeleted="gesuch.aktueller_status_ebau_value != 'new'"
                ></fileupload
            ></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="6">
                <fileupload
                    :ppa="gesuch"
                    :contexts="['energietechnische_nachweise']"
                    :parent-id="gesuch.id"
                    :readonly="readonly"
                    entity="baugesuch"
                    :min="0"
                    :max="0"
                    :showDeleted="gesuch.aktueller_status_ebau_value != 'new'"
                ></fileupload>
            </v-col>
            <v-col sm="6">
                <fileupload
                    :ppa="gesuch"
                    :contexts="['formulare']"
                    :parent-id="gesuch.id"
                    :readonly="readonly"
                    entity="baugesuch"
                    :min="0"
                    :max="0"
                    :showDeleted="gesuch.aktueller_status_ebau_value != 'new'"
                ></fileupload
            ></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="6">
                <fileupload
                    :ppa="gesuch"
                    :contexts="['shelter_control']"
                    :parent-id="gesuch.id"
                    :readonly="readonly"
                    entity="baugesuch"
                    :min="0"
                    :max="0"
                    :showDeleted="gesuch.aktueller_status_ebau_value != 'new'"
                ></fileupload>
            </v-col>
            <v-col sm="6">
                <fileupload
                    :ppa="gesuch"
                    :contexts="['ohne_kategorie']"
                    :parent-id="gesuch.id"
                    :readonly="readonly"
                    entity="baugesuch"
                    :min="0"
                    :max="0"
                    :showDeleted="gesuch.aktueller_status_ebau_value != 'new'"
                ></fileupload>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Fileupload from '@/components/fileupload';

export default {
    components: { Fileupload },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            record: this.gesuch
        };
    }
};
</script>
