<template>
    <v-select :value="value" :items="items ? items.models : []" :label="label" item-value="code" @input="onSelect">
        <template #item="{ item }">{{ getText(item) }}</template>
        <template #selection="{ item }">{{ getText(item) }}</template>
    </v-select>
</template>

<script>
import { mapActions } from 'vuex';
import { errToStr } from '@/helpers/errors';
export default {
    props: {
        value: { type: Number, default: null },
        echVariableName: { type: String, default: null },
        echDocId: { type: String, default: null },
        i18nKey: { type: String, default: null },
        label: { type: String, default: null },
        dependentEch: { type: Number, default: null }
    },
    data() {
        return {
            items: []
        };
    },
    watch: {
        dependentEch: {
            handler(val) {
                this.loadEchCodes();
            },
            deep: true
        }
    },
    created() {
        this.loadEchCodes();
    },
    methods: {
        ...mapActions('ech_code', ['fetchEchCodes']),
        async loadEchCodes() {
            try {
                this.items = await this.fetchEchCodes({
                    filter: [
                        { property: 'ech_doc_id', value: this.echDocId },
                        { property: 'ech_variable_name', value: this.echVariableName }
                    ],
                    dependent_ech: this.dependentEch
                });
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        onSelect(value) {
            this.$emit('input', value);
        },
        getText(item) {
            if (this.i18nKey) {
                let obj = {};
                obj[this.i18nKey] = item.i18n_index;
                return this.$t(item.i18n_key, obj);
            } else {
                return item.code + ' ' + this.$t(item.i18n_key);
            }
        }
    }
};
</script>

<style></style>
