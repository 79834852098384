/**
 * Dwelling entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Dwelling extends BaseModel {
    defaults() {
        return {
            id: null,
            building_entrance_id: null,
            administrative_dwelling_no: null,
            ewid: null,
            physical_dwelling_no: null,
            date_of_construction: null,
            no_of_habitable_rooms: null,
            floor: null,
            location_of_dwelling_on_floor: null,
            multiple_floor: null,
            usage_limitation: null,
            kitchen: null,
            surface_area_of_dwelling: null,
            status: null,
            dwelling_usage: null,
            dwelling_free_text_1: null,
            dwelling_free_text_2: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Dwelling';
    }
}

export class DwellingCollection extends BaseCollection {
    options() {
        return {
            model: Dwelling
        };
    }

    entityName() {
        return 'Dwelling';
    }
}
