/**
 * Planungsgeschaeft entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import {
    PlanningPermissionApplication,
    PlanningPermissionApplicationCollection
} from './PlanningPermissionApplication';
import { ECH_STATUS } from '@/models/Status.js';

export class Planungsgeschaeft extends PlanningPermissionApplication {
    defaults() {
        return {
            ...super.defaults(),
            type: 'pg'
        };
    }

    mutations() {
        return {
            ...super.mutations()
        };
    }

    entityName() {
        return 'Planungsgeschaeft';
    }

    isDeleteAllowed(user) {
        if (this.aktueller_status_ebau_value !== ECH_STATUS.STATUS_NEW) {
            return false;
        }

        if (user.isRoleAdmin) {
            return true;
        }

        if (this.deleted) {
            return false;
        }

        if (user.isRoleGemeinde && user.isInGemeinde(this.stelle_id)) {
            return true;
        }

        return false;
    }
}

export class PlanungsgeschaeftCollection extends PlanningPermissionApplicationCollection {
    options() {
        return {
            model: Planungsgeschaeft
        };
    }

    entityName() {
        return 'Planungsgeschaeft';
    }
}
