<template>
    <div>
        <v-row no-gutters>
            <v-col sm="4">
                <strong>{{ $t('stammdaten').toUpperCase() }}</strong>
            </v-col>
            <v-col sm="2"> {{ $t('id').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ planungsgeschaeft.id }} </v-col>
            <v-col sm="2"> {{ $t('gesuchstyp').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ planungsgeschaeft.aktueller_status }} </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="2"> {{ $t('status').toUpperCase() }} </v-col>
            <v-col sm="2">{{ planungsgeschaeft.business_type }} </v-col>
            <v-col sm="2"> {{ $t('gemeinde').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ planungsgeschaeft.stelle }} </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="2"> {{ $t('pg_nummer_kanton').toUpperCase() }} </v-col>
            <v-col sm="2"> {{ planungsgeschaeft.boa_bgnumber || '-' }} </v-col>
        </v-row>
        <hr />
        <v-row v-if="!readonly" no-gutters>
            <v-col sm="4">
                <strong>{{ $t('aktionen').toUpperCase() }}</strong>
            </v-col>
            <v-col sm="8">
                <strong>{{ $t('formular_weiterbearbeiten') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('formular_weiterbearbeiten_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn :to="{ name: 'pg_edit_beteiligte' }" outlined>{{
                    $t('formular_bearbeiten').toUpperCase()
                }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!readonly" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong> {{ $t('weitere_personen_freischalten') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                {{ $t('weitere_personen_freischalten_description') }}
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn :to="{ name: 'pg_berechtigungen' }" outlined>{{
                    $t('berechtigungen_verwalten').toUpperCase()
                }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!readonly" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong> {{ $t('dossier_einreichen') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('dossier_einreichen_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn outlined :to="{ name: 'pg_edit_abschluss' }">{{ $t('dossier_einreichen').toUpperCase() }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!readonly" no-gutters>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <strong>{{ $t('dossier_loeschen') }}</strong>
            </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8"> {{ $t('dossier_loeschen_description') }} </v-col>
            <v-col sm="4"> &nbsp; </v-col>
            <v-col sm="8">
                <v-btn :disabeled="isDeleteAllowed" outlined @click="onDelete">{{ $t('dossier_loeschen') }}</v-btn>
            </v-col>
        </v-row>
        <hr />
        <v-row no-gutters>
            <v-col sm="4">
                <strong>{{ $t('rueckmeldungen').toUpperCase() }}</strong>
            </v-col>
            <v-col sm="8">
                {{ $t('rueckmeldungen_description') }}
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { errToStr } from '@/helpers/errors';
import { mapActions } from 'vuex';

export default {
    components: {},
    props: {
        planungsgeschaeft: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false
        };
    },
    methods: {
        ...mapActions(['runAsync']),

        onDelete() {
            this.delete(this.planungsgeschaeft);
        },

        isDeleteAllowed() {
            if (typeof this.planungsgeschaeft.isDeleteAllowed === 'function') {
                return this.planungsgeschaeft.isDeleteAllowed(this.user);
            } else {
                return true;
            }
        },

        async delete(planungsgeschaeft) {
            if (planungsgeschaeft) {
                try {
                    this.loading = true;
                    await this.runAsync(() => planungsgeschaeft.delete());
                    this.$router.push({ name: 'planungsgeschaefte' });
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                    planungsgeschaeft.reset();
                } finally {
                    this.loading = false;
                }
            }
        }
    }
};
</script>
