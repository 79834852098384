import axios from 'axios';

export async function getTgLayers() {
    const instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common.accept = 'application/json';
    let res = await instance.get('https://map.geo.tg.ch/services/geofy_chsdi3/services');
    if (res && res.data) {
        return res.data;
    }
    return {};
}

export async function getSubLayers(topic) {
    const instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common.accept = 'application/json';
    let res = await instance.get(
        'https://map.geo.tg.ch/services/geofy_chsdi3/rest/services/' + topic + '/CatalogServer'
    );
    if (res && res.data) {
        return res.data;
    }
    return {};
}
