/**
 * Country entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class AdditionalBaugesuchData extends BaseModel {
    defaults() {
        return {
            id: null,
            planning_permission_application_id: null,
            construction_project_type: null,
            construction_project_type_other_description: null,
            requested_connections: [],
            connected_electrical_load: null,
            indicator_type: null,
            indicator_value: null,
            property_area: null,
            building_volume_norm: null,
            building_volume: null,
            construction_mass_indicator: null,
            civil_defence_shelter_required: null,
            civil_defence_comments: null,
            civil_defence_shelter_replacement_contribution: null,
            civil_defence_shelter_create_mandatory_shelter: null,
            civil_defence_shelter_create_public_shelter: null,
            water_protection_op_waste_water_disposal: null,
            water_protection_roofwater_leachate: null,
            water_protection_pollution: null,
            water_protection_pollution_kind: null,
            water_protection_pollution_amount: null,
            water_protection_pollution_unit: null,
            heizung_bau_vorgesehen: false,
            heizung_geraete_vorgesehen: false,
            arbeitnehmende_beschaeftigt: false,
            arbeitnehmende_beschaeftigt_verordnung_4: false,
            duration_of_construction_phase: null,
            project_start_date: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            planning_permission_application_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'AdditionalBaugesuchData';
    }
}

export class AdditionalBaugesuchDataCollection extends BaseCollection {
    options() {
        return {
            model: AdditionalBaugesuchData
        };
    }

    entityName() {
        return 'AdditionalBaugesuchData';
    }
}
