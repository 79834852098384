<template>
    <v-app-bar :app="app" color="white" class="kp-header" clipped-left height="90px">
        <v-container fluid>
            <v-row justify="space-between" align="end">
                <img :src="tgImg" height="45" alt="Thurgau-Logo" class="ml-2 mr-3 hidden-sm-and-up" />
                <v-col class="hidden-xs-only">
                    <div class="d-none d-sm-block" style="min-width: 340px">
                        <h1 class="title">eBau / ePlan</h1>
                        <div class="subtitle">
                            {{ $t('k_tg') }}
                        </div>
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="auto" style="text-align: end">
                    <slot></slot>
                </v-col>
                <img :src="tgImg" height="45" alt="Thurgau-Logo" class="mr-3 mb-2 hidden-sm-and-down" />
            </v-row>
            <v-row>
                <v-col cols="12" style="height: 24px" class="tg-horizonte">&nbsp;</v-col>
            </v-row>
        </v-container>
        <template #extension>
            <v-tabs show-arrows icons-and-text class="topnav">
                <v-tab :to="{ name: 'home' }" exact class="py-1">
                    <span class="d-none d-sm-flex">
                        {{ $t('home') }}
                    </span>
                    <v-icon dense>fa-regular fa-home fa-xs</v-icon>
                </v-tab>
                <v-tab :to="{ name: 'baugesuche' }" class="py-1">
                    <span class="d-none d-sm-flex">
                        {{ $t('baugesuche') }}
                    </span>
                    <v-icon dense>fa-regular fa-house-building fa-xs</v-icon>
                </v-tab>
                <v-tab
                    v-if="user.isRoleGemeinde || user.isRoleAdmin || user.count_pg_acls > 0"
                    :to="{ name: 'planungsgeschaefte' }"
                    class="py-1"
                >
                    <span class="d-none d-sm-flex">
                        {{ $t('planungsgeschaefte') }}
                    </span>
                    <v-icon dense>fa-regular fa-map fa-xs</v-icon>
                </v-tab>
                <v-tab :to="{ name: 'stelle' }" class="py-1">
                    <span class="d-none d-sm-flex">
                        {{ $t('behoerdenverzeichnis') }}
                    </span>
                    <v-icon dense>fa-regular fa-building fa-xs</v-icon>
                </v-tab>
                <v-tab v-if="user.isRoleAdmin" :to="{ name: 'users' }" class="py-1">
                    <span class="d-none d-sm-flex"> {{ $t('users') }}</span>
                    <v-icon dense>fa-regular fa-user fa-xs</v-icon>
                </v-tab>
                <v-tab v-if="user.isRoleAdmin" :to="{ name: 'admin_mail' }" class="py-1">
                    <span class="d-none d-sm-flex"> {{ $t('system') }}</span>
                    <v-icon dense>fa-regular fa-gear fa-xs</v-icon>
                </v-tab>
            </v-tabs>
        </template>
    </v-app-bar>
</template>

<script>
import { TgLogo, TgHorizont } from '@/helpers/images.js';
import { mapState } from 'vuex';

export default {
    props: {
        app: {
            type: Boolean,
            default: false
        },
        teamrequests: {
            type: Array,
            default: () => []
        }
    },
    data: function () {
        return {
            tgImg: TgLogo,
            tgHorizont: TgHorizont
        };
    },
    computed: {
        ...mapState('user', {
            user: 'record'
        })
    }
};
</script>

<style lang="scss">
.kp-header {
    .topnav {
        border-bottom: 1px solid #ccc;

        .v-tab {
            font-size: 16px;
            &:not(.v-tab--active) {
                color: black !important;
            }
        }
    }

    .v-toolbar__content {
        padding: 0 !important;
    }
    .tg-horizonte {
        background-image: url(~@/assets/horizont.png);
        background-repeat: repeat-x;
        background-size: 24px;
    }
}
</style>
