<template>
    <Comment
        :planning-permission-application="planungsgeschaeft"
        :stelle="stelle"
        :readonly="readonly"
        :user="user"
    ></Comment>
</template>

<script>
import Comment from '@/components/comment.vue';
export default {
    components: {
        Comment
    },
    props: {
        planungsgeschaeft: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    }
};
</script>
