<template>
    <div>
        <div class="tg-horizont">
            <v-container>
                <v-row justify="center" align="end">
                    <v-col cols="8">
                        <h1>{{ $t('welcome') }}</h1>
                    </v-col>
                    <v-col cols="4">
                        <v-img contain :src="TgLogo" height="60" />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="welcome-container">
            <v-row class="pt-4" justify="center">
                <v-col cols="12">
                    <v-card class="trans-bg mx-auto" max-width="1180px">
                        <v-card-title>{{ $t('login') }}</v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn height="80px" color="action white--text" :to="{ name: 'login' }"
                                >{{ $t('login') }} (old school)</v-btn
                            >
                            <v-btn height="80px" color="action white--text" @click="oidcLogin"
                                >{{ $t('login') }} (OIDC)</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="pt-4" justify="center">
                <v-col cols="12">
                    <v-card class="trans-bg mx-auto" max-width="1180px">
                        <v-card-title>
                            <v-icon left color="orange">fa-solid fa-triangle-exclamation</v-icon>
                            {{ $t('actual_news') }}
                        </v-card-title>
                        <v-card-text>
                            <news />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="pt-4" justify="center">
                <v-col cols="12">
                    <v-card class="trans-bg mx-auto" max-width="1180px">
                        <v-card-title> Verfügbare {{ $t('gemeinden') }} </v-card-title>
                        <v-card-text>
                            <gemeinden />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import news from '@/components/news.vue';
import gemeinden from '@/components/gemeinden.vue';
import { WelcomeTg, WelcomeTitleTg, TgLogo } from '@/helpers/images.js';
import { apiUrl, appUrl } from '@/helpers/globals';

export default {
    name: 'WelcomeView',
    components: {
        news,
        gemeinden
    },
    data: function () {
        return {
            WelcomeTg,
            WelcomeTitleTg,
            TgLogo
        };
    },
    methods: {
        oidcLogin() {
            document.location.href = apiUrl('/oidc/login') + '?url=' + appUrl('/');
        }
    }
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 16pt;
}
.img-rights {
    font-size: 10pt;
    color: #333;
}
.news {
    h2 {
        font-size: 1.2em;
        color: #333;
    }
}
.tg-horizont {
    background-color: white;
    padding-bottom: 70px !important;
    box-shadow: inset 0 -30px 0 #4ab1eb, inset 0px -37px 0px #00a4e8, inset 0px -44px 0px #72a536,
        inset 0px -56px 0px #adc427, inset 0px -63px 0px #ffec00;
}
</style>
