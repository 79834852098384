/**
 * Document entity model class
 *
 * This file is part of eBau
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Document extends BaseModel {
    defaults() {
        return {
            id: null,
            path: null,
            filename: null,
            parent_entity: null,
            parent_id: null,
            parent_context: null,
            created: null,
            creator_id: null,
            mime: null,
            size: null,
            external_id: null,
            source: null,
            decision_ruling_id: null,
            deleted: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            parent_id: (id) => (Number(id) > 0 ? Number(id) : null),
            creator_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'Document';
    }
}

export class DocumentCollection extends BaseCollection {
    options() {
        return {
            model: Document
        };
    }

    entityName() {
        return 'Document';
    }
}
