<template>
    <div class="pa-2">
        <v-row no-gutters>
            <anstoesser-grid
                v-if="record && record.aktueller_status_ebau_value !== 'new' && !anstoesser"
                :anstoesser="anstoesser"
                :planning-permission-application-id="record.id"
            ></anstoesser-grid>
            <div v-else>{{ $t('neighbours_visible_after_commit') }}</div>
        </v-row>
        <v-row class="ma-2">
            <v-btn :loading="loading" @click="generateAnstoesser">{{ $t('anstoesser_generieren') }}</v-btn>
        </v-row>
    </div>
</template>

<script>
import { instance as getApi } from '@/helpers/api';
import { errToStr } from '@/helpers/errors.js';
import AnstoesserGrid from '@/components/anstoesser-grid.vue';

export default {
    components: { AnstoesserGrid },
    props: {
        gesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            record: this.gesuch,
            anstoesser: null,
            reload: false,
            loading: false
        };
    },
    async mounted() {
        this.getAnstoesser();
    },
    methods: {
        async getAnstoesser() {
            let res = await getApi().post('/Realestate/getAnstoesser', {
                baugesuch_id: this.gesuch.id
            });
            if (res.data && res.data.records) {
                this.anstoesser = res.data.records;
            }
        },

        async generateAnstoesser(item) {
            this.loading = true;
            try {
                await getApi().post('/Realestate/createAnstoesser', {
                    baugesuch_id: this.gesuch.id
                });
                this.getAnstoesser();
            } catch (err) {
                this.$toast({ message: errToStr(err), color: 'error' });
            }
            this.reload = true;
            this.loading = false;
        }
    }
};
</script>
