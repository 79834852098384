<template>
    <v-app id="auth-app">
        <AppHeaderBarTG app @navbtn="drawer = !drawer">
            <v-spacer />
            <UserMenu
                class="align-end"
                :benutzer="benutzer"
                :text="getUserInfo()"
                @logout="logout"
                @roleChange="showroledlg = true"
                @emailChange="onEmailChange"
            />
        </AppHeaderBarTG>

        <v-main class="white tg-padding">
            <v-fade-transition mode="out-in">
                <router-view />
            </v-fade-transition>
        </v-main>

        <v-dialog v-model="showroledlg" persistent max-width="600px">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row>{{ $t('bestaetigen_rolle_wechsel') }}</v-row>
                        <v-row>
                            <v-select
                                :items="benutzerRoles"
                                :value="activeRole"
                                dense
                                @change="onRoleChange"
                            ></v-select>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showroledlg = false">
                        {{ $t('cancel') }}
                    </v-btn></v-card-actions
                >
            </v-card>
        </v-dialog>

        <v-overlay :value="loading">
            <v-container fluid fill-height>
                <v-layout justify-center align-center>
                    <v-progress-circular indeterminate color="primary" :size="70" :width="7"> </v-progress-circular>
                </v-layout>
            </v-container>
        </v-overlay>

        <KpFooterTG app :loading="loading" />
    </v-app>
</template>

<script>
import AppHeaderBarTG from '@/components/app-header-bar.vue';
import KpFooterTG from '@/components/app-footer.vue';
import UserMenu from '@/components/user-menu-btn.vue';
import { errToStr } from '@/helpers/errors.js';

// import MainNavDrawer from 'js/components/main-nav-drawer.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        AppHeaderBarTG,
        KpFooterTG,
        UserMenu
    },
    data: function () {
        return {
            drawer: null,
            welcomeDlg: false,
            profileMsg: null,
            showHelp: false,
            showroledlg: false
        };
    },
    computed: {
        loading() {
            return this.$store.state.loading;
        },
        benutzer() {
            return this.$store.state.user.record;
        },
        benutzerRoles() {
            let roles = [];
            for (var i = 0; i < this.benutzer.roles.length; i++) {
                let value = this.benutzer.roles[i];
                let text = this.$t('rolle_' + value);
                let gemeinden = this.benutzer.gemeinden ? this.benutzer.gemeinden.split(', ') : [];
                let fachstellen = this.benutzer.fachstellen ? this.benutzer.fachstellen.split(', ') : [];
                switch (value) {
                    case 'fachstelle':
                        for (let j = 0; j < fachstellen.length; j++) {
                            roles.push({
                                text: text + ' (' + fachstellen[j] + ')',
                                value: value + '::' + this.benutzer.fachstellen_ids[j]
                            });
                        }
                        break;
                    case 'gemeinde':
                        for (let j = 0; j < gemeinden.length; j++) {
                            roles.push({
                                text: text + ' (' + gemeinden[j] + ')',
                                value: value + '::' + this.benutzer.gemeinden_ids[j]
                            });
                        }
                        break;
                    default:
                        roles.push({
                            text,
                            value
                        });
                }
            }

            return roles;
        },
        activeRole() {
            let role = this.benutzer.active_role;
            switch (role) {
                case 'fachstelle':
                    role = 'fachstelle::' + this.benutzer.active_role_stelle_id;
                    break;
                case 'gemeinde':
                    role = 'gemeinde::' + this.benutzer.active_role_stelle_id;
                    break;
                default:
            }
            return role;
        }
    },
    methods: {
        ...mapActions('user', ['logout', 'storeUser']),

        async onRoleChange(val) {
            let oldVal = this.benutzer.active_role;
            try {
                if (val.split('::').length === 2) {
                    this.benutzer.active_role = val.split('::')[0];
                    this.benutzer.active_role_stelle_id = val.split('::')[1];
                } else {
                    this.benutzer.active_role = val;
                }

                await this.storeUser(this.benutzer);
                this.showroledlg = false;
                window.location.href = this.$store.state.globals.frontend_base;
            } catch (e) {
                this.benutzer.active_role = oldVal;
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },

        async onEmailChange(value) {
            this.benutzer.event_notification_per_mail = value;
            await this.storeUser(this.benutzer);
        },

        getUserInfo() {
            let text = this.benutzer.name;
            if (this.benutzer.isRoleGemeinde || this.benutzer.isRoleFachstelle) {
                text += ' - ' + this.benutzer.active_role_stelle;
            }
            if (this.benutzer.isRoleAdmin) {
                text += ' - Admin';
            }
            return text;
        }
    }
};
</script>

<style lang="scss" scoped>
.tg-padding {
    // padding-top: 0 !important;
}
</style>
