<template>
    <v-tooltip
        v-model="showtooltip"
        :top="top"
        :left="left"
        :bottom="bottom"
        :right="right"
        max-width="500"
        eager
        @click="showtooltip = false"
    >
        <template #activator="{ attrs }">
            <v-icon
                v-click-outside="() => (showtooltip = false)"
                dense
                v-bind="attrs"
                style="color: rgb(0, 34, 186)"
                @click="showtooltip = !showtooltip"
            >
                fa-regular fa-circle-info fa-xs
            </v-icon>
        </template>
        <div v-html="text"></div>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        text: { type: String, default: null },
        top: { type: Boolean, default: false },
        bottom: { type: Boolean, default: false },
        left: { type: Boolean, default: false },
        right: { type: Boolean, default: false }
    },
    data: () => ({
        showtooltip: false
    }),
    methods: {
        onClick() {
            alert('Clicked!');
        }
    }
};
</script>
<style lang="sass" scoped>
.v-tooltip__content
    pointer-events: auto !important
    opacity: 1 !important
    background-color: rgb(65, 190, 238) !important
</style>
