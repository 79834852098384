<template>
    <v-container>
        <v-row class="pt-5">
            <v-col cols="3">
                <v-btn
                    v-if="!readonly"
                    color="blue"
                    :loading="buildingInfosLoading"
                    @click="showReloadBuildingWarning"
                    >{{ $t('reload_buildings') }}</v-btn
                >
            </v-col>
            <v-col cols="2">
                <v-btn v-if="!readonly" color="yellow" :loading="buildingInfosLoading" @click="addNewBuilding">{{
                    $t('new_building')
                }}</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-expansion-panels>
                <v-expansion-panel v-for="b in buildings" :key="b.id">
                    <v-expansion-panel-header>{{ $t('building') }} EGID: {{ b.egid }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card elevation="0" class="pb-2">
                            <v-card-text>
                                <v-row>
                                    <v-col sm="4">{{ $t('building_category') }}</v-col>
                                    <v-col sm="6">{{ $t(b.building_category_text) }}</v-col>
                                    <v-col sm="2" class="text-right">
                                        <v-btn v-if="!readonly" icon small @click.stop="editBuilding(b)">
                                            <v-icon small>fa-solid fa-pen</v-icon>
                                        </v-btn>
                                        <v-btn v-if="!readonly" icon small color="red" @click.stop="deleteBuilding(b)">
                                            <v-icon small>fa-solid fa-trash</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col sm="4">{{ $t('number_of_floors') }}</v-col>
                                    <v-col sm="8">{{ b.number_of_floors }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2">
                                        <v-btn
                                            v-if="!readonly"
                                            color="yellow"
                                            :loading="buildingInfosLoading"
                                            @click="addNewEntrance(b)"
                                            >{{ $t('new_entrance') }}</v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-expansion-panels>
                            <template v-for="e in b.children">
                                <v-expansion-panel :key="e.id">
                                    <v-expansion-panel-header v-if="e.children.length > 0"
                                        >{{ $t('dwellings') }} {{ e.street_name }}
                                        {{ e.building_entrance_no }}</v-expansion-panel-header
                                    >
                                    <v-expansion-panel-header v-else
                                        >{{ e.street_name }} {{ e.building_entrance_no }} ({{
                                            $t('no_dwellings')
                                        }})</v-expansion-panel-header
                                    >
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col cols="2">
                                                <v-btn
                                                    v-if="!readonly"
                                                    color="yellow"
                                                    :loading="buildingInfosLoading"
                                                    @click="addNewDwelling(e)"
                                                    >{{ $t('new_dwelling') }}</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="d in e.children" :key="d.id">
                                            <v-col>
                                                <v-container>
                                                    <v-row>
                                                        <v-col sm="10">
                                                            <v-card elevation="0">
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col sm="4">{{ $t('ewid_long') }}</v-col>
                                                                        <v-col sm="6">{{ d.ewid }}</v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col sm="4">{{ $t('floor') }}</v-col>
                                                                        <v-col sm="8">{{
                                                                            getFloorText(d.floor)
                                                                        }}</v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col sm="4">{{
                                                                            $t('no_of_habitable_rooms')
                                                                        }}</v-col>
                                                                        <v-col sm="8">{{
                                                                            d.no_of_habitable_rooms
                                                                        }}</v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col sm="4">{{
                                                                            $t('surface_area_of_dwelling')
                                                                        }}</v-col>
                                                                        <v-col sm="8"
                                                                            >{{ d.surface_area_of_dwelling }} m²</v-col
                                                                        >
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col sm="2" class="text-right">
                                                            <v-btn
                                                                v-if="!readonly"
                                                                icon
                                                                small
                                                                @click.stop="editDwelling(e, d)"
                                                            >
                                                                <v-icon small>fa-solid fa-pen</v-icon>
                                                            </v-btn>
                                                            <v-btn
                                                                v-if="!readonly"
                                                                icon
                                                                small
                                                                color="red"
                                                                @click.stop="deleteDwelling(d)"
                                                            >
                                                                <v-icon small>fa-solid fa-trash</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                <v-divider />
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <confirm-dialog
                v-if="showConfirmDelDialog"
                v-model="showConfirmDelDialog"
                persistent
                :title="$t('confirm')"
                :message="$t('confirm_rec_delete')"
                @yes="confirmDestroy"
            />
            <building-edit-dlg
                v-if="showBuildingEditDlg"
                v-model="showBuildingEditDlg"
                :building="toEditBuilding"
                @cancel="showBuildingEditDlg = false"
                @save="saveBuilding"
            />
            <dwelling-edit-dlg
                v-if="showDwellingEditDlg"
                v-model="showDwellingEditDlg"
                :dwelling="toEditDwelling"
                :entrance="toEditEntrance"
                @cancel="showDwellingEditDlg = false"
                @save="saveDwelling"
            />
            <confirm-dialog
                v-if="showConfirmDlg"
                v-model="showConfirmDlg"
                persistent
                :title="$t('attention')"
                :message="$t('confirm_load_buildings')"
                @yes="loadBuildingInfos"
            />
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDialog from '@/components/confirm_dialog.vue';
import { Building } from '@/models/Building';
import { BuildingEntrance } from '@/models/BuildingEntrance';
import { Dwelling } from '@/models/Dwelling';
import { errToStr } from '@/helpers/errors';
import BuildingEditDlg from '@/components/building-edit-dlg.vue';
import DwellingEditDlg from '@/components/dwelling-edit-dlg.vue';
export default {
    components: {
        'confirm-dialog': ConfirmDialog,
        'building-edit-dlg': BuildingEditDlg,
        'dwelling-edit-dlg': DwellingEditDlg
    },
    props: {
        gesuch: { type: Object },
        readonly: { type: Boolean }
    },
    data: () => ({
        buildingInfosLoading: false,
        showConfirmDelDialog: false,
        showBuildingEditDlg: false,
        showDwellingEditDlg: false,
        showConfirmDlg: false,
        toDeleteBuilding: null,
        toDeleteDwelling: null,
        buildings: [],
        toEditBuilding: null,
        toEditEntrance: null,
        toEditDwelling: null,
        floors: null
    }),

    created() {
        this.loadBuildingTree();
        this.loadEchCodes();
    },

    methods: {
        ...mapActions('building', ['getBuildingTree', 'destroyBuilding', 'storeBuilding', 'createBuildingTree']),
        ...mapActions('dwelling', ['destroyDwelling', 'storeDwelling']),
        ...mapActions('building_entrance', ['destroyBuildingEntrance', 'storeBuildingEntrance']),
        ...mapActions('ech_code', ['fetchEchCodesByVariable']),

        async loadEchCodes() {
            try {
                this.floors = await this.fetchEchCodesByVariable({
                    ech_doc_id: 'eCH-0129',
                    ech_variable_name: 'floor'
                });
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        async loadBuildingTree() {
            this.buildings = await this.getBuildingTree({
                planning_permission_application_id: this.gesuch.id
            });
        },
        addNewBuilding() {
            this.showBuildingEditDlg = true;
            this.toEditBuilding = new Building({
                planning_permission_application_id: this.gesuch.id
            });
        },
        editBuilding(building) {
            this.showBuildingEditDlg = true;
            this.toEditBuilding = new Building(building);
        },
        deleteBuilding(building) {
            this.toDeleteBuilding = new Building(building);
            this.showConfirmDelDialog = true;
        },
        addNewDwelling(entrance) {
            this.showDwellingEditDlg = true;
            this.toEditEntrance = new BuildingEntrance(entrance);
            this.toEditDwelling = new Dwelling({
                building_entrance_id: entrance.id
            });
        },
        async addNewEntrance(building) {
            let building_entrance = new BuildingEntrance({
                building_id: building.id
            });

            await this.storeBuildingEntrance(building_entrance);

            this.showDwellingEditDlg = true;
            this.toEditEntrance = building_entrance;
            this.toEditDwelling = new Dwelling({
                building_entrance_id: building_entrance.id
            });
        },
        editDwelling(entrance, dwelling) {
            this.showDwellingEditDlg = true;
            this.toEditDwelling = new Dwelling(dwelling);
            this.toEditEntrance = new BuildingEntrance(entrance);
        },
        deleteDwelling(dwelling) {
            this.toDeleteDwelling = new Dwelling(dwelling);
            this.showConfirmDelDialog = true;
        },
        async confirmDestroy() {
            if (this.toDeleteBuilding) {
                this.loading = true;
                try {
                    await this.destroyBuilding(this.toDeleteBuilding);
                    this.loadBuildingTree();
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    this.toDeleteBuilding = null;
                    this.loading = false;
                }
            }
            if (this.toDeleteDwelling) {
                this.loading = true;
                try {
                    await this.destroyDwelling(this.toDeleteDwelling);
                    this.loadBuildingTree();
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    this.toDeleteDwelling = null;
                    this.loading = false;
                }
            }
        },
        async saveDwelling(dwelling) {
            this.showDwellingEditDlg = false;
            await this.storeDwelling(dwelling);
            this.toEditDwelling = null;
            this.loadBuildingTree();
        },
        async saveBuilding(building) {
            this.showBuildingEditDlg = false;
            await this.storeBuilding(building);
            this.toEditBuilding = null;
            this.loadBuildingTree();
        },
        getFloorText(floorCode) {
            if (this.floors && floorCode) {
                let f = this.floors.find({ code: floorCode });
                if (f) {
                    return this.$t(f.i18n_key, { floorNo: f.i18n_index });
                }
            }
            return '';
        },
        showReloadBuildingWarning() {
            if (this.buildings.length > 0) {
                this.showConfirmDlg = true;
            } else {
                this.loadBuildingInfos();
            }
        },
        async loadBuildingInfos() {
            this.buildingInfosLoading = true;
            await this.createBuildingTree({
                planning_permission_application_id: this.gesuch.id
            });
            await this.loadBuildingTree();
            this.buildingInfosLoading = false;
        }
    }
};
</script>

<style></style>
