<template>
    <AccessControlList
        :planning-permission-application="baugesuch"
        :readonly="readonly"
        :user="user"
    ></AccessControlList>
</template>

<script>
import AccessControlList from '@/components/access-control/access-control-list';
export default {
    components: {
        AccessControlList
    },
    props: {
        baugesuch: { type: Object },
        stelle: { type: Object },
        readonly: { type: Boolean },
        user: { type: Object }
    },
    data() {
        return {
            loading: false,
            valid: false
        };
    }
};
</script>
